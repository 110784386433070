<template>
  <div 
    class="card-c"
    :style="[
      cardFontColor ?
        {color: cardFontColor} 
      : '',
      cardFontSize ?
        {fontSize: cardFontSize} 
      : ''
    ]"
    >
    <span
      v-if="displayType === 'text'"
    >
      {{value}}
    </span>
    <img
      draggable="false" 
      v-else
      :src="assetMediaImageUrl + image"
    >
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'CardC',
  props: [
    "cardFontColor",
    "cardFontSize",
    "value",
    "displaySource",
    "displayType",
    "cardRelation",
    "assetMediaImageUrl",
  ],
  data () {
    return {
      image: ''
    }
  },
  created: function() {
    if (this.displayType == "image") {
      for (let index = 0; index < this.cardRelation.length; index++) {
        if (this.cardRelation[index][0] === this.value) {
          this.image = this.cardRelation[index][1][this.displaySource];
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.card-c {
  pointer-events: none;
  font-size: 45px;
  z-index: 1;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  
  * {
    pointer-events: none;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
