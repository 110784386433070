var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ordo-layout-a"},[(_vm.cardQuestionSource)?_c('div',{staticClass:"ordo-content-question",style:([
    _vm.cardQuestionWidth ?
      {width: _vm.cardQuestionWidth}
    : '',
    _vm.cardQuestionHeight ?
      {height: _vm.cardQuestionHeight}
    : '',
    _vm.cardQuestionBackgroundColor ?
      {backgroundColor: _vm.cardQuestionBackgroundColor}
    : '',
    _vm.cardQuestionBottomMargin ? 
      {marginBottom: _vm.cardQuestionBottomMargin}
    : '' ])},[_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],style:([
      _vm.cardQuestionPaddingWidth ?
        {maxWidth: 'calc(100% - ' + _vm.cardQuestionPaddingWidth + 'px)' }
      : '',
      _vm.cardQuestionPaddingHeight ?
        {maxHeight: 'calc(100% - ' + _vm.cardQuestionPaddingHeight + 'px)' }
      : '' ]),attrs:{"draggable":"false","src":_vm.assetMediaImageUrl + _vm.cardQuestionSourceValue}})]):_vm._e(),_c('div',{staticClass:"ordo-content-cards"},[_c('div',{staticClass:"left-space",style:([
        (_vm.hideAudioAndNotify) ?
          {opacity: 0, transition: 'all 0.3s ease-out'}
        : {opacity: 1} ])},[(_vm.themeName && _vm.isShowCardAudioButton && _vm.cardAudioType === 'mp3' && _vm.isShowAudio && _vm.elements.length)?_c('AudioA',{attrs:{"messageAudioFile":_vm.elements.length ? _vm.elements[0].element[_vm.cardAudioSource] : '',"classAdded":"content-audio","isDataSound":true,"assetMediaAudioUrl":_vm.assetMediaAudioUrl}}):(_vm.themeName && _vm.isShowCardAudioButton && _vm.isShowAudio && _vm.elements.length)?_c('AudioB',{attrs:{"classAdded":"content-audio","correctWord":_vm.elements.length ? _vm.elements[0].element[_vm.cardAudioSource] : '',"audioType":_vm.cardAudioType}}):_vm._e()],1),_c('ul',{staticClass:"stand-container",style:([
        _vm.standWidth ?
          {width: _vm.standWidth} 
        : '' ])},[_c('div',{staticClass:"stand",style:([
          _vm.themeLocation ?
            {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/stand.png)'} 
          : '' ])}),_c('div',{staticClass:"dummy-element",style:([
          _vm.cardHeight ?
            {height: _vm.cardHeight} 
          : '' ])}),_vm._l((_vm.question),function(item,index){return _c('li',{key:index,staticClass:"draggable",class:{
          'hide-animation': _vm.enableHideAnimate, 
          'show-animation': _vm.enableShowAnimate,
          'switch-animation': _vm.enableSwitchAnimate,
          disable         : _vm.disableCard || _vm.isCorrect
        },style:([
          {transform: 'translate3d(' + item.x + 'px, ' + item.y + 'px, 0px)'},
          _vm.cardHeight ?
            {height: _vm.cardHeight} 
          : '',
          _vm.cardWidth ?
            {width: _vm.cardWidth} 
          : '',
          _vm.cardPaddingHeight || _vm.cardPaddingWidth ?
            {padding: _vm.cardPaddingHeight + ' ' + _vm.cardPaddingWidth} 
          : '',
          item.show ?
            {opacity: '1'} 
          : {opacity: '0'} ]),attrs:{"currentIndex":index,"itemIndex":item.index,"postion-x":item.x,"postion-y":item.y,"default-pos":item.d},on:{"mousedown":_vm.mouseDown,"mouseup":_vm.mouseUp,"touchstart":_vm.touchStart,"touchend":_vm.touchEnd,"touchmove":_vm.touchMove}},[_c('div',{staticClass:"card-border",style:([
            {borderWidth: _vm.retroBorderSize},
            {borderStyle: 'solid'},
            item.border ?
              {borderColor: _vm.borderColor[item.border]}
            : {borderColor: 'transparent'},
            item.border === 3 ?
              {backgroundColor: _vm.backgroundColorWrong}
            : ''
            
          ])}),_c('div',{staticClass:"card-default-border",style:([
            {border: '2px solid ' + _vm.borderColor[1], boxShadow: _vm.borderColor[1] + ' 0px 6px 0px -2px'},
            _vm.backgroundColorCard ? 
              {backgroundColor: _vm.backgroundColorCard}
            : 'transparent'
          ])}),_c('CardC',{attrs:{"value":item.text,"cardFontColor":_vm.cardFontColor,"cardFontSize":_vm.cardFontSize,"displaySource":_vm.displaySource,"displayType":_vm.displayType,"cardRelation":_vm.cardRelation,"assetMediaImageUrl":_vm.assetMediaImageUrl}})],1)})],2),_c('div',{staticClass:"right-space",style:([
        _vm.hideAudioAndNotify ?
          {opacity: 0, transition: 'all 0.3s ease-out'}
        : {opacity: 1} ])},[(_vm.isCorrect)?_c('Notify',{attrs:{"addedClass":"ordo-a","hasBorder":false}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }