<template>
  <div class="body-container">
    <OrdoLayoutA
      v-if="!isFinal"
      :elements="elements"
      :question="question"
      :cardWidth="cardWidth"
      :cardHeight="cardHeight"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :cardFontColor="cardFontColor"
      :cardFontSize="cardFontSize"
      :themeLocation="themeLocation"
      :themeName="themeName"
      :standWidth="standWidth"
      :reinitialize="reinitialize"
      :callHideAnimation="callHideAnimation"
      :retroBorderSize="retroBorderSize"
      :borderColor="borderColor"
      :disableCard="disableCard"
      :isShowCardAudioButton="isShowCardAudioButton"
      :isCorrect="isCorrect"
      :hideAudioAndNotify="hideAudioAndNotify"
      :cardAudioType="cardAudioType"
      :cardAudioSource="cardAudioSource"
      :correctWord="correctWord"
      :backgroundColorWrong="backgroundColorWrong"
      :backgroundColorCard="backgroundColorCard"
      :backgroundColorDisable="backgroundColorDisable"
      :assetMediaImageUrl="assetMediaImageUrl"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :cardQuestionWidth="cardQuestionWidth"
      :cardQuestionHeight="cardQuestionHeight"
      :cardQuestionPaddingWidth="cardQuestionPaddingWidth"
      :cardQuestionPaddingHeight="cardQuestionPaddingHeight"
      :cardQuestionBottomMargin="cardQuestionBottomMargin"
      :cardQuestionSource="cardQuestionSource"
      :cardQuestionSourceValue="cardQuestionSourceValue"
      :cardQuestionBackgroundColor="cardQuestionBackgroundColor"
      :displaySource="displaySource"
      :displayType="displayType"
      :cardRelation="cardRelation"
    >
    </OrdoLayoutA>
    <FinalB
      v-if="isFinal && finalType.toLowerCase() === 'finalb'"
      :finalImages="finalImages"
      :themeName="themeName"
      :themeLocation="themeLocation"
    >
    </FinalB>
    <FinalC
      v-if="isFinal && finalType.toLowerCase() === 'finalc'"
      :themeName="themeName"
      :themeLocation="themeLocation"
      :finalBoardAnswers="finalBoardAnswers"
      :boardBorderColor="boardBorderColor"
      :boardBackgroundColor="boardBackgroundColor"
      :bannerTextColor="bannerTextColor"
      :boardTitle="boardTitle"
      :audioImg="audioImg"
      :cardAudioSource="cardAudioSource"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :cardAudioType="cardAudioType"
      :bannerImg1="bannerImg1"
      :bannerImg2="bannerImg2"
      :finalDisplaySource="finalDisplaySource"
    >
    </FinalC>
  </div>
</template>

<script>
import Vue from 'vue'
import OrdoLayoutA from '../components/OrdoLayoutA'
import FinalB from '../components/FinalB'
import FinalC from '../components/FinalC'
import Velocity from 'velocity-animate'

export default {
  name: 'OrdoA',
  components: {
    'FinalB': FinalB,
    'FinalC': FinalC,
    'OrdoLayoutA': OrdoLayoutA,
  },
  props: [
    "elements",
    "question",
    "isFinal",
    "finalImages",
    "themeName",
    "themeLocation",
    "cardWidth",
    "cardHeight",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "cardFontColor",
    "cardFontSize",
    "standWidth",
    "isCardMoved",
    "reinitialize",
    "callHideAnimation",
    "retroBorderSize",
    "borderColor",
    "disableCard",
    "finalType",
    "finalBoardAnswers",
    "boardBorderColor",
    "boardBackgroundColor",
    "boardTitle",
    "audioImg",
    "bannerImg1",
    "bannerImg2",
    "bannerTextColor",
    "backgroundColorWrong",
    "backgroundColorCard",
    "backgroundColorDisable",
    "isShowCardAudioButton",
    "isCorrect",
    "hideAudioAndNotify",
    "cardAudioType",
    "cardAudioSource",
    "correctWord",
    "assetMediaImageUrl",
    "assetMediaAudioUrl",
    "cardQuestionWidth",
    "cardQuestionHeight",
    "cardQuestionPaddingWidth",
    "cardQuestionPaddingHeight",
    "cardQuestionBottomMargin",
    "cardQuestionSource",
    "cardQuestionSourceValue",
    "cardQuestionBackgroundColor",
    "displaySource",
    "finalDisplaySource",
    "displayType",
    "cardRelation",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.body-container {
  min-height: 380px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;;
}
</style>
