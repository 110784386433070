<template>
  <div class="footer-container">
    <ActionC
      :flipClass="flipClass"
      :isDisabled="isDisabled"
      :isDisableClick="isDisableClick"
      :nextText="nextText"
      :validateText="validateText"
      :finalActionNextText="finalActionNextText"
      :actionTextColor="actionTextColor"
      :activeButtonImg="activeButtonImg"
      :inactiveButtonImg="inactiveButtonImg"
      :isPlayAgainMode="isPlayAgainMode"
      :playAgainText="playAgainText"
      :isFinal="isFinal"
    >
    </ActionC>
  </div>
</template>

<script>
  import Vue from 'vue'
  import ActionC from '../components/ActionC'

  export default {
    name: 'FooterB',
    props: [
      "flipClass",
      "isDisabled",
      "isDisableClick",
      "nextText",
      "validateText",
      "finalActionNextText",
      "actionTextColor",
      "activeButtonImg",
      "inactiveButtonImg",
      "isPlayAgainMode",
      "playAgainText",
      "isFinal",
    ],
    components: {
      'ActionC': ActionC,
    },
    data () {
      return {
        myComponent: this.$parent.myComponent,
        myHome: this.$parent.myHome,
      }
    },
    methods: {
      loadThemeImages: function() {
        var images = [
          'bouton-actif.svg',
          'bouton-inactif.svg',
        ];

        // Preloading images for smooth animations
        for(var i = 0; i < images.length; i++) {
          var img = new Image();
          img.src = this.myHome.getThemeImage(images[i]);
        }
      },
    },
    created: function() {
      this.loadThemeImages()
    }
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.footer-container {
    min-height: 90px;
    display: flex;
}
</style>
