<template>
  <div 
    class="card"
    :class="[
      addedClass, 
      'answer-letter-' + dataIndex, 
      {
        selected: isSelected, 
        wrong: isWrong, 
        correct: isCorrect, 
        done: isComplete
      }
    ]"
    :style="[
      cardHeight ? 
        {height: cardHeight}
      : '',
      cardWidth ? 
        {width: cardWidth}
      : '',
      cardMarginWidth ?
        {margin: '0 calc(' + cardMarginWidth + ' / 2)'}
      : '',
    ]"
    :index="index"
    :data="data"
    :isSelected="isSelected"
    v-on:click="onSelect()"
  >
    <div class="card-container">
      <div 
        class="card-border"
        :style="[
          isWrong ?
            {borderColor: wrongBorderColor}
          : '',
          isSelected && !isCorrect ?
            {borderColor: selectedBorderColor}
          : '',
          isCorrect ?
            {borderColor: correctBorderColor}
          : '',
          retroBorderSize ?
            {borderWidth: retroBorderSize}
          : '',
        ]"
      >
      </div>
      <div 
        class="card-content"
        :style="[
          retroBorderSize ?
            {padding: retroBorderSize}
          : '',
        ]"
      >
        <div 
          class="content"
          :style="[
            isWrong ?
              {backgroundColor: backgroundColorWrong}
            : '',
            cardFontColor ?
              {color: cardFontColor}
            : '',
            cardFontSize ?
              {fontSize: cardFontSize}
            : '',
            cardPaddingTop ? 
              {paddingTop: cardPaddingTop}
            : '',
            cardPaddingBottom ? 
              {paddingBottom: cardPaddingBottom}
            : '',
            cardPaddingWidth ? 
              {paddingLeft: cardPaddingWidth, paddingRight: cardPaddingWidth}
            : '',
          ]"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Util from '../helpers/Util'
import Notify from '../components/Notify'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CardE',
  components: {
    'Notify': Notify,
  },
  props: [
    "cardType",
    "data",
    "index",
    "dataIndex",
    "addedClass",
    "isSelected",
    "isWrong",
    "isCorrect",
    "isComplete",
    "backgroundColorWrong",
    "correctBorderColor",
    "selectedBorderColor",
    "wrongBorderColor",
    "cardBorderColor",
    "cardFontColor",
    "cardFontSize",
    "cardHeight",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "cardWidth",
    "displaySource",
    "displayType",
    "retroBorderSize",
    "cardMarginWidth",
  ],
  data () {
    return {
      isSelected1: false,
    }
  },
  computed: {
    ...mapGetters(['getOrthoArrayData']),
  },
  methods: {
    ...mapActions([
      'setOrthoArrayData',
      'setOrthoArrayDataByElem',
      'setOrthoArrayDataResetIsSelected'
    ]),

    onSelect: async function(className) {
      var _this = this;
      this.setOrthoArrayDataResetIsSelected();
      this.setOrthoArrayDataByElem({
        'index': this.index, 
        'element': 'isSelected',
        'value': true
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.card {
  width: 72px;
  height: 72px;
  font-size: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;

  &[index="0"] {
    margin-left: 5px !important;
  }

  &:last-of-type {
    margin-right: 5px !important;
  }

  &.ortho {
    &.selected {
      .card-container {
        .card-border {
          box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }

    &.correct, &.wrong, &.done {
      cursor: default;
      pointer-events: none;
    }

    &.correct, &.wrong, &.selected {
      .card-container {
        .card-border {
          box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);
        }
        .card-content {
          .content {
            box-shadow: none;
          }
        }
      }
    }

    &.wrong {
      .card-container {
        .card-border {
          transition: all 0.6s ease;
        }
      }
    }

    &.done {
      .card-container {
        .card-border {
          border: none;
          box-shadow: none;
        }
        .card-content {
          .content {
            box-shadow: none;
          }
        }
      }
    }

    .card-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .card-border {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 22px;
        transition: all 0.3s ease;
        border-color: transparent;
        border-style: solid;
        border-width: 6px;
      }
      .card-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;

        .content {
          width: 100%;
          height: 100%;
          border-radius: 15px;
          background-color: #fff;
          box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);
          padding-bottom: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
