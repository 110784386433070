const Actions = {
	initStorage(context, mutation) {
		const key = 'IA_STORAGE_KEY_' + mutation;
		const value = window.localStorage.getItem(key);

		if (value) {
			const local = JSON.parse(value);

			if (local.payload)
				context.commit(mutation, local.payload);
		}
	},
	setAppConfig(context, payload) {
		context.commit('SET_APP_CONFIG', payload);
  },
  setNavigationData(context, payload) {
		context.commit('SET_NAVIGATION_DATA', payload);
	},
	//ORTHO
  setOrthoArrayData(context, payload) {
		context.commit('SET_ORTHO_ARRAY_DATA', payload);
  },
  setOrthoArrayDataByElem(context, payload) {
		context.commit('SET_ORTHO_ARRAY_DATA_BY_ELEM', payload);
  },
  setOrthoArrayDataResetIsSelected(context) {
		context.commit('SET_ORTHO_ARRAY_DATA_RESET_ISSELECT');
  },
	//MEMO
  setMemoLeftArrayData(context, payload) {
		context.commit('SET_MEMO_LEFT_ARRAY_DATA', payload);
  },
  setMemoLeftArrayDataByElem(context, payload) {
		context.commit('SET_MEMO_LEFT_ARRAY_DATA_BY_ELEM', payload);
  },
  setMemoLeftArrayDataResetIsSelected(context) {
		context.commit('SET_MEMO_LEFT_ARRAY_DATA_RESET_ISSELECT');
  },
  setMemoRightArrayData(context, payload) {
		context.commit('SET_MEMO_RIGHT_ARRAY_DATA', payload);
  },
  setMemoRightArrayDataByElem(context, payload) {
		context.commit('SET_MEMO_RIGHT_ARRAY_DATA_BY_ELEM', payload);
  },
  setMemoRightArrayDataResetIsSelected(context) {
		context.commit('SET_MEMO_RIGHT_ARRAY_DATA_RESET_ISSELECT');
  },
  setMultiObjectData(context, payload) {
		context.commit('SET_MULTI_OBJECT_DATA', payload);
  },
  setI18n(context, payload) {
    context.commit('SET_I18N', payload);
  },
  setLocale(context, payload) {
    context.commit('SET_LOCALE', payload);
  },
  setProduct(context, payload) {
    context.commit('SET_PRODUCT', payload);
  }
}

export default Actions
