<template>
  <div class="ordo-layout-a">
    <div
      v-if="cardQuestionSource"
      class="ordo-content-question"
      :style="[
      cardQuestionWidth ?
        {width: cardQuestionWidth}
      : '',
      cardQuestionHeight ?
        {height: cardQuestionHeight}
      : '',
      cardQuestionBackgroundColor ?
        {backgroundColor: cardQuestionBackgroundColor}
      : '',
      cardQuestionBottomMargin ? 
        {marginBottom: cardQuestionBottomMargin}
      : '',
      ]"
    >
      <img 
        draggable="false" 
        v-rjs="2"
        :src="assetMediaImageUrl + cardQuestionSourceValue"
        :style="[
        cardQuestionPaddingWidth ?
          {maxWidth: 'calc(100% - ' + cardQuestionPaddingWidth + 'px)' }
        : '',
        cardQuestionPaddingHeight ?
          {maxHeight: 'calc(100% - ' + cardQuestionPaddingHeight + 'px)' }
        : '',
        ]"
      >
    </div>
    <div class="ordo-content-cards">
      <div 
        class="left-space"
        :style="[
          (hideAudioAndNotify) ?
            {opacity: 0, transition: 'all 0.3s ease-out'}
          : {opacity: 1},
        ]"
      >
        <AudioA
          v-if="themeName && isShowCardAudioButton && cardAudioType === 'mp3' && isShowAudio && elements.length"
          :messageAudioFile="elements.length ? elements[0].element[cardAudioSource] : ''"
          classAdded="content-audio"
          :isDataSound="true"
          :assetMediaAudioUrl="assetMediaAudioUrl"
        >
        </AudioA>
        <AudioB
          v-else-if="themeName && isShowCardAudioButton && isShowAudio && elements.length"
          classAdded="content-audio"
          :correctWord="elements.length ? elements[0].element[cardAudioSource] : ''"
          :audioType="cardAudioType"
        >
        </AudioB>
      </div>

      <ul
        class="stand-container"
        :style="[
          standWidth ?
            {width: standWidth} 
          : '',
        ]"
      >
        <div 
          class="stand"
          :style="[
            themeLocation ?
              {backgroundImage: 'url(' + themeLocation + themeName + '/stand.png)'} 
            : '',
          ]"
        >

        </div>
        <div 
          class="dummy-element"
          :style="[
            cardHeight ?
              {height: cardHeight} 
            : '',
          ]"
        >

        </div>
        <li
          class="draggable"
          :class="{
            'hide-animation': enableHideAnimate, 
            'show-animation': enableShowAnimate,
            'switch-animation': enableSwitchAnimate,
            disable         : disableCard || isCorrect
          }"
          v-for="(item, index) in question"
          :key="index"
          :currentIndex="index"
          :itemIndex="item.index"
          :postion-x="item.x"
          :postion-y="item.y"
          :default-pos="item.d"
          @mousedown="mouseDown"
          @mouseup="mouseUp"
          @touchstart="touchStart"
          @touchend="touchEnd"
          @touchmove="touchMove"
          :style="[
            {transform: 'translate3d(' + item.x + 'px, ' + item.y + 'px, 0px)'},
            cardHeight ?
              {height: cardHeight} 
            : '',
            cardWidth ?
              {width: cardWidth} 
            : '',
            cardPaddingHeight || cardPaddingWidth ?
              {padding: cardPaddingHeight + ' ' + cardPaddingWidth} 
            : '',
            item.show ?
              {opacity: '1'} 
            : {opacity: '0'} ,
          ]"
        >
          <div
            class="card-border"
            :style="[
              {borderWidth: retroBorderSize},
              {borderStyle: 'solid'},
              item.border ?
                {borderColor: borderColor[item.border]}
              : {borderColor: 'transparent'},
              item.border === 3 ?
                {backgroundColor: backgroundColorWrong}
              : ''
              
            ]"
          >
          </div>
          <div
            class="card-default-border"
            :style="[
              {border: '2px solid ' + borderColor[1], boxShadow: borderColor[1] + ' 0px 6px 0px -2px'},
              backgroundColorCard ? 
                {backgroundColor: backgroundColorCard}
              : 'transparent'
            ]"
          >
          </div>
          <CardC
            :value="item.text"
            :cardFontColor="cardFontColor"
            :cardFontSize="cardFontSize"
            :displaySource="displaySource"
            :displayType="displayType"
            :cardRelation="cardRelation"
            :assetMediaImageUrl="assetMediaImageUrl"
          >
          </CardC>
        </li>
      </ul>
      <div 
        class="right-space"
        :style="[
          hideAudioAndNotify ?
            {opacity: 0, transition: 'all 0.3s ease-out'}
          : {opacity: 1},
        ]"
      >
        <Notify
          v-if="isCorrect"
          addedClass="ordo-a"
          :hasBorder="false"
        >
        </Notify>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Velocity from 'velocity-animate'
import CardC from '../components/CardC'
import Util from '../helpers/Util'
import AudioA from '../components/AudioA'
import AudioB from '../components/AudioB'
import Notify from '../components/Notify'

export default {
  name: 'OrdoLayoutA',
  components: {
    'CardC': CardC,
    'AudioA': AudioA,
    'AudioB': AudioB,
    'Notify': Notify
  },
  props: [
    "elements",
    "question",
    "cardWidth",
    "cardHeight",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "cardFontColor",
    "cardFontSize",
    "themeLocation",
    "themeName",
    "boardBorderColor",
    "standWidth",
    "reinitialize",
    "callHideAnimation",
    "retroBorderSize",
    "borderColor",
    "disableCard",
    "isShowCardAudioButton",
    "isCorrect",
    "hideAudioAndNotify",
    "cardAudioType",
    "cardAudioSource",
    "correctWord",
    "backgroundColorWrong",
    "backgroundColorCard",
    "backgroundColorDisable",
    "assetMediaImageUrl",
    "assetMediaAudioUrl",
    "cardQuestionWidth",
    "cardQuestionHeight",
    "cardQuestionPaddingWidth",
    "cardQuestionPaddingHeight",
    "cardQuestionBottomMargin",
    "cardQuestionSource",
    "cardQuestionSourceValue",
    "cardQuestionBackgroundColor",
    "displaySource",
    "displayType",
    "cardRelation",
  ],
  data () {
    return {
      myComponent: false,
      myHome: this.$parent.myHome,
      elementItem: [],
      itemsSizeContainer: [],
      itemDefaultPos: [],
      animationArray: [],
      cardMargin: 18,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      halfTotalSize: 0,
      isEnableSwitch: true,
      oldMouseX: 0,
      oldMouseY: 0,
      isMouseMoved: 0,
      enableHideAnimate: false,
      enableShowAnimate: false,
      enableSwitchAnimate: false,
      isShowAudio: false,
      isTouch: false,
    }
  },

  mounted() {
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  },

  beforeDestroy() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  },

  methods: {
    initialize: async function() {
      var _this = this,
          addedDelay = 0;
      this.myComponent = this.$parent.myComponent;
      this.itemDefaultPos = [];
      this.itemsSizeContainer = [];
      this.animationArray = [];
      this.enableShowAnimate = false;
      this.enableSwitchAnimate = false;
      this.isShowAudio = true;
      
      if (this.cardQuestionSource) {
        await Util.setWait(function () {
          _this.showCardQuestion();
        }, 550);
        addedDelay = 200;
      }
      
      await Util.setWait(function () {
        _this.initializeCard();
      }, 400 + addedDelay);
    },

    initializeCard: function() {
      var halfTotalSizeNegative = 0, 
          questionLength = this.question.length,
          totalSize = this.calculateItemSize(),
          _this = this;
          
      halfTotalSizeNegative = -(totalSize / 2);
      
      for (let index = 0; index < questionLength; index++) {
        var value = !index ? halfTotalSizeNegative : halfTotalSizeNegative + (this.itemsSizeContainer[index-1] + this.cardMargin);
        halfTotalSizeNegative = value;
        value = value + (this.itemsSizeContainer[index] / 2);

        this.question[index].x = value;
        this.question[index].y = -120;
        this.question[index].d = value;
        this.question[index].show = 0;
        this.question[index].border = 0;
        this.question[index].size = this.itemsSizeContainer[index];
        this.question[index].index = index;
        this.question.splice(index, 1, this.question[index]);
        this.itemDefaultPos.push(this.question[index]);
        this.animationArray.push(index);
      }
      
      setTimeout(function(){
        _this.showCardsAnimate();
      }, 800);
    },

    calculateItemSize: function() {
      var items = document.getElementsByClassName("draggable"),
          total = 0;
      for (let index = 0; index < items.length; index++) {
        var value = items[index].clientWidth,
            addedVal = this.cardMargin;

        if (index == (items.length -1)) {
          addedVal = 0;
        }
        this.itemsSizeContainer.push(value);
        total += value + addedVal;
      }
      return total;
    },

    backDefaultPos: function(event) {
      var currentIndex = [];
      this.elementItem = event.target;
      event.target.classList.add('disable');
      currentIndex = parseInt(this.elementItem.getAttribute("currentIndex"));

      this.question[currentIndex].x = this.question[currentIndex].d;
      this.question[currentIndex].y = 0;
      
      this.question.splice(currentIndex, 1, this.question[currentIndex]);
      this.isMouseMoved = false;

      event.target.classList.add('switch-animation');
      setTimeout(function(){
        event.target.classList.remove('dragging');
        event.target.classList.remove('disable');
      }, 450);
    },

    touchStart: function(e) {
      this.isTouch = true;
      this.dragStart(e);
    },

    touchEnd: function(e) {
      this.isTouch = false;
      this.backDefaultPos(e);
    },

    touchMove: function(e) {
      this.dragMove(e);
    },

    mouseDown: function(e) {
      this.dragStart(e);
    },

    mouseUp: function(e) {
      this.backDefaultPos(e);
    },

    onMouseUp: function(e) {
      this.isMouseMoved = false;
    },

    onMouseMove: function(e) {
      this.dragMove(e);
    },

    dragStart: function(e) {
      var tempE = e;
      if (this.isTouch) 
        tempE = e.changedTouches[0];
      this.elementItem = e;
      this.pos3 = tempE.clientX;
      this.pos4 = tempE.clientY;
      e.target.classList.remove('switch-animation');
      this.isMouseMoved = true;
    },

    dragMove: function(e) {
      if (this.isMouseMoved) {
        var tempE = e,
            transform = "",
            comparedItemIndex = 0,
            xCounter = 0,
            tempArray = {}, 
            Xpos = parseInt(this.elementItem.target.getAttribute("postion-x")), 
            Ypos = parseInt(this.elementItem.target.getAttribute("postion-y")), 
            Dpos = parseInt(this.elementItem.target.getAttribute("default-pos")),
            currentIndex = parseInt(this.elementItem.target.getAttribute("currentIndex")),
            thisIndex = parseInt(this.elementItem.target.getAttribute("itemIndex")),
            screenLimitData = {};

      if (this.isTouch) 
        tempE = e.changedTouches[0];

        this.myComponent.isCardMoved = true;
        this.elementItem.target.classList.add('dragging');
        // calculate the new cursor position:
        this.calculateCursorPos(e);
        // set the element's new position:
        tempArray = this.calculateElementPos(Xpos, Ypos);

        Xpos = tempArray.Xpos;
        Ypos = tempArray.Ypos;

        screenLimitData = this.setScreenLimit(e, Xpos, Ypos, currentIndex);

        if (!screenLimitData) 
          return;

        Xpos = screenLimitData.Xpos;
        Ypos = screenLimitData.Ypos;

        if (tempE.pageX > this.oldMouseX) {
          xCounter = 1;
        }
        else if (tempE.pageX < this.oldMouseX) {
          xCounter = 0;
        }
        
        this.checkingNewPos(xCounter, currentIndex, Xpos, Dpos, thisIndex);

        this.question[currentIndex].x = Xpos;
        this.question[currentIndex].y = Ypos;
        this.question.splice(currentIndex, 1, this.question[currentIndex]);
        this.question.splice(comparedItemIndex, 1, this.question[comparedItemIndex]);

        this.oldMouseX = tempE.pageX;
        this.oldMouseY = tempE.pageY;
      }
      e.preventDefault();
    },

    setScreenLimit: function(e, Xpos, Ypos, currentIndex) {
      var tempE = e,
          halftSizeCardWidth = 0,
          fullSizeCardHeight = 60,
          containerWidth = document.getElementsByClassName("right-container")[0].clientWidth,
          containerHeight = document.getElementsByClassName("right-container")[0].clientHeight,
          screenWidthLimit1 = containerWidth / 2,
          screenWidthLimit2 = containerWidth / 2,
          screenHeightLimit1 = containerHeight / 2,
          screenHeightLimit2 = containerHeight / 2,
          containerHeight = document.getElementsByClassName("stand-container")[0].offsetParent.clientHeight / 2,
          dynamicPos = document.getElementsByClassName("stand-container")[0].offsetTop - containerHeight,
          xCounter = 0,
          yCounter = 0,
          isLimit = false;
          
      if (this.isTouch) 
        tempE = e.changedTouches[0];
          
      if (this.cardHeight) 
        fullSizeCardHeight = parseInt(this.cardHeight.slice(0,this.cardHeight.length -2));
      
      halftSizeCardWidth = (this.question[currentIndex].size / 2) + 10;
      fullSizeCardHeight = (fullSizeCardHeight / 2) + 10;
      halftSizeCardWidth = -(halftSizeCardWidth);
      if (tempE.pageX > this.oldMouseX) 
        xCounter = 1;
      else if (tempE.pageX < this.oldMouseX) 
        xCounter = 0;
      
      if (tempE.pageY > this.oldMouseY) 
        yCounter = 1;
      else if (tempE.pageY < this.oldMouseY) 
        yCounter = 0;
      
      dynamicPos += fullSizeCardHeight + 20;
      screenWidthLimit1 += halftSizeCardWidth;
      screenWidthLimit2 += halftSizeCardWidth;
      screenHeightLimit1 += -(fullSizeCardHeight) - dynamicPos;
      screenHeightLimit2 += dynamicPos - fullSizeCardHeight;

      screenWidthLimit2 = -(screenWidthLimit2);
      screenHeightLimit2 = -(screenHeightLimit2);
      
      if (Xpos > screenWidthLimit1 && xCounter) {
        Xpos = screenWidthLimit1;
        isLimit = true;
      }
      else if (Xpos < screenWidthLimit2 && !xCounter) {
        Xpos = screenWidthLimit2;
        isLimit = true;
      }

      if (Ypos > screenHeightLimit1 && yCounter){
        Ypos = screenHeightLimit1;
        isLimit = true;
      }
      else if (Ypos < screenHeightLimit2 && !yCounter){
        Ypos = screenHeightLimit2;
        isLimit = true;
      }

      if (isLimit) 
        this.backDefaultPos(this.elementItem);
      else
        return {"Xpos": Xpos, "Ypos": Ypos};

      return false;
    },

    checkingNewPos: function(counter, currentIndex, Xpos, Dpos, thisIndex) {
      var obj = {
            "resIndex": null,
            "resX": null,
            "resD": null,
            "prevIndex": null,
            "prevX": null,
            "prevD": null,
            "prevSize": null,
            "tempX": null,
            "tempD": null,
            "tempSize": null,
            "tempIndex": null,
            "tempIndexLimit": null
          };
      
      if (counter) 
        this.checkingLeftToRightArray(obj, currentIndex, Xpos, thisIndex, counter);
      else 
        this.checkingRightToLeftArray(obj, currentIndex, Xpos, thisIndex, counter);
    },
    checkingLeftToRightArray: function(obj, currentIndex, Xpos, thisIndex, counter) {
      var tempCounter = false,
          tempCounter2 = false;
      for (let index = this.itemDefaultPos.length - 1; index >= 0 ; index--) {
        var aheadIndex = false,
            comparedItemIndex = this.findItemIndex(index),
            comparedItem = this.question[comparedItemIndex].d,
            currentItemDeducted = this.question[currentIndex].size / 2;

        if (Xpos > (comparedItem - currentItemDeducted) && index > thisIndex) {
          tempCounter = true;
          obj = this.getFinalPosInfo(obj, comparedItemIndex, currentIndex);
          break;
        }
      }

      for (let index = 0; index < this.itemDefaultPos.length; index++) {
        var comparedItemIndex = this.findItemIndex(index);
        
        if (index > thisIndex && tempCounter && index <= obj.tempIndexLimit) {
          tempCounter2 = true;
          this.setAllItemPosInfo(obj, comparedItemIndex, counter);
          break;
        }
      }

      if (tempCounter2) {
        this.setFinalPosInfo(obj, currentIndex, counter);
        tempCounter = false;
      }
    },

    checkingRightToLeftArray: function(obj, currentIndex, Xpos, thisIndex, counter) {
      var tempCounter = false,
          tempCounter2 = false;

      for (let index = 0; index < this.itemDefaultPos.length; index++) {
        var aheadIndex = false,
            comparedItemIndex = this.findItemIndex(index),
            comparedItem = this.question[comparedItemIndex].d,
            currentItemDeducted = this.question[currentIndex].size / 2;

        if (Xpos < (comparedItem + currentItemDeducted) && index < thisIndex) {
          tempCounter = true;
          obj = this.getFinalPosInfo(obj, comparedItemIndex, currentIndex);
          break;
        }
      }

      for (let index = this.itemDefaultPos.length - 1; index >= 0 ; index--) {
        var comparedItemIndex = this.findItemIndex(index);
        
        if (index < thisIndex && tempCounter && index >= obj.tempIndexLimit) {
          tempCounter2 = true;
          this.setAllItemPosInfo(obj, comparedItemIndex, counter);
          break;
        }
      }

      if (tempCounter2) {
        this.setFinalPosInfo(obj, currentIndex, counter);
        tempCounter = false;
      }
    },

    getFinalPosInfo: function(obj, comparedItemIndex, currentIndex) {
      obj.resD = this.question[comparedItemIndex].d;
      obj.resIndex = this.question[comparedItemIndex].index;
      
      obj.prevD = this.question[currentIndex].d;
      obj.prevIndex = this.question[currentIndex].index;
      obj.prevSize = this.question[currentIndex].size;
      obj.tempIndexLimit = obj.resIndex;
     
      return obj;
    },
    setAllItemPosInfo: function(obj, comparedItemIndex, counter) {
      obj.tempD = this.question[comparedItemIndex].d;
      obj.tempSize = this.question[comparedItemIndex].size;
      obj.tempIndex = this.question[comparedItemIndex].index;

      obj.prevD = this.setupNewPos(obj, comparedItemIndex, counter);

      this.question[comparedItemIndex].d = obj.prevD;
      this.question[comparedItemIndex].x = obj.prevD;
      this.question[comparedItemIndex].index = obj.prevIndex;

      obj.prevD = obj.tempD;
      obj.prevSize = obj.tempSize;
      obj.prevIndex = obj.tempIndex;

      this.question.splice(comparedItemIndex, 1, this.question[comparedItemIndex]);

      return obj;
    },
    
    setFinalPosInfo: function(obj, currentIndex, counter) {
      obj.prevD = this.setupNewPos(obj, currentIndex, !counter);

      this.question[currentIndex].d = obj.prevD;
      this.question[currentIndex].index = obj.prevIndex;

      this.question.splice(currentIndex, 1, this.question[currentIndex]);
    },

    setupNewPos: function(obj, currentItemIndex, counter) {
      var diffSize = 0,
          currentItemNewDpos;

      diffSize = (this.question[currentItemIndex].size - obj.prevSize) / 2;
      currentItemNewDpos = counter ? obj.prevD + diffSize : obj.prevD - diffSize;

      return currentItemNewDpos
    },

    findItemIndex: function(index) {
      return this.question.findIndex(obj => {
        return obj.index === index
      })
    },

    calculateCursorPos: function(e) {
      var tempE = e;
      if (this.isTouch) {
        tempE = e.changedTouches[0];
      }
      this.pos1 = this.pos3 - tempE.clientX;
      this.pos2 = this.pos4 - tempE.clientY;
      
      this.pos3 = tempE.clientX;
      this.pos4 = tempE.clientY;
    },
    
    calculateElementPos: function(Xpos, Ypos) {
      Xpos = Xpos - this.pos1;
      Ypos = Ypos - this.pos2;
      return {"Xpos": Xpos, "Ypos": Ypos};
    },

    showCardQuestion: function() {
      var el = document.getElementsByClassName('ordo-content-question');
      Velocity(el, {
        scale: 1
      }, { duration: 6 });
    },

    hideCardQuestion: function() {
      var el = document.getElementsByClassName('ordo-content-question');
      Velocity(el, {
        scale: 0
      }, { duration: 6 });
    },

    showCardsAnimate: function() {
      var tempArray = this.shuffle(this.animationArray),
          _this = this;

      this.enableShowAnimate = true;
      for (let index = 0; index < this.question.length; index++) {
        setTimeout(function() {
          var randomIndex = tempArray[index];
          _this.question[randomIndex].y = 0;
          _this.question[randomIndex].show = 1;
          _this.question.splice(randomIndex, 1, _this.question[randomIndex]);

          setTimeout(function() {
            if (index === (_this.question.length -1)) {
              _this.myComponent.disableCard = false;
              _this.enableShowAnimate = false;
              _this.enableSwitchAnimate = true;
            }
          }, 600);
        }, 300 * index);
      }
    },

    hideCardsAnimate: function (){
      var _this = this;
      for (let index = 0; index < this.question.length; index++) {
        this.question[index].x = this.question[index].x - 50;
        this.question.splice(index, 1, this.question[index]);
      }
      
      setTimeout(function() {
        _this.enableHideAnimate = true;
        for (let index = 0; index < _this.question.length; index++) {
          var newX = (document.getElementsByClassName("ordo-layout-a")[0].clientWidth / 2) - 160;
          _this.question[index].x = newX;
          _this.question.splice(index, 1, _this.question[index]);
        }
         
        for (let index = 0; index < _this.question.length; index++) {
          _this.question[index].show = 0;
          _this.question.splice(index, 1, _this.question[index]);
        }
        _this.enableHideAnimate = false;
      }, 500);
      
      if (this.cardQuestionSource) {
        setTimeout(function() {
          _this.hideCardQuestion();
          _this.isShowAudio = false;
        }, 1200);
      }
    },
    shuffle: function(array) {
      var currentIndex = array.length, temporaryValue, randomIndex;
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
        array.splice(randomIndex, 1, array[randomIndex]);
        array.splice(currentIndex, 1, array[currentIndex]);
      }

      return array;
    }
  },
  created: async function () {
    var _this = this;
    this.initialize();
  },
  watch: {
    reinitialize: function(data) {
      if (data) 
        this.initialize();
    },
    callHideAnimation: function(data) {
      if (data) 
        this.hideCardsAnimate();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.ordo-layout-a {
    @extend .flex-all-center;
    flex-direction: column;

  .ordo-content-question {
    @extend .flex-all-center;
    margin-bottom: 30px;
    position: relative;
    border-radius: 12px;
    transform: scale(0);
    transition: all .6s cubic-bezier(.57,.07,.08,1.01);
    
    img {
      position: absolute;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .ordo-content-cards {
    @extend .flex-all-center;
    width: 100%;
    margin-top: 2px;

    .left-space, .right-space {
      width: 60px;
      height: 100%;
    }

    .left-space {
      display: flex;
      justify-content: flex-end;
      align-self: flex-end;
      margin-bottom: 21px;
      flex-direction: column;
      height: 40px;
    }

    .right-space {
      display: flex;
      align-self: flex-end;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 10.5px;
    }

    .disable {
      pointer-events: none;
    }

    ul {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      list-style: none;
      position: relative;
      min-height: 82px;
      padding-bottom: 34px;

      .stand {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 82px;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      
      li {
        @extend .flex-all-center;
        width: auto;
        height: auto;
        position: absolute;
        cursor: grab;
        border-radius: 15px;
        padding: 10px;
        min-width: 60px;

        &.show-animation {
          transition: all 400ms cubic-bezier(0.69, 0.43, 0.48, 1.18);
        }

        &.switch-animation {
          transition: all 600ms cubic-bezier(0.69, 0.43, 0.48, 1.18);
          z-index: 1;
        }

        &.hide-animation {
          transition: all 900ms cubic-bezier(0.69, 0.43, 0.48, 1.18);
        }

        &.dragging {
          z-index: 2;
          box-shadow: 0px 10px 0px 2px rgba(0, 0, 0, 0.2);
          border-radius: 15px;
        }

        &.disable {
          pointer-events: none;
        }

        .card-border {
          pointer-events: none;
          border-radius: 23px;
          position: absolute;
          box-sizing: content-box;
          width: 100%;
          height: 100%;
          border-color: transparent;
          transition: all 0.3s ease-in;
        }

        .card-default-border {
          pointer-events: none;
          border-radius: 15px;
          position: absolute;
          box-sizing: content-box;
          width: 100%;
          height: 100%;
          z-index: -1;
        }

      }
    }
  }
}
.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
