<template>
  <div 
    class="card-content"
    @mousedown="mouseDown"
    @mouseover="mouseOver"
    @mouseout="mouseOut"
    :style="[
      backgroundColorCard ? 
        {backgroundColor: backgroundColorCard}
      : '',
      sink ?
        {transform: 'scale('+ setReducePercentage + ')'}
      : '',
      cardBorderSize ?
        {padding: cardBorderSize}
      : '',
      !isShowCard ?
        {transform: 'scale(0)'}
      : '',
      backgroundDisable || isCorrect ? 
        {backgroundColor: backgroundColorDisable}
      : '',
    ]"
  >
    <div 
      class="border"
      :style="[
        {borderColor: borderColor[1]},
        cardBorderSize ?
          {borderWidth: cardBorderSize}
        : '',
        isCorrect ?
          {boxShadow: 'none'}
        : '',
      ]"
    ></div>
    <div 
      class="value"
      :style="[
        cardContentPosition === 'left' ? 
          {justifyContent: 'flex-start', textAlign: 'left'}
        : '',
        cardContentPosition === 'right' ? 
          {justifyContent: 'flex-end', textAlign: 'right'}
        : '',
        cardPaddingHeight ?
          {paddingTop: cardPaddingHeight, paddingBottom: cardPaddingHeight}
        : '',
        cardPaddingWidth ?
          {paddingLeft: cardPaddingWidth, paddingRight: cardPaddingWidth}
        : '',
        cardBorderSize ?
          {borderWidth: cardBorderSize}
        : '',
      ]"
    >
      <img 
        draggable="false" 
        v-rjs="2"
        v-if="displayType === 'image'"
        :src="assetMediaImageUrl + item[displaySource]" 
      >
      <span
        v-else
      >
        {{item[displaySource]}}
      </span>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Util from '../helpers/Util'

  export default {
    name: 'CardB',
    props: [
      "sink",
      "cardPaddingHeight",
      "cardPaddingWidth",
      "isShowCard",
      "backgroundDisable",
      "backgroundColorWrong",
      "backgroundColorCard",
      "backgroundColorDisable",
      "borderColor",
      "cardBorderSize",
      "item",
      "displaySource",
      "displayType",
      "cardContentPosition",
      "assetMediaImageUrl",
      "isCorrect",
    ],
    data () {
      return {
        myComponent: this.$parent.myComponent,
        myHome: this.$parent.myHome,
        setReducePercentage: '0.5985',
      }
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      onResize: async function() {
        var _this = this,
            items = document.getElementsByClassName("card");

        await Util.setWait(function () {
          if (_this.myHome.isIpadOrIphone) 
            _this.setReducePercentage = 0.7;
          else
            _this.setReducePercentage = 0.5985;
        }, 1);
      },

      mouseOver: function(e) {
        if (e.target.classList.contains('card-content')) {
          e.target.parentElement.classList.remove('back');
          e.target.parentElement.classList.add('front');
        }
      },

      mouseOut: function(e) {
        if (e.target.classList.contains('card-content')) 
          e.target.parentElement.classList.remove('front');
      },

      mouseDown: function(e) {
        e.preventDefault();
      },
    },
    created: function() {
      if (this.myHome.isIpadOrIphone) 
        this.setReducePercentage = 0.7;
    }
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.card-content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #fff;
  transition: all 400ms cubic-bezier(0.69, 0.43, 0.48, 1.18);
  cursor: grab;

  .border {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 0px 2px rgba(0, 0, 0, 0.2);
  }
  .border, .value {
    pointer-events: none;
    border-style: solid;
  }
  .value {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: transparent;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: relative;
    }
  }
}
</style>
