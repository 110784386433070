<template>
  <div
    class="cards-container"
    :class="{'two-line-title': titleStyle, 'secondary-theme': templateName === templateMultiB || templateName === templateMultiC}"
  >

    <div
      class="question-image card"
      id="qcard-layout-a"
      :style="[
        cardQuestionBottomMargin ?
          {marginBottom: cardQuestionBottomMargin} :
          '',
      ]"
    >
      <AudioA
        v-if="cardQuestionAudioType == 'mp3' && cardQuestionAudioSource"
        :messageAudioFile="cards.questions[0].element[cardQuestionAudioSource]"
        :classAdded="'multib-question-audio'"
        :isDataSound="true"
        :assetMediaAudioUrl="assetMediaAudioUrl"
      >
      </AudioA>

      <AudioB
        v-else-if="cardQuestionAudioType == 'googleVoiceCardQuestion' && cardQuestionAudioSource"
        :classAdded="'multib-question-audio'"
        :correctWord="cardQuestionAudioSource ? cards.questions[0].element[cardQuestionAudioSource] : ''"
        :audioType="cardQuestionAudioType"
      >
      </AudioB>

      <div
        id="qcard-layout-a-box"
        class="card-box"
        :style="[
          cardQuestionWidth ?
            {'width': cardQuestionWidth} 
          : '',
          cardQuestionHeight ?
            {'height': cardQuestionHeight} 
          : '',
          cardQuestionBackgroundColor ?
            {'background-color': cardQuestionBackgroundColor, 'border-color': cardQuestionBackgroundColor}
          : '',
          cardQuestionPaddingWidth ?
            {paddingLeft: 'calc(' + cardQuestionPaddingWidth + ' / 2)', paddingRight: 'calc(' + cardQuestionPaddingWidth + ' / 2)'} 
          : '',
          cardQuestionPaddingTop ?
            {paddingTop: cardQuestionPaddingTop} 
          : '',
          cardQuestionPaddingBottom ?
            {paddingBottom: cardQuestionPaddingBottom} 
          : '',
          cardQuestionFontSize ?
            {fontSize: cardQuestionFontSize} 
          : '',
          cardQuestionFontColor ?
            {color: cardQuestionFontColor} 
          : '',
          cardQuestionContentPosition ?
            {
              justifyContent: cardQuestionContentPosition == 'right' ? 'flex-end' : (cardQuestionContentPosition == 'left' ? 'start' : 'center'),
              textAlign: cardQuestionContentPosition
            }
          : '',
        ]"
      >
        <img 
          v-if="cardQuestionDisplayType == 'image' && cardQuestionSource"
          v-bind:src="assetMediaImageUrl + cards.questions[0].element[cardQuestionSource]" 
          v-rjs="2"
          draggable="false"
          :style="[
            cards.questions[0].element[cardQuestionSource].indexOf('.svg') == (cards.questions[0].element[cardQuestionSource].length - 4) ?
              {height: '-webkit-fill-available'}
            : '',
          ]"
        >
        <span v-else-if="cardQuestionDisplayType == 'text' && cardQuestionSource">
          {{cards.questions[0].element[cardQuestionSource]}}
        </span>
      </div>
    </div>
    <div
      class="displayCards cards"
      v-bind:class="{correct: isCorrect, 'display-info': isDisplayInfo}"
      :style="[
        cardHeight ?
          {height: cardHeight}
        : '',
      ]"
    >
      <div
        :id="'card'+index"
        class="card"
        v-if="card.isShow"
        v-bind:class="{selected: card.isSelected, wrong: card.isWrongPick, 'disabled-click': disableCards, used: card.isUsed}"
        v-bind:css="false"
        v-for="(card, index) in displayedCards"
        v-bind:key="card.id"
      >

        <CardF
          :alternateEnable="true"
          addedClass="multiB-layoutA"
          :key="'card' + index"
          :data="card"
          :index="index"
          :isSelected="card.isSelected"
          :isCorrect="card.isCorrect"
          :isDone="isCorrect"
          :isWrong="card.isWrongPick"
          :isUsed="card.isUsed"
          :backgroundColorWrong="backgroundColorWrong"
          :correctBorderColor="correctBorderColor"
          :selectedBorderColor="selectedBorderColor"
          :wrongBorderColor="wrongBorderColor"
          :cardWidth="'100%'"
          :cardHeight="'calc(100% - 9px)'"
          :displaySource="displaySource"
          :displayType="displayType"
          :assetMediaImageUrl="assetMediaImageUrl"
          :displayedCards="displayedCards"
          :retroBorderSize="retroBorderSize"
          :cardFontColor="cardFontColor"
          :cardFontSize="cardFontSize"
          :cardPaddingTop="cardPaddingTop"
          :cardPaddingBottom="cardPaddingBottom"
          :cardPaddingHeight="cardPaddingHeight"
          :cardPaddingWidth="cardPaddingWidth"
          :isSelectBump="false"
          :cardContentPosition="cardContentPosition"
          :displaySourceAlt="card.isCorrect ? displaySourceCorrect : displaySourceWrong"
          :displayTypeAlt="card.isCorrect ? displayTypeCorrect : displayTypeWrong"
          :isConfirmBump="true"
        >
        </CardF>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import AudioA from '../components/AudioA'
import AudioB from '../components/AudioB'
import Notify from '../components/Notify'
import CardF from '../components/CardF'

export default {
  name: 'MultiBLayoutA',
  components: {
    'AudioA': AudioA,
    'AudioB': AudioB,
    'Notify': Notify,
    'CardF': CardF,
  },
  props: [
    "secondFinalPage",
    "templateName",
    "templateMultiA",
    "templateMultiB",
    "templateMultiC",
    "titleStyle",
    "isFinal",
    "themeLayout",
    "cardQuestionDisplayType",
    "cardQuestionContentPosition",
    "cardQuestionFontColor",
    "cardQuestionFontSize",
    "cardQuestionWidth",
    "cardQuestionHeight",
    "cardQuestionBackgroundColor",
    "assetMediaImageUrl",
    "cards",
    "isDisplayInfo",
    "cardWidth",
    "cardHeight",
    "cardFontSize",
    "cardFontColor",
    "cardContentPosition",
    "tag",
    "progressValue",
    "isCorrect",
    "disableCards",
    "isShowAudioButton",
    "finalImages",
    "displayType",
    "answerDisplaySource",
    "cardAudioType",
    "cardQuestionAudioType",
    "displaySource",
    "assetMediaAudioUrl",
    "isDisableSubmit",
    "valueSource",
    "cardQuestionSource",
    "cardQuestionAudioSource",
    "displayedCards",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "backgroundColorWrong",
    "retroBorderSize",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "showAnimation",
    "hideAnimation",
    "isTwoWrongAnswers",
    "cardQuestionPaddingWidth",
    "cardQuestionPaddingTop",
    "cardQuestionPaddingBottom",
    "cardQuestionBottomMargin",
    "displaySourceCorrect",
    "displaySourceWrong",
    "displayTypeCorrect",
    "displayTypeWrong",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
  methods: {
    showCardAnimation() {
      var w = '210px',
          h = '169px',
          getBodyTag = document.getElementsByTagName("body");

      if (this.cardWidth) 
        w = this.cardWidth;
      if (this.cardHeight) 
        h = this.cardHeight;

      if (!this.isTwoWrongAnswers) {
        let el = document.getElementById('qcard-layout-a');
        Velocity(el, {
          scale: 1
        }, { duration: 4 });
      }

      setTimeout( _ => {
        this.displayedCards.forEach(function(paramCard, index) {
          let el = document.getElementById('card' + index);
          var i = index + 1;
          Velocity(el, {
            width: w,
            height: h,
            scale: 1
          }, { duration: 300 * i });
        });
      }, 100);
    },

    hideCardAnimation() {
      $(".displayCards .card").each( function (i){
        var _this = $(this);
        _this
          .delay(150*i)
          .queue(function (next) {
            _this.animate({
              width: "1px",
              height: "1px"
            });
            next();
          });
          setTimeout(function() {
            _this.css('transform', 'scale(0)');
          }, 350 + (150*i));
      });
      
      if (!this.isTwoWrongAnswers) {
        setTimeout(function() {
          let el = document.getElementById('qcard-layout-a');
          Velocity(el, {
            scale: 0
          }, { duration: 6 });
        }, 1100);
      }
    }
  },
  watch: {
    showAnimation(data) {
      if (data) 
        this.showCardAnimation();
    },
    hideAnimation(data) {
      if (data) 
        this.hideCardAnimation();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .question-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    line-height: 0;
    cursor: default;
    margin-bottom: 30px;
    transform: scale(0);
    transition: all .5s cubic-bezier(.57,.07,.08,1.01);

    .card-box {
      width: 210px;
      height: 140px;
      margin: 0;
      box-shadow: none;
      cursor: default;
      border-radius: 12px;
      padding: 0 22px;
      flex-direction: row;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 340px;
    transform: scale(1)!important;

    &.disabled-click {
      pointer-events: none;
    }

    &.correct {
      .selected {
        overflow: visible;

        .card-img {
          img {
            margin-bottom: 0;
            animation-name: bumb_image;
            animation-duration: 0.6s;
          }
        }
      }

      .card * {
        cursor: default;
        pointer-events: none;
      }
    }

    &.displayCards {
      transform: scale(1) !important;
    }

    .card {
      position: relative;
      width: 1px;
      height: 1px;
      min-width: 1px;
      min-height: 1px;
      margin: 0 22.5px 0px 22.5px;
      overflow: hidden;
      transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform-origin: 50% 50%;
      z-index: 900;

      &.disabled-click {
        pointer-events: none;
      }

      &.wrong {
        .card-img {
          margin-bottom: 0;
          animation-name: bumb_image;
          animation-duration: 0.6s;
        }
      }
    }
  }
}


@keyframes bumb_image {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
