import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const Select = Object.create(Base);

Select.correctElementsBlacklist = [];
Select.wrongElementsBlacklist = [];
Select.correctElements = new Map();
Select.wrongElements = new Map();

Select.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.correctElementsBlacklist, "correct_elements_blacklist");
    LocalStorage.saveLocalStorage(this.wrongElementsBlacklist, "wrong_elements_blacklist");
    LocalStorage.saveLocalStorage(this.correctElements, "correct_elements_remaining");
    LocalStorage.saveLocalStorage(this.wrongElements, "wrong_elements_remaining");
}

Select.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.correctElementsBlacklist);
    Util.clearElements(this.wrongElementsBlacklist);
    this.correctElements.clear();
    this.wrongElements.clear();

    var config = configuration.data.config;
    var exclude = config.excludeCriterias;
    var include = config.includeCriterias;
    var criteriasField = config.criteriasField;
    var valueSource = config.valueSource;

    this.database = await this.populateCollections(config.collections);

    for (var element of this.database) {
        if ( this.hasCriteria(element[criteriasField], exclude) )
            continue;
        else if ( this.hasCriteria(element[criteriasField], include) )
            this.setMapValue(this.correctElements, element, valueSource)
        else
            this.setMapValue(this.wrongElements, element, valueSource)
    }
}

Select.getElements = function (n, size) {
    if (n < 0 || size < 0)
        throw new Error("Negative argument is invalid.");

    var m = size - n;
    if (m < 0)
        throw new Error("Invalid number of wrong elements.");

    var right = this.fetchElements(n, this.correctElements, this.correctElementsBlacklist, {isCorrect: true});
    var wrong = this.fetchElements(m, this.wrongElements, this.wrongElementsBlacklist, {isCorrect: false});

    var elements = right.concat(wrong);
    Util.shuffle(elements);

    Util.clearElements(this.currentElements);
    Util.addElements(this.currentElements, elements);

    return this.currentElements;
}

export default Select;
