<template>
  <div
    class="title"
    v-bind:class="{fadeIn: fadeIn}"
  >
    <div 
      class="title-text"
      :class="[
        {'title-tag': (tag && isShowTag && !isFinal)}
      ]"
    >
      <AudioA
        v-if="(messageAudioType === 'mp3' &&  messageAudioFile) || (isFinal && finalMessageAudioFile)"
        :messageAudioFile="isFinal ? finalMessageAudioFile : messageAudioFile"
        classAdded="title-audio"
        :isDataSound="false"
        :assetMediaAudioUrl="assetMediaAudioUrl"
      >
      </AudioA>
      <AudioB
        v-else-if="(messageAudioType === 'googleVoiceSteps' && messageGoogleVoice) && !isFinal "
        classAdded="title-audio"
        :correctWord="messageGoogleVoice ? messageGoogleVoice : ''"
        :audioType="messageAudioType"
      >
      </AudioB>
      {{isFinal ? finalMessage : message}}
      <div
        v-if="tag && isShowTag && !isFinal"
        class="title-sound"
      >
        <span :style="'backgroundImage: url(' + myHome.getThemeImage('tag.svg') + ');'">
          <span>{{tag}}</span>
        </span>
        .
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import AudioA from '../components/AudioA'
  import AudioB from '../components/AudioB'
  import GoogleVoice from '../models/GoogleVoice'

  export default {
    name: 'TitleB',
    components: {
      'AudioA': AudioA,
      'AudioB': AudioB
    },
    props: [
      "fadeIn",
      "message",
      "messageGoogleVoice",
      "messageAudioFile",
      "messageAudioType",
      "assetMediaAudioUrl",
      "finalMessage",
      "isFinal",
      "finalMessageAudioFile",
      "tag",
      "isShowTag",
    ],
    data () {
      return {
        myComponent: this.$parent.myComponent,
        myHome: this.$parent.myHome
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.title {
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  .title-text {
    color: #000;
    text-align: center;
    position: relative;
    display: inline-block;
    line-height: 54px;

    &.title-tag {
      padding-right: 81px;
    }
  }
  .title-sound {
    margin-left: -2px;
    display: inline-block;
    right: 0;
    width: 73.02px;
    height: 62px;
    position: absolute;
    text-align: right;
    top: 0;

    > span {
      padding: 12px;
      display: inline-block;
      width: 63px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      text-align: center;
      font-weight: 700;
      line-height: 39px;
      height: 62px;
      margin-right: -10px;
      position: absolute;
      left: 0;
      top: -5px;
      justify-content: center;
      display: inline-flex;
    }
  }
}
</style>
