import Util from '../helpers/Util'
import Api from './Api'

export default {
    configuration: {},
    database: [],
    currentElements: [],
    currentBlacklist: [],

    getCurrentElements: function () {
        return this.currentElements;
    },

    hasCriteria: function (criterias = [], values) {
        if (!values) 
            return false;
        
        for (var value of values) {
            if ( criterias.includes(value) )
                return true;
        }

        return false;
    },

    getCollection: function (collectionId) {
        const url =  process.env.VUE_APP_API_URL + process.env.VUE_APP_READ_PATH;
        const params = {
            tname: process.env.VUE_APP_TABLE_NAME,
            pkname: 'PK',
            pkvalue: collectionId
        };

        return Api.get(url, params);
    },

    populateCollections: async function (collections) {
        var promises = [], ret = [];

        for (var collectionId of collections)
            promises.push(this.getCollection(collectionId));

        var responses = await Promise.all(promises);

        for (var response of responses)
            Util.addElements(ret, response.body.Items);

        return ret;
    },

    setMapValue: function (map, item, prop) {
        var key = item[prop];

        if (key) {
            if ( map.has(key) )
                map.get(key).push(item);
            else
                map.set(key, [item]);
        }
    },

    getMapValue: function (map, key) {
        var ret = {};

        if ( map.has(key) ) {
            var values = map.get(key);
            var index = Util.getRandom(values.length);
            ret = values[index];
        }

        return ret;
    },

    removeElements: function (keys, elements, blacklist) {
        if (0 === elements.size)
            return;

        for (let key of keys) {
            if ( elements.has(key) ) {
                let value = elements.get(key);
                elements.delete(key);

                if (blacklist)
                    blacklist.push(value);
            }
        }
    },

    pickElementsByKeys: function (keys, elements, blacklist) {
        const ret = [];
        const temp = new Map();

        for (let key of keys) {
            if (!elements.has(key) || keys.length >= elements.size) {
                this.releaseBlacklist(elements, blacklist);
                break;
            }
        }

        for (let key of keys) {
            if (elements.has(key)) {
                let values = elements.get(key);
                temp.set(key, values);
                ret.push({element: values[Util.getRandom(values.length)]});
            }
        }

        if (blacklist) {
            temp.forEach((v, k) => {
                blacklist.push(v);
                elements.delete(k);
            });
        }

        temp.clear();
        return ret;
    },

    releaseBlacklist: function (elements, blacklist) {
        var valueSource = this.configuration.data.config.valueSource;

        while ( !Util.isEmpty(blacklist) ) {
            var temp = blacklist.pop();

            if (temp[0][valueSource])
                elements.set(temp[0][valueSource], temp);
        }
    },

    addBlacklist: function (blacklist, list) {
        while ( !Util.isEmpty(list) )
            blacklist.push(list.pop());
    },

    repopulate: function (n, elements, blacklist, props) {
        var ret = [];

        if (n > elements.size) {
            var m = n - elements.size, temp = [];
            var remaining = this.selectElements(n, elements, temp, props);
            var missing = this.selectBlacklist(m, blacklist, temp, props);

            ret = missing.concat(remaining);
            var start = blacklist.length - this.currentBlacklist.length;

            if (start > 0)
                blacklist.splice(start);

            this.releaseBlacklist(elements, blacklist);
            Util.addElements(temp, this.currentBlacklist);

            if (0 === elements.size)
                this.releaseBlacklist(elements, temp);
            else
                this.addBlacklist(blacklist, temp);
        }
        else if (n === elements.size) {
            var temp = [];
            ret = this.selectElements(n, elements, temp, props);
            var start = blacklist.length - this.currentBlacklist.length;

            if (start > 0)
                blacklist.splice(start);

            this.releaseBlacklist(elements, blacklist);
            Util.addElements(temp, this.currentBlacklist);

            if (0 === elements.size)
                this.releaseBlacklist(elements, temp);
            else
                this.addBlacklist(blacklist, temp);
        }

        return ret;
    },

    selectBlacklist: function (n, blacklist, list, props) {
        var index, values, element, obj, ret = [];
        var m = n > blacklist.length ? blacklist.length : n;

        for (var i = 0; i < m; ++i) {
            index = Util.getRandom(blacklist.length);
            Util.swap(blacklist, index, blacklist.length - 1);
            values = blacklist.pop();

            index = Util.getRandom(values.length);
            element = values[index];
            obj = Object.assign({element: element}, props);

            ret.push(obj);
            list.push(values);
        }

        return ret;
    },

    selectElements: function (n, elements, blacklist, props) {
        var index, key, keys, values, element, obj, ret = [];
        var m = n > elements.size ? elements.size : n;

        for (var i = 0; i < m; ++i) {
            index = Util.getRandom(elements.size);
            keys = Array.from(elements.keys());
            key = keys[index];
            values = elements.get(key);

            index = Util.getRandom(values.length);
            element = values[index];
            obj = Object.assign({element: element}, props);

            ret.push(obj);
            blacklist.push(values);
            elements.delete(key);
        }

        return ret;
    },

    fetchElements: function (n, elements, blacklist, props) {
        var ret = this.repopulate(n, elements, blacklist, props);

        if ( Util.isEmpty(ret) )
            ret = this.selectElements(n, elements, blacklist, props);

        return ret;
    }
}
