import { render, staticRenderFns } from "./FinalC.vue?vue&type=template&id=543fab12&scoped=true&"
import script from "./FinalC.vue?vue&type=script&lang=js&"
export * from "./FinalC.vue?vue&type=script&lang=js&"
import style0 from "./FinalC.vue?vue&type=style&index=0&id=543fab12&lang=scss&scoped=true&"
import style1 from "./FinalC.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543fab12",
  null
  
)

export default component.exports