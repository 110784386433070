import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const MultiB = Object.create(Base);

MultiB.currentElements = {questions: [], answers: []};
MultiB.correctBlacklist = [];
MultiB.incorrectBlacklist = [];
MultiB.questionsBlacklist = [];
MultiB.answers = new Map();
MultiB.questions = new Map();

MultiB.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.correctBlacklist, "correct_blacklist");
    LocalStorage.saveLocalStorage(this.incorrectBlacklist, "incorrect_blacklist");
    LocalStorage.saveLocalStorage(this.answers, "answers");
    LocalStorage.saveLocalStorage(this.questionsBlacklist, "questions_blacklist");
    LocalStorage.saveLocalStorage(this.questions, "questions");
}

MultiB.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements.questions);
    Util.clearElements(this.currentElements.answers);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.correctBlacklist);
    Util.clearElements(this.incorrectBlacklist);
    Util.clearElements(this.questionsBlacklist);
    this.answers.clear();
    this.questions.clear();

    let config = this.configuration.data.config;
    let collectionsQuestion = config.collectionsQuestion;
    let collections = config.collections;
    let valueSource = config.valueSource;

    let questions = await this.populateCollections(collectionsQuestion);
    let answers = await this.populateCollections(collections);

    for (let element of questions)
        this.setMapValue(this.questions, element, valueSource);

    for (let element of answers)
        this.setMapValue(this.answers, element, valueSource);

    this.database = questions.concat(answers);
}

MultiB.getElements = function (n, m, i) {
    if (n < 0 || m < 0 || i < 0)
        throw new Error("Negative argument is invalid.");

    if (Number.isInteger(i))
        return changeElements(i, this.answers, this.incorrectBlacklist);

    let config = this.configuration.data.config;
    let valueSource = config.valueSource;

    this.releaseBlacklist(this.answers, this.correctBlacklist);
    this.releaseBlacklist(this.answers, this.incorrectBlacklist);

    let questions = this.fetchElements(1, this.questions, this.questionsBlacklist);
    let keys = new Array(n), value = questions[0].element[valueSource];
    keys.fill(value, 0, n);

    let correct = pickElementsByKeys(keys, this.answers, this.correctBlacklist).map(obj => Util.assign(obj, {isCorrect: true}));
    let incorrect = this.fetchElements(m, this.answers, this.incorrectBlacklist).map(obj => Util.assign(obj, {isCorrect: false}));

    let answers = correct.concat(incorrect);
    Util.shuffle(answers);

    Util.clearElements(this.currentElements.questions);
    Util.clearElements(this.currentElements.answers);

    Util.addElements(this.currentElements.questions, questions);
    Util.addElements(this.currentElements.answers, answers);

    return this.currentElements;
}

const changeElements = function (i, elements, blacklist) {
    let size = MultiB.currentElements.answers.length - 1;
    let incorrect = MultiB.fetchElements(size, elements, blacklist).map(obj => Util.assign(obj, {isCorrect: false}));

    let correct = [MultiB.currentElements.answers[i]];
    let keys = [...MultiB.currentElements.answers.keys()];
    keys.splice(i, 1);

    let key = Util.getRandom(keys.length);
    let j = keys[key];

    let answers = correct.concat(incorrect);
    Util.swap(answers, 0, i);
    Util.swap(answers, i, j);

    Util.clearElements(MultiB.currentElements.answers);
    Util.addElements(MultiB.currentElements.answers, answers);

    return MultiB.currentElements;
}

const pickElementsByKeys = function (keys, elements, blacklist) {
    const ret = [];
    const temp = new Map();

    for (let key of keys) {
        if (elements.has(key)) {
            let values = elements.get(key);
            temp.set(key, values);
            ret.push({element: values[Util.getRandom(values.length)]});
        }
    }

    if (blacklist) {
        temp.forEach((v, k) => {
            blacklist.push(v);
            elements.delete(k);
        });
    }

    temp.clear();
    return ret;
}

export default MultiB;
