import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const AssoA = Object.create(Base);

AssoA.currentElements = {first: [], second: [], third: []};
AssoA.criterias = [];
AssoA.incorrectBlacklist = [];
AssoA.incorrectElements = new Map();
AssoA.correctElements = {};
AssoA.correctBlacklist = {};

AssoA.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.criterias, "criterias");
    LocalStorage.saveLocalStorage(this.incorrectBlacklist, "incorrect_blacklist");
    LocalStorage.saveLocalStorage(this.incorrectElements, "incorrect_elements");
    LocalStorage.saveLocalStorage(this.correctElements, "correct_elements");
    LocalStorage.saveLocalStorage(this.correctBlacklist, "correct_blacklist");
}

AssoA.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements.first);
    Util.clearElements(this.currentElements.second);
    Util.clearElements(this.currentElements.third);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.criterias);
    Util.clearElements(this.incorrectBlacklist);
    this.incorrectElements.clear();
    this.correctElements = {};
    this.correctBlacklist = {};

    let config = this.configuration.data.config;
    let criteriasField = config.criteriasField;
    let valueSource = config.valueSource;
    let collections = config.collections;
    this.criterias = config.wheelCriterias || [];

    initialize(this.correctElements, this.criterias, true);
    initialize(this.correctBlacklist, this.criterias, false);
    this.database = await this.populateCollections(collections);

    for (let element of this.database) {
        let isCorrect = false;

        for (let criteria of this.criterias) {
            if ( element[criteriasField].includes(criteria.value) ) {
                this.setMapValue(this.correctElements[criteria.value], element, valueSource);
                isCorrect = true;
            }
        }

        if (!isCorrect)
            this.setMapValue(this.incorrectElements, element, valueSource);
    }
}

AssoA.getElements = function () {
    let config = this.configuration.data.config;
    let intruderSize = config.numberIntruder;
    let correct = [], firstIncorrect = [], secondIncorrect = [];

    for (let criteria of this.criterias) {
        let goodSize = criteria.numberGoodElements;
        let temp = this.fetchElements(goodSize, this.correctElements[criteria.value], this.correctBlacklist[criteria.value]).map(obj => Util.assign(obj, {isGoodElement: true}));
        criteria.isGoodCriteria = !Util.isEmpty(temp);
        Util.addElements(correct, temp);
        Util.clearElements(temp);
    }

    if (intruderSize > 0) {
        firstIncorrect = this.fetchElements(intruderSize, this.incorrectElements, this.incorrectBlacklist).map(obj => Util.assign(obj, {isGoodElement: false}));
        secondIncorrect = this.fetchElements(intruderSize, this.incorrectElements, this.incorrectBlacklist).map(obj => Util.assign(obj, {isGoodElement: false}));
    }

    let first = correct.concat(firstIncorrect);
    let second = correct.concat(secondIncorrect);
    let third = this.criterias.slice(0);

    if ('wheelOne' === config.wheelCriteriasSource) {
        let temp = first;
        first = third;
        third = temp;
    }
    else if ('wheelTwo' === config.wheelCriteriasSource) {
        let temp = second;
        second = third;
        third = temp;
    }

    Util.shuffle(first);
    Util.shuffle(second);
    Util.shuffle(third);

    Util.clearElements(this.currentElements.first);
    Util.clearElements(this.currentElements.second);
    Util.clearElements(this.currentElements.third);

    Util.addElements(this.currentElements.first, first);
    Util.addElements(this.currentElements.second, second);
    Util.addElements(this.currentElements.third, third);

    return this.currentElements;
}

const initialize = function (obj, criterias, isMap) {
    for (let criteria of criterias) {
        if (isMap) {
            if (obj[criteria.value])
                obj[criteria.value].clear();
            else
                obj[criteria.value] = new Map();
        }
        else {
            if (obj[criteria.value])
                Util.clearElements(obj[criteria.value]);
            else
                obj[criteria.value] = [];
        }
    }
}

export default AssoA;
