<template>
  <header class="header">
    <Menu
      v-if="isDocumentReady"
      :jsonConfig="jsonConfig"
      :theme="theme"
      :weeks="weeks"
      :productName="productName"
      :themeLocation="themeLocation"
      :themeName="themeName"
    >
    </Menu>

    <div class="nav-action-buttons">
      <span
        v-if="!isRealIpadOrIphone && isDocumentReady"
        class="min-max-screen"
        v-on:click="onChangeScreenSize(minMaxScreenCounter)"
        :style="{
            'background-image': 'url(' + themeLocation + themeName +  '/' + minMaxScreen + ')',
        }"
      >
      </span>
      <span
        v-if="isDocumentReady"
        class="refresh"
        v-on:click="onRefresh()"
        :style="{
            'background-image': 'url(' + themeLocation + themeName +  '/' + refreshIcon + ')',
        }"
      ></span>
    </div>
</header>
</template>

<script>
import Vue from 'vue'
import Menu from '../components/Menu'

export default {
  name: 'Nav',
  components: {
    'Menu': Menu
  },
  props: [
    "jsonConfig",
    "theme",
    "weeks",
    "productName",
    "isRealIpadOrIphone",
    "themeLocation",
    "themeName",
    "isDocumentReady",
  ],
  data () {
    return {
      myHome: this.$parent.myHome,
      minMaxScreen: 'fullscreen1.svg',
      minMaxScreenCounter: false,
      refreshIcon: 'refresh.svg',
    }
  },
  methods: {
    onChangeScreenSize: function(counter) {
      var elem = document.documentElement;
      if (counter) {
          if (document.exitFullscreen) {
              document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
          }
          this.minMaxScreenCounter = false;
          this.minMaxScreen = 'fullscreen1.svg';
      }else {
          if (elem.requestFullscreen) {
              elem.requestFullscreen();
          } else if (elem.mozRequestFullScreen) { /* Firefox */
              elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
              elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE/Edge */
              elem.msRequestFullscreen();
          }
          this.minMaxScreenCounter = true;
          this.minMaxScreen = 'fullscreen2.svg';
      }
    },

    onRefresh: function() {
      location.reload();
    },

    loadThemeImages: function() {
      var images = [
        'fullscreen1.svg',
        'fullscreen2.svg',
        'refresh.svg',
      ];
      // Preloading images for smooth animations
      for(var i = 0; i < images.length; i++) {
        var img = new Image();
        img.src = this.myHome.getThemeImage(images[i]);
      }
    },
  },
  watch: {
    isDocumentReady(value) {
      if (value)
        this.loadThemeImages()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

header.header {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 50px;
  background-color: #45454E;
  align-items: center;
  position: relative;
  z-index: 2;

  .nav-action-buttons {
    top: 102px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .refresh, .min-max-screen {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    display: block;
    cursor: pointer;
    position: relative;
    margin-bottom: 20px;
  }

  .refresh {
    background-size: 100% 100%;
    width: 27px;
    height: 35px;
  }

  .min-max-screen {
    height: 25px;
    top: 0;
    width: 25px;
  }
}
</style>
