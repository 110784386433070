<template>
  <div
    class="cards-container"
    :class="{'two-line-title': titleStyle}"
  >
    <div
      class="displayCards cards"
      v-bind:class="{correct: isCorrect, 'display-info': isDisplayInfo}"
      v-if="!isFinal"
    >
      <div
        :id="'card'+index"
        class="card"
        v-if="card.isShow"
        v-bind:class="{selected: card.isSelected, wrong: card.isWrongPick, 'disabled-click': disableCards, used: card.isUsed}"
        v-bind:css="false"
        v-for="(card, index) in displayedCards"
        v-bind:key="card.id">
          <div
            class="card-box"
            :class="{
              'no-audio': !isShowAudioButton,
            }"
            :style="[
              card.isWrongPick ?
                {backgroundColor: backgroundColorWrong}
              : '',
            ]"
            v-on:click="clickCard(card.id)"
          >
            <Notify
              v-if="isCorrect && card.isSelected"
              :hasBorder="false"
              addedClass="multi-a"
            >
            </Notify>
            <div 
              class="card-img-border"
              :style="[
                card.isSelected ?
                  {borderColor: selectedBorderColor}
                : '',
                card.isWrongPick ?
                  {borderColor: wrongBorderColor}
                : '',
                isCorrect && card.isCorrect ?
                  {borderColor: correctBorderColor}
                : '',
                (isCorrect && card.isCorrect) || card.isSelected || card.isWrongPick ?
                  {borderWidth: '6px'}
                : '',
                ((isCorrect && card.isCorrect) || card.isSelected || card.isWrongPick) && retroBorderSize ?
                  {borderWidth: retroBorderSize}
                : '',
              ]"
            >

            </div>
            <div 
              class="card-content"
              :style="[
                retroBorderSize && ((isCorrect && card.isCorrect) || card.isWrongPick) ?
                  {padding: retroBorderSize}
                : '',
              ]"
            >
              <div
                v-if=" tag"
                class="card-sound"
                :style="[
                  retroBorderSize && (isCorrect && card.isCorrect) ?
                    {marginTop: retroBorderSize,}
                  : '',
                ]"
              >
                <span>{{tag}}</span>
              </div>
              <div
                class="card-img"
                :style="[
                  cardFontSize ?
                    {fontSize: cardFontSize}
                  : '',
                  cardContentPosition ?
                    {justifyContent: cardContentPosition, textAlign: cardContentPosition}
                  : '',
                  cardPaddingTop ?
                   {paddingTop: cardPaddingTop}
                  : '',
                  cardPaddingBottom ?
                   {paddingBottom: cardPaddingBottom}
                  : '',
                  cardPaddingWidth ?
                    {
                      paddingLeft: 'calc(' + cardPaddingWidth + ' / 2)', 
                      paddingRight: 'calc(' + cardPaddingWidth + ' / 2)'
                    }
                  : '',
                  retroBorderSize && ((isCorrect && card.isCorrect) || card.isWrongPick) ?
                    {marginBottom: '40px',}
                  : '',
                ]"
              >
                <img 
                  v-if="displayType == 'image'" 
                  v-bind:src="assetMediaImageUrl + card.element[displaySource]" 
                  v-rjs="2"
                  draggable="false"
                  :style="[
                    isSvg(card) ?
                      {height: '-webkit-fill-available'}
                    : '',
                  ]"
                  :width="isSvg(card) ? '': '250'"
                  :height="isSvg(card) ? '': '260'"
                >
                <span 
                  v-else-if="displayType == 'text'"
                  :style="[
                    cardFontColor ?
                      {color: cardFontColor}
                    : '',
                    cardFontSize ?
                      {fontSize: cardFontSize}
                    : '',
                    cardContentPosition ?
                      {textAlign: cardContentPosition == 'flex-end' ? 'right' : (cardContentPosition == 'baseline' ? 'left' : 'center') }
                    : '',
                  ]"
                >
                  {{card.element[displaySource]}}
                </span>
              </div>

              <div
                class="card-title"
                :style="[
                  answerDisplayFontSize ?
                    {fontSize: answerDisplayFontSize}
                  : '',
                  retroBorderSize && ((isCorrect && card.isCorrect) || card.isWrongPick) ?
                    {marginBottom: 'calc(2px + ' + retroBorderSize + ')',}
                  : '',
                ]"
              >
                {{ 
                  answerDisplaySource ?
                    card.element[answerDisplaySource]
                  : ''
                }}
              </div>
            </div>
          </div>
          <span></span>
          <AudioA
            v-if="isShowAudioButton && cardAudioType === 'mp3' && cardAudioSource"
            :messageAudioFile="card.element[cardAudioSource]"
            :classAdded="'multi'"
            :isDataSound="true"
            :assetMediaAudioUrl="assetMediaAudioUrl"
          >
          </AudioA>
          <AudioB
            v-else-if="isShowAudioButton && cardAudioType && cardAudioSource"
            :classAdded="'multi'"
            :correctWord="cardAudioSource ? card.element[cardAudioSource] : ''"
            :audioType="cardAudioType"
          >
          </AudioB>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import AudioA from '../components/AudioA'
import AudioB from '../components/AudioB'
import Notify from '../components/Notify'

export default {
  name: 'MultiALayoutA',

  components: {
    'AudioA': AudioA,
    'AudioB': AudioB,
    'Notify': Notify,
  },

  props: [
    "secondFinalPage",
    "templateName",
    "templateMultiA",
    "templateMultiB",
    "templateMultiC",
    "titleStyle",
    "isFinal",
    "themeLayout",
    "cardQuestionWidth",
    "cardQuestionHeight",
    "cardQuestionBackgroundColor",
    "assetMediaImageUrl",
    "cards",
    "isDisplayInfo",
    "cardWidth",
    "cardHeight",
    "cardFontSize",
    "cardFontColor",
    "cardContentPosition",
    "displayedCards",
    "tag",
    "progressValue",
    "isCorrect",
    "disableCards",
    "isShowAudioButton",
    "finalImages",
    "displayType",
    "answerDisplaySource",
    "answerDisplayFontSize",
    "cardAudioType",
    "cardAudioSource",
    "displaySource",
    "assetMediaAudioUrl",
    "isDisableSubmit",
    "showAnimation",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "backgroundColorWrong",
    "retroBorderSize",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "showAnimation",
    "hideAnimation",
  ],

  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },

  methods: {
    isSvg(card) {
      return card.element[this.displaySource].indexOf('.svg') == (card.element[this.displaySource].length - 4);
    },

    clickCard: function(paramIndex) {
      this.displayedCards.forEach(function(paramCard, index) {
        if (paramCard.id === paramIndex)
          paramCard.isSelected = true;
        else
          paramCard.isSelected = false;
			});
      this.displayedCards.splice(0, 1, this.displayedCards[0]);
      this.myComponent.isDisabledButton = false;
    },
    
    showCardAnimation() {
      var w = '193px',
          h = '311px';

      if (this.cardWidth) 
        w = this.cardWidth;
      if (this.cardHeight) 
        h = this.cardHeight;
      
      this.displayedCards.forEach(function(paramCard, index) {
        let el = document.getElementById('card' + index);
        var i = index + 1;
        Velocity(el, {
          width: w,
          height: h,
          scale: 1
        }, { duration: 300 * i });
      });
    },

    hideCardAnimation() {
      $(".displayCards .card").each( function (i){
        var _this = $(this);
        _this
          .delay(150*i)
          .queue(function (next) {
            _this.animate({
              width: "1px",
              height: "1px"
            });
            next();
          });
          setTimeout(function() {
            _this.css('transform', 'scale(0)');
          }, 350 + (150*i));
      });
    }
  },
  watch: {
    showAnimation(data) {
      if (data) 
        this.showCardAnimation();
    },
    hideAnimation(data) {
      if (data) 
        this.hideCardAnimation();
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .cards {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;

    &.correct {
      .selected {
        overflow: visible;
        .card-box {
          .card-content {
            .card-img {
              border: 1px solid;
              margin-top: 25px;
              margin-bottom: 40px;
              margin-left: 12px;
              margin-right: 13px;
              height: calc(100% - 25px - 40px);
              width: calc(100% - 25px); 
            }
            .card-sound {
              top: 8px;
              position: absolute;
              z-index: 9;
              opacity: 1;
            }
            .card-title {
              opacity: 1;
              height: 38px;
            }
          }
        }
      }

      .card .card-box {
        cursor: default;
        pointer-events: none;
      }
    }

    &.displayCards {
      transform: scale(1) !important;
    }

    .card {
      position: relative;
      width: 1px;
      height: 1px;
      min-width: 1px;
      min-height: 1px;
      margin: 0 22.5px 0px 22.5px;
      overflow: hidden;
      -webkit-transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -moz-transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -ms-transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -o-transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform-origin: 50% 50%;
      z-index: 900;

      &.disabled-click {
        pointer-events: none;
      }

      &.wrong {
        .card-box {
          box-shadow: none;
          pointer-events: none;
          .card-content {
            .card-title {
              opacity: 1;
              height: 38px;
            }
          }
        }
      }
      .card-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 60px);
        width: 100%;
        margin: 0 0 12px;
        border-radius: 30px;
        background: #ffffff;
        z-index: 900;
        flex-direction: column;
        box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: all 0.3s ease-in-out 0s;

        &.no-audio {
          height: calc(100% - 9px);
        }

        .card-img-border {
          width: 100%;
          height: 100%;
          border-radius: 30px;
          position: absolute;
          border: 3px solid transparent;
          transition: all 0.3s ease-in-out 0s;
          z-index: 1;
        }

        .card-content {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;

          .card-sound {
            opacity: 0;
            top: 0;
            padding: 3px 3px 8px 2px;
            min-width: 55px;
            min-height: 39px;
            background: #ffffff;
            font-weight: 700;
            font-size: 30px;
            border: 1px solid #252525;
            border-radius: 5px;
            line-height: 20px;
            text-align: center;
            transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .card-img  {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: relative;
            align-items: center;
            transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }

            span {
              text-align: center;
            }
          }

          .card-title {
            opacity: 0;
            position: absolute;
            font-size: 26px;
            text-align: center;
            bottom: 0px;
            transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            -webkit-transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            display: flex;
            align-items: center;
          }
        }

      }
    }
  }
}
</style>

<style lang="scss">
.ipad, .mac {
  .cards-container {
    .cards {
      .card {
        .card-box {
          .card-content {
            .card-sound {
              align-items: flex-end;
            }
          }
        }
      }
    }
  }
}
</style>
