<template>
	<div
    class="multiple-choice"
    :class="[
      {
        'alpha' : templateName === templateMultiA,
        'numerik' : templateName === templateMultiB,
        'multi-c' : templateName === templateMultiC,
        'layout-a': themeLayout === 'layoutA',
        'layout-b': themeLayout === 'layoutB'
      }
    ]"
  >

    <HeaderB
      :stepsLength="stepsLength"
      :progressValue="progressValue"
      :isReRotateStars="isReRotateStars"
      :message="message"
      :messageGoogleVoice="(templateName === templateMultiA && themeLayout === 'layoutB') || templateName === templateMultiC || templateName === templateMultiB ? message : messageGoogleVoice"
      :finalMessage="finalMessage"
      :messageAudioFile="messageAudioFile"
      :messageAudioType="messageAudioType"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isFinal="isFinal"
      :finalMessageAudioFile="finalMessageAudioFile"
      :tag="tag"
      :isShowTag="templateName === templateMultiA ? true : false"
    >
    </HeaderB>

    <MultiA
      v-if="templateName === templateMultiA"
      :templateName="templateName"
      :templateMultiA="templateMultiA"
      :templateMultiB="templateMultiB"
      :templateMultiC="templateMultiC"
      :titleStyle="titleStyle"
      :isFinal="isFinal"
      :themeLayout="themeLayout"
      :cardQuestionWidth="cardQuestionWidth"
      :cardQuestionHeight="cardQuestionHeight"
      :cardQuestionBackgroundColor="cardQuestionBackgroundColor"
      :assetMediaImageUrl="assetMediaImageUrl"
      :cards="cards"
      :isDisplayInfo="isDisplayInfo"
      :cardWidth="cardWidth"
      :cardHeight="cardHeight"
      :cardFontSize="cardFontSize"
      :cardContentPosition="cardContentPosition"
      :displayedCards="displayedCards"
      :tag="tagCard"
      :progressValue="progressValue"
      :isCorrect="isCorrect"
      :disableCards="disableCards"
      :isShowAudioButton="isShowAudioButton"
      :finalImages="finalImages"
      :displayType="displayType"
      :answerDisplaySource="answerDisplaySource"
      :answerDisplayFontSize="answerDisplayFontSize"
      :cardAudioType="cardAudioType"
      :cardAudioSource="cardAudioSource"
      :displaySource="displaySource"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :secondFinalPage="secondFinalPage"
      :themeLocation="themeLocation"
      :themeName="themeName"
      :finalType="finalType"
      :correctCards="correctCards"
      :wrongBorderColor="wrongBorderColor"
      :selectedBorderColor="selectedBorderColor"
      :correctBorderColor="correctBorderColor"
      :backgroundColorWrong="backgroundColorWrong"
      :retroBorderSize="retroBorderSize"
      :cardFontColor="cardFontColor"
      :cardPaddingTop="cardPaddingTop"
      :cardPaddingBottom="cardPaddingBottom"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :showAnimation="showAnimation"
      :hideAnimation="hideAnimation"
    >
    </MultiA>

    <MultiB
      v-if="templateName === templateMultiB"
      :templateName="templateName"
      :templateMultiA="templateMultiA"
      :templateMultiB="templateMultiB"
      :templateMultiC="templateMultiC"
      :titleStyle="titleStyle"
      :isFinal="isFinal"
      :themeLayout="themeLayout"
      :cardQuestionDisplayType="cardQuestionDisplayType"
      :cardQuestionContentPosition="cardQuestionContentPosition"
      :cardQuestionFontColor="cardQuestionFontColor"
      :cardQuestionFontSize="cardQuestionFontSize"
      :cardQuestionWidth="cardQuestionWidth"
      :cardQuestionHeight="cardQuestionHeight"
      :cardQuestionBackgroundColor="cardQuestionBackgroundColor"
      :assetMediaImageUrl="assetMediaImageUrl"
      :cards="cards"
      :isDisplayInfo="isDisplayInfo"
      :cardWidth="cardWidth"
      :cardHeight="cardHeight"
      :cardFontSize="cardFontSize"
      :cardContentPosition="cardContentPosition"
      :displayedCards="displayedCards"
      :tag="tagCard"
      :progressValue="progressValue"
      :isCorrect="isCorrect"
      :disableCards="disableCards"
      :isShowAudioButton="isShowAudioButton"
      :finalImages="finalImages"
      :displayType="displayType"
      :answerDisplaySource="answerDisplaySource"
      :cardAudioType="cardAudioType"
      :cardAudioSource="cardAudioSource"
      :cardQuestionAudioType="cardQuestionAudioType"
      :displaySource="displaySource"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :secondFinalPage="secondFinalPage"
      :themeLocation="themeLocation"
      :themeName="themeName"
      :finalType="finalType"
      :correctCards="correctCards"
      :isDocumentReady="isDocumentReady"
      :valueSource="valueSource"
      :cardQuestionSource="cardQuestionSource"
      :cardQuestionAudioSource="cardQuestionAudioSource"
      :wrongBorderColor="wrongBorderColor"
      :selectedBorderColor="selectedBorderColor"
      :correctBorderColor="correctBorderColor"
      :backgroundColorWrong="backgroundColorWrong"
      :retroBorderSize="retroBorderSize"
      :cardFontColor="cardFontColor"
      :cardPaddingTop="cardPaddingTop"
      :cardPaddingBottom="cardPaddingBottom"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :showAnimation="showAnimation"
      :hideAnimation="hideAnimation"
      :isTwoWrongAnswers="isTwoWrongAnswers"
      :cardQuestionPaddingWidth="cardQuestionPaddingWidth"
      :cardQuestionPaddingTop="cardQuestionPaddingTop"
      :cardQuestionPaddingBottom="cardQuestionPaddingBottom"
      :cardQuestionBottomMargin="cardQuestionBottomMargin"
      :displaySourceCorrect="displaySourceCorrect"
      :displaySourceWrong="displaySourceWrong"
      :displayTypeCorrect="displayTypeCorrect"
      :displayTypeWrong="displayTypeWrong"
    >
    </MultiB>

    <MultiC
      v-if="templateName === templateMultiC"
      :templateName="templateName"
      :templateMultiA="templateMultiA"
      :templateMultiB="templateMultiB"
      :templateMultiC="templateMultiC"
      :titleStyle="titleStyle"
      :isFinal="isFinal"
      :themeLayout="themeLayout"
      :cardQuestionWidth="cardQuestionWidth"
      :cardQuestionHeight="cardQuestionHeight"
      :cardQuestionBackgroundColor="cardQuestionBackgroundColor"
      :assetMediaImageUrl="assetMediaImageUrl"
      :cards="cards"
      :isDisplayInfo="isDisplayInfo"
      :cardWidth="cardWidth"
      :cardHeight="cardHeight"
      :cardFontSize="cardFontSize"
      :cardContentPosition="cardContentPosition"
      :displayedCards="displayedCards"
      :tag="tagCard"
      :progressValue="progressValue"
      :isCorrect="isCorrect"
      :disableCards="disableCards"
      :isShowAudioButton="isShowAudioButton"
      :finalImages="finalImages"
      :displayType="displayType"
      :answerDisplaySource="answerDisplaySource"
      :answerDisplayFontSize="answerDisplayFontSize"
      :cardAudioType="cardAudioType"
      :cardAudioSource="cardAudioSource"
      :displaySource="displaySource"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :secondFinalPage="secondFinalPage"
      :themeLocation="themeLocation"
      :themeName="themeName"
      :finalType="finalType"
      :correctCards="correctCards"
      :wrongBorderColor="wrongBorderColor"
      :selectedBorderColor="selectedBorderColor"
      :correctBorderColor="correctBorderColor"
      :backgroundColorWrong="backgroundColorWrong"
      :retroBorderSize="retroBorderSize"
      :cardFontColor="cardFontColor"
      :cardPaddingTop="cardPaddingTop"
      :cardPaddingBottom="cardPaddingBottom"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :showAnimation="showAnimation"
      :hideAnimation="hideAnimation"
    >
    </MultiC>

    <FooterA
      :flipClass="flipClass"
      :isDisabled="isDisabledButton"
      :isDisableClick="isDisableClick"
      :nextText="actionNextText"
      :validateText="actionValidateText"
      :finalActionNextText="finalActionNextText"
      :actionTextColor="myHome.theme.actionTextColor"
      :activeButtonImg="myHome.getThemeImage('bouton-actif.svg')"
      :inactiveButtonImg="myHome.getThemeImage('bouton-inactif.svg')"
      :isFinal="isFinal"
    >
    </FooterA>
	</div>
</template>

<script>
import Vue from 'vue'
import Velocity from 'velocity-animate'
import MultiA from '../components/MultiA'
import MultiB from '../components/MultiB'
import MultiC from '../components/MultiC'
import MultiModelA from '../models/Multi-a'
import MultiModelB from '../models/Multi-b'
import MultiModelC from '../models/Multi-c'
import HeaderB from '../components/HeaderB'
import FooterA from '../components/FooterA'
import { mapGetters, mapActions } from 'vuex'
import Util from '../helpers/Util'

export default {
  props: ['config'],
  components: {
    'HeaderB': HeaderB,
    'FooterA': FooterA,
    'MultiA': MultiA,
    'MultiB': MultiB,
    'MultiC': MultiC,
  },
  data () {
    return {
      myHome: this.$parent.myHome,
      myComponent: this,
      templateMultiA: 'multi-a',
      templateMultiB: 'multi-b',
      templateMultiC: 'multi-c',
      templateName: this.config.data.config.templateName,
      themeName: false,
      themeLocation: false,
      isShowAudioButton: true,
      isDisableSubmit: true,
      isDisableNext: true,
      isShowAllCorrect: false,
      isCorrect: false,
      isDisplayInfo: false,
      isSwitch: false,
      ifProgressAddFilledClass: 0,
      ifProgressValue: 0,
      progressValue: 0,
      cards: [],
			correctCards: [],
			correctIndexCard: 0,
      stepsLength: 0,
      numSteps: 0,
      switchCounter: 0,
      playedSound: '',
      audioHtml: '',
      audio: '',
			weeks: this.week,
      numCardWrong: 0,
      titleStyle: false,
      starFilled: [],
      flipNextButton: false,
      hideCards: false,
      flipClass: '',
      titleStepNum: 0,
      disableSubmitButton: false,
      disableNextButton: false,
      disableCards: false,
      tempHide: false,
      displayedCards: [],
      secondFinalPage: false,
      themeLayout: false,
      cardQuestionBackgroundColor: '',
      cardQuestionWidth: '',
      cardQuestionHeight: '',
      cardWidth: '',
      cardHeight: '',
      cardDefaultHeight: 169,
      isQCardHasAnimationShow: false,
      isQCardHasAnimationHide: false,
      cardFontSize: '',
      cardContentPosition: false,
      cardAudioType: 'mp3',
      cardAudioSource: false,
      cardQuestionAudioType: 'mp3',
      images: '',
      displayType: false,
      displaySource: false,
      answerDisplaySource: false,
      answerDisplayFontSize: false,
      cardFontColor: false,
      cardFontSize: false,
      assetMediaAudioUrl: false,
      isReRotateStars: false,
      message: '',
      messageGoogleVoice: '',
      finalMessage: '',
      messageAudioFile: false,
      messageAudioType: false,
      isFinal: false,
      finalMessageAudioFile: false,
      isDisabledButton: true,
      isDisableClick: false,
      actionNextText: false,
      actionValidateText: false,
      finalActionNextText: false,
      tag: 'ou',
      assetMediaImageUrl: false,
      finalImages: [],
      tag: false,
      tagCard: false,
      finalType: false,
      question: [],
      isDocumentReady: false,
      valueSource: false,
      cardQuestionSource: false,
      cardQuestionAudioSource: false,
      wrongBorderColor: false,
      selectedBorderColor: false,
      correctBorderColor: false,
      backgroundColorWrong: false,
      retroBorderSize: false,
      cardPaddingTop: false,
      cardPaddingBottom: false,
      cardPaddingHeight: false,
      cardPaddingWidth: false,
      showAnimation: false,
      hideAnimation: false,
      isTwoWrongAnswers: false,
      cardQuestionPaddingWidth : false,
      cardQuestionPaddingTop : false,
      cardQuestionPaddingBottom : false,
      cardQuestionBottomMargin : false,
      wrongAnswerLimit: 2,
      displaySourceCorrect: false,
      displaySourceWrong: false,
      displayTypeCorrect: false,
      displayTypeWrong: false,
      cardQuestionDisplayType: false,
      cardQuestionContentPosition: false,
      cardQuestionFontColor: false,
      cardQuestionFontSize: false,
		}
  },

  computed: {
    ...mapGetters(['getMultiObjectData'])
  },

  methods: {
    ...mapActions(['setMultiObjectData']),

    getElementById: function (id) {
      return document.getElementById(id);
    },
    showCardsAnimate: async function (paramData = 1, isTwoWrongAnswers = false) {
      this.showAnimation = true;
      this.isTwoWrongAnswers = isTwoWrongAnswers;
      await Util.setWait( _ => {
        this.showAnimation = false;
        this.isTwoWrongAnswers = false;
      }, 1);
    },
    hideCardsAnimate: async function (isTwoWrongAnswers = false) {
      this.hideAnimation = true;
      this.isTwoWrongAnswers = isTwoWrongAnswers;
      await Util.setWait( _ => {
        this.hideAnimation = false;
        this.isTwoWrongAnswers = false;
      }, 1);
    },

    onPlayAudio: function(sound, isDataSound = true) {
      if(typeof this.audio.ended === 'undefined' || this.audio.ended) {
        var audio, source = (isDataSound) ? this.config.data.config.assetMediaAudioUrl : '';

        audio = new Audio(source + sound);
        this.audio = audio;
        audio.play();
      }
    },

    onReset: function() {
			this.resetCards();
    },

    resetCards: function() {
      var _this = this;
      this.getCurrentCards(1, 3);

      document.getElementsByTagName("body")[0].classList.remove("finish");
      this.myHome.changeBackground(false);

      this.isDisabledButton = true;
			this.isDisableNext = true;
			this.isFinal = false;
      this.isReRotateStars = false;
			this.isShowAllCorrect = false;
			this.isCorrect = false;
			this.isSwitch = false;
			this.correctCards = [];
      this.numSteps = 0;
      this.progressValue = 0;
      this.titleStepNum = 0;
      this.ifProgressValue = 0;
      this.ifProgressAddFilledClass = 0;
      this.secondFinalPage = false;
      this.cardQuestionBackgroundColor = this.config.data.config.hasOwnProperty('theme') ?
        this.config.data.config.theme.hasOwnProperty('cardQuestionBackgroundColor')?
          this.config.data.config.theme.cardQuestionBackgroundColor :
          false
        :
        false;

      if (this.config.data.config.hasOwnProperty('theme')) {
        this.themeLocation = this.config.data.config.theme.hasOwnProperty('themeLocation') ? this.config.data.config.theme.themeLocation : false;
        this.themeName = this.config.data.config.theme.hasOwnProperty('themeName') ? this.config.data.config.theme.themeName : false;
        this.wrongBorderColor = this.config.data.config.theme.hasOwnProperty("wrongBorderColor") ? this.config.data.config.theme.wrongBorderColor : false;
        this.selectedBorderColor = this.config.data.config.theme.hasOwnProperty("selectedBorderColor") ? this.config.data.config.theme.selectedBorderColor : false;
        this.correctBorderColor = this.config.data.config.theme.hasOwnProperty("correctBorderColor") ? this.config.data.config.theme.correctBorderColor : false;
        this.backgroundColorWrong = this.config.data.config.theme.hasOwnProperty("backgroundColorWrong") ? this.config.data.config.theme.backgroundColorWrong : false;
      }

      if (this.config.data.config.hasOwnProperty('steps')) {
        this.actionNextText = this.config.data.config.steps.hasOwnProperty('actionNextText') ? this.config.data.config.steps.actionNextText : '';
        this.actionValidateText = this.config.data.config.steps.hasOwnProperty('actionValidateText') ? this.config.data.config.steps.actionValidateText : '';
        this.message = this.config.data.config.steps.hasOwnProperty('message') ? this.config.data.config.steps.message : '';
        this.messageGoogleVoice = this.config.data.config.steps.hasOwnProperty('message') ? this.config.data.config.steps.messageGoogleVoice : '';
        this.messageAudioType = this.config.data.config.steps.hasOwnProperty('messageAudioType') ? this.config.data.config.steps.messageAudioType : 'mp3';
        this.messageAudioFile = this.config.data.config.steps.hasOwnProperty('messageAudioFile') ? this.config.data.config.steps.messageAudioFile : false;
        this.stepsLength = this.config.data.config.steps.hasOwnProperty('numberSteps') ? parseInt(this.config.data.config.steps.numberSteps) : 0;
        this.tag = this.config.data.config.steps.hasOwnProperty('tag') ? this.config.data.config.steps.tag : '';
        this.tagCard = this.config.data.config.steps.hasOwnProperty('tagCard') ? this.config.data.config.steps.tagCard : '';
      }

      if (this.config.data.config.hasOwnProperty('layout')) {
        this.cardWidth = this.config.data.config.layout.hasOwnProperty('cardWidth') ? this.config.data.config.layout.cardWidth : false;
        this.cardHeight = this.config.data.config.layout.hasOwnProperty('cardHeight') ? this.config.data.config.layout.cardHeight : false;
        this.cardQuestionWidth = this.config.data.config.layout.hasOwnProperty('cardQuestionWidth') ? this.config.data.config.layout.cardQuestionWidth : false;
        this.cardQuestionHeight = this.config.data.config.layout.hasOwnProperty('cardQuestionHeight') ? this.config.data.config.layout.cardQuestionHeight : false;
        this.cardQuestionPaddingWidth = this.config.data.config.layout.hasOwnProperty('cardQuestionPaddingWidth') ? this.config.data.config.layout.cardQuestionPaddingWidth : false;
        this.cardQuestionPaddingTop = this.config.data.config.layout.hasOwnProperty('cardQuestionPaddingTop') ? this.config.data.config.layout.cardQuestionPaddingTop : false;
        this.cardQuestionPaddingBottom = this.config.data.config.layout.hasOwnProperty('cardQuestionPaddingBottom') ? this.config.data.config.layout.cardQuestionPaddingBottom : false;
        this.cardQuestionBottomMargin = this.config.data.config.layout.hasOwnProperty('cardQuestionBottomMargin') ? this.config.data.config.layout.cardQuestionBottomMargin : false;
        this.cardFontSize = this.config.data.config.layout.hasOwnProperty('cardFontSize') ? this.config.data.config.layout.cardFontSize : false;
        this.cardContentPosition = this.config.data.config.layout.hasOwnProperty('cardContentPosition') ?
          this.config.data.config.layout.cardContentPosition === 'left' ?
            'start'
          : this.config.data.config.layout.cardContentPosition === 'right' ?
                'flex-end'
              : 'center'
            : 'start';
        this.displayType = this.config.data.config.layout.hasOwnProperty('displayType') ? this.config.data.config.layout.displayType : false;
        this.displaySource = this.config.data.config.layout.hasOwnProperty('displaySource') ? this.config.data.config.layout.displaySource : false;
        this.answerDisplaySource = this.config.data.config.layout.hasOwnProperty('answerDisplaySource') ? this.config.data.config.layout.answerDisplaySource : false;
        this.answerDisplayFontSize = this.config.data.config.layout.hasOwnProperty('answerDisplayFontSize') ? this.config.data.config.layout.answerDisplayFontSize : false;
        this.cardFontColor = this.config.data.config.layout.hasOwnProperty('cardFontColor') ? this.config.data.config.layout.cardFontColor : false;
        this.cardFontSize = this.config.data.config.layout.hasOwnProperty('cardFontSize') ? this.config.data.config.layout.cardFontSize : false;
        this.cardAudioType = this.config.data.config.layout.hasOwnProperty('cardAudioType') ? this.config.data.config.layout.cardAudioType : 'mp3';
        this.cardAudioSource = this.config.data.config.layout.hasOwnProperty('cardAudioSource') ? this.config.data.config.layout.cardAudioSource : false;
        this.cardQuestionAudioType = this.config.data.config.layout.hasOwnProperty('cardQuestionAudioType') ? this.config.data.config.layout.cardQuestionAudioType : 'mp3';
        this.themeLayout = this.config.data.config.layout.hasOwnProperty('format') ? this.config.data.config.layout.format : 'layoutA';
        this.retroBorderSize = this.config.data.config.layout.hasOwnProperty('retroBorderSize') ? this.config.data.config.layout.retroBorderSize : false;
        this.cardPaddingTop = this.config.data.config.layout.hasOwnProperty('cardPaddingTop') ? this.config.data.config.layout.cardPaddingTop : false;
        this.cardPaddingBottom = this.config.data.config.layout.hasOwnProperty('cardPaddingBottom') ? this.config.data.config.layout.cardPaddingBottom : false;
        this.cardPaddingHeight = this.config.data.config.layout.hasOwnProperty('cardPaddingHeight') ? this.config.data.config.layout.cardPaddingHeight : false;
        this.cardPaddingWidth = this.config.data.config.layout.hasOwnProperty('cardPaddingWidth') ? this.config.data.config.layout.cardPaddingWidth : false;
        this.displaySourceCorrect = this.config.data.config.layout.hasOwnProperty('displaySourceCorrect') ? this.config.data.config.layout.displaySourceCorrect : false;
        this.displaySourceWrong = this.config.data.config.layout.hasOwnProperty('displaySourceWrong') ? this.config.data.config.layout.displaySourceWrong : false;
        this.displayTypeCorrect = this.config.data.config.layout.hasOwnProperty('displayTypeCorrect') ? this.config.data.config.layout.displayTypeCorrect : false;
        this.displayTypeWrong = this.config.data.config.layout.hasOwnProperty('displayTypeWrong') ? this.config.data.config.layout.displayTypeWrong : false;
        this.cardQuestionDisplayType = this.config.data.config.layout.hasOwnProperty('cardQuestionDisplayType') ? this.config.data.config.layout.cardQuestionDisplayType : false;
        this.cardQuestionContentPosition = this.config.data.config.layout.hasOwnProperty('cardQuestionContentPosition') ? this.config.data.config.layout.cardQuestionContentPosition : false;
        this.cardQuestionFontColor = this.config.data.config.layout.hasOwnProperty('cardQuestionFontColor') ? this.config.data.config.layout.cardQuestionFontColor : false;
        this.cardQuestionFontSize = this.config.data.config.layout.hasOwnProperty('cardQuestionFontSize') ? this.config.data.config.layout.cardQuestionFontSize : false;
      }

      if (this.config.data.config.hasOwnProperty('final')) {
        this.finalActionNextText = this.config.data.config.final.hasOwnProperty('actionNextText') ? this.config.data.config.final.actionNextText : false;
        this.finalImages = this.config.data.config.final.hasOwnProperty('finalImages') ? this.config.data.config.final.finalImages : false;
        this.finalMessage = this.config.data.config.final.hasOwnProperty('message') ? this.config.data.config.final.message : false;
        this.finalMessageAudioFile = this.config.data.config.final.hasOwnProperty('messageAudioFile') ? this.config.data.config.final.messageAudioFile : false;
        this.finalType = this.config.data.config.final.hasOwnProperty('type') ? this.config.data.config.final.type : 'finalA';
      }

      this.assetMediaImageUrl = this.config.data.config.hasOwnProperty('assetMediaImageUrl') ? this.config.data.config.assetMediaImageUrl : false;
      this.assetMediaAudioUrl = this.config.data.config.hasOwnProperty('assetMediaAudioUrl') ? this.config.data.config.assetMediaAudioUrl : false;
      this.templateName = this.config.data.config.hasOwnProperty('templateName') ? this.config.data.config.templateName : false;
      this.valueSource = this.config.data.config.hasOwnProperty('valueSource') ? this.config.data.config.valueSource : false;
      this.cardQuestionSource = this.config.data.config.hasOwnProperty('cardQuestionSource') ? this.config.data.config.cardQuestionSource : 'imageFileA';
      this.cardQuestionAudioSource = this.config.data.config.hasOwnProperty('cardQuestionAudioSource') ? this.config.data.config.cardQuestionAudioSource : false;
      this.wrongAnswerLimit = this.config.data.config.hasOwnProperty('wrongAnswerLimit') ? parseInt(this.config.data.config.wrongAnswerLimit) : 2;

      setTimeout(function() {
        if (_this.themeLayout === 'layoutB' && _this.templateName === _this.templateMultiB) {
          _this.isQCardHasAnimationShow = true;
          _this.isQCardHasAnimationHide = true;
          _this.showCardsAnimate(2);
        }
        else if (_this.themeLayout === 'layoutB' && _this.templateName === _this.templateMultiA)
          _this.showCardsAnimate(3);
        else {
          if (_this.templateName != _this.templateMultiA) {
            _this.showCardsAnimate();
            _this.checkingQuestionCardHeight();
          }
          else
            _this.showCardsAnimate();
        }
      }, 100);
		},

    checkingQuestionCardHeight: function() {
      var cardBox = document.getElementById('qcard-layout-b');
      if (cardBox && (cardBox.children[0].offsetHeight % cardBox.children[0].children[0].offsetHeight) % 2 == 0 ) {
        var temp = cardBox.children[0].offsetHeight;
        cardBox.children[0].style.height = (temp + 1) + 'px';
      }
		},

    onSubmit: async function() {
      this.fadeCard = [];
      var _this = this,
          starTimer = 1000,
          flipBtnTimer = 1000;

      if (this.isDisableClick == true)
        return false;
      else {
        this.isDisableClick = true;

        this.displayedCards.forEach(function(paramCard) {
          if (paramCard.isSelected) {
            if (paramCard.isCorrect) {
              _this.isCorrect = true;
              paramCard.isUsed = true;
              _this.isDisplayInfo = true;
            }
            else {
              _this.numCardWrong = _this.numCardWrong + 1;
              paramCard.isWrongPick = true;
              paramCard.isUsed = true;
              paramCard.isSelected = false;
              _this.isDisabledButton = true;
              _this.isDisableClick = false;
            }
          }
        });

        this.displayedCards.splice(0, 1, this.displayedCards[0]);

        if (_this.numCardWrong === _this.wrongAnswerLimit) {
          _this.isDisableClick = true;
            _this.disableCards = true;
          setTimeout( _ => {
            _this.isDisableClick = false;
            _this.disableCards = true;
            _this.changeCards(true);
          }, 1000);
        }

        if (this.isCorrect) {
          if (this.switchCounter === 0) {
            if (this.templateName === this.templateMultiC) {
              starTimer = 1800;
              flipBtnTimer = 1000;
            }
            await Util.setWait( _ => {
              this.progressValue++;
            }, starTimer);

            await Util.setWait( _ => {
              this.flipClass = true;
              this.isSwitch = true;
              this.switchCounter = 0;
            }, flipBtnTimer);

            await Util.setWait( _ => {
              this.isDisableClick = false;
            }, 100);
          }
        }
      }
		},

		changeCards: function(isTwoWrongAnswers) {
      var _this = this,
          showTimer = 1500,
          hideTimer = 0;
      this.isDisplayInfo = false;
      this.displayedCards.forEach(function(paramCard) {
        paramCard.isSelected = false;
        paramCard.isWrongPick = false;
      });

      this.displayedCards.splice(0, 1, this.displayedCards[0]);

      if (this.templateName === this.templateMultiB || (this.templateName === this.templateMultiA && this.themeLayout === "layoutB")) {
        hideTimer = 300;
        showTimer = this.templateName === this.templateMultiB && this.themeLayout != "layoutB" && isTwoWrongAnswers ? 1800 : 2600;
      }
      setTimeout(function() {
        _this.hideCardsAnimate(isTwoWrongAnswers);
      }, hideTimer);

      setTimeout(function() {
        var showAnimationParam = 0;
        if (!_this.isFinal) {
          if(_this.templateName === _this.templateMultiA) {
            if(isTwoWrongAnswers)
              _this.cards = MultiModelA.getElements(1, 3, _this.correctIndexCard);
            else
              _this.cards = MultiModelA.getElements(1, 3);
            MultiModelA.debug();
          }
          else if(_this.templateName === _this.templateMultiB){
            if(isTwoWrongAnswers)
              _this.cards = MultiModelB.getElements(1, 3, _this.correctIndexCard);
            else
              _this.cards = MultiModelB.getElements(1, 3);
            MultiModelB.debug();
          }
          else {
            if(isTwoWrongAnswers)
              _this.cards = MultiModelC.getElements(1, 3, _this.correctIndexCard);
            else
              _this.cards = MultiModelC.getElements(1, 3);
            MultiModelC.debug();
          }

          _this.setupCurrentCards();
          _this.numCardWrong = 0;
          if (_this.themeLayout === 'layoutB' && _this.templateName === _this.templateMultiB) {
            showAnimationParam = 2;
            _this.isQCardHasAnimationShow = true;
            _this.isQCardHasAnimationHide = true;
          }
          else if (_this.themeLayout === 'layoutB' && _this.templateName === _this.templateMultiA)
            showAnimationParam = 3;
          else
            showAnimationParam = 1;
        }
        _this.isDisableClick = false;

        if (!isTwoWrongAnswers) {
          setTimeout(function() {
            _this.showCardsAnimate(showAnimationParam);
          }, 300);
        }
        else
           _this.showCardsAnimate(showAnimationParam, isTwoWrongAnswers);
        _this.disableCards = false;
      }, showTimer);
		},

    onNext: function() {
      var _this = this,
          timer = 1300;

      if (this.isDisableClick)
        return false;
      else {
        this.isDisableClick = true;
        this.saveCorrectCard();
        if (this.templateName === this.templateMultiB || (this.templateName === this.templateMultiA && this.themeLayout === "layoutB"))
          timer = 2500;

        this.isCorrect = false;
        setTimeout(function() {
          _this.tempHide = false;

          if (_this.progressValue === _this.stepsLength) {
            setTimeout(function() {
              document.getElementsByTagName("body")[0].classList.add("finish");
              document.getElementsByTagName("html")[0].style = "";
              _this.myHome.changeBackground(true);
            }, 100);

            if (_this.finalType === 'finalB') {
              _this.secondFinalPage = true;
              _this.correctCards = _this.finalImages;
            }
            else
              _this.secondFinalPage = false;

            _this.isShowAllCorrect = true;
            _this.isFinal = true;
            setTimeout(function() {
              _this.isReRotateStars = true;
            }, 300);
          }
          else {
            _this.isDisabledButton = true;
            _this.isSwitch = false;
          }
          _this.flipClass = false;
          _this.numCardWrong = 0;
        }, timer);
        this.changeCards(false);
      }
		},

    saveCorrectCard: function() {
      var _this = this;

      this.displayedCards.forEach(function(paramCard, index) {
        if (paramCard.isSelected) {
          if (paramCard.isCorrect) {
            _this.correctCards[_this.progressValue - 1] = paramCard;
            _this.correctCards[_this.progressValue - 1].sound = _this.tagCard;
          }
        }
      });
		},

		setupCurrentCards: function() {
			var _this = this;

      this.displayedCards.forEach(function(paramCard, paramIndex) {
				if (paramCard.isCorrect)
          _this.correctIndexCard = paramIndex;

        paramCard.isSelected = false;
        paramCard.isShow = true;
        paramCard.isWrongPick = false;
        paramCard.isUsed = false;
        paramCard.id = paramIndex;
      });

      this.displayedCards.splice(0, 1, this.displayedCards[0]);
		},

    getCurrentCards: function(paramCorrect = 1, paramWrong = 3) {
      if(this.templateName === this.templateMultiA) {
        this.cards = MultiModelA.getElements(paramCorrect, paramWrong);
        this.displayedCards = this.cards;
        MultiModelA.debug();
      }
      else if(this.templateName === this.templateMultiB){
        this.cards = MultiModelB.getElements(paramCorrect, paramWrong);
        this.displayedCards = this.cards.answers;
        MultiModelB.debug();
      }
      else {
        this.cards = MultiModelC.getElements(paramCorrect, paramWrong);
        this.displayedCards = this.cards;
        MultiModelC.debug();
      }

      this.isDocumentReady = true;
			this.setupCurrentCards();
		},
  },

	created: async function() {
    if(this.templateName === this.templateMultiA) {
      try {
        await MultiModelA.populateElements(this.config);
      }
      catch (err) { // need to catch error or rejected promise
        console.error(err);
      }

      this.themeLayout = this.config.data.config.layout.hasOwnProperty('format') ? this.config.data.config.layout.format : 'layoutA';
      this.isShowAudioButton = this.config.data.config.hasOwnProperty('showCardAudioButton') ?
        this.config.data.config.showCardAudioButton == 'yes' ?
          true
        : false
      :
        this.themeLayout ?
          this.config.data.config.layout.hasOwnProperty('showCardAudioButton') ?
            this.config.data.config.layout.showCardAudioButton == 'yes' ?
              true
            : false
          :
            true
        :
          true
      ;
    }
    else if(this.templateName === this.templateMultiB) {
      var getHtmlTag = document.getElementsByTagName("html");
      if (this.themeLayout != 'layoutB') {
        getHtmlTag[0].classList.add('layoutB-version');
      }

      try {
        await MultiModelB.populateElements(this.config);
      }
      catch (err) { // need to catch error or rejected promise
        console.error(err);
      }
    }
    else {
      try {
        await MultiModelC.populateElements(this.config);
      }
      catch (err) { // need to catch error or rejected promise
        console.error(err);
      }
    }
    this.resetCards();
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.multiple-choice {
  height: 100%;
  display: flex;
  justify-content: normal;
  align-items: center;
  flex-direction: column;
}
</style>
