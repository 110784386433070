<template>
  <div
    class="wheel"
    :class="wheelName+'-wheel'"
    :previousText="previousText"
  >
    <Notify
      v-if="previousText && isRemoveWheelBorder == 'active'"
      :key="wheelName"
      addedClass="asso"
      :hasBorder="false"
    >
    </Notify>
    <div
      class="wheel-container"
      :style="
      [
        wheelCardWidth ?
          {width: wheelCardWidth}
        : '',
      ]"
    >
      <img class="wheel-container-img" :src="background" alt="" draggable="false" ondragstart="return false;">
      <div
        class="wheel-overlay"
      >
        <div
          class="swipe-wheel"
          v-on:mousedown="swipeStartWheel($event)"
          v-on:mouseup="swipeEndWheel($event, wheelName)"
          v-on:touchstart="swipeStartWheel($event)"
          v-on:touchend="swipeEndWheel($event, wheelName)"
          :class="{disable: isButtonDisableWheel || isWheelDisableFromConfig}"
          :style="
          [
            wheelFadeData ?
              {backgroundColor: 'white'}
            : {backgroundColor: 'transparent'},
          ]"
        >
          <img class="overlay-img" :src="overlay" alt="" draggable="false" ondragstart="return false;">
        </div>
      </div>
      <div
        class="wheel-content"
        :ref="wheelName+'Wheel'"
      >
        <div
          v-if="!displayedWheel == []"
          v-for="(item, index) in displayedWheel"
          class="item"
          :class="[
            (item.isSelected) ? selectedClassName + ' selected' : '', 
            {
              paired: item.isPaired, 
              'criteria skip-paired':
              wheelIsCrit, 
              finishItem: item.imageSrc,
              wrong: item.wrong && !item.isPaired && !(numberOfDisable == 1 &&  isWheelDisableFromConfig && wrongBorderRestriction),
              correct: item.correct && !item.isPaired,
            }
          ]"
          v-bind:key="wheelName + '-'+ index"
          :isSelected="item.isSelected"
          :data-active="isRemoveWheelBorder"
          :data-value="!wheelIsCrit ? item.element[valueSource] : item.value.value"
          :index="item.index"
          :target-index="index"
          :target-wheel-type="wheelName"
          :style="[
              item.css,
              item.isPaired ?
                {
                  backgroundColor: wheelDisableBackgroundColor, 
                  borderColor: wheelDisableBackgroundColor
                } 
              : 
                {
                  borderTop: '2px solid ' + wheelBorderColor, 
                  borderBottom: '2px solid ' + wheelBorderColor
                },
              isSmoothEffect ?
                {transition: 'top 0.3s cubic-bezier(0, 0, 1, 1) 0s'} 
              : '',
              backgroundColorWrong && isRemoveWheelBorder && (item.wrong && !item.isPaired && !(numberOfDisable == 1 &&  isWheelDisableFromConfig && wrongBorderRestriction)) ?
                {backgroundColor: backgroundColorWrong}
              : '',
          ]"
        >
          <div 
            class="wheel-item-border"
            :style="[
              wheelRetroBorderSize && isRemoveWheelBorder && ((item.wrong && !item.isPaired && !(numberOfDisable == 1 &&  isWheelDisableFromConfig && wrongBorderRestriction)) || (item.correct && !item.isPaired)) ?
                {borderWidth: wheelRetroBorderSize}
              : '',
              wrongBorderColor && isRemoveWheelBorder && (item.wrong && !item.isPaired && !(numberOfDisable == 1 &&  isWheelDisableFromConfig && wrongBorderRestriction)) ?
                {borderColor: wrongBorderColor}
              : '',
              correctBorderColor && isRemoveWheelBorder && (item.correct && !item.isPaired) ?
                {borderColor: correctBorderColor}
              : '',
            ]"
          >
          </div>
          <span 
            class="wheel-item-content"
            :style="[
              wheelCardFontColor ? 
                {color: wheelCardFontColor}
              : '',
              wheelCardFontSize ?
                {fontSize: wheelCardFontSize}
              : '',
              wheelCardPaddingTop && !item.imageSrc ?
                {paddingTop: wheelCardPaddingTop}
              : '',
              wheelCardPaddingBottom && !item.imageSrc ?
                {paddingBottom: wheelCardPaddingBottom}
              : '',
              wheelCardPaddingWidth && !item.imageSrc ?
                {
                  paddingLeft: 'calc( ' + wheelCardPaddingWidth + ' / 2)',
                  paddingRight: 'calc( ' + wheelCardPaddingWidth + ' / 2)'
                }
              : '',
              wheelCardContentPosition && !item.imageSrc ?
                {
                  justifyContent: wheelCardContentPosition == 'left' ? 
                    'flex-start' 
                  : wheelCardContentPosition == 'right' ? 
                      'flex-end' 
                    : wheelCardContentPosition
                }
              : '',
            ]"
          >
            <img
              draggable="false"
              v-if="item.imageSrc"
              :src="item.imageSrc"
              class="bravo-img"
              v-rjs="2"
            >
            <span
              v-else-if="!wheelIsCrit && wheelDisplayType == 'text'"
              :style="[
                item.isPaired ? 
                  {opacity: wheelDisableElementOpacity} 
                : '', 
              ]"
            >
              {{wheelDisplaySource ? item.element[wheelDisplaySource] : ''}}
            </span>
            <img
              draggable="false"
              v-else-if="!wheelIsCrit && wheelDisplayType == 'image'"
              :src="assetMediaImageUrl + (wheelDisplaySource ? item.element[wheelDisplaySource] : '')"
              :style="[item.isPaired ? {'opacity': wheelDisableElementOpacity} : '']"
              v-rjs="2"
            >
            <img
              draggable="false"
              v-else-if="!wheelIsCrit"
              :src="assetMediaImageUrl + ''"
              :style="[item.isPaired ? {'opacity': wheelDisableElementOpacity} : '']"
              v-rjs="2"
            >
            <img
              draggable="false"
              v-else-if="wheelIsCrit && item.value.displayType == 'image'"
              :src="item.value.image"
              v-rjs="2"
            >
            <span
              v-else-if="wheelIsCrit && item.value.displayType == 'text'"
              :style="[
                (item.value.showTag == 'yes' || item.value.showTag == undefined) ? {backgroundImage: 'url(' + tagImage + ')'} : '',
                item.isPaired ? 
                  {opacity: wheelDisableElementOpacity} 
                : '', 
              ]"
            >
              {{item.value.text}}
            </span>
            <span
              v-else
              :style="[
                (item.value.showTag == 'yes' || item.value.showTag == undefined) ? {backgroundImage: 'url(' + tagImage + ')'} : '',
                item.isPaired ? 
                  {opacity: wheelDisableElementOpacity} 
                : '', 
              ]"
              :class="(item.value.showTag == 'yes' || item.value.showTag == undefined) ? '' : 'critImage'"
            >
            </span>
          </span>
        </div>
      </div>
    </div>
    <div
      class="wheel-btn"
      :style="buttonFinishStyle"
    >
      <span
        class="btn-up"
        v-on:click="onScroll(selectedClassName, 1)"
        :class="{disable: isButtonDisableWheel || isWheelDisableFromConfig}"
      >
        <img
          :src="buttonUp"
          :class="{disable: isButtonDisableWheel || isWheelDisableFromConfig}"
          v-rjs="2"
          draggable="false" 
        >
        <img
          :src="buttonUpDisable"
          :class="{disable: !isButtonDisableWheel || isWheelDisableFromConfig}"
          v-rjs="2"
          draggable="false" 
        >
      </span>
      <span
        class="btn-down"
        v-on:click="onScroll(selectedClassName, 0)"
        :class="{disable: isButtonDisableWheel || isWheelDisableFromConfig}"
      >
        <img
          :src="buttonDown"
          :class="{disable: isButtonDisableWheel || isWheelDisableFromConfig}"
          v-rjs="2"
          draggable="false" 
        >
        <img
          :src="buttonDownDisable"
          :class="{disable: !isButtonDisableWheel || isWheelDisableFromConfig}"
          v-rjs="2"
          draggable="false" 
        >
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Notify from '../components/Notify'

export default {
  name: 'WheelA',
  components: {
    'Notify': Notify,
  },
  props: [
    'wheelName',
    'background',
    'overlay',
    'isButtonDisableWheel',
    'isWheelDisableFromConfig',
    'wrongBorderRestriction',
    'displayedWheel',
    'isRemoveFirstWheelBorder',
    'wheelIsCrit',
    'wheelThemeColorBorder',
    'tagImage',
    'buttonFinishStyle',
    'buttonUp',
    'buttonUpDisable',
    'buttonDown',
    'buttonDownDisable',
    'wheelDisplayType',
    'wheelDisplaySource',
    'selectedClassName',
    "wheelCSSData",
    "wheelItem",
    "autoSpinDelay",
    "autoSpin",
    "wheelTypeThemeImage",
    "removeAllBorders",
    "previousText",
    "themeLocation",
    "themeName",
    "isFinal",
    "assetMediaImageUrl",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "backgroundColorWrong",
    "wheelBorderColor",
    "wheelDisableBackgroundColor",
    "wheelDisableElementOpacity",
    "valueSource",
    "numberOfDisable",
    "wheelCardFontColor",
    "wheelCardFontSize",
    "wheelCardPaddingTop",
    "wheelCardPaddingBottom",
    "wheelCardPaddingWidth",
    "wheelCardWidth",
    "wheelCardHeight",
    "wheelRetroBorderSize",
    "wheelCardContentPosition",
    "wheelFadeData"
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
      swipeUpY: 0,
      swipeDownY: 0,
      preventSwipe: false,
      isSmoothEffect: false,
      isRemoveWheelBorder: '',
      preventClickWheelScroll: false,
      images: '',
    }
  },
  methods: {
    swipeStartWheel: function(event) {
      if (this.preventSwipe)
        return false;

      this.swipeDownY = event.clientY;

      if (this.swipeDownY == undefined)
        this.swipeDownY = event.changedTouches[0].clientY;
    },
    swipeEndWheel: function(event, wheelType) {
      if (this.preventSwipe)
        return false;

      this.swipeUpY = event.clientY;

      if (this.swipeUpY == undefined)
        this.swipeUpY = event.changedTouches[0].clientY;

      if (this.swipeDownY > this.swipeUpY)
        this.onScroll(this.selectedClassName, 1);
      else if (this.swipeDownY < this.swipeUpY)
        this.onScroll(this.selectedClassName, 0);
    },
    onScroll: function(selectedClassName, action) {
      var tempWheel = document.getElementsByClassName(selectedClassName),
          selectedItems = document.getElementsByClassName('selected'),
          parent = this.$parent.$refs,
          tempLenght = 0,
          newIndex = 0,
          formula  = 1,
          delay = 0,
          _this = this,
          ctr = 0;

      if (this.myComponent.preventSwipe)
        return false;

      if(this.preventClickWheelScroll)
        return false;
      else
        this.preventClickWheelScroll = true;

      tempLenght = this.wheelItem.length;
      
      for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i].classList.contains('wrong') && selectedItems[i].getAttribute('target-wheel-type') == this.wheelName) {
          let index = selectedItems[i].getAttribute('target-index');
          this.displayedWheel[index].wrong = false;
        }
      }
      
      if (!parent.parentFirstWheel.isButtonDisableWheel) 
        parent.parentFirstWheel.isRemoveWheelBorder = '';
      if (!parent.parentSecondWheel.isButtonDisableWheel) 
        parent.parentSecondWheel.isRemoveWheelBorder = '';
      if (parent.hasOwnProperty('parentThirdWheel') && parent.parentThirdWheel) {
        if (!parent.parentThirdWheel.isButtonDisableWheel) 
          parent.parentThirdWheel.isRemoveWheelBorder = '';
      }
      
      this.isRemoveWheelBorder = '';

      formula = formula + this.myComponent.checkFormula(this.displayedWheel, formula, action);

      while (ctr < formula) {
        delay = 290 * ctr;
        this.rollWheelByStep(delay, this.displayedWheel, this.wheelCSSData, formula, action);
        ctr++;
      }

      setTimeout(function() {
        _this.preventClickWheelScroll = false;
      }, 500);

      var newFormula = formula;
      this.myComponent.afterScrollButton(newFormula);
    },
    rollWheelByStep: function(delay, displayedWheel, wheelCSSData, formula, action) {
      var _this = this;
      setTimeout(function() {
        if (formula > 1)
          _this.isSmoothEffect = true;
        else
          _this.isSmoothEffect = false;

        _this.setSlotStyle(action, displayedWheel, wheelCSSData, 0 , 1);
      }, delay);
    },
    spinWheel: function(wheel, iteration, numberOfRoll, i, transitionDelay, index, displayedWheel, wheelCSSData, finalImage, addedFormula) {
      var formula = Math.round(((iteration * numberOfRoll) * ((i * 0.3) + 1))) + addedFormula;
      var _this = this;
      transitionDelay = ((formula) - 1 == index) ? 0 : 16;
      this.setSlotStyle(0, displayedWheel, wheelCSSData, transitionDelay, 1);
    },
    setSlotStyle: function(event, displayedWheel, wheelCSSData , transitionSpeed = 0, alternateValue = 1) {
      var wheelLength = displayedWheel.length - 1;
      var tempWheelCSSData1 = 0;
      var tempWheelCSSData2 = [];
      var tempStorageIndex  = 0;
      var temp  = [];
      var index = 0;

      if (event) {
        tempWheelCSSData1 = wheelCSSData.splice(wheelLength, 1);
        wheelCSSData.splice(0, 0, tempWheelCSSData1[0]);
      }
      else {
        tempWheelCSSData1 = wheelCSSData.splice(0, 1);
        wheelCSSData.splice(wheelLength, 0, tempWheelCSSData1[0]);
      }

      for (let i = 0; i < displayedWheel.length; i++) {
        if (transitionSpeed) {
          wheelCSSData[i].css.transition = 'top 0.' + transitionSpeed + 's cubic-bezier(1, 1, 1, 1) 0s';
        }
        else {
          wheelCSSData[i].css.transition = '';
        }

        if (alternateValue) {
          displayedWheel[i].css = wheelCSSData[i].css;
          displayedWheel[i].isSelected = wheelCSSData[i].isSelected;
        }

        tempWheelCSSData2 = displayedWheel[i];
        displayedWheel.splice(i, 1, tempWheelCSSData2);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wheel {
  margin-right: 99px;
  position: relative;

  &.first-wheel {
      .item {
          .bravo-img {
              margin-left: 5px;
              width: 147px;
          }
      }
  }
  &:last-of-type {
    margin-left: -1px;
    margin-right: 0;
  }
  .wheel-container {
    width: 216px;
    height: 270px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 7px 9px;
    position: relative;
    overflow: hidden;

    .wheel-container-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .wheel-overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 99;
      padding: 7px 9px;
      transform: translateZ(1px);

      .swipe-wheel {
        width: 100%;
        height: 100%;
        transition: background 0.6s ease;

        &.disable {
            pointer-events: none;
        }
        
        .overlay-img {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
    .wheel-content {
      height: 100%;
      overflow: hidden;
      font-size: 33px;
      position: relative;
      z-index: 9;

    .item {
        @extend .display-flex;
        height: 126px;
        position: absolute;
        width: 100%;
        top: 71px;
        transition: top 0.5s cubic-bezier(0, 0, 0.7, 1.44) 0s;
        background: #fff;

        &.criteria {
          font-weight: 700;
  
          .wheel-item-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              @extend .display-flex;
              background-repeat: no-repeat;
              height: 100%;
              width: 100%;
              background-size: 62px 62px;
              background-position: center;
              margin-top: 6px;

              &.critImage {
                height: 100%;
                width: 100%;
                margin: 0;
              }
            }
          }
        }
        &[data-active='active'] {
          border: none;
        }
        &.correct[data-active='active'], &.wrong[data-active='active'] {
          z-index: 9999 !important;
          transition: background 1s ease !important;

          .wheel-item-border {
            transition: border 0.6s cubic-bezier(.29,.6,.7,1.44) !important;
          }
        }
        &.paired {
          margin-top: -2px;
          height: 130px;
          z-index: 999;
        }
        &.finishItem {
          z-index: 9999;
          height: 130px;
          border-top-width: 4px !important;
          border-bottom-width: 4px !important;
          margin-top: -2px;
        }
        .wheel-item-border {
          border: 0px solid transparent;
          position: absolute;
          width: 100%;
          height: calc(100% + 8px);
          left: 0;
          top: 0;
          margin-top: -4px;
        }
        .wheel-item-content {
          overflow: hidden;
          width: 100%;
          height: 100%;
          @extend .display-flex;

          span {
            margin-top: 4px;
            text-align: center;
          }
          img {
            max-height: 100%;
            width: auto;
            height: auto;
            max-width: 100%;
          }
          .bravo-img {
            height: 97px;
            margin-bottom: 1px;
            position: absolute;
            max-width: none;
            max-height: none;
          }
        }
      }
    }
  }
  .wheel-btn {
    @extend .display-flex;
    margin-top: 6px;
    padding-right: 1px;

    .disable {
      pointer-events: none;
    }
    .btn-up {
      margin-right: 15px;
    }
    span {
      width: 40px;
      height: 42px;
      display: inline-block;
      position: relative;
      cursor: pointer;

      img {
        position: absolute;
        visibility: visible;
        opacity: 1;
        transition: visibility 0.3s, opacity 0.3s cubic-bezier(1, 1, 1, 1) 0s;

        &.disable {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
}
.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
