import Vue from 'vue'

const API_URL = process.env.VUE_APP_API_URL;

export default {
	swap: function (data, x, y) {
		var temp = data[y];
		Vue.set(data, y, data[x]);
		Vue.set(data, x, temp);
	},

	shuffle: function (data) {
		var r, i = data.length;

		while (i > 1) {
			r = this.getRandom(i);
			--i;
			this.swap(data, r, i);
		}
	},

	getRandom: function (n) {
    	return Math.floor(Math.random() * n);
    },

	addElements: function (elements, data) {
        for (var i = 0; i < data.length; i++)
            elements.push(data[i]);
    },

  clearElements: function (elements) {
      while (elements.length > 0)
          elements.pop();
  },

  isEmpty: function (elements) {
      return (0 === elements.length);
  },

  reshuffle: function (a, b) {
      for (var i = 0; i < b.length; ++i) {
          for (var j = 1; a[i] === b[i]; ++j)
              this.swap(b, i, b.length - j);
      }
  },

  wait: function (milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
  },

  setWait: function (callback, milliseconds) {
      return this.wait(milliseconds).then(() => callback());
  },

  clone: function (obj) {
      return JSON.parse(JSON.stringify(obj));
  },

  assign: function (obj, props) {
      return Object.assign(this.clone(obj), props);
  },

  getRouterMode () {
    return (process.env.VUE_APP_ROUTER_MODE) ? process.env.VUE_APP_ROUTER_MODE : 'history';
  },

  getBaseUrl () {
    let baseUrl = process.env.VUE_APP_BASE_URL;
    return baseUrl;
  },

  getRouterBaseUrl () {
    let baseUrl = this.getBaseUrl();
    return (this.getRouterMode() !== 'history') ? baseUrl += '/#/' : baseUrl += '/';
  }
}
