<template>
  <div class="buttons">
    <div class="container">
      <div
        class="btn-container" 
        :class="{'flip-btn': flipClass}"
      >
          <div class="front">
              <ButtonA
                classNames = "btn btn-submit"
                :isDisabled = "isDisabled"
                :isDisableClick = "isDisableClick"
                :value = "validateText"
                buttonType = "1"
                :actionTextColor = "actionTextColor"
                :activeButtonImg = "activeButtonImg"
                :inactiveButtonImg = "inactiveButtonImg"
                v-if="!isFinal"
              >
              </ButtonA>
              <ButtonA
                classNames = "btn btn-reset"
                :isDisabled = "isDisabled"
                :isDisableClick = "isDisableClick"
                :value = "finalActionNextText"
                buttonType = "3"
                :actionTextColor = "actionTextColor"
                :activeButtonImg = "activeButtonImg"
                :inactiveButtonImg = "inactiveButtonImg"
                v-if="isFinal"
              >
              </ButtonA>
          </div>
          <div class="back">
              <ButtonA
                classNames = "btn btn-next"
                :isDisabled = "isDisabled"
                :isDisableClick = "isDisableClick"
                :value = "nextText"
                buttonType = "2"
                :actionTextColor = "actionTextColor"
                :activeButtonImg = "activeButtonImg"
                :inactiveButtonImg = "inactiveButtonImg"
                v-if="!isFinal"
              >
              </ButtonA>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import ButtonA from '../components/ButtonA'


  export default {
    name: 'ActionA',
    components: {
      'ButtonA': ButtonA
    },
    props: [
      "value",
      "isDisabled",
      "flipClass",
      "actionTextColor",
      "activeButtonImg",
      "inactiveButtonImg",
      "isFinal",
      "nextText",
      "validateText",
      "finalActionNextText",
      "isDisableClick"
    ],
    data () {
      return {
        myComponent: this.$parent.myComponent
      }
    },
    methods: {
    },
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.btn-container {
  display: flex;
  height: 60px;
  width: 100%;
  justify-content: center;
  
  &.flip-btn {
    transform: rotateY(180deg);
    pointer-events: none;
  }
  .front {
    z-index: 2;
    transform: rotateY(0deg);
    position: absolute;
    backface-visibility: hidden;
  }
  .back {
    transform: rotateY(180deg);
    position: absolute;
    backface-visibility: hidden;
  }
}
</style>
