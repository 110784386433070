import Store from '../store/Vuex';
import Api from './Api';

export default {
    setLocale(locale) {
        Store.dispatch('setLocale', locale);
    },

    getLocale() {
        return Store.getters.getLocale;
    },

    setI18n(i18n) {
        Store.dispatch('setI18n', i18n);
    },

    getI18n(key) {
        return Store.getters.getI18n(key);
    },

    async initialize(locale) {
        try {
            let params = {
                tname: process.env.VUE_APP_I18N_TABLE_NAME,
                pkname: 'application',
                pkvalue: process.env.VUE_APP_I18N_APP_NAME,
                skname: 'PK',
                skvalue: 'i18n',
                idxname: 'application-PK-index',
                projexp: 'SK1, fr, en'
            };
            let url = process.env.VUE_APP_API_URL + process.env.VUE_APP_READ_PATH;
            let response = await Api.get(url, params);
            let items = response.body.Items;
            let i18n = {};

            for (let item of items)
                i18n[item.SK1] = {fr: item.fr, en: item.en};

            if (locale)
                this.setLocale(locale);

            this.setI18n(i18n);
        }
        catch (err) {
            console.error(err);
        }
    }
};