import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const EMPTY_STRING = '';
const UNDERSCORE = '_';
const CHAR = 'char';
const RELATION = 'relation';
const Ordo = Object.create(Base);

Ordo.correctElementsBlacklist = [];
Ordo.correctElements = new Map();
Ordo.collections = new Map();

Ordo.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.correctElementsBlacklist, "correct_elements_blacklist");
    LocalStorage.saveLocalStorage(this.correctElements, "correct_elements_remaining");
    LocalStorage.saveLocalStorage(this.collections, "COLLECTIONS");
}

Ordo.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.correctElementsBlacklist);
    this.correctElements.clear();
    this.collections.clear();

    var config = this.configuration.data.config;
    var valueSource = config.valueSource;

    this.database = await this.populateCollections(config.suiteCollections);

    for (var element of this.database)
        this.setMapValue(this.correctElements, element, valueSource);

    if (RELATION === config.suiteType) {
        var items = await this.populateCollections(config.collections);

        for (var item of items)
            this.setMapValue(this.collections, item, config.collectionsSource);
    }
}

Ordo.getElements = function (n, reset) {
    if (n < 0)
        throw new Error("Negative argument is invalid.");

    var config = this.configuration.data.config;
    var delimiter = (CHAR === config.suiteType) ? EMPTY_STRING : UNDERSCORE;
    var elements = this.fetchElements(n, this.correctElements, this.correctElementsBlacklist);

    if (reset)
        Util.clearElements(this.currentBlacklist);

    var beginning = this.correctElementsBlacklist.length - elements.length;
    var current = this.correctElementsBlacklist.slice(beginning);
    Util.addElements(this.currentBlacklist, current);

    var obj = elements[0];
    var values = obj.element[config.valueSource].split(delimiter);

    obj.answer = values;
    obj.question = values.slice(0);

    if (RELATION === config.suiteType) {
        obj.relation = new Map();

        for (var value of obj.answer) {
            var element = this.getMapValue(this.collections, value);
            obj.relation.set(value, element);
        }
    }

    Util.shuffle(obj.question);
    Util.shuffle(obj.question);
    Util.reshuffle(obj.answer, obj.question);

    Util.clearElements(this.currentElements);
    Util.addElements(this.currentElements, elements);

    return this.currentElements;
}

export default Ordo;
