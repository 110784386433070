<template>
    <div class="stars-container">
        <span 
          class="star" 
          v-for="n in stepsLength" 
          v-bind:key="n" 
          v-bind:class="{rotatestar: rotateStar >= n}"
        >
            <img draggable="false" v-rjs="2" :src="myHome.getThemeImage('etoile-inactive.svg')" alt="" v-if="progressValue < n">
            <img draggable="false" v-rjs="2" :src="myHome.getThemeImage('etoile-active.svg')" alt="" v-if="progressValue >= n">
        </span>
    </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'StarsA',
    props: [
      "stepsLength",
      "progressValue",
      "isReRotateStars",
    ],
    data () {
      return {
        myComponent: this.$parent.myComponent,
        myHome: this.$parent.myHome,
        rotateStar: 0,
      }
    },
    methods: {
      reRotateStars: function() {
        var _this = this;
        for (let index = 0; index < this.progressValue; index++) {
          setTimeout(function() {
            _this.rotateStar = index + 1;
          }, (300 * index) + 300);
        }
      },
    },
    watch: {
      progressValue: function(data) {
        this.rotateStar = data;
      },
      isReRotateStars: function(data) {
        this.rotateStar = 0;
        if (data) 
          this.reRotateStars();
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.stars-container {
  display: flex;
  justify-content: center;

  .star {
    margin: 0 7px;

    img {
        width: 58px;
        height: 57px;
    }
  }
}
</style>
