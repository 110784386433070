const PREFIX_KEY = process.env.VUE_APP_STORAGE_PREFIX;

const StoragePlugin = (mutations) => store => {
	if ( !(window && window.localStorage) ) {
		console.error('Local storage is not available. Disabling vuex plugin.');
		return;
	}

	if ( !Array.isArray(mutations) ) {
		console.error('Mutations should be an array of names or empty. Disabling vuex plugin.');
		return;
	}

	let committing = false;
	const share = mutation => {
		return mutations.length === 0 || mutations.indexOf(mutation.type) !== -1;
	}

	store.subscribe((mutation, state) => {
		if ( committing || !share(mutation) )
			return;

		try {
			const key = PREFIX_KEY + mutation.type;
			window.localStorage.setItem(key, JSON.stringify(mutation));
		}
		catch (e) {
			console.error(e);
		}
	});

	window.addEventListener('storage', event => {
		if ( !event.key.startsWith(PREFIX_KEY) )
			return;

		committing = true;

		try {
			const mutation = JSON.parse(event.newValue);
			store.commit(mutation.type, mutation.payload);
		}
		catch (e) {
			console.error(e);
		}
		finally {
			committing = false;
		}
	});
};

export default StoragePlugin;
