<template>
  <div class="body-container">
    <SelectLayoutA
      v-if="!isFinal"
      :cards="cards"
      :cardsLayout="cardsLayout"
      :cardWidth="cardWidth"
      :cardHeight="cardHeight"
      :displayType="displayType"
      :displaySource="displaySource"
      :numberCorrectElements="numberCorrectElements"
      :numOfSelected="numOfSelected"
      :correctBorderColor="correctBorderColor"
      :selectedBorderColor="selectedBorderColor"
      :wrongBorderColor="wrongBorderColor"
      :backgroundColor="backgroundColor"
      :cardBorderColor="cardBorderColor"
      :cardContentPosition="cardContentPosition"
      :retroBorderSize="retroBorderSize"
      :cardFontSize="cardFontSize"
      :cardFontColor="cardFontColor"
      :numberCards="numberCards"
      :assetMediaImageUrl="assetMediaImageUrl"
      :cardPaddingWidth="cardPaddingWidth"
      :cardPaddingHeight="cardPaddingHeight"
      :backgroundColorCard="backgroundColorCard"
      :backgroundColorDisable="backgroundColorDisable"
      :backgroundColorWrong="backgroundColorWrong"
      :themeName="themeName"
      :themeLocation="themeLocation"
    >
    </SelectLayoutA>
    <FinalB
      v-if="isFinal"
      :finalImages="finalImages"
      :themeName="themeName"
      :themeLocation="themeLocation"
    >
    </FinalB>
  </div>
</template>

<script>
import Vue from 'vue'
import SelectLayoutA from '../components/SelectLayoutA'
import FinalB from '../components/FinalB'
import Velocity from 'velocity-animate'

export default {
  name: 'SelectA',
  components: {
    'FinalB': FinalB,
    'SelectLayoutA': SelectLayoutA,
  },
  props: [
    "cards",
    "cardsLayout",
    "cardWidth",
    "cardHeight",
    "displayType",
    "displaySource",
    "numberCorrectElements",
    "numOfSelected",
    "correctBorderColor",
    "selectedBorderColor",
    "wrongBorderColor",
    "cardBorderColor",
    "cardContentPosition",
    "retroBorderSize",
    "cardFontSize",
    "cardFontColor",
    "isFinal",
    "finalImages",
    "themeName",
    "themeLocation",
    "numberCards",
    "assetMediaImageUrl",
    "cardPaddingWidth",
    "cardPaddingHeight",
    "backgroundColor",
    "backgroundColorCard",
    "backgroundColorDisable",
    "backgroundColorWrong",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

  .body-container {
    min-height: 380px;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .cards {
      display: flex;
      justify-content: center;
      min-height: 380px;
      align-items: center;
      width: 100%;
      max-width: 1024px;
      
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        max-height: 100%;
        width: 100%;
        align-items: center;
        place-content: center;
        justify-content: center;

        li {
            display: inline-block;
            width: 237px;
            height: 121px;
            background-size: 100%;
            background-repeat: no-repeat;
            transition: all 0.6s cubic-bezier(0.33, 0.66, 0.36, 1.19);
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            position: relative;
            font-weight: 700;
            font-size: 30px;
            margin: 2px 7px;
            transform: scale(0);
        }
      }
    }
  }
</style>
