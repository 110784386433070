<template>
  <div 
    :class="className"
    :setIsCorrect="setIsCorrect + ' setIsCorrect'"
  >
    <Notify
      v-if="isCorrect"
      addedClass="calendar"
      :hasBorder="true"
      :style="[
        fadeOutContent ?
          {opacity: '0', transition: 'all 0.6s ease'}
        : '',
      ]"
    >
    </Notify>
    <div 
      class="calendar-default-background"
      :style="[
        setIsCorrect == null || fadeOutContent ?
          myHome.isIpadOrIphone ?
            {backgroundImage: 'url(' + themeLocation + themeName + '/class5.svg)'}
          : {backgroundImage: 'url(' + themeLocation + themeName + '/class1.svg)'}
        : {opacity: '0', transition: 'all 0.6s ease'},
      ]"
    ></div>
    <div 
      class="calendar-hover-background"
      :style="[
        dropContent ?
          {opacity: '1'}
        : {opacity: '0'},
        myHome.isIpadOrIphone ?
          {backgroundImage: 'url(' + themeLocation + themeName + '/class6.svg)'}
        : {backgroundImage: 'url(' + themeLocation + themeName + '/class2.svg)'}
      ]"
    ></div>
    <div 
      class="calendar-correct-background"
      :style="[
        setIsCorrect && !myHome.isIpadOrIphone ?
          {opacity: '1'}
        : {opacity: '0'},
        fadeOutContent ?
          {opacity: '0', transition: 'all 0.6s ease'}
        : '',
        {backgroundImage: 'url(' + themeLocation + themeName + '/class3.svg)'}
      ]"
    ></div>
    <div 
      class="calendar-correct-background"
      :style="[
        setIsCorrect && myHome.isIpadOrIphone ?
          {opacity: '1'}
        : {opacity: '0'},
        fadeOutContent ?
          {opacity: '0', transition: 'all 0.6s ease'}
        : '',
        {backgroundImage: 'url(' + themeLocation + themeName + '/class7.svg)'}
      ]"
    ></div>
    <div 
      class="calendar-wrong-background"
      :style="[
        setIsCorrect === false && !myHome.isIpadOrIphone ?
          {opacity: '1'}
        : {opacity: '0'},
        {backgroundImage: 'url(' + themeLocation + themeName + '/class4.svg)'}
      ]"
    ></div>
    <div 
      class="calendar-wrong-background"
      :style="[
        setIsCorrect === false && myHome.isIpadOrIphone ?
          {opacity: '1'}
        : {opacity: '0'},
        {backgroundImage: 'url(' + themeLocation + themeName + '/class8.svg)'}
      ]"
    ></div>
    <div 
      class="title"
      :style="[
        setFontColor ?
          {color: setFontColor}
        : '',
        setFontSize?
          {fontSize: setFontSize}
        : '',
      ]"
    >
      {{setTitle}}
    </div>
    <div class="content">
      <div 
        class="guide-card"
        :class="'guide-card-' + guidCardAddedClass"
        v-for="(item, index) in setItems"
        :key="index"
        :style="[
          item.show ?
            {opacity: 1, transition: 'all 300ms ease'}
          : {opacity: 0},
          removeTrans ?
            {transition: 'none 0s ease'}
          : ''
        ]"
      >
        <div 
          class="border"
          :style="[
            {borderColor: borderColor[1]},
            item.hideBorder ?
              {borderWidth: '0'}
            : ''
          ]"
        >
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Notify from '../components/Notify'

export default {
  name: 'Set',
  components: {
    'Notify': Notify,
  },
  props: [
    "className",
    "dropContent",
    "themeLocation",
    "themeName",
    "setIsCorrect",
    "fadeOutContent",
    "setItems",
    "cardSinkWidth",
    "cardSinkHeight",
    "guidCardAddedClass",
    "isCorrect",
    "setTitle",
    "setFontColor",
    "setFontSize",
    "removeTrans",
    "borderColor",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.left-calendar {
  margin: 0 11.5px 0 0;
}

.right-calendar {
  margin: 0 0 0 11.5px;
}

.left-calendar, .right-calendar {
  width: 497px;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;

  .calendar-correct-background, 
  .calendar-wrong-background, 
  .calendar-hover-background,
  .calendar-default-background {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all 0.3s ease;
    background-repeat: no-repeat;
    background-position: center; 
  }

  .title {
    width: 100%;
    height: 81px;
    padding-top: 10px;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 4px;
    z-index: 11;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 50px 0;
    flex-wrap: wrap;

    .guide-card {
      margin: -12px 7.5px 10px;
      width: 142px;
      height: 69px;

      &:nth-of-type(1), &:nth-of-type(2) {
        margin-top: 0;
      }
      
      &.hidden {
        opacity: 0;
      }
      
      .border {
        border: dashed 2px gray;
        border-radius: 15px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.ipad-version {
  .left-calendar, .right-calendar {
    width: 427px;
    height: 238px;
    
    .content {
      padding-top: 7px;

      .guide-card {
        
        &:nth-of-type(1), &:nth-of-type(2) {
          margin-top: 0px;
        }
      }
    }
  }
  .left-calendar {
    margin: 0 11px 0 0;
  }
  .right-calendar {
      margin: 0 0 0 11px;
  }
}
</style>
