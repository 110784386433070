<template>
  <div class="final">
    <div
      :id="'card'+index"
      class="card"
      v-for="(image, index) in finalImages"
      v-bind:key="'step' + index"
    >
      <div class="card-box">
        <div class="card-img">
            <img draggable="false" v-bind:src="themeLocation + themeName + '/' + image" v-rjs="2">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Velocity from 'velocity-animate'

  export default {
    name: 'FinalB',
    props: [
      "finalImages",
      "themeName",
      "themeLocation"
    ],
    methods: {
      showAnimation: function() {
        var _this = this,
          el = '',
          w = '193px',
          h = '311px',
          cards = document.getElementsByClassName('card');

          for (let index = 0; index < cards.length; index++) {
            el = cards[index];
            var i = index + 1;
            Velocity(el, {
              width: w,
              height: h,
            }, { duration: 300 * i });
            Velocity(el, {
              scale: 1
            }, { duration: 350 + (200*i) });
            
          }
      },
    },
    created: function () {
      var _this = this;
      setTimeout(function() {
        _this.showAnimation();
      }, 100);
    },
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.final {
  display: flex;
  justify-content: center;
  height: 340px;
  width: 100%;
  align-items: center;
  transform: scale(1) !important;

  .card {
    position: relative;
    width: 1px;
    height: 1px;
    min-width: 1px;
    min-height: 1px;
    margin: 0 22.5px 0px 22.5px;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform-origin: 50% 50%;
    z-index: 900;

    .card-box {
      height: 80.71%;
      pointer-events: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80.71%;
      width: 100%;
      margin: 0 0 10px;
      border-radius: 30px;
      background: #ffffff;
      z-index: 900;
      flex-direction: column;
      box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      border: 3px solid #ffffff;

      .card-img  {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 233.594px;
        max-width: 177px;
        position: relative;

        img {
          max-height: 72%;
          max-width: 77%;
          align-self: center;
          position: absolute;
          min-width: 10px;
          min-height: 10px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ipad-version {
  .final {
    .card {
      max-width: 165px;
      max-height: 273px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}


</style>