var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-c",style:([
    _vm.cardFontColor ?
      {color: _vm.cardFontColor} 
    : '',
    _vm.cardFontSize ?
      {fontSize: _vm.cardFontSize} 
    : ''
  ])},[(_vm.displayType === 'text')?_c('span',[_vm._v(" "+_vm._s(_vm.value)+" ")]):_c('img',{attrs:{"draggable":"false","src":_vm.assetMediaImageUrl + _vm.image}})])}
var staticRenderFns = []

export { render, staticRenderFns }