import Vue from 'vue'
import Router from 'vue-router'
import Page from '../views/Page'
import Memo from '../views/Memo'
import Multi from '../views/Multi'
import Asso from '../views/Asso'
import Ortho from '../views/Ortho'
import Ordo from '../views/Ordo'
import Class from '../views/Class'
import Select from '../views/Select'
import Home from '../views/Home'
import NotFound from '../views/NotFound'
import ModelConfig from '../models/Config'
import Util from '../helpers/Util'
import Store from '../store/Vuex'

let baseUrl = Util.getBaseUrl();
let routerMode = Util.getRouterMode();
let config = ''
let database = ''

Vue.use(Router)

function setConfig (route) {
  return {
    config: config,
    database: database,
    week: (route.query.s) ? route.query.s : 1
  }
}

const routes = new Router({
  mode: routerMode,
  base: baseUrl,
  routes: [
    {
      path: '/',
      name: 'page',
      component: Page,
      children: [
        {
          path: '/:product/multi/:config',
          name: 'multi',
          component: Multi,
          props: setConfig
        },
        {
          path: '/:product/memo/:config',
          name: 'memo',
          component: Memo,
          props: setConfig
        },
        {
          path: '/:product/asso/:config',
          name: 'asso',
          component: Asso,
          props: setConfig
        },
        {
          path: '/:product/ortho/:config',
          name: 'ortho',
          component: Ortho,
          props: setConfig
        },
        {
          path: '/:product/select/:config',
          name: 'select',
          component: Select,
          props: setConfig
        },
        {
          path: '/:product/ordo/:config',
          name: 'ordo',
          component: Ordo,
          props: setConfig
        },
        {
          path: '/:product/class/:config',
          name: 'class',
          component: Class,
          props: setConfig
        },
        {
          path: '/:product',
          name: 'home',
          component: Home,
          props: setConfig
        }
      ]
    },
    {
      path: '*',
      name: 'notfound',
      component: NotFound
    }
  ]
})

routes.beforeEach(async (to, from, next) => {
  if (to.name === 'notfound') {
    next();
  }
  else {
    if (to.params.product)
      Store.dispatch('setProduct', to.params.product);

    var configName = to.params.config;

    if ('select'=== to.name ||
        'ordo'  === to.name || 
        'ortho' === to.name || 
        'class' === to.name || 
        'memo'  === to.name || 
        'multi' === to.name || 
        'asso'  === to.name) {
      try {
        var response = await ModelConfig.getConfiguration(configName);
        var configuration = response.body.Items[0];

        response = await ModelConfig.getTheme(configuration.theme);
        configuration.theme = response.body.Items[0];

        config = {data: {config: configuration}};
        ModelConfig.setConfig(config);
        ModelConfig.setConfigName(configName);

        next();
      }
      catch (err) {
        routes.push({name: 'notfound'});
      }
    }
    else if('home' === to.name) {
      next();
    }
    else { // this is for backward compatibility
      ModelConfig.getVerifyConfig(configName)
      .then(function(configResponse){
        ModelConfig.setConfig(configResponse.body);
        config = configResponse.body

        ModelConfig.getVerifyDatabase(configResponse.body.data.config.databaseUrl)
        .then(function(databaseResponse){
          ModelConfig.setDatabase(databaseResponse.body)
          database = databaseResponse.body
          next();
        }).catch(error => {
          routes.push({name: 'notfound'});
        });

      }).catch(error => {
        routes.push({name: 'notfound'});
      });
    }
  }
});

export default routes;
