var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-b"},[(_vm.isCorrectBorder)?_c('Notify',{attrs:{"addedClass":"select","hasBorder":false}}):_vm._e(),_c('div',{staticClass:"card-border",class:{correct: _vm.isCorrectBorder, wrong: _vm.isWrongBorder},style:([
      _vm.isCorrectBorder ?
        {borderColor: _vm.correctBorderColor} :
      _vm.isWrongBorder ?
        {borderColor: _vm.wrongBorderColor} :
      _vm.isSelectedBorder ?
        {borderColor: _vm.selectedBorderColor} :
        '',
      _vm.retroBorderSize ?
        {borderWidth: _vm.retroBorderSize} :
        ''
    ]),attrs:{"itemIndex":_vm.itemIndex},on:{"click":_vm.onSelect}}),_c('div',{staticClass:"card-content",style:([
      _vm.backgroundColorCard ?
        {backgroundColor: _vm.backgroundColorCard}
      : 'transparent',
      _vm.isCorrectBorder ?
        {backgroundColor: _vm.backgroundColorDisable, boxShadow: 'none'} 
      : '',
      _vm.isWrongBorder ?
        {backgroundColor: _vm.backgroundColorWrong} 
      : '',
      _vm.cardContentPosition === 'left' ?
        {justifyContent: 'flex-start', textAlign: 'left'} 
      : '',
      _vm.cardContentPosition === 'right' ?
        {justifyContent: 'flex-end', textAlign: 'right'} 
      : '',
      _vm.cardFontColor ?
        {color: _vm.cardFontColor}
      : '',
      _vm.cardFontSize ?
        {fontSize: _vm.cardFontSize}
      : '',
      _vm.cardPaddingWidth ?
        {paddingRight: _vm.cardPaddingWidth, paddingLeft: _vm.cardPaddingWidth}
      : '',
      _vm.cardPaddingHeight ?
        {paddingTop: _vm.cardPaddingHeight, paddingBottom: _vm.cardPaddingHeight}
      : '',
      _vm.cardBorderColor ?
        {borderColor: _vm.cardBorderColor}
      : '' ])},[(_vm.displayType == 'text')?_c('span',[_vm._v(_vm._s(_vm.value))]):_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],attrs:{"draggable":"false","src":_vm.assetMediaImageUrl + _vm.value}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }