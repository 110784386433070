import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const MultiA = Object.create(Base);

MultiA.correctBlacklist = [];
MultiA.incorrectBlacklist = [];
MultiA.correctElements = new Map();
MultiA.incorrectElements = new Map();

MultiA.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.correctBlacklist, "correct_blacklist");
    LocalStorage.saveLocalStorage(this.correctElements, "correct_elements");
    LocalStorage.saveLocalStorage(this.incorrectBlacklist, "incorrect_blacklist");
    LocalStorage.saveLocalStorage(this.incorrectElements, "incorrect_elements");
}

MultiA.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.correctBlacklist);
    Util.clearElements(this.incorrectBlacklist);
    this.correctElements.clear();
    this.incorrectElements.clear();

    let config = this.configuration.data.config;
    let exclude = config.excludeCriterias;
    let include = config.includeCriterias;
    let collections = config.collections;
    let criteriasField = config.criteriasField;
    let valueSource = config.valueSource;

    this.database = await this.populateCollections(collections);

    for (let element of this.database) {
        if ( this.hasCriteria(element[criteriasField], exclude) )
            continue;
        else if ( this.hasCriteria(element[criteriasField], include) )
            this.setMapValue(this.correctElements, element, valueSource)
        else
            this.setMapValue(this.incorrectElements, element, valueSource)
    }
}

MultiA.getElements = function (n, m, i) {
    if (n < 0 || m < 0 || i < 0)
        throw new Error("Negative argument is invalid.");

    if (Number.isInteger(i))
        return changeElements(i, this.incorrectElements, this.incorrectBlacklist);

    let correct = this.fetchElements(n, this.correctElements, this.correctBlacklist).map(obj => Util.assign(obj, {isCorrect: true}));
    let incorrect = this.fetchElements(m, this.incorrectElements, this.incorrectBlacklist).map(obj => Util.assign(obj, {isCorrect: false}));

    let elements = correct.concat(incorrect);
    Util.shuffle(elements);

    Util.clearElements(this.currentElements);
    Util.addElements(this.currentElements, elements);

    return this.currentElements;
}

const changeElements = function (i, elements, blacklist) {
    let size = MultiA.currentElements.length - 1;
    let incorrect = MultiA.fetchElements(size, elements, blacklist).map(obj => Util.assign(obj, {isCorrect: false}));

    let correct = [MultiA.currentElements[i]];
    let keys = [...MultiA.currentElements.keys()];
    keys.splice(i, 1);

    let key = Util.getRandom(keys.length);
    let j = keys[key];

    let currentElements = correct.concat(incorrect);
    Util.swap(currentElements, 0, i);
    Util.swap(currentElements, i, j);

    Util.clearElements(MultiA.currentElements);
    Util.addElements(MultiA.currentElements, currentElements);

    return MultiA.currentElements;
}

export default MultiA;
