<template>
  <div 
    class="keyboard-container"
    :class="{'fade-in': keyboardFadeIn}"
  >
    <div class="keyboard-overlay"></div>

    <div
      class="popup-keys keys"
      :class="[key]"
      v-for="(keys, key) in popupKeys"
      :key="key"
      :data-action="key"
    >
      <div class="popup-container">
        <div class="close-popup" v-on:click="onClosePopup(key)">
          <span>
            <img 
              :src="myHome.getThemeImage('key-fond-close.svg')"
              draggable="false"
            >
          </span>
        </div>
        <span
          :ref="keybtn[0]"
          v-for="keybtn in keys.element"
          :key="'key' + keybtn + '-popup'"
          v-on:mousedown="keyAction(keybtn, 'down', $event)"
          v-on:mouseup="keyAction(keybtn, 'up', $event)"
        >
          <img draggable="false" class="key-up" :src="myHome.getThemeImage(keybtn[0] + '.svg')">
          <img draggable="false" class="key-down" :src="myHome.getThemeImage(keybtn[0] + '-2.svg')">
        </span>
      </div>
    </div>

    <div
      :class="'keys-line-' + (index + 1) + ' keys'"
      v-for="(keys, index) in keysLine"
      :key="'keys' + '-line' + index"
    >
      <span
        :ref="key[0]"
        v-for="(key, index) in keys"
        :key="'key' + index + '-line1'"
          v-on:mousedown="keyAction(key, 'down', $event)"
          v-on:mouseup="keyAction(key, 'up', $event)"
      >
        <img draggable="false" class="key-up" :src="myHome.getThemeImage(key[0] + '.svg')">
        <img draggable="false" class="key-down" :src="myHome.getThemeImage(key[0] + '-2.svg')">
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Util from '../helpers/Util'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Keyboard',
  props: [
    "keyboardFadeIn",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
      popupKeys:{
        popupA: {
          element: [
            ["key_a1", 'a', ''],
            ["key_a2", 'à', ''],
            ["key_a3", 'â', ''],
            ["key_a4", 'ä', '']
          ],
          background: 'key-fond5.svg',
        },
        popupC: {
          element: [
            ["key_c", 'c', ''],
            ["key_c2", 'ç', ''],
          ],
          background: 'key-fond2.svg',
        },
        popupE: {
          element: [
            ["key_e1", 'e', ''],
            ["key_e2", 'é', ''],
            ["key_e3", 'è', ''],
            ["key_e4", 'ê', ''],
            ["key_e5", 'ë', '']
          ],
          background: 'key-fond4.svg',
        },
        popupI: {
          element: [
            ["key_i1", 'i', ''],
            ["key_i2", 'î', ''],
            ["key_i3", 'ï', '']
          ],
          background: 'key-fond3.svg',
        },
        popupO: {
          element: [
            ["key_o1", 'o', ''],
            ["key_o2", 'ô', ''],
            ["key_o3", 'ö', '']
          ],
          background: 'key-fond3.svg',
        },
        popupU: {
          element: [
            ["key_u", 'u', ''],
            ["key_u2", 'ù', ''],
            ["key_u3", 'û', '']
          ],
          background: 'key-fond3.svg',
        }
      },
      keysLine: [
        [
          ["key_q", 'q', ''],
          ["key_w", 'w', ''],
          ["key_e1", 'popupE', 'popup'],
          ["key_r", 'r', ''],
          ["key_t", 't', ''],
          ["key_y", 'y', ''],
          ["key_u", 'popupU', 'popup'],
          ["key_i1", 'popupI', 'popup'],
          ["key_o1", 'popupO', 'popup'],
          ["key_p", 'p', ''],
          ["key_delete", 'erase', '']
        ],
        [
          ["key_a1", 'popupA', 'popup'],
          ["key_s", 's', ''],
          ["key_d", 'd', ''],
          ["key_f", 'f', ''],
          ["key_g", 'g', ''],
          ["key_h", 'h', ''],
          ["key_j", 'j', ''],
          ["key_k", 'k', ''],
          ["key_l", 'l', ''],
          ["key_trait-union", '-', '']
        ],
        [
          ["key_z", 'z', ''],
          ["key_x", 'x', ''],
          ["key_c", 'popupC', 'popup'],
          ["key_v", 'v', ''],
          ["key_b", 'b', ''],
          ["key_n", 'n', ''],
          ["key_m", 'm', ''],
          ["key_apostrophe", "'", "'"]
        ]
      ],
    }
  },
  computed: {
    ...mapGetters(['getOrthoArrayData']),
  },
  methods: {
    ...mapActions(['setOrthoArrayData']),
    ...mapActions(['setOrthoArrayDataByElem']),
    ...mapActions(['setOrthoArrayDataResetIsSelected']),

    keyAction: async function(keys, action, event) {
      var _this = this;
      var keyImages = this.$refs[keys[0]][0];

      if (keyImages.children[1].classList.contains('key-down') && action === 'down') {
        keyImages.classList.add('clicked');
        keyImages.children[0].classList.add('hide');
        keyImages.children[1].classList.add('show');
      }
      else {
        var selected = document.getElementsByClassName('selected');
        var keyboardOverlay = document.getElementsByClassName('keyboard-overlay');

        keyImages.classList.remove('clicked');
        keyImages.children[0].classList.remove('hide');
        keyImages.children[1].classList.remove('show');

        if (keys[2] == 'popup') {
          var popup = document.getElementsByClassName(keys[1]);

          keyboardOverlay[0].classList.add('show');
          popup[0].classList.add('show');
        }

        if (selected.length) {
          var className = [selected[0].classList[2]];
          var answerLetter = document.getElementsByClassName(className[0]);

          if(keys[1] != 'erase' && keys[2] != 'popup'){
            answerLetter[0].children[0].children[1].children[0].innerHTML = keys[1];
            
            var answerLetters = document.getElementsByClassName('card');
            var nextCounter = false;

            for (let index = 0; index < answerLetters.length; index++) {
              if (nextCounter) {
                if (answerLetters[index].classList.contains('done') === false &&
                answerLetters[index].classList.contains('correct') === false &&
                answerLetters[index].classList.contains('wrong') === false ) {
                  this.setOrthoArrayDataResetIsSelected();
                  this.setOrthoArrayDataByElem({
                    'index': answerLetters[index].getAttribute('index'), 
                    'element': 'isSelected',
                    'value': true
                  });
                  break;
                }
                else {
                  await Util.setWait( _ => {
                    this.setOrthoArrayDataByElem({
                      'index': answerLetters[index].getAttribute('index'), 
                      'element': 'isSelected',
                      'value': true
                    });
                  }, 1);
                }
              }
              nextCounter = answerLetters[index].classList.contains('selected') ? true: false;
            }

            var popup = document.getElementsByClassName('popup-keys');

            for (let index = 0; index < popup.length; index++) {
              if (popup[index].classList.contains('show')) {
                popup[index].classList.remove('show');
                keyboardOverlay[0].classList.remove('show');
              }
            }
          }
          else if(keys[1] == 'erase'){
            answerLetter[0].children[0].children[1].children[0].innerHTML = '';
          }
        }
      }

      this.onKeysCheck();
    },

    onClosePopup: function(className) {
      var popup = document.getElementsByClassName(className);
      var keyboardOverlay = document.getElementsByClassName('keyboard-overlay');
      popup[0].classList.remove('show');
      keyboardOverlay[0].classList.remove('show');
    },
    
    onKeysCheck: function() {
      var keysAnswer = document.getElementsByClassName('card');
      var noValue = false;

      for (let index = 0; index < keysAnswer.length; index++) {
        if (keysAnswer[index].children[0].children[1].children[0].innerText == false) {
          noValue = true;
          break;
        }
      }

      this.myComponent.isDisabledButton = (noValue)? true: false;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

  
  .keyboard-container {
    height: 188px;
    width: 666px;
    margin-top: 23px;
    background-repeat: no-repeat;
    padding: 9px;
    position: relative;
    border: 4px solid #3AB8EA;
    border-radius: 30px;
    background-color: #68C8EF;
    transition: all 0.6s ease-in;
    opacity: 0;

    &.fade-in {
      opacity: 1;
    }

    .keyboard-overlay {
      height: 188px;
      width: 666px;
      position: absolute;
      top: -4px;
      left: -4px;
      opacity: 0.7;
      display: none;
      background-repeat: no-repeat;
      border: 4px solid #3AB8EA;
      border-radius: 30px;
      background-color: #68C8EF;
    }

    .key-down {
      display: none;
    }
    
    .keys {
      display: flex;

      &.keys-line-2 {
        margin: 3px 0;
        justify-content: start;
        padding-left: 27px;
        height: 52px;
      }
      &.keys-line-3 {
        justify-content: start;
        padding-left: 55px;
      }

      &.popup-keys {
        position: absolute;
        height: 74px;
        background-repeat: no-repeat;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: none;
        justify-content: center;
        border: 4px solid #3AB8EA;
        border-radius: 20px;
        background-color: #68C8EF;
        box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);

        &.keys {
          span {
            display: block;
          }
          .close-popup span {
            margin: 0;
          }
        }

        &.hidden {
          display: none;
        }

        &.show {
          animation-name: fadeIn;
          animation-duration: 0.3s;
          display: flex !important;
        }

        &.popupA {
          bottom: 53px;
          left: 25.5px;
          width: 248px;
          padding-right: 5px;
        }

        &.popupC {
          bottom: 0px;
          left: 162.5px;
          width: 141px;
          height: 72px;
          padding-top: 2px;
          padding-right: 2px;
        }

        &.popupE {
          top: -3px;
          left: 110px;
          width: 305px;
          padding-top: 2px;
        }
        
        &.popupI {
          top: -3px;
          left: 392px;
          width: 199px;
          padding-right: 2px;
          padding-top: 2px;
        }

        &.popupO {
          top: -3px;
          left: 449px;
          width: 199px;
          padding-right: 2px;
          padding-top: 2px;
        }

        &.popupU {
          top: -3px;
          left: 335px;
          width: 199px;
          padding-right: 2px;
          padding-top: 2px;
        }
      }
      
      &.popupA, &.popupU, &.popupC, &.popupI, &.popupO, &.popupE {
        .close-popup {
          right: -41px;
        }
      }

      .popup-container {
        display: flex;
        position: relative;

        .close-popup {
          position: absolute;
          width: 35px;
          height: 40px;
          top: -11px;
          right: -32px;

          span, span img {
            width: 100%;
            height: 100%;
          }
        }
      }

      span {
        max-height: 52px;
        margin: 0 3px;
        cursor: pointer;
        width: 51px;
        height: 52px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
        
        img {
          height: 52px;
          width: auto;
        }
      }
    }
  }

  .show {
    display: block !important;
  }
  .hide {
    display: none !important;
  }
</style>
