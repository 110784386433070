const State = {
  appConfig: {},
  navigationData: {},
  orthoArrayData: {},
  memoLeftArrayData: {},
  memoRightArrayData: {},
  multiObjectData:{},
  i18n: {},
  locale: 'fr',
  product: ''
}

export default State
