import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const AssoB = Object.create(Base);

AssoB.currentElements = {first: [], second: [], third: []};
AssoB.wheelOneBlacklist = [];
AssoB.wheelTwoBlacklist = [];
AssoB.wheelThreeBlacklist = [];
AssoB.wheelOneElements = new Map();
AssoB.wheelTwoElements = new Map();
AssoB.wheelThreeElements = new Map();

AssoB.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.wheelOneElements, "wheel_one_elements");
    LocalStorage.saveLocalStorage(this.wheelOneBlacklist, "wheel_one_blacklist");
    LocalStorage.saveLocalStorage(this.wheelTwoElements, "wheel_two_elements");
    LocalStorage.saveLocalStorage(this.wheelTwoBlacklist, "wheel_two_blacklist");
    LocalStorage.saveLocalStorage(this.wheelThreeElements, "wheel_three_elements");
    LocalStorage.saveLocalStorage(this.wheelThreeBlacklist, "wheel_three_blacklist");
}

AssoB.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements.first);
    Util.clearElements(this.currentElements.second);
    Util.clearElements(this.currentElements.third);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.wheelOneBlacklist);
    Util.clearElements(this.wheelTwoBlacklist);
    Util.clearElements(this.wheelThreeBlacklist);
    this.wheelOneElements.clear();
    this.wheelTwoElements.clear();
    this.wheelThreeElements.clear();

    let config = this.configuration.data.config;
    let valueSource = config.valueSource;
    let wheelOneCollections = config.wheelOne.collections;
    let wheelTwoCollections = config.wheelTwo.collections;
    let wheelThreeCollections = config.wheelThree.collections;

    let wheelOne = await this.populateCollections(wheelOneCollections);
    let wheelTwo = await this.populateCollections(wheelTwoCollections);
    let wheelThree = await this.populateCollections(wheelThreeCollections);

    for (let element of wheelOne)
        this.setMapValue(this.wheelOneElements, element, valueSource);

    for (let element of wheelTwo)
        this.setMapValue(this.wheelTwoElements, element, valueSource);

    for (let element of wheelThree)
        this.setMapValue(this.wheelThreeElements, element, valueSource);

    this.database = wheelOne.concat(wheelTwo, wheelThree);
}

AssoB.getElements = function (size) {
    if (size < 0)
        throw new Error("Negative argument is invalid.");

    const config = this.configuration.data.config;
    const valueSource = config.valueSource;
    const sameSize = config.numberSameElements || 0;
    const diffSize = size - sameSize;
    const sameKeys = [], intruderKeys = [];

    // base on the rule, each collection on every wheel have the same values
    // therefore, we can get the common elements on first wheel
    const firstSameElements = this.fetchElements(sameSize, this.wheelOneElements, this.wheelOneBlacklist);
    firstSameElements.forEach(obj => sameKeys.push(obj.element[valueSource]));

    const firstIntruder = this.fetchElements(diffSize, this.wheelOneElements, this.wheelOneBlacklist)
    firstIntruder.forEach(obj => intruderKeys.push(obj.element[valueSource]));

    const thirdSameElements = this.pickElementsByKeys(sameKeys.concat(intruderKeys), this.wheelThreeElements, this.wheelThreeBlacklist);
    thirdSameElements.splice(thirdSameElements.length - intruderKeys.length);

    const thirdIntruder = this.fetchElements(diffSize, this.wheelThreeElements, this.wheelThreeBlacklist)

    const first = firstIntruder.concat(firstSameElements);
    const second = this.fetchElements(size, this.wheelTwoElements, this.wheelTwoBlacklist);
    const third = thirdIntruder.concat(thirdSameElements);

    Util.shuffle(first);
    Util.shuffle(second);
    Util.shuffle(third);

    Util.clearElements(this.currentElements.first);
    Util.clearElements(this.currentElements.second);
    Util.clearElements(this.currentElements.third);

    Util.addElements(this.currentElements.first, first);
    Util.addElements(this.currentElements.second, second);
    Util.addElements(this.currentElements.third, third);

    return this.currentElements;
}

export default AssoB;
