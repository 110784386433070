<template>
  <div 
    class="cards completed-cards"
    :class="[addedClass, {'max-card': correctCards.length == 5}]"
  >
    <div
      :id="'card'+index"
      class="card"
      :class="{'numerik': templateName != templateMultiA, 'no-audio': !isShowAudioButton}"
      v-for="(correctCard, index) in correctCards"
      v-bind:key="'step' + index + '-' + correctCard.id"
    >
      <div
        class="card-box"
      >
        <div
          class="card-sound"
          v-if="correctCard.sound"
        >
          <span>{{correctCard.sound}}</span>
        </div>

        <div 
          class="card-img"
          :style="[
            cardHeight ?
              ''
            : '',
            cardPaddingTop ?
              {paddingTop: cardPaddingTop}
            : '',
            cardPaddingBottom ?
              {paddingBottom: cardPaddingBottom}
            : '',
            cardPaddingWidth ?
              {
                paddingLeft: 'calc(' + cardPaddingWidth + ' / 2)', 
                paddingRight: 'calc(' + cardPaddingWidth + ' / 2)'
              }
            : '',
          ]"
        >
          <img 
            v-if="displayType == 'image'" 
            v-bind:src="assetMediaImageUrl + correctCard.element[displaySource]" v-bind:alt="displaySource ? correctCard.element[displaySource] : ''" 
            v-rjs="2"
            draggable="false"
          >
          <span 
            v-else-if="displayType == 'text'"
            :style="[
              cardFontColor ?
                {color: cardFontColor}
              : '',
              cardFontSize ?
                {fontSize: cardFontSize}
              : '',
              cardContentPosition ?
                {textAlign: cardContentPosition == 'flex-end' ? 'right' : (cardContentPosition == 'baseline' ? 'left' : 'center') }
              : ''
            ]"
          >
            {{correctCard.element[displaySource]}}
          </span>
        </div>
        
        <div
          class="card-title"
          :class="[answerDisplaySource && correctCard.element.hasOwnProperty(answerDisplaySource) ? (correctCard.element[answerDisplaySource].length >= 10? 'exceed-text' : '') : '']"
          :style="[
            answerDisplayFontSize ?
              {fontSize: answerDisplayFontSize}
            : '',
          ]"
          >
            {{
              answerDisplaySource ?
                correctCard.element[answerDisplaySource]
              : ''
            }}
        </div>
      </div>

      <AudioA
        v-if="templateName === templateMultiA && isShowAudioButton && cardAudioType === 'mp3' && cardAudioSource"
        :messageAudioFile="correctCard.element[cardAudioSource]"
        :classAdded="'multi'"
        :isDataSound="true"
        :assetMediaAudioUrl="assetMediaAudioUrl"
      >
      </AudioA>
      <AudioB
        v-else-if="templateName === templateMultiA && cardAudioType && isShowAudioButton && cardAudioSource"
        :classAdded="'multi'"
        :correctWord="cardAudioSource ? correctCard.element[cardAudioSource] : ''"
        :audioType="cardAudioType"
      >
      </AudioB>

      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Velocity from 'velocity-animate'
import AudioA from '../components/AudioA'
import AudioB from '../components/AudioB'

export default {
  name: 'FinalB',
  components: {
    'AudioA': AudioA,
    'AudioB': AudioB,
  },
  props: [
    "correctCards",
    "themeLayout",
    "themeName",
    "themeLocation",
    "templateName",
    "templateMultiA",
    "assetMediaImageUrl",
    "assetMediaAudioUrl",
    "isShowAudioButton",
    "cardAudioType",
    "cardAudioSource",
    "addedClass",
    "cardWidth",
    "cardHeight",
    "displayType",
    "displaySource",
    "cardFontColor",
    "cardFontSize",
    "cardContentPosition",
    "answerDisplaySource",
    "answerDisplayFontSize",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome
    }
  },
  methods: {
    showAnimation: function() {
      var _this = this,
        el = '',
        w = '193px',
        h = '311px',
        cards = document.getElementsByClassName('card');

      if (this.cardWidth) 
        w = this.cardWidth;
      if (this.cardHeight) 
        h = this.cardHeight;

        for (let index = 0; index < cards.length; index++) {
          el = cards[index];
          var i = index + 1;
          Velocity(el, {
            width: w,
            height: h,
          }, { duration: 300 * i });
          Velocity(el, {
            scale: 1
          }, { duration: 350 + (200*i) });
          
        }
    },
  },
  created: function () {
    var _this = this;
    setTimeout(function() {
      _this.showAnimation();
    }, 100);
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.cards.completed-cards {
  transform: scale(1)!important;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;

  &.multiC {
    .card {
      .card-box {
        height: calc(100% - 9px);

        .card-sound {
          font-size: 24px;
        }
      }
    }
  }

  .card {
    position: relative;
    width: 1px;
    height: 1px;
    min-width: 1px;
    min-height: 1px;
    margin: 0 22.5px;
    overflow: hidden;
    transition: all .6s cubic-bezier(.68,-.55,.265,1.55);
    transform-origin: 50% 50%;
    z-index: 900;

    &.no-audio {
      .card-box {
        height: calc(100% - 9px);
      }
    }

    .card-box {
      height: calc(100% - 60px);
      cursor: default;
      pointer-events: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 0 12px;
      border-radius: 30px;
      background: #fff;
      z-index: 900;
      flex-direction: column;
      box-shadow: 0 8px 0 0 rgba(0,0,0,.2);
      transition: all .3s ease-in-out;

      .card-sound {
        top: 13px;
        position: absolute;
        z-index: 9;
        display: block;
        opacity: 1;
        padding: 3px 3px 8px 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 55px;
        min-height: 39px;
        background: #fff;
        font-weight: 700;
        font-size: 30px;
        border: 1px solid #252525;
        border-radius: 5px;
        line-height: 20px;
        text-align: center;
        transition: all .6s cubic-bezier(.68,-.55,.265,1.55);
      }

      .card-img {
        border: 1px solid;
        height: calc((100% - 48px) - 30px);
        width: calc(100% - 25px);
        margin: 30px 13px 48px 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: all .6s cubic-bezier(.68,-.55,.265,1.55);

        img {
          display: block;
          max-height: 100%;
          max-width: 100%;
          align-self: center;
          min-width: 10px;
          min-height: 10px;
          width: auto;
          height: auto;
          transform: scale(0.9);
        }

        span {
          text-align: center;
        }
      }

      .card-title {
        position: absolute;
        bottom: 6px;
        font-size: 26px;
        text-align: center;
        transition: all .6s cubic-bezier(.68,-.55,.265,1.55);

        .exceed-text {
          font-size: 24px;
        }
      }
    }
  }
}

</style>

<style lang="scss">
.ipad-version, .ipad {
  .cards.completed-cards.max-card {
    .card {
      max-width: 165px;
      max-height: 273px;
      margin-left: 12px;
      margin-right: 12px;
        
      &.numerik, &.no-audio {
        max-height: 213px;
      }

      .card-box {
        .card-img {
          margin-bottom: 40px;
          height: calc((100% - 40px) - 30px);
          
          span {
            font-size: 25px !important;
          }
        }
        .card-title {
          font-size: 22px !important;
        }
      }
    }
  }
}
.ipad, .mac {
  .cards.completed-cards { 
    .card {
      .card-box {
        .card-sound {
          align-items: flex-end;
        }
      }
    }
  }
}
@media (max-width: 1239px) {
  .mac  {
    .cards.completed-cards.max-card {
      .card {
        max-width: 165px;
        max-height: 273px;
        margin-left: 12px;
        margin-right: 12px;
          
        &.numerik, &.no-audio {
          max-height: 213px;
        }

        .card-box {
          .card-img {
            margin-bottom: 40px;
            height: calc((100% - 40px) - 30px);
            
            span {
              font-size: 25px !important;
            }
          }
          .card-title {
            font-size: 22px !important;
          }
        }
      }
    }
  }
  
  .cards.completed-cards.max-card {
    .card {
      max-width: 165px;
      max-height: 273px;
      margin-left: 12px;
      margin-right: 12px;
        
      &.numerik, &.no-audio {
        max-height: 213px;
      }

      .card-box {
        .card-img {
          margin-bottom: 40px;
          height: calc((100% - 40px) - 30px);
          
          span {
            font-size: 25px !important;
          }
        }
        .card-title {
          font-size: 22px !important;
        }
      }
    }
  }
}
</style>

