<script>
export default {

    beforeCreate() {
        var notFound = process.env.VUE_APP_NOT_FOUND_URL
        var lang = window.location.href.search('fr') != -1 ? 'fr' : 'en'

        if(process.env.VUE_APP_NOT_FOUND_URL.search('[LANG]]') != -1)
            notFound = notFound.replace("[LANG]", lang)

        window.location = notFound
    }
}
</script>
