<template>
  <button 
    v-bind:class="[classNames, {disabled: isDisabled, 'disabled-click': isDisableClick}]"
    v-on:click="onClick"
    v-bind:style="
        [isDisabled ?
        {'backgroundImage': 'url(' + inactiveButtonImg + ')', 'color': actionTextColor} :
        {'backgroundImage': 'url(' + activeButtonImg + ')'}]"
  >
    {{value ? value : ''}}
  </button>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'ButtonA',
    props: [
      "classNames",
      "isDisabled",
      "value",
      "buttonType",
      "actionTextColor",
      "activeButtonImg",
      "inactiveButtonImg",
      "isDisableClick"
    ],
    methods: {
      onClick: function() {
        var myComponent = this.$parent.myComponent;
        if (this.buttonType == "1") 
          myComponent.onSubmit();
        else if (this.buttonType == "2") 
          myComponent.onNext();
        else if (this.buttonType == "3") 
          myComponent.onReset();
        else
          myComponent.onPlayAgain();
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.rotate-button-360 {
  transform: rotateY(360deg);
  pointer-events: none;
}
.btn {
  width: 162px;
  height:69px;
  line-height: 60px;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  font-size: 22px;
  border: none;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: auto;
  background-color: transparent;
  background-repeat: no-repeat;
  color: #000;
  background-position: center;
  padding: 0 0 8px 0;

  &.btn-submit.disabled {
    animation-name: diactivate;
    animation-duration: 0.8s;
  }

  &:focus {
    outline:0;
  }
  &.disabled {
    cursor: default;
    box-shadow: none;
    text-decoration: none;
    height: 61px;
    padding-bottom: 0px;
  }
  &.disabled-click, &.disabled {
    pointer-events: none;
  }
}

@keyframes diactivate {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
