<template>
    <div class="calendars">
      <SetA
        className="left-calendar"
        :dropContent="dropLeftContent"
        :themeLocation="themeLocation"
        :themeName="themeName"
        :setIsCorrect="leftSetIsCorrect"
        :fadeOutContent="fadeOutContent && !isFinalFadein"
        :setItems="leftSetItems"
        :cardSinkWidth="cardSinkWidth"
        :cardSinkHeight="cardSinkHeight"
        guidCardAddedClass="left"
        :isCorrect="leftSetIsCorrect && rightSetIsCorrect ? true : false"
        :setTitle="titleSetOne"
        :setFontColor="setFontColor"
        :setFontSize="setFontSize"
        :removeTrans="removeTrans"
        :borderColor="borderColor"
      >
      </SetA>
      <SetA
        className="right-calendar"
        :dropContent="dropRightContent"
        :themeLocation="themeLocation"
        :themeName="themeName"
        :setIsCorrect="rightSetIsCorrect"
        :fadeOutContent="fadeOutContent && !isFinalFadein"
        :setItems="rightSetItems"
        :cardSinkWidth="cardSinkWidth"
        :cardSinkHeight="cardSinkHeight"
        guidCardAddedClass="right"
        :isCorrect="leftSetIsCorrect && rightSetIsCorrect ? true : false"
        :setTitle="titleSetTwo"
        :setFontColor="setFontColor"
        :setFontSize="setFontSize"
        :removeTrans="removeTrans"
        :borderColor="borderColor"
      >
      </SetA>
    </div>
</template>

<script>
import Vue from 'vue'
import SetA from '../components/SetA'

export default {
  name: 'SetLyout',
  components: {
    'SetA': SetA,
  },
  props: [
    "dropLeftContent",
    "dropRightContent",
    "themeLocation",
    "themeName",
    "leftSetIsCorrect",
    "rightSetIsCorrect",
    "fadeOutContent",
    "leftSetItems",
    "rightSetItems",
    "cardSinkWidth",
    "cardSinkHeight",
    "titleSetOne",
    "titleSetTwo",
    "setFontColor",
    "setFontSize",
    "removeTrans",
    "borderColor",
    "isFinalFadein",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.calendars {
  height: 238px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
}

.ipad-version {
  .calendars {
    height: 237px;
    margin-bottom: 20px;
  }
}
</style>
