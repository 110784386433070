var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cards-container",class:{'two-line-title': _vm.titleStyle, 'secondary-theme': _vm.templateName === _vm.templateMultiB || _vm.templateName === _vm.templateMultiC},style:([
    _vm.cardQuestionHeight ?
      {'height': ''}
    : '' ])},[_c('div',{staticClass:"question-image card",attrs:{"id":"qcard-layout-b"}},[(_vm.cardQuestionAudioType == 'mp3' && _vm.cardQuestionAudioSource)?_c('AudioA',{attrs:{"messageAudioFile":_vm.cards.questions[0].element[_vm.cardQuestionAudioSource],"classAdded":'multib-question-audio',"isDataSound":true,"assetMediaAudioUrl":_vm.assetMediaAudioUrl}}):(_vm.cardQuestionAudioType == 'googleVoiceCardQuestion' && _vm.cardQuestionAudioSource)?_c('AudioB',{attrs:{"classAdded":'multib-question-audio',"correctWord":_vm.cardQuestionAudioSource ? _vm.cards.questions[0].element[_vm.cardQuestionAudioSource] : '',"audioType":_vm.cardQuestionAudioType}}):_vm._e(),_c('div',{staticClass:"card-box",style:([
        _vm.cardQuestionBackgroundColor ?
          {'background-color': _vm.cardQuestionBackgroundColor, 'border-color': _vm.cardQuestionBackgroundColor}
        : '',
        _vm.cardQuestionWidth ?
          {'width': _vm.cardQuestionWidth} 
        : '',
        _vm.cardQuestionHeight ?
          {'height': _vm.cardQuestionHeight} 
        : '',
        _vm.cardQuestionPaddingWidth ?
          {paddingLeft: 'calc(' + _vm.cardQuestionPaddingWidth + ' / 2)', paddingRight: 'calc(' + _vm.cardQuestionPaddingWidth + ' / 2)'} 
        : '',
        _vm.cardQuestionPaddingTop ?
          {paddingTop: _vm.cardQuestionPaddingTop} 
        : '',
        _vm.cardQuestionPaddingBottom ?
          {paddingBottom: _vm.cardQuestionPaddingBottom} 
        : '',
        _vm.cardQuestionFontSize ?
          {fontSize: _vm.cardQuestionFontSize} 
        : '',
        _vm.cardQuestionFontColor ?
          {color: _vm.cardQuestionFontColor} 
        : '',
        _vm.cardQuestionContentPosition ?
          {
            justifyContent: _vm.cardQuestionContentPosition == 'right' ? 'flex-end' : (_vm.cardQuestionContentPosition == 'left' ? 'start' : 'center'),
            textAlign: _vm.cardQuestionContentPosition
          }
        : '' ]),attrs:{"id":"qcard-layout-b-box"}},[(_vm.cardQuestionDisplayType == 'image' && _vm.cardQuestionSource)?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],style:([
          _vm.cards.questions[0].element[_vm.cardQuestionSource].indexOf('.svg') == (_vm.cards.questions[0].element[_vm.cardQuestionSource].length - 4) ?
            {height: '-webkit-fill-available'}
          : '' ]),attrs:{"src":_vm.assetMediaImageUrl + _vm.cards.questions[0].element[_vm.cardQuestionSource],"draggable":"false"}}):(_vm.cardQuestionDisplayType == 'text' && _vm.cardQuestionSource)?_c('span',[_vm._v(" "+_vm._s(_vm.cards.questions[0].element[_vm.cardQuestionSource])+" ")]):_vm._e()])],1),_c('div',{staticClass:"displayCards cards",class:{correct: _vm.isCorrect, 'display-info': _vm.isDisplayInfo}},_vm._l((_vm.displayedCards),function(card,index){return (card.isShow)?_c('div',{key:card.id,staticClass:"card",class:{selected: card.isSelected, wrong: card.isWrongPick, 'disabled-click': _vm.disableCards, used: card.isUsed},style:([
      _vm.cardWidth ?
        {width: _vm.cardWidth}
      : '',
      _vm.cardHeight ?
        {height: _vm.cardHeight}
      : '' ]),attrs:{"id":'card'+index,"css":false}},[_c('CardF',{key:'card' + index,attrs:{"alternateEnable":true,"addedClass":"multiB-layoutB","data":card,"index":index,"isSelected":card.isSelected,"isCorrect":card.isCorrect,"isDone":_vm.isCorrect,"isWrong":card.isWrongPick,"isUsed":card.isUsed,"backgroundColorWrong":_vm.backgroundColorWrong,"correctBorderColor":_vm.correctBorderColor,"selectedBorderColor":_vm.selectedBorderColor,"wrongBorderColor":_vm.wrongBorderColor,"cardWidth":'100%',"cardHeight":'calc(100% - 9px)',"displaySource":_vm.displaySource,"displayType":_vm.displayType,"assetMediaImageUrl":_vm.assetMediaImageUrl,"displayedCards":_vm.displayedCards,"retroBorderSize":_vm.retroBorderSize,"cardFontColor":_vm.cardFontColor,"cardFontSize":_vm.cardFontSize,"cardPaddingTop":_vm.cardPaddingTop,"cardPaddingBottom":_vm.cardPaddingBottom,"cardPaddingHeight":_vm.cardPaddingHeight,"cardPaddingWidth":_vm.cardPaddingWidth,"isSelectBump":false,"cardContentPosition":_vm.cardContentPosition,"displaySourceAlt":card.isCorrect ? _vm.displaySourceCorrect : _vm.displaySourceWrong,"displayTypeAlt":card.isCorrect ? _vm.displayTypeCorrect : _vm.displayTypeWrong,"isConfirmBump":true}})],1):_vm._e()}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }