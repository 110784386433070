<template>
  <div class="card-b">
    <Notify
      v-if="isCorrectBorder"
      addedClass="select"
      :hasBorder="false"
    >
    </Notify>
    <div
      class="card-border"
      v-on:click="onSelect"
      :itemIndex ="itemIndex"
      :class="{correct: isCorrectBorder, wrong: isWrongBorder}"
      :style="[
        isCorrectBorder ?
          {borderColor: correctBorderColor} :
        isWrongBorder ?
          {borderColor: wrongBorderColor} :
        isSelectedBorder ?
          {borderColor: selectedBorderColor} :
          '',
        retroBorderSize ?
          {borderWidth: retroBorderSize} :
          ''
      ]"
    ></div>
    <div
      class="card-content"
      :style="[
        backgroundColorCard ?
          {backgroundColor: backgroundColorCard}
        : 'transparent',
        isCorrectBorder ?
          {backgroundColor: backgroundColorDisable, boxShadow: 'none'} 
        : '',
        isWrongBorder ?
          {backgroundColor: backgroundColorWrong} 
        : '',
        cardContentPosition === 'left' ?
          {justifyContent: 'flex-start', textAlign: 'left'} 
        : '',
        cardContentPosition === 'right' ?
          {justifyContent: 'flex-end', textAlign: 'right'} 
        : '',
        cardFontColor ?
          {color: cardFontColor}
        : '',
        cardFontSize ?
          {fontSize: cardFontSize}
        : '',
        cardPaddingWidth ?
          {paddingRight: cardPaddingWidth, paddingLeft: cardPaddingWidth}
        : '',
        cardPaddingHeight ?
          {paddingTop: cardPaddingHeight, paddingBottom: cardPaddingHeight}
        : '',
        cardBorderColor ?
          {borderColor: cardBorderColor}
        : '',
      ]"
    >
      <span v-if="displayType == 'text'">{{value}}</span>
      <img draggable="false" v-else :src="assetMediaImageUrl + value"  v-rjs="2" >
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Util from '../helpers/Util'
import Notify from '../components/Notify'

export default {
  name: 'CardB',

  components: {
    'Notify': Notify,
  },

  props: [
    "value",
    "displayType",
    "numberCorrectElements",
    "numOfSelected",
    "itemIndex",
    "isCorrectBorder",
    "isWrongBorder",
    "isSelectedBorder",
    "correctBorderColor",
    "selectedBorderColor",
    "wrongBorderColor",
    "backgroundColor",
    "cardBorderColor",
    "cardContentPosition",
    "retroBorderSize",
    "cardFontSize",
    "cardFontColor",
    "assetMediaImageUrl",
    "cardPaddingWidth",
    "cardPaddingHeight",
    "backgroundColorCard",
    "backgroundColorDisable",
    "backgroundColorWrong",
    "themeName",
    "themeLocation",
  ],

  data () {
    return {
      images: ''
    }
  },

  methods: {
    async onSelect(e) {
      var response = true,
          itemIndex = 0,
          isSelected = false,
          correctItems = document.getElementsByClassName('correct'),
          myComponent = this.$parent.myComponent,
          lastItem = false;

      myComponent.removeWrong();
      itemIndex = e.target.getAttribute('itemindex');

      if (this.numberCorrectElements - correctItems.length == 1) {
        lastItem = true;
      }

      if (e.target.classList.contains("selected")) {
        e.target.classList.remove("selected");
        myComponent.numOfSelected--;
        isSelected = false;
      }
      else {
        if (lastItem) {
          let cards = document.getElementsByClassName('selected');
          let wrongCards = document.getElementsByClassName('selected');

          for (let i = 0; i < myComponent.cards.length; i++) {
            myComponent.cards[i].isSelectedBorder = false;
          }
          if (wrongCards.length) 
            wrongCards[0].classList.remove("wrong");

          if (cards.length) 
            cards[0].classList.remove("selected");
          
          if (myComponent.numOfSelected == this.numberCorrectElements) 
            myComponent.numOfSelected--;

          e.target.classList.add("selected");
          isSelected = true;
        }
        else if (this.numOfSelected < this.numberCorrectElements) {
          e.target.classList.add("selected");
          isSelected = true;
        }
        await Util.setWait(function () {
          myComponent.numOfSelected++;
        }, 1);
      }

      myComponent.cards[itemIndex].isSelectedBorder = isSelected;
      myComponent.cards.splice(itemIndex, 1, myComponent.cards[itemIndex]);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.card-b {
  .card-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 8px);
    z-index: 1;
    border-radius: 25px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent;
    transition: all 0.5s ease;
    cursor: pointer;

    &.selected, &.correct, &.wrong {
      &.selected {
        &.bounce-animation {
          animation-name: bounce;
          animation-duration: 1s;
        }
        &.shake-animation {
          animation-name: shake;
          animation-duration: 1s;
          transition: all 0.3s cubic-bezier(0, 0, 0, 0);
        }
      }
    }
    &.wrong, &.bounce-animation, &.shake-animation, &.correct {
      pointer-events: none;
      cursor: default;
    }
  }
  .card-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: calc(100% - 8px);
    pointer-events: none;
    background-repeat: no-repeat;
    box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);
    border-color: rgb(191, 218, 255);
    border-radius: 25px;
    border-width: 6px;
    border-style: solid;
    transition: all 0.5s ease 0.5s;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: relative;
    }
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shake {
  0% {
    transform: rotateZ(-2deg);
    left: -3px;
  }
  15% {
    transform: rotateZ(2deg);
    left: -3px;
  }
  30% {
    transform: rotateZ(-2deg);
    left: 3px;
  }
  45% {
    transform: rotateZ(2deg);
    left: 3px;
  }
  60% {
    transform: rotateZ(-2deg);
    left: -3px;
  }
  85% {
    transform: rotateZ(2deg);
    left: -3px;
  }
  92% {
    transform: rotateZ(1deg);
    left: -1px;
  }
  100% {
    transform: rotateZ(0deg);
    left: 0px;
  }
}
</style>
