<template>
  <div
    class="navbar"
    v-bind:style="navStyle"
    :class="{'open-menu': isClickNav}"
  >
    <div
      class="navbar-container"
      v-bind:style="{'color': theme.textColor}"
    >
      <div
        class="left-content"
        :style="[
          addBoxShadow ?
            {boxShadow: '5px 0px 10px 0px #c6c6c6'}
          : ''
        ]"
      >
        <div class="empty-bar"></div>
        <div
          v-for="(item, index) in themes"
          :key="'theme' + index"
          :id="'list' + index"
          :index="index"
          class="theme-list"
          :data-target="item.SK1"
          :active="false"
          v-on:click="onNavThemeList($event.target)"
        >
          <div class="theme-title">
            {{item.categoryValueA}}
          </div>
          <div class="theme-desc">
            {{item.categoryValueB}}
          </div>
        </div>
        <div
          v-if="themes.length"
          :id="'list-all'"
          :index="'all'"
          class="theme-list"
          data-target="all-list"
          :active="false"
          v-on:click="onNavThemeList($event.target)"
        >
          <div class="theme-title">
            {{allExercises}}
          </div>
        </div>
        <div class="theme-list empty-bar"></div>
      </div>

      <MenuRightContent
        :isShowRightContent="isShowRightContent"
        :speedCounter="speedCounter"
        :addBoxShadow="addBoxShadow"
        :themes="themes"
        :sections="sections"
        :productName="productName"
        :now="now"
        :configName="configName"
      >
      </MenuRightContent>
    </div>

    <span class="navbar-btn"
      :class="{'open': isClickNav}"
      v-on:click="onClickNav()"
      v-bind:style="{'background-image': 'url(' + themeLocation + themeName +  '/navigation.svg' + ')'}"
    >
      <span></span>
      <span></span>
      <span></span>
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import MenuRightContent from '../components/MenuRightContent'
import VueSlideUpDown from 'vue-slide-up-down'
import Navigation from '../models/Navigation'
import Util from '../helpers/Util'
import I18n from '../models/I18n'

export default {
  name: 'Menu',
  components: {
    'MenuRightContent': MenuRightContent,
    'vue-slide-up-down': VueSlideUpDown
  },
  props: [
    "jsonConfig",
    "theme",
    "weeks",
    "productName",
    "menuButtonImg",
    "themeLocation",
    "themeName",
  ],
  data () {
    return {
      myHome: this.$parent.myHome,
      isClickNav: false,
      navStyle: [],
      isShowRightContent: false,
      addBoxShadow: false,
      prevThemeList: null,
      speedCounter: null,
      active: false,
      themes: [],
      sections: [],
      sectionIndex: 0,
      isSectionListActive: null,
      now: Date.now(),
      activityTheme: null,
      isHome: (this.$route.name === 'home') ? true : false,
      configName: this.$route.params.config
    }
  },
  computed: {
    ...mapGetters(['getNavigationData']),

    allExercises() {
      return I18n.getI18n('i18n_ebf03a70-9a97-11ea-954e-6f82b3043cd5');
    }
  },
  methods: {
    ...mapActions(['setNavigationData']),

    async onClickNav () {
      let _this = this,
          themeList = document.getElementsByClassName('theme-list'),
          selectedTheme;

      this.isShowRightContent = false;
      this.prevThemeList = null;

      if (this.navStyle === "") {
        this.navStyle = {
          "z-index": "999"
        }
      }
      else {
        this.navStyle = {
          "z-index": "999"
        }
        setTimeout(function() {
          _this.navStyle = [];
        }, 400);
      }

      if (this.addBoxShadow) {
        setTimeout(function() {
          _this.addBoxShadow = false;
        }, 400);
      }
      else
        this.addBoxShadow = true;


      if (!this.isShowRightContent) {
        setTimeout(function() {
          _this.resetToggle();
        }, 400);
      }

      if (!this.isClickNav) {
        for (let index = 0; index < themeList.length; index++) {
          if (themeList[index].getAttribute('data-target') === this.activityTheme) {
            themeList[index].setAttribute('active', true);
            selectedTheme = themeList[index];
          }
          else
            themeList[index].setAttribute('active', false);
        }
      }

      this.isClickNav = !this.isClickNav;

      if(selectedTheme)
        this.onNavThemeList(selectedTheme)
    },
    async onNavThemeList (e) {
      if (this.isSectionListActive || e.classList.contains('empty-bar'))
        return false;

      let _this = this,
          dataTarget = e.getAttribute('data-target'),
          showContent = document.getElementById(dataTarget),
          allContent = document.getElementsByClassName('lessons'),
          themeListId = document.getElementById(e.id),
          themeList = document.getElementsByClassName('theme-list'),
          activities = document.getElementsByClassName('item'),
          active = (e.getAttribute('active') == 'true'),
          index = e.getAttribute('index');

      this.loadNavItems(dataTarget, index);

      if (this.isSectionListActive === null)
        showContent.classList.add('show');

      this.speedCounter = this.prevThemeList;
      this.isSectionListActive = true;
      this.isShowRightContent = !this.isShowRightContent;

      for (let index = 0; index < themeList.length; index++) {
        themeList[index].setAttribute('active', false);
      }

      if (this.prevThemeList != e.id)
        themeListId.setAttribute('active', true);

      if (!this.isShowRightContent) {
        setTimeout(function() {
          _this.resetToggle();
        }, 400);
      }

      if (this.prevThemeList != e.id && this.prevThemeList != null) {
        await Util.setWait(function () {
          _this.isShowRightContent = !_this.isShowRightContent;
        }, 600);
        this.prevThemeList = e.id;
      }
      else {
        if (this.prevThemeList != null)
          this.prevThemeList = null;
        else
          this.prevThemeList = e.id;
      }

      for (let index = 0; index < allContent.length; index++) {
        allContent[index].classList.remove('show');
      }

      showContent.classList.add('show');

      await Util.setWait(function () {
        _this.isSectionListActive = false;

        for (let index = 0; index < activities.length; index++) {
          activities[index].setAttribute('active', false);
        }
      }, 500);
    },

    collapseToggle (e, itemIndex, isThemeList = true) {
      let contentID = e.target.getAttribute('data-target'),
          collapseContent = document.getElementById(contentID),
          indexCollapse = collapseContent.getAttribute('index-collapse');

      if (isThemeList) {
        this.themes[itemIndex].sections[indexCollapse].active = !this.themes[itemIndex].sections[indexCollapse].active;
        this.themes.splice(itemIndex, 1, this.themes[itemIndex]);
      }
      else {
        this.sections[0].classLvl3[itemIndex].active = !this.sections[0].classLvl3[itemIndex].active;
        this.sections.splice(0, 1, this.sections[0]);
      }
    },

    resetToggle () {
      document.getElementsByClassName('scroll-content')[0].scrollTop = 0;
      for (let i = 0; i < this.themes.length; i++) {
        if (this.themes[i].hasOwnProperty('sections')) {
          for (let j = 0; j < this.themes[i].sections.length; j++) {
            this.themes[i].sections[j].active = true;
            this.themes.splice(i, 1, this.themes[i]);
          }
        }
      }

      if (this.sections[0] !== undefined) {
        for (let i = 0; i < this.sections[0].classLvl3.length; i++) {
          this.sections[0].classLvl3[i].active = true;
        }
        this.sections.splice(0, 1, this.sections[0]);
      }
    },

    stringifyThis (data) {
      return JSON.parse(JSON.stringify(data));
    },

    loadAllActivities () {
      if (this.sections.length > 0) // caching
        return;

      let productId = Navigation.getProductId(this.productName);
      let classLvl3 = Navigation.getClassLvl3('navProduct', productId);
      let sections = [{}];
      let tempActivity = [];

      for (let m = 0; m < classLvl3.length; m++) {
        let classLvl4 = this.stringifyThis(Navigation.getClassLvl4('navClassLvl3', classLvl3[m].SK1)),
        activitieslvl3 = [];

        for (let n = 0; n < classLvl4.length; n++) {
          let classLvl5 = this.stringifyThis(Navigation.getClassLvl5('navClassLvl4', classLvl4[n].SK1)),
          activitieslvl4 = [], activities = [];

          for (let o = 0; o < classLvl5.length; o++) {
            activities = this.stringifyThis(
              Navigation.getActivities('navClassLvl5', classLvl5[o].SK1, {
                'navClassLvl3': classLvl3[m].SK1,
                'navClassLvl4': classLvl4[n].SK1,
              }));
            classLvl5[o].activities = activities;
          }

          if (activities.length)
            classLvl4[n].classLvl5 = classLvl5;

          //GETTING LVL 4 ACTIVITIES WITHOUT LVL 5
          activitieslvl4 = this.stringifyThis(Navigation.getActivities('navClassLvl3', classLvl3[m].SK1, {'navClassLvl4': classLvl4[n].SK1}));
          tempActivity = [];
          for (let p = 0; p < activitieslvl4.length; p++) {
            if (!activitieslvl4[p].hasOwnProperty('navClassLvl5'))
              tempActivity.push(activitieslvl4[p]);
          }
          if (tempActivity.length)
            classLvl4[n].activities = tempActivity;
        }
        //GETTING LVL 3 ACTIVITIES WITHOUT LVL 4 AND 5
        activitieslvl3 = this.stringifyThis(Navigation.getActivities('navClassLvl3', classLvl3[m].SK1));
        tempActivity = [];
        for (let q = 0; q < activitieslvl3.length; q++) {
          if (!activitieslvl3[q].hasOwnProperty('navClassLvl5') && !activitieslvl3[q].hasOwnProperty('navClassLvl4'))
            tempActivity.push(activitieslvl3[q]);
        }
        if (tempActivity.length)
          classLvl3[m].activities = tempActivity;

        classLvl3[m].classLvl4 = classLvl4;
        classLvl3[m].active = true;
        classLvl3[m].now = Date.now();
      }

      sections[0].classLvl3 = classLvl3;
      sections[0].active = true;
      this.sections = sections;
    },

    loadThemeActivities (themeId, index) {
      if (index && this.themes[index].hasOwnProperty('sections')) // caching
        return;

      let sections = Navigation.getSections('navTheme', themeId);

      for (let j = 0; j < sections.length; j++) {
        let subSections = this.stringifyThis(Navigation.getSubSections('navSection', sections[j].SK1));
        let activities = this.stringifyThis(Navigation.getActivities('navSection', sections[j].SK1));

        if (subSections) {
          sections[j].activities = [];

          for (let k = 0; k < activities.length; k++) {
            if (!activities[k].hasOwnProperty('navSubSection')) {
              sections[j].activities.push(activities[k]);
            }
          }
        }

        if (subSections) {
          for (let l = 0; l < subSections.length; l++) {
            let activities = this.stringifyThis(Navigation.getActivities('navSubSection', subSections[l].SK1, {'navSection': sections[j].SK1}));
            subSections[l].activities = activities;
          }

          sections[j].subSections = subSections;
        }

        sections[j].active = true;
      }

      Vue.set(index ? this.themes[index] : '', 'sections', sections);
    },

    loadNavItems(themeId, index) {
      if (index === 'all')
        this.loadAllActivities()
      else
        this.loadThemeActivities(themeId, index);
    },

    loadNavTheme () {
      if (this.themes.length > 0) // caching
        return;

      let productId = Navigation.getProductId(this.productName);
      this.themes = Navigation.getThemes('navProduct', productId);
    },
    async initNavigation () {
      if (this.getNavigationData.hasOwnProperty(this.productName)) {
        let categories = this.getNavigationData[this.productName].categories;
        let activities = this.getNavigationData[this.productName].activities;
        await Navigation.init(this.productName, categories, activities);
      }
      else {
        let navigationData = {
          [this.productName]: await Navigation.init(this.productName)
        };

        this.setNavigationData(navigationData);
      }
    }
  },

  async created () {
    let _this = this;

    await this.initNavigation();
    this.loadNavTheme();

    if (!this.isHome) {
      let activities = this.getNavigationData[this.productName].activities;

      for (let i = 0; i < activities.length; i++) {
        if (activities[i].configName === this.configName)
          this.activityTheme = activities[i].navTheme;
      }
    }

    if (this.isHome) {
      setTimeout(function() {
        _this.isClickNav = true;
        _this.addBoxShadow = true;
      }, 200);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.navbar {
  margin-left: -339px;
  left: 0;
  display: flex;
  flex-direction: row;
  min-width: 402px;
  top: 25px;
  position: absolute;
  transition: ease-in-out all 0.5s;
  height: calc(100% - 25px);
  letter-spacing: 0px;

  &.open-menu {
    margin-left: 0;
    transition: ease-in-out all 0.5s;
    z-index: 999;
  }

  .navbar-container {
    display: flex;
    left: 0px;
    background-color: #fff;

    .left-content {
      width: 339px;
      z-index: 1;
      overflow: auto;

      .empty-bar, .theme-list {
        min-height: 52px;
        width: 100%;
      }

      .theme-list {
        border-top: solid 1px #b7b7b7;
        padding: 5px 12px;
        cursor: pointer;

        &.empty-bar {
          cursor: default;
        }

        &[active="true"] {
          background: #fae56b;
        }

        &#list-all {
          align-items: center;
          display: flex;
        }

        * {
          pointer-events: none;
        }

        .theme-title, .theme-desc {
          color: #45454e;
          font-size: 18px;
        }

        .theme-title {
          font-family: "Open Sans Bold";
          line-height: 23px;
        }

        .theme-desc {
          font-family: "Open Sans Regular";
          margin-top: -3px;
          line-height: 18px;
        }
      }

      .empty-bar {
        &[active="true"] {
          background: #fff;
        }
      }
    }


  }
}
.navbar-btn {
  width: 64px;
  height: 51px;
  display: block;
  background-repeat: no-repeat;
  cursor: pointer;
}

.navbar-container > span {
  display: block;
}
.navbar-container .collection-title span img {
  width: 20px;
  height: auto;
}
.navbar-container .collection-title > span {
  position: absolute;
  left: 0;
  line-height: 0;
  top: 0px;
}
.navbar-container .collection-title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 14px;
  border-bottom: 1px solid rgba(0,0,0, 0.2);
  padding-bottom: 8px;
  position: relative;
  text-transform: uppercase;
}
.navbar-container .collection-title label {
  line-height: 28px;
  display: block;
}
.navbar-container .collection-title a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.navbar-container .weeks, .navbar-container .theme-title {
  font-size: 18px;
  color: #45454E;
}
.navbar-container .activity-name {
  font-size: 18px;
  color: #45454E;
}
.navbar-container > span {
  margin-bottom: 6px;
}
.navbar-btn {
  width: 63px;
  height: 57px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  padding-bottom: 8px;
  padding-right: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.navbar-btn span {
  display: block;
  height: 5px;
  width: 30px;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  margin: 3px 0;
}
.navbar-btn.open span:nth-child(2) {
  display: none;
}
.navbar-btn.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.navbar-btn.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.navbar-btn.open span {
  position: absolute;
}

@keyframes openMenu {
  from {
      margin-left: -249px;
  }

  to {
      margin-left: 0;
  }
}
</style>
