<template>
  <div class="body-container">
    <ClassLayoutA
      v-if="!isFinal"
      :items="items"
      :leftSetItems="leftSetItems"
      :rightSetItems="rightSetItems"
      :assetMediaImageUrl="assetMediaImageUrl"
      :themeLocation="themeLocation"
      :themeName="themeName"
      :cardFontColor="cardFontColor"
      :cardFontSize="cardFontSize"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :displaySource="displaySource"
      :displayType="displayType"
      :setFontColor="setFontColor"
      :setFontSize="setFontSize"
      :cardBorderSize="cardBorderSize"
      :backgroundColorWrong="backgroundColorWrong"
      :backgroundColorCard="backgroundColorCard"
      :backgroundColorDisable="backgroundColorDisable"
      :borderColor="borderColor"
      :rightSetIsCorrect="rightSetIsCorrect"
      :leftSetIsCorrect="leftSetIsCorrect"
      :reinitialize="reinitialize"
      :callHideAnimation="callHideAnimation"
      :cardContentPosition="cardContentPosition"
      :titleSetOne="titleSetOne"
      :titleSetTwo="titleSetTwo"
      :isFinalFadein="isFinalFadein"
      :style="[
        isFinalFadein ? 
          {opacity: 0, transition: 'opacity 0.6s ease' }
        : ''
      ]"
    >
    </ClassLayoutA>
    <FinalB
      v-if="isFinal && finalType.toLowerCase() === 'finalb'"
      :finalImages="finalImages"
      :themeName="themeName"
      :themeLocation="themeLocation"
    >
    </FinalB>
  </div>
</template>

<script>
import Vue from 'vue'
import ClassLayoutA from '../components/ClassLayoutA'
import FinalB from '../components/FinalB'
import FinalC from '../components/FinalC'
import Velocity from 'velocity-animate'
import Util from '../helpers/Util'

export default {
  name: 'ClassA',
  components: {
    'FinalB': FinalB,
    'ClassLayoutA': ClassLayoutA,
  },
  props: [
    "items",
    "isFinal",
    "finalImages",
    "themeName",
    "themeLocation",
    "finalType",
    "bannerTextColor",
    "leftSetItems",
    "rightSetItems",
    "assetMediaImageUrl",
    "cardFontColor",
    "cardFontSize",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "displaySource",
    "displayType",
    "setFontColor",
    "setFontSize",
    "cardBorderSize",
    "backgroundColorWrong",
    "backgroundColorCard",
    "backgroundColorDisable",
    "borderColor",
    "rightSetIsCorrect",
    "leftSetIsCorrect",
    "reinitialize",
    "callHideAnimation",
    "cardAudioSource",
    "cardContentPosition",
    "titleSetOne",
    "titleSetTwo",
    "isFinalFadein",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.body-container {
  min-height: 380px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;;
}
</style>
