var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"class-layout"},[_c('SetLayout',{attrs:{"dropLeftContent":_vm.dropLeftContent,"dropRightContent":_vm.dropRightContent,"themeLocation":_vm.themeLocation,"themeName":_vm.themeName,"leftSetIsCorrect":_vm.leftSetIsCorrect,"rightSetIsCorrect":_vm.rightSetIsCorrect,"fadeOutContent":_vm.fadeOutContent,"leftSetItems":_vm.leftSetItems,"rightSetItems":_vm.rightSetItems,"cardSinkWidth":_vm.cardSinkWidth,"cardSinkHeight":_vm.cardSinkHeight,"titleSetOne":_vm.titleSetOne,"titleSetTwo":_vm.titleSetTwo,"setFontColor":_vm.setFontColor,"setFontSize":_vm.setFontSize,"removeTrans":_vm.removeTrans,"borderColor":_vm.borderColor,"isFinalFadein":_vm.isFinalFadein}}),_c('div',{staticClass:"cards"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"card",class:{disable: item.disable},style:([
        {transform: 'translate3d(' + item.x + 'px, ' + item.y + 'px, 0px)'},
        _vm.cardFontColor ?
          {color: _vm.cardFontColor}
        : '',
        _vm.cardFontSize ?
          {fontSize: _vm.cardFontSize}
        : '',
        _vm.fadeOutContent ? 
          {opacity: '0', transition: 'all 0.6s ease'}
        : '',
        _vm.removeTrans ?
          {transition: 'none 0s ease'}
        : '',
        _vm.leftSetIsCorrect && _vm.rightSetIsCorrect ? 
          {pointerEvents: 'none'} 
        : '' ]),attrs:{"currentIndex":index,"postion-x":item.x,"postion-y":item.y,"default-pos":item.d,"default-x":item.defaultX,"default-y":item.defaultY,"prev-guide-type":item.prevGuideType,"prev-guide-index":item.prevGuideIndex},on:{"mousedown":_vm.mouseDown,"mouseup":_vm.mouseUp,"touchstart":_vm.touchStart,"touchend":_vm.touchEnd,"touchmove":_vm.touchMove,"mouseover":_vm.mouseOver,"mouseout":_vm.mouseOut}},[_c('CardD',{attrs:{"sink":item.sink,"cardPaddingHeight":_vm.cardPaddingHeight,"cardPaddingWidth":_vm.cardPaddingWidth,"isShowCard":_vm.isShowCard,"backgroundDisable":item.disable,"backgroundColorWrong":_vm.backgroundColorWrong,"backgroundColorCard":_vm.backgroundColorCard,"backgroundColorDisable":_vm.backgroundColorDisable,"borderColor":_vm.borderColor,"cardBorderSize":_vm.cardBorderSize,"item":item.element,"cardContentPosition":_vm.cardContentPosition,"displaySource":_vm.displaySource,"displayType":_vm.displayType,"assetMediaImageUrl":_vm.assetMediaImageUrl,"isCorrect":_vm.leftSetIsCorrect && _vm.rightSetIsCorrect ? true : false}})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }