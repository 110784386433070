<template>
    <div class="class">
      <HeaderA
        v-if="isDocumentReady"
        :isFinal="isFinal"
        :audioHtml="audioHtml"
        :stepsLength="stepsLength"
        :progressValue="progressValue"
        :isReRotateStars="isReRotateStars"
        :fadeIn="fadeIn"
        :message="message"
        :finalMessage="finalMessage"
        :messageAudioFile="messageAudioFile"
        :messageAudioType="messageAudioType"
        :assetMediaAudioUrl="assetMediaAudioUrl"
        :finalMessageAudioFile="finalMessageAudioFile"
      >
      </HeaderA>
      <ClassA
        v-if="isDocumentReady"
        :items="items"
        :isFinal="isFinal"
        :leftSetItems="leftSetItems"
        :rightSetItems="rightSetItems"
        :assetMediaImageUrl="assetMediaImageUrl"
        :themeLocation="themeLocation"
        :themeName="themeName"
        :cardFontColor="cardFontColor"
        :cardFontSize="cardFontSize"
        :cardPaddingHeight="cardPaddingHeight"
        :cardPaddingWidth="cardPaddingWidth"
        :displaySource="displaySource"
        :displayType="displayType"
        :setFontColor="setFontColor"
        :setFontSize="setFontSize"
        :cardBorderSize="cardBorderSize"
        :backgroundColorWrong="backgroundColorWrong"
        :backgroundColorCard="backgroundColorCard"
        :backgroundColorDisable="backgroundColorDisable"
        :borderColor="borderColor"
        :rightSetIsCorrect="rightSetIsCorrect"
        :leftSetIsCorrect="leftSetIsCorrect"
        :reinitialize="reinitialize"
        :callHideAnimation="callHideAnimation"
        :cardAudioSource="cardAudioSource"
        :finalType="finalType"
        :finalImages="finalImages"
        :cardContentPosition="cardContentPosition"
        :titleSetOne="titleSetOne"
        :titleSetTwo="titleSetTwo"
        :isFinalFadein="isFinalFadein"
      >
      </ClassA>
      <FooterA
        v-if="isDocumentReady"
        :flipClass="flipClass"
        :isDisabled="isDisableNext"
        :isDisableClick="isDisableClick"
        :nextText="config.data.config.steps.actionNextText"
        :validateText="config.data.config.steps.actionValidateText"
        :finalActionNextText="finalActionNextText"
        :actionTextColor="actionTextColor"
        :activeButtonImg="myHome.getThemeImage('bouton-actif.svg')"
        :inactiveButtonImg="myHome.getThemeImage('bouton-inactif.svg')"
        :isFinal="isFinal"
      >
      </FooterA>
    </div>
</template>

<script>
import HeaderA from '../components/HeaderA'
import FooterA from '../components/FooterA'
import ClassA from '../components/ClassA'
import ClassModel from '../models/Class'
import Util from '../helpers/Util'

export default {
  props: ['config'],
  components: {
    'HeaderA': HeaderA,
    'FooterA': FooterA,
    'ClassA': ClassA
  },
  data () {
    return {
      myComponent: this,
      myHome: this.$parent.myHome,
      items: [],
      elements: [],
      audioHtml: '',
      audio: false,
      progressValue: 0,
      fadeIn: false,
      stepsLength: 0,
      message: '',
      actionNextText: false,
      actionValidateText: false,
      messageAudioType: false,
      messageAudioFile: false,
      flipClass: false,
      isDisableNext: true,
      isDisableClick: false,
      isReRotateStars: false,
      finalActionNextText: '',
      finalImages: [],
      finalMessage: '',
      finalType: false,
      finalMessageAudioFile: false,
      isFinal: false,
      isFinalFadein: false,
      itemsSizeContainer: [],
      cardWidth: 0,
      cardHeight: 0,
      themeLocation: false,
      themeName: false,
      assetMediaAudioUrl: false,
      assetMediaImageUrl: false,
      cardFontColor: false,
      cardFontSize: false,
      cardPaddingHeight: false,
      cardPaddingWidth: false,
      displaySource: false,
      displayType: false,
      setFontColor: false,
      setFontSize: false,
      cardBorderSize: false,
      cardBorderColor: false,
      correctBorderColor: false,
      wrongBorderColor: false,
      boardBorderColor: false,
      boardBackgroundColor: false,
      bannerTextColor: false,
      backgroundColorWrong: false,
      backgroundColorCard: false,
      backgroundColorDisable: false,
      actionTextColor: false,
      backgroundColorWrong: false,
      borderColor: [],
      rightSetIsCorrect: null,
      leftSetIsCorrect: null,
      reinitialize: false,
      callHideAnimation: false,
      cardAudioSource: false,
      cardContentPosition: false,
      titleSetOne: '',
      titleSetTwo: '',
      leftSetItems: [],
      rightSetItems: [],
      disableCard: false,
      isDocumentReady: false,
      onResizeScreen: false,
    }
  },
  methods: {
    initialize: function() {
      if (this.config.data.config.hasOwnProperty('layout')) {
        this.cardFontColor = this.config.data.config.layout.hasOwnProperty('cardFontColor') ? this.config.data.config.layout.cardFontColor : false;
        this.cardFontSize = this.config.data.config.layout.hasOwnProperty('cardFontSize') ? this.config.data.config.layout.cardFontSize : false;
        this.cardPaddingHeight = this.config.data.config.layout.hasOwnProperty('cardPaddingHeight') ? this.config.data.config.layout.cardPaddingHeight : false;
        this.cardPaddingWidth = this.config.data.config.layout.hasOwnProperty('cardPaddingWidth') ? this.config.data.config.layout.cardPaddingWidth : false;
        this.displaySource = this.config.data.config.layout.hasOwnProperty('displaySource') ? this.config.data.config.layout.displaySource : 'value';
        this.displayType = this.config.data.config.layout.hasOwnProperty('displayType') ? this.config.data.config.layout.displayType : 'text';
        this.setFontColor = this.config.data.config.layout.hasOwnProperty('setFontColor') ? this.config.data.config.layout.setFontColor : false;
        this.setFontSize = this.config.data.config.layout.hasOwnProperty('setFontSize') ? this.config.data.config.layout.setFontSize : false;
        this.cardBorderSize   = this.config.data.config.layout.hasOwnProperty('cardBorderSize') ? this.config.data.config.layout.cardBorderSize : '6px';
        this.cardAudioSource = this.config.data.config.layout.hasOwnProperty('cardAudioSource') ? this.config.data.config.layout.cardAudioSource : false;
        this.cardContentPosition = this.config.data.config.layout.hasOwnProperty('cardContentPosition') ? this.config.data.config.layout.cardContentPosition : 'center';
        this.titleSetOne = this.config.data.config.layout.hasOwnProperty('titleSetOne') ? this.config.data.config.layout.titleSetOne : '';
        this.titleSetTwo = this.config.data.config.layout.hasOwnProperty('titleSetTwo') ? this.config.data.config.layout.titleSetTwo : '';
      }
      if (this.config.data.config.hasOwnProperty('theme')) {
        this.themeLocation = this.config.data.config.theme.hasOwnProperty('themeLocation') ? this.config.data.config.theme.themeLocation : false;
        this.themeName = this.config.data.config.theme.hasOwnProperty('themeName') ? this.config.data.config.theme.themeName : false;
        this.cardBorderColor = this.config.data.config.theme.hasOwnProperty('cardBorderColor') ? this.config.data.config.theme.cardBorderColor : false;
        this.correctBorderColor = this.config.data.config.theme.hasOwnProperty('correctBorderColor') ? this.config.data.config.theme.correctBorderColor : false;
        this.wrongBorderColor = this.config.data.config.theme.hasOwnProperty('wrongBorderColor') ? this.config.data.config.theme.wrongBorderColor : false;
        this.boardBorderColor = this.config.data.config.theme.hasOwnProperty('boardBorderColor') ? this.config.data.config.theme.boardBorderColor : false;
        this.boardBackgroundColor = this.config.data.config.theme.hasOwnProperty('boardBackgroundColor') ? this.config.data.config.theme.boardBackgroundColor : false;
        this.bannerTextColor = this.config.data.config.theme.hasOwnProperty('bannerTextColor') ? this.config.data.config.theme.bannerTextColor : false;
        this.backgroundColorWrong = this.config.data.config.theme.hasOwnProperty('backgroundColorWrong') ? this.config.data.config.theme.backgroundColorWrong : false;
        this.actionTextColor = this.config.data.config.theme.hasOwnProperty('actionTextColor') ? this.config.data.config.theme.actionTextColor : false;
        this.backgroundColorCard = this.config.data.config.theme.hasOwnProperty('backgroundColorCard') ? this.config.data.config.theme.backgroundColorCard : 'transparent';
        this.backgroundColorDisable = this.config.data.config.theme.hasOwnProperty('backgroundColorDisable') ? this.config.data.config.theme.backgroundColorDisable : false;

        this.borderColor = ["transparent", this.cardBorderColor, this.correctBorderColor, this.wrongBorderColor]
      }
      if (this.config.data.config.hasOwnProperty('steps')) {
        this.message = this.config.data.config.steps.hasOwnProperty('message') ? this.config.data.config.steps.message : '';
        this.stepsLength = this.config.data.config.steps.hasOwnProperty('numberSteps') ? parseInt(this.config.data.config.steps.numberSteps) : 0;
        this.actionNextText = this.config.data.config.steps.hasOwnProperty('actionNextText') ? this.config.data.config.steps.actionNextText : '';
        this.actionValidateText = this.config.data.config.steps.hasOwnProperty('actionValidateText') ? this.config.data.config.steps.actionValidateText : '';
        this.messageAudioType = this.config.data.config.steps.hasOwnProperty('messageAudioType') ? this.config.data.config.steps.messageAudioType : 'mp3';
        this.messageAudioFile = this.config.data.config.steps.hasOwnProperty('messageAudioFile') ? this.config.data.config.steps.messageAudioFile : '';
      }
      if (this.config.data.config.hasOwnProperty('final')) {
        this.finalImages = this.config.data.config.final.hasOwnProperty('finalImages') ? this.config.data.config.final.finalImages : [];
        this.finalActionNextText = this.config.data.config.final.hasOwnProperty('actionNextText') ? this.config.data.config.final.actionNextText : '';
        this.finalMessage = this.config.data.config.final.hasOwnProperty('message') ? this.config.data.config.final.message : '';
        this.finalType = this.config.data.config.final.hasOwnProperty('type') ? this.config.data.config.final.type : 'finalb';
        this.finalMessageAudioFile = this.config.data.config.final.hasOwnProperty('messageAudioFile') ? this.config.data.config.final.messageAudioFile : '';
      }

      this.assetMediaAudioUrl = this.config.data.config.hasOwnProperty('assetMediaAudioUrl') ? this.config.data.config.assetMediaAudioUrl : '';
      this.assetMediaImageUrl = this.config.data.config.hasOwnProperty('assetMediaImageUrl') ? this.config.data.config.assetMediaImageUrl : '';
      document.getElementsByTagName("body")[0].classList.remove("finish");
    },

    onSubmit: async function () {
      var _this = this,
          nullCTR = 0,
          rightCorrect = 0,
          rightWrong = 0,
          leftCorrect = 0,
          leftWrong = 0,
          leftCorrects = [],
          rightCorrects = [];
      this.isDisableClick = true;
      this.rightSetIsCorrect = null;
      this.leftSetIsCorrect = null;

      for (let index = 0; index < this.items.length; index++) {
        if (this.items[index].guideType != this.items[index].set) {
          nullCTR++;
          if (this.items[index].guideType === 'one') 
            leftWrong++;
          else
            rightWrong++;
        }
        else {
          if (this.items[index].guideType === 'one') {
            leftCorrect++;
            leftCorrects.push(index);
          }
          else {
            rightCorrect++;
            rightCorrects.push(index);
          }

        }
      }

      if (nullCTR === 0) {
        this.rightSetIsCorrect = true;
        this.leftSetIsCorrect = true;

        await Util.setWait(function () {
          _this.progressValue++;
        }, 1000);
        await Util.setWait(function () {
          _this.flipClass = true;
        }, 600);
      }
      else {
        if (rightCorrect === 0 || rightWrong > 0) 
          this.rightSetIsCorrect = false;
        else 
          this.rightSetIsCorrect = true;
          
        if (leftCorrect === 0 || leftWrong > 0) 
          this.leftSetIsCorrect = false;
        else 
          this.leftSetIsCorrect = true;

        this.isDisableNext = true;
      }
      
      if (this.leftSetIsCorrect) {
        for (let index = 0; index < leftCorrects.length; index++) 
         this.items[leftCorrects[index]].disable = true;
      }

      if (this.rightSetIsCorrect) {
        for (let index = 0; index < rightCorrects.length; index++) 
         this.items[rightCorrects[index]].disable = true;
      }
          
      this.isDisableClick = false;
    },

    onNext: async function() {
      var _this = this,
          isFinal = false;
      this.callHideAnimation = true;
      this.isDisableClick = true;

      isFinal = (_this.progressValue === _this.stepsLength);
      
      if (isFinal) 
        this.isFinalFadein = true;

      await Util.setWait(function () {
        _this.rightSetIsCorrect = null;
        _this.leftSetIsCorrect = null;
        _this.elements = [];
        _this.items = [];
        _this.callHideAnimation = false;
        
        if (isFinal) {
          _this.flipClass = false;
          _this.isFinal = true;
          document.getElementsByTagName("body")[0].classList.add("finish");

          setTimeout(function() {
            _this.isReRotateStars = true;
          }, 300);
          setTimeout(function() {
            _this.isDisableClick = false;
            _this.isReRotateStars = false;
          }, 2000);
        }
        else {
          _this.flipClass = false;
          _this.getElements();
          setTimeout(function() {
            _this.reinitialize = true;
          }, 1);
        }
      }, 600);

      await Util.setWait(function () {
        if (_this.progressValue != _this.stepsLength) {
          _this.isDisableNext = true;
          _this.isDisableClick = false;
        }
        _this.reinitialize = false;
      }, 100);

    },

    onReset: async function() {
      var _this = this;
      this.items = [];
      this.isFinal = false;
      this.isFinalFadein = false;
      this.isDisableClick = false;
      this.isDisableNext = true;
      this.progressValue = 0;
      this.getElements();
      await Util.setWait(function () {
        _this.reinitialize = true;
      }, 1);
      document.getElementsByTagName("body")[0].classList.remove("finish");
      this.reinitialize = false;
      this.isDisableNext = true;
    },

    getElements: async function() {
      var _this = this;
      this.elements = [];

      await Util.setWait(function () {
        _this.elements = ClassModel.getElements(4);
      }, 100);
      
      this.leftSetItems = [];
      this.rightSetItems = [];
      
      for (let index = 0; index < this.elements.length; index++) {
        this.items.push({'value': this.elements[index].element.value, 'set': this.elements[index].set, 'guideType': null, 'element': this.elements[index].element});
      }
      
      for (let index = 0; index < 4; index++) {
        this.leftSetItems.push({
          "x": 0,
          "y": 0,
          "d": 0,
          "size": 0,
          "show": 0
        });
        this.rightSetItems.push({
          "x": 0,
          "y": 0,
          "d": 0,
          "size": 0,
          "show": 0
        });
      }
      ClassModel.debug();
      this.isDocumentReady = true;
    },
  },
  
  created: async function () {
    var _this = this;
    try {
      await ClassModel.populateElements(this.config);
    }
    catch (err) { // need to catch error or rejected promise
      console.error(err);
    }
    this.getElements();
    this.initialize();
  },
  watch: {
    items: function(value) {
      var nullCTR = 0;
      for (let index = 0; index < value.length; index++) {
        if (value[index].guideIndex == null) {
          nullCTR++;
        }
      }

      if (!this.onResizeScreen) {
        if (nullCTR === 0) 
          this.isDisableNext = false;
        else 
          this.isDisableNext = true;
        this.rightSetIsCorrect = null;
        this.leftSetIsCorrect = null;
      }
      this.onResizeScreen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.class {
  height: 100%;
  display: flex;
  justify-content: normal;
  align-items: center;
  flex-direction: column;
}

.body-container {
  min-height: 380px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

}
</style>
