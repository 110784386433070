<template>
  <div 
    class="card-body"
    :class="[addedClass, {wrong: isWrong, correct: isCorrect && isDone}]"
    :style="[
      cardHeight ? 
        {height: cardHeight}
      : '',
      cardWidth ? 
        {width: cardWidth}
      : '',
    ]"
    :index="index"
    v-on:click="onSelect(index)"
  >
    <div class="card-container">
      <div 
        class="card-border"
        :style="[
          (isCorrect && isDone) || isSelected || isWrong ?
            {borderWidth: '6px'}
          : '',
          isSelected ?
            {borderColor: selectedBorderColor}
          : '',
          isWrong ?
            {borderColor: wrongBorderColor}
          : '',
          isCorrect && isDone ?
            {borderColor: correctBorderColor}
          : '',
          ((isCorrect && isDone) || isSelected || isWrong) && retroBorderSize ?
            {borderWidth: retroBorderSize}
          : '',
        ]"
      >
      </div>
      <div 
        class="card-content"
        :style="[
          isWrong ?
            {backgroundColor: backgroundColorWrong, boxShadow: 'none'}
          : '',
        ]"
      >
      
        <Notify
          v-if="isDone && isSelected"
          :hasBorder="false"
          addedClass="multi-a"
        >
        </Notify>
        <div 
          class="content"
          :class="[{'bounce': isConfirmBump}]"
          :style="[
            cardFontColor ?
              {color: cardFontColor}
            : '',
            cardFontSize ?
              {fontSize: cardFontSize}
            : '',
            cardPaddingTop ? 
              {paddingTop: cardPaddingTop}
            : '',
            cardPaddingBottom ? 
              {paddingBottom: cardPaddingBottom}
            : '',
            cardPaddingWidth ? 
              {
                paddingLeft: 'calc(' + cardPaddingWidth + ' / 2)', 
                paddingRight: 'calc(' + cardPaddingWidth + ' / 2)'
              }
            : '',
            cardContentPosition ?
              {justifyContent: cardContentPosition}
            : '',
          ]"
        >
          <AlternateDataA
            v-if="displayTypeAlt && displaySourceAlt && alternateEnable"
            :class="[{'fade-in': (isCorrect && isDone) || isWrong || isUsed}]"
            :data="data"
            :isDone="isDone"
            :isWrong="isWrong"
            :isCorrect="isCorrect"
            :cardFontSize="cardFontSize"
            :cardFontColor="cardFontColor"
            :displayTypeAlt="displayTypeAlt"
            :displaySourceAlt="displaySourceAlt"
            :assetMediaImageUrl="assetMediaImageUrl"
            :cardContentPosition="cardContentPosition"
            :style="[
              cardPaddingTop && ((isCorrect && isDone) || isWrong || isUsed) ? 
                {paddingTop: cardPaddingTop}
              : '',
              cardPaddingBottom && ((isCorrect && isDone) || isWrong || isUsed) ? 
                {paddingBottom: cardPaddingBottom}
              : '',
              cardPaddingWidth && ((isCorrect && isDone) || isWrong || isUsed) ? 
                {
                  paddingLeft: 'calc(' + cardPaddingWidth + ' / 2)', 
                  paddingRight: 'calc(' + cardPaddingWidth + ' / 2)'
                }
              : '',
            ]"
          >
          </AlternateDataA>
          <div
            class="card-img"
            :class="[{'fade-out': ((isCorrect && isDone) || isWrong || isUsed) && alternateEnable && (displayTypeAlt && displaySourceAlt)}]"
            v-if="displayType == 'image'"
            :style="[
              cardContentPosition ?
                {justifyContent: cardContentPosition}
              : '',
            ]"
          >
            <img 
              v-if="displaySource"
              draggable="false" 
              v-bind:src="assetMediaImageUrl + data.element[displaySource]" 
              v-rjs="2"
              :style="[
                (isSelected || isWrong) && isSelectBump ?
                  {transform: 'scale(0.8)', transition: 'all .3s ease-in-out'}
                : '',
                isSvg(data) ?
                  {height: '-webkit-fill-available'}
                : '',
              ]"
              :width="isSvg(data) ? '': '210'"
              :height="isSvg(data) ? '': '160'"
            >
          </div>

          <div
            v-if="displayType == 'text'"
            :class="[{'fade-out': ((isCorrect && isDone) || isWrong || isUsed) && alternateEnable && (displayTypeAlt && displaySourceAlt)}]"
            class="card-title"
            :style="[
              (isSelected || isWrong) && isSelectBump ? 
                {transform: 'scale(0.8)', transition: 'all .3s ease-in-out'}
              : '',
              cardFontSize ?
                {fontSize: cardFontSize} 
              : '',
              cardContentPosition ?
                {textAlign: cardContentPosition == 'flex-end' ? 'right' : (cardContentPosition == 'start' ? 'left' : 'center')}
              : '',
            ]"
          >
            {{ displaySource ? data.element[displaySource] : '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Util from '../helpers/Util'
import Notify from '../components/Notify'
import AlternateDataA from '../components/AlternateDataA'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CardF',
  components: {
    'Notify': Notify,
    'AlternateDataA': AlternateDataA
  },
  props: [
    "alternateEnable",
    "cardType",
    "data",
    "index",
    "addedClass",
    "isSelected",
    "isWrong",
    "isCorrect",
    "isComplete",
    "isDone",
    "isUsed",
    "backgroundColorWrong",
    "correctBorderColor",
    "selectedBorderColor",
    "wrongBorderColor",
    "cardBorderColor",
    "cardFontColor",
    "cardFontSize",
    "cardHeight",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "cardWidth",
    "displaySource",
    "displayType",
    "retroBorderSize",
    "assetMediaImageUrl",
    "displayedCards",
    "isSelectBump",
    "isConfirmBump",
    "cardContentPosition",
    "displaySourceAlt",
    "displayTypeAlt",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
      isSelected1: false,
    }
  },
  methods: {
    onSelect: function(paramIndex) {
      this.displayedCards.forEach(function(paramCard, index) {
        if (paramCard.id === paramIndex)
          paramCard.isSelected = true;
        else
          paramCard.isSelected = false;
			});
      this.displayedCards.splice(0, 1, this.displayedCards[0]);
      this.myComponent.isDisabledButton = false;
    },
    
    isSvg(data) {
      return data.element[this.displaySource].indexOf('.svg') == (data.element[this.displaySource].length - 4);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.multiA-layoutB {
  &.card-body {
    .card-container {
      .card-border, .card-content {
        border-radius: 18px;
      }
      .card-content {
        .content {
          .card-img {
            img {
              transition: all 0.3s ease-in-out 0s;
            }
          }
        }
      }
    }
  }
}
.multiB-layoutA, .multiB-layoutB {
  &.card-body {
    .card-container {
      .card-border, .card-content {
        border-radius: 24px;
      }
    }
  }
}
.card-body {
  height: 100%;
  min-width: 50px;
  position: relative;
  cursor: pointer;
  
  &.correct, &.wrong {
    pointer-events: none;
    cursor: default;

    .card-container {
      .card-content {
        .content.bounce {
          margin-bottom: 0;
          animation-name: bumb_content;
          animation-duration: .6s;
        }
      }
    }
  }

  .card-container {
    height: 100%;
    width: 100%;

    .card-border {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: all .3s ease-in-out;
      border-width: 0;
      border-style: solid;
      border: 3px solid transparent;
      z-index: 1;
    }

    .card-content {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      transition: all .3s ease-in-out;
      box-shadow: 0 8px 0 0 rgba(0,0,0,.2);
      overflow: hidden;

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .card-title {
          font-size: 35px;
          position: relative;
          text-align: center;
          backface-visibility: hidden;
          transition: all .1s ease .2s;
          -webkit-perspective: 1000;
          perspective: 1000;

          &.fade-out {
            transition: all .3s ease-in-out;
            opacity: 0;
          }
        }

        .card-img {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: relative;
          margin: 0;
          -webkit-perspective: 1000;
          perspective: 1000;

          &.fade-out {
            transition: all 0.3s ease-in-out;
            opacity: 0;
          }

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@keyframes bumb_content {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade_out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
