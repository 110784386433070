<template>
  <div 
    class="card-a"
    :class="{correct: isCorrect}"
    :data-index="index"
  >
    <div
      class="flip-overlay"
      :data-target="cardDataTarget"
      :id-target="container + '-' + index" 
      v-on:click="$parent.onFlip"
      :class="{'prevent-click': preventClickCard}"
    ></div>
    <div 
      class="card-img front"
      v-bind:style="[
        cssColor ?
          {color: cssColor}
        : '',
        isShowCards && !rotateCard ?
          ''
        : {display: isShowCards},
        cssBGColorA ?
          {backgroundColor: cssBGColorA}
        : '',
        cssBorderColorA ?
          {borderColor: cssBorderColorA}
        : '',
      ]
      
      "
      >
      <img draggable="false" v-rjs="2" :src="frontIMG">
      <span>{{cardIdentifiers ? cardIdentifiers[index] : ''}}</span>
    </div>
    <div 
      class="card-title back"
      :data-value="collectionCard.element[pairingSource]"
      :style="[
        isFinished === true && (index + 1) % 2 == 0 ?
          {backgroundColor: cssBGColorB}
        : {backgroundColor: cssBGColorOptionB},
        finalFirstBGColorFinal && isFinished === true && (index + 1) % 2 == 1 ?
          {backgroundColor: finalFirstBGColorFinal}
        : '',
        finalSecondBGColorFinal && isFinished === true && (index + 1) % 2 == 0 ?
          {backgroundColor: finalSecondBGColorFinal}
        : '',
        isWrong ?
          {
            backgroundColor: backgroundColorWrong,
          }
        : '',
        collectionCard.isComplete ?
          {
            backgroundColor: cssBGColorDisable,
            boxShadow: 'none'
          }
        : '',
        retroBorderSize ?
          {padding: retroBorderSize}
        : '',
        finalRetroBorderSizeLeft && isFinished === true && (index + 1) % 2 == 1 ?
          {padding: finalRetroBorderSizeLeft}
        : '',

        finalRetroBorderSizeRight && isFinished === true && (index + 1) % 2 == 0 ?
          {padding: finalRetroBorderSizeRight}
        : '',
      ]"
    >
      <Notify
        v-if="collectionCard.isCorrect"
        addedClass="memo"
        :hasBorder="false"
      >
      </Notify>
      <div 
        class="card-back-border-default"
        :style="[
          isFinished === true ?
            (index + 1) % 2 == 0 ?
              {borderColor: cssBorderColorB}
            : {borderColor: cssBorderColorOptionB}
          : {borderColor: cssBorderDefaultColor},
          finalFirstBorderColorFinal && isFinished === true && (index + 1) % 2 == 1 ?
            {borderColor: finalFirstBorderColorFinal}
          : '',
          finalSecondBorderColorFinal && isFinished === true && (index + 1) % 2 == 0 ?
            {borderColor: finalSecondBorderColorFinal}
          : '',
        ]"
      >
      </div>
      <div 
        class="card-back-border"
        :style="[
          isSelected && isShowCards ?
            {
              borderColor: selectedBorderColor
            }
          : '',
          isWrong ?
            {
              borderColor: wrongBorderColor
            }
          : '',
          collectionCard.isCorrect ?
            {
              borderColor: correctBorderColor
            }
          : '',
          collectionCard.isComplete ?
            {
              borderColor: cssBorderColorDisable, 
            }
          : '',
          retroBorderSize && !collectionCard.isComplete ?
            {borderWidth: retroBorderSize}
          : '',
        ]"
      ></div>
      <span 
        class="card-back-content"
        :style="[
          cardPaddingWidth ?
            {
              paddingLeft: 'calc(' + cardPaddingWidth + ' / 2)', 
              paddingRight: 'calc(' + cardPaddingWidth + ' / 2)'
            }
          : '',
          cardPaddingTop ?
            {paddingTop: cardPaddingTop}
          : '',
          cardPaddingBottom ?
            {paddingBottom: cardPaddingBottom}
          : '',
          cardContentPosition ? 
            {
              textAlign: cardContentPosition,
              justifyContent: cardContentPosition == 'left' ? 
                'start' 
              : cardContentPosition == 'right' ? 
                  'flex-end' 
                : cardContentPosition
            }
          : '',
          finalCardContentPositionLeft && isFinished === true && (index + 1) % 2 == 1 ?
            {
              textAlign: finalCardContentPositionLeft,
              justifyContent: finalCardContentPositionLeft == 'left' ? 
                'start' 
              : finalCardContentPositionLeft == 'right' ? 
                  'flex-end' 
                : finalCardContentPositionLeft
            }
          : '',
          finalCardContentPositionRight && isFinished === true && (index + 1) % 2 == 0 ?
            {
              textAlign: finalCardContentPositionRight,
              justifyContent: finalCardContentPositionRight == 'left' ? 
                'start' 
              : finalCardContentPositionRight == 'right' ? 
                  'flex-end' 
                : finalCardContentPositionRight
            }
          : '',
          finalCardPaddingTopLeft && isFinished === true && (index + 1) % 2 == 1 ?
            {paddingTop: finalCardPaddingTopLeft}
          : '',
          finalCardPaddingBottomLeft && isFinished === true && (index + 1) % 2 == 1 ?
            {paddingBottom: finalCardPaddingBottomLeft}
          : '',
          finalCardPaddingWidthLeft && isFinished === true && (index + 1) % 2 == 1 ?
            {
              paddingLeft: 'calc(' + finalCardPaddingWidthLeft + ' / 2)', 
              paddingRight: 'calc(' + finalCardPaddingWidthLeft + ' / 2)'
            }
          : '',
          finalCardPaddingTopRight && isFinished === true && (index + 1) % 2 == 0 ?
            {paddingTop: finalCardPaddingTopRight}
          : '',
          finalCardPaddingBottomRight && isFinished === true && (index + 1) % 2 == 0 ?
            {paddingBottom: finalCardPaddingBottomRight}
          : '',
          finalCardPaddingWidthRight && isFinished === true && (index + 1) % 2 == 0 ?
            {
              paddingLeft: 'calc(' + finalCardPaddingWidthRight + ' / 2)', 
              paddingRight: 'calc(' + finalCardPaddingWidthRight + ' / 2)'
            }
          : '',
        ]"
      >
        <span 
          v-if="isFinished && (index + 1) % 2 == 1 && finalFirstDisplayType === 'text'"
          :style="[
            finalFirstCardFontSize ?
              {fontSize: finalFirstCardFontSize}
            : '',
            finalFirstCardFontColor ?
              {color: finalFirstCardFontColor}
            : {color: collectionCard.color},
            
          ]"
        >
            {{collectionCard.element[finalFirstDisplaySource]}}
        </span>

        <img v-else-if="isFinished && (index + 1) % 2 == 1 && finalFirstDisplayType === 'image'" draggable="false" v-rjs="2" :src="assetMediaImageUrl + collectionCard.element[finalFirstDisplaySource]">
        
        <span 
          v-if="isFinished && (index + 1) % 2 == 0 && finalSecondDisplayType === 'text'"
          :style="[
            finalSecondCardFontSize ?
              {fontSize: finalSecondCardFontSize}
            : '',
            finalSecondCardFontColor ?
              {color: finalSecondCardFontColor}
            : {color: collectionCard.color}
          ]"
        >
            {{collectionCard.element[finalSecondDisplaySource]}}
        </span>

        <img v-else-if="isFinished && (index + 1) % 2 == 0 && finalSecondDisplayType === 'image'" draggable="false" v-rjs="2" :src="assetMediaImageUrl + collectionCard.element[finalSecondDisplaySource]">
        
        <span 
          v-else-if="!isFinished && displayType === 'text'"
          :style="[
            cardFontSize ?
              {fontSize: cardFontSize}
            : '',
            cardFontColor ?
              {color: cardFontColor}
            : {color: collectionCard.color},
          ]"
        >
            {{collectionCard.element[displaySource]}}
        </span>
        
        <img v-else-if="!isFinished" draggable="false" v-rjs="2" :src="assetMediaImageUrl + collectionCard.element[displaySource]">
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Notify from '../components/Notify'

export default {
  name: 'Wheel',
  components: {
    'Notify': Notify,
  },
  props: [
    "container",
    "isShowCards",
    "collectionCard",
    "pairingSource",
    "index",
    "cardDataTarget",
    "cssColor",
    "cssBGColorA",
    "cssBorderColorA",
    "frontIMG",
    "cardIdentifiers",
    "cssBackCardColor",
    "cssBGColorB",
    "cssBGColorOptionB",
    "cssBorderColorB",
    "cssBorderColorOptionB",
    "cssBorderDefaultColor",
    "assetMediaImageUrl",
    "isFinished",
    "isCorrect",
    "isSelected",
    "isWrong",
    "themeName",
    "themeLocation",
    "displaySource",
    "displayType",
    "cardFontColor",
    "cardFontSize",
    "backgroundColorWrong",
    "secondDisplaySource",
    "secondDisplayType",
    "secondCardFontColor",
    "secondCardFontSize",
    "formatTwoCol",
    "finalFirstDisplaySource",
    "finalFirstDisplayType",
    "finalFirstCardFontColor",
    "finalFirstCardFontSize",
    "finalSecondDisplaySource",
    "finalSecondDisplayType",
    "finalSecondCardFontColor",
    "finalSecondCardFontSize",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "cssBGColorDisable",
    "cssBorderColorDisable",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingWidth",
    "retroBorderSize",
    "cardContentPosition",
    "preventClickCard",
    "rotateCard",
    "finalFirstBGColorFinal",
    "finalFirstBorderColorFinal",
    "finalSecondBGColorFinal",
    "finalSecondBorderColorFinal",
    "finalCardPaddingTopLeft",
    "finalCardPaddingBottomLeft",
    "finalCardPaddingWidthLeft",
    "finalCardContentPositionLeft",
    "finalRetroBorderSizeLeft",
    "finalCardPaddingTopRight",
    "finalCardPaddingBottomRight",
    "finalCardPaddingWidthRight",
    "finalCardContentPositionRight",
    "finalRetroBorderSizeRight",
  ],
  data() {
    return {
      images: [],
      myHome: this.$parent.myHome,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.card-front-back-css {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - 8px);
  pointer-events: none;
	background-repeat: no-repeat;
  box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  border-width: 6px;
  border-style: solid;
  transform-style: preserve-3d;
}

.card-a {
  transform-style: preserve-3d;
  display: block;
  width: 100%;
  height: 100%;

  .flip-overlay {
    width: 100%;
    height: 100%;
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
  }
  .card-img {

    &.front{
      @extend .card-front-back-css;

      img{
        position: absolute;
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      span {
        top: 15px;
        position: absolute;
        right: 25px;
        font-size: 24px
      }
    }
  }
  .card-title {

    &.back {
      @extend .card-front-back-css;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      align-items: center;
      background-position: center top;
      border: none;
      padding: 6px;

      .card-back-border, .card-back-border-default {
        border-radius: 25px;
        width: 100%;
        height: 100%;
        position: absolute;
        border: 6px solid transparent;
        transition: all .3s ease;
      }

      .card-back-content {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;

        span {
          margin-right: 1px;
          letter-spacing: 1px;
          word-wrap: break-word;
          overflow-wrap: break-word;
          z-index: 1;
          display: flex;
          align-items: center;
        }

        img {
          height: auto;
          width: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }

}
.prevent-click {
  pointer-events: none;
}

@keyframes fadeInPairedCards {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
