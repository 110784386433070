var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wheel",class:_vm.wheelName+'-wheel',attrs:{"previousText":_vm.previousText}},[(_vm.previousText && _vm.isRemoveWheelBorder == 'active')?_c('Notify',{key:_vm.wheelName,attrs:{"addedClass":"asso","hasBorder":false}}):_vm._e(),_c('div',{staticClass:"wheel-container",style:([
      _vm.wheelCardWidth ?
        {width: _vm.wheelCardWidth}
      : '' ])},[_c('img',{staticClass:"wheel-container-img",attrs:{"src":_vm.background,"alt":"","draggable":"false","ondragstart":"return false;"}}),_c('div',{staticClass:"wheel-overlay"},[_c('div',{staticClass:"swipe-wheel",class:{disable: _vm.isButtonDisableWheel || _vm.isWheelDisableFromConfig},style:([
          _vm.wheelFadeData ?
            {backgroundColor: 'white'}
          : {backgroundColor: 'transparent'} ]),on:{"mousedown":function($event){return _vm.swipeStartWheel($event)},"mouseup":function($event){return _vm.swipeEndWheel($event, _vm.wheelName)},"touchstart":function($event){return _vm.swipeStartWheel($event)},"touchend":function($event){return _vm.swipeEndWheel($event, _vm.wheelName)}}},[_c('img',{staticClass:"overlay-img",attrs:{"src":_vm.overlay,"alt":"","draggable":"false","ondragstart":"return false;"}})])]),_c('div',{ref:_vm.wheelName+'Wheel',staticClass:"wheel-content"},_vm._l((_vm.displayedWheel),function(item,index){return (!_vm.displayedWheel == [])?_c('div',{key:_vm.wheelName + '-'+index,staticClass:"item",class:[
          (item.isSelected) ? _vm.selectedClassName + ' selected' : '', 
          {
            paired: item.isPaired, 
            'skip-paired': _vm.wheelIsCrit, 
            finishItem: item.imageSrc,
            wrong: item.wrong && !item.isPaired && !(_vm.numberOfDisable == 1 &&  _vm.isWheelDisableFromConfig && _vm.wrongBorderRestriction),
            correct: item.correct && !item.isPaired,
          }
        ],style:([
          item.css,
            item.isPaired ?
              {
                backgroundColor: _vm.wheelDisableBackgroundColor, 
                borderColor: _vm.wheelDisableBackgroundColor
              } 
            : 
              {
                borderTop: '2px solid ' + _vm.wheelBorderColor, 
                borderBottom: '2px solid ' + _vm.wheelBorderColor
              },
          _vm.isSmoothEffect ? 
            {'transition': 'top 0.3s cubic-bezier(0, 0, 1, 1) 0s'} 
          : '',
            _vm.backgroundColorWrong && _vm.isRemoveWheelBorder && (item.wrong && !item.isPaired && !(_vm.numberOfDisable == 1 &&  _vm.isWheelDisableFromConfig && _vm.wrongBorderRestriction)) ?
              {backgroundColor: _vm.backgroundColorWrong}
            : '' ]),attrs:{"isSelected":item.isSelected,"data-active":_vm.isRemoveWheelBorder,"data-value":item.element[_vm.valueSource],"index":item.index,"target-index":index,"target-wheel-type":_vm.wheelName}},[_c('div',{staticClass:"wheel-item-border",style:([
            _vm.wheelRetroBorderSize && _vm.isRemoveWheelBorder && ((item.wrong && !item.isPaired && !(_vm.numberOfDisable == 1 &&  _vm.isWheelDisableFromConfig && _vm.wrongBorderRestriction)) || (item.correct && !item.isPaired)) ?
              {borderWidth: _vm.wheelRetroBorderSize}
            : '',
            _vm.wrongBorderColor && _vm.isRemoveWheelBorder && (item.wrong && !item.isPaired && !(_vm.numberOfDisable == 1 &&  _vm.isWheelDisableFromConfig && _vm.wrongBorderRestriction)) ?
              {borderColor: _vm.wrongBorderColor}
            : '',
            _vm.correctBorderColor && _vm.isRemoveWheelBorder && (item.correct && !item.isPaired) ?
              {borderColor: _vm.correctBorderColor}
            : '' ])}),_c('span',{staticClass:"wheel-item-content",style:([
            _vm.wheelCardFontColor ? 
              {color: _vm.wheelCardFontColor}
            : '',
            _vm.wheelCardFontSize ?
              {fontSize: _vm.wheelCardFontSize}
            : '',
            _vm.wheelCardPaddingTop && !item.imageSrc ?
              {paddingTop: _vm.wheelCardPaddingTop}
            : '',
            _vm.wheelCardPaddingBottom && !item.imageSrc ?
              {paddingBottom: _vm.wheelCardPaddingBottom}
            : '',
            _vm.wheelCardPaddingWidth && !item.imageSrc ?
              {
                paddingLeft: 'calc( ' + _vm.wheelCardPaddingWidth + ' / 2)',
                paddingRight: 'calc( ' + _vm.wheelCardPaddingWidth + ' / 2)'
              }
            : '',
            _vm.wheelCardContentPosition && !item.imageSrc ?
              {
                justifyContent: _vm.wheelCardContentPosition == 'left' ? 
                  'flex-start' 
                : _vm.wheelCardContentPosition == 'right' ? 
                    'flex-end' 
                  : _vm.wheelCardContentPosition
              }
            : '' ])},[(item.imageSrc)?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],staticClass:"bravo-img",attrs:{"src":item.imageSrc,"draggable":"false"}}):(_vm.wheelDisplayType == 'text')?_c('span',{style:([
              item.isPaired ? 
                {opacity: _vm.wheelDisableElementOpacity} 
              : '' ])},[_vm._v(" "+_vm._s(item.element[_vm.wheelDisplaySource])+" ")]):_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],style:([item.isPaired ? {'opacity': _vm.wheelDisableElementOpacity} : '']),attrs:{"src":_vm.assetMediaImageUrl + 
            (_vm.wheelDisplayType == 'image' ? item.element[_vm.wheelDisplaySource] : item.element.imageFile),"draggable":"false"}})])]):_vm._e()}),0)]),_c('div',{staticClass:"wheel-btn",style:(_vm.buttonFinishStyle)},[_c('span',{staticClass:"btn-up",class:{disable: _vm.isButtonDisableWheel || _vm.isWheelDisableFromConfig},on:{"click":function($event){return _vm.onScroll(_vm.selectedClassName, 1)}}},[_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],class:{disable: _vm.isButtonDisableWheel || _vm.isWheelDisableFromConfig},attrs:{"src":_vm.buttonUp,"draggable":"false"}}),_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],class:{disable: !_vm.isButtonDisableWheel || _vm.isWheelDisableFromConfig},attrs:{"src":_vm.buttonUpDisable,"draggable":"false"}})]),_c('span',{staticClass:"btn-down",class:{disable: _vm.isButtonDisableWheel || _vm.isWheelDisableFromConfig},on:{"click":function($event){return _vm.onScroll(_vm.selectedClassName, 0)}}},[_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],class:{disable: _vm.isButtonDisableWheel || _vm.isWheelDisableFromConfig},attrs:{"src":_vm.buttonDown,"draggable":"false"}}),_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],class:{disable: !_vm.isButtonDisableWheel || _vm.isWheelDisableFromConfig},attrs:{"src":_vm.buttonDownDisable,"draggable":"false"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }