<template>
  <div
    class="cards-container"
    :class="{'two-line-title': titleStyle}"
  >
    <div
      class="displayCards cards"
      v-bind:class="{correct: isCorrect, 'display-info': isDisplayInfo}"
      v-if="!isFinal"
    >
      <div
        :id="'card'+index"
        class="card"
        v-if="card.isShow"
        v-bind:class="{selected: card.isSelected, wrong: card.isWrongPick, 'disabled-click': disableCards, used: card.isUsed}"
        v-bind:css="false"
        v-for="(card, index) in displayedCards"
        v-bind:key="card.id"
        :style="[
          isShowAudioButton ?
            {paddingLeft: '56px'}
          : '',
        ]"
      >

        <CardF
          :alternateEnable="false"
          addedClass="multiA-layoutB"
          :key="'card' + index"
          :data="card"
          :index="index"
          :isSelected="card.isSelected"
          :isCorrect="card.isCorrect"
          :isDone="isCorrect"
          :isWrong="card.isWrongPick"
          :isUsed="card.isUsed"
          :backgroundColorWrong="backgroundColorWrong"
          :correctBorderColor="correctBorderColor"
          :selectedBorderColor="selectedBorderColor"
          :wrongBorderColor="wrongBorderColor"
          :cardWidth="'100%'"
          :displaySource="displaySource"
          :displayType="displayType"
          :assetMediaImageUrl="assetMediaImageUrl"
          :displayedCards="displayedCards"
          :retroBorderSize="retroBorderSize"
          :cardFontColor="cardFontColor"
          :cardFontSize="cardFontSize"
          :cardPaddingTop="cardPaddingTop"
          :cardPaddingBottom="cardPaddingBottom"
          :cardPaddingHeight="cardPaddingHeight"
          :cardPaddingWidth="cardPaddingWidth"
          :isSelectBump="false"
          :cardContentPosition="cardContentPosition"
          :style="[
            cardWidth ?
              {width: cardWidth}
            : '',
          ]"
        >
        </CardF>

          <AudioA
            v-if="isShowAudioButton && cardAudioType === 'mp3' && cardAudioSource"
            :messageAudioFile="card.element[cardAudioSource]"
            :classAdded="'multia-layoutb-audio'"
            :isDataSound="true"
            :assetMediaAudioUrl="assetMediaAudioUrl"
          >
          </AudioA>
          <AudioB
            v-else-if="isShowAudioButton && cardAudioSource && cardAudioType"
            :classAdded="'multia-layoutb-audio'"
            :correctWord="cardAudioSource ? card.element[cardAudioSource] : ''"
            :audioType="cardAudioType"
          >
          </AudioB>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Velocity from 'velocity-animate'
import AudioA from '../components/AudioA'
import AudioB from '../components/AudioB'
import Notify from '../components/Notify'
import CardF from '../components/CardF'

export default {
  name: 'MultiALayoutB',
  components: {
    'AudioA': AudioA,
    'AudioB': AudioB,
    'Notify': Notify,
    'CardF': CardF,
  },
  props: [
    "secondFinalPage",
    "templateName",
    "templateMultiA",
    "templateMultiB",
    "templateMultiC",
    "titleStyle",
    "isFinal",
    "themeLayout",
    "cardQuestionWidth",
    "cardQuestionHeight",
    "cardQuestionBackgroundColor",
    "assetMediaImageUrl",
    "cards",
    "isDisplayInfo",
    "cardWidth",
    "cardHeight",
    "cardFontSize",
    "cardFontColor",
    "cardContentPosition",
    "displayedCards",
    "tag",
    "progressValue",
    "isCorrect",
    "disableCards",
    "isShowAudioButton",
    "finalImages",
    "displayType",
    "answerDisplaySource",
    "cardAudioType",
    "cardAudioSource",
    "displaySource",
    "assetMediaAudioUrl",
    "isDisableSubmit",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "backgroundColorWrong",
    "retroBorderSize",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "showAnimation",
    "hideAnimation"
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
  methods: {
    showCardAnimation() {
      var _this = this;
      this.displayedCards.forEach(function(paramCard, index) {
        let el = document.getElementById('card' + index);
        var i = index + 1;
        setTimeout( _ => {
          Velocity(el, {
            rotateX: "360deg",
            opacity: 1
          }, { duration: 6 });
          el.children[0].children[0].classList.add('fliped');
        }, 350 * i);
        setTimeout(function() {
          el.style.pointerEvents = '';
        }, 600 * i);
      });
    },

    hideCardAnimation() {
      this.displayedCards.forEach(function(paramCard, index) {
        let el = document.getElementById('card' + index);
        el.style.pointerEvents = 'none';
        var i = 3 - index;
        setTimeout(function() {
          Velocity(el, {
            rotateX: "",
            opacity: ""
          }, { duration: 6 });
          el.children[0].children[0].classList.remove('fliped');
        }, 350 * i);
      });
    }
  },
  watch: {
    showAnimation(data) {
      if (data) 
        this.showCardAnimation();
    },
    hideAnimation(data) {
      if (data) 
        this.hideCardAnimation();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.cards-container {
  height: 350px;

  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 350px;
    transform: scale(1);
    margin-left: -1px;

    &.correct {
      .selected {
        overflow: visible;
      }

      .card * {
        cursor: default;
        pointer-events: none;
      }
    }

    &.displayCards {
      transform: scale(1) !important;
    }

     .card {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      width: auto;
      height: 65px;
      min-width: 1px;
      min-height: 1px;
      margin-bottom: 30px;
      align-items: center;
      overflow: visible;
      transition: all .8s ease!important;
      transform-origin: 50% 50%;
      z-index: 900;
      transform-style: preserve-3d;
      transform: rotateX(540deg);
      opacity: 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.disabled-click {
        pointer-events: none;
      }
    } 
  }
}
</style>
