<template>
  <div 
    class="final"
    :class="{'board-item-wrap': finalBoardAnswers.length > 5}"
  >
    <div 
      class="ribbon"
      :style="{
        'backgroundImage': 'url(' + ((finalBoardAnswers.length > 5) ? bannerImg1 : bannerImg2) + ')',
        'color': bannerTextColor
      }"
    >
      <span>
        {{boardTitle}}
      </span>
    </div>
    <div 
      class="board"
      :style="{
        'background-color': boardBackgroundColor,
        'border-color': boardBorderColor
      }"
    >
      <ul>
        <li
          v-for="(word, index) in finalBoardAnswers"
          :key="word + index"
          :style="{
            'border-color': boardBorderColor
          }"
        >
          <AudioA
            v-if="cardAudioType === 'mp3' && cardAudioSource"
            :messageAudioFile="word[cardAudioSource]"
            classAdded="final-audio"
            :isDataSound="true"
            :assetMediaAudioUrl="assetMediaAudioUrl"
          >
          </AudioA>
          <AudioB
            v-else-if="cardAudioType && cardAudioSource"
            classAdded="final-audio"
            :correctWord="word[cardAudioSource]"
            :audioType="cardAudioType"
          >
          </AudioB>
          <div 
            v-if="finalDisplaySource"
            class="answer-word-list"
          >
            {{word[finalDisplaySource]}}
          </div>
          <div 
            v-else
            class="answer-word-list"
          >
            {{word.value}}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import AudioA from '../components/AudioA'
  import AudioB from '../components/AudioB'

  export default {
    name: 'FinalC',
    components: {
      'AudioA': AudioA,
      'AudioB': AudioB
    },
    props: [
      "themeName",
      "themeLocation",
      "finalBoardAnswers",
      "boardBorderColor",
      "boardBackgroundColor",
      "boardTitle",
      "audioImg",
      "bannerImg1",
      "bannerImg2",
      "bannerTextColor",
      "cardAudioSource",
      "cardAudioType",
      "assetMediaAudioUrl",
      "finalDisplaySource"
    ],
    data () {
      return {
        myComponent: this.$parent.myComponent,
        myHome: this.$parent.myHome
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.final {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation-name: fadeIn;
  animation-duration: 1s;
  margin-top: -14px;

  &.board-item-wrap {
    .ribbon {
      width: 885px;
    }
    .board {
      width: 715px;

      ul {
        li {
          padding-left: 20px;
          float: left;
          width: 50%;

          &:nth-of-type(2) {
            border-top: 0;
          }
          &:nth-of-type(odd) {
            border-right: 1px solid;
          }
          &:nth-of-type(even) {
            border-left: 1px solid;
          }
        }
      }
    }
  }
  .ribbon {
    background-repeat: no-repeat;
    width: 616px;
    height: 65px;
    position: absolute;
    top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
    font-size: 32px;
    background-size: 100% 100%;
    font-weight: 700;
  }
  .board {
    width: 470px;
    border-width: 6px;
    border-style: solid;
    border-radius: 18px;
    padding-top: 60px;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 2px;

    ul {
      list-style: none;

      li {
        display: flex;
        height: 54px;
        border-top: 2px solid;
        display: flex;
        align-items: center;
        padding-left: 40px;

        &:first-of-type {
          border: none;
        }
        .answer-word-list {
          font-size: 32px;
          color: #000;
          margin-left: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span.sound.finish-sound {
          margin-bottom: 2px;
          cursor: pointer;
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ipad-version {
  .final {
    .card {
      max-width: 165px;
      max-height: 273px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}


</style>