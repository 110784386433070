<template>
  <div
    class="correct-img"
    :class="addedClass"
  >
    <GreenCheck :hasBorder="hasBorder">
    </GreenCheck>
  </div>
</template>

<script>
import Vue from 'vue'
import GreenCheck from '../components/GreenCheck'

export default {
  name: 'AudioB',
  components: {
    'GreenCheck': GreenCheck,
  },
  props: [
    "addedClass",
    "hasBorder",
  ],
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.correct-img {
  width: 61px;
  height: 61px;
  background-size: 100%;

  &.ordo-a {
    align-self: flex-start;
    margin-top: -12px;
    margin-left: -6px;
    z-index: 11;
  }
  &.multi-a, &.calendar,
  &.select, &.asso,
  &.ortho, &.memo {
    position: absolute;
  }
  &.multi-a {
    right: -25px;
    top: -22px;
    z-index: 999;
  }
  &.calendar {
    z-index: 1;
    top: -10px;
    right: 25px;
    width: 62px;
    height: 62px;
  }
  &.select {
    right: -17px;
    top: -19px;
    z-index: 2;
  }
  &.asso {
    right: -15px;
    top: 48px;
    z-index: 100;
  }
  &.ortho {
    right: -33px;
    z-index: 12;
    height: 61px;
  }
  &.memo {
    right: -24px;
    top: -22px;
    z-index: 11;
  }
}
.ipad-version {
  .correct-img {
    &.calendar {
      right: -10px;
    }
  }
}
</style>
