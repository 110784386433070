<template>
  <div class="association">
      
    <HeaderC
      :audioHtml="audioHtml"
      :stepsLength="stepsLength"
      :progressValue="progressValue"
      :isReRotateStars="isReRotateStars"
      :message="message"
      :finalMessage="finalMessage"
      :messageAudioFile="isPlayAgainModeWrong ? finalMessageAudioFileAlternate : messageAudioFile"
      :messageAudioType="isPlayAgainModeWrong ? 'mp3' : messageAudioType"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isPlayAgainMode="isPlayAgainModeWrong"
      :playAgainMessage="finalMessageAlternate"
      :isFinal="isFinal"
      :finalMessageAudioFile="finalMessageAudioFile"
      :fadeIn="fadeInTitle"
    >
    </HeaderC>
    
    <AssoA
      v-if                        ="templateName === templateAssoA"
      ref                         ="assoA"      
      :isFinish                   ="isFinish"
      :isTwoWheels                ="isTwoWheels"
      :templateName               ="templateName"
      :templateAssoA              ="templateAssoA"
      :templateAssoB              ="templateAssoB"
      :templateAssoC              ="templateAssoC"
      :wheelOneItem               ="firstWheel"
      :wheelOneIsCrit             ="firstWheelIsCrit"
      :wheelOneCSSData            ="firstWheelCSSData"
      :buttonFinishStyle          ="buttonFinishStyle"
      :wheelOneDisplayed          ="displayedFirstWheel"
      :wheelThemeColorBorder      ="wheelThemeColorBorder"
      :wheelOneIsButtonDisable    ="isButtonDisableFirstWheel || isWheelButtonDisable"
      :wheelOneDisplayType        ="wheelOneDisplayType"
      :wheelOneDisplaySource      ="wheelOneDisplaySource"
      :wheelOneThemeImage         ="wheelOneImage"
      :wheelOneIsDisable          ="wheelOneDisable"
      :tagImage                   ="myHome.getThemeImage('tag.svg')"
      :buttonUp                   ="myHome.getThemeImage('fleches1a.svg')"
      :buttonUpDisable            ="myHome.getThemeImage('fleches2a.svg')"
      :buttonDown                 ="myHome.getThemeImage('fleches1b.svg')"
      :buttonDownDisable          ="myHome.getThemeImage('fleches2b.svg')"
      :background                 ="myHome.getThemeImage('roulette-fond2.svg')"
      :overlay                    ="myHome.getThemeImage('roulette-ombrages2.png')"
      :removeAllBorders           ="removeAllBorders"
      :wheelOnePreviousText       ="previousTempFirstWheelValue"
      :wheelOneCardFontColor      ="wheelOneCardFontColor"
      :wheelOneCardFontSize       ="wheelOneCardFontSize"
      :wheelOneCardPaddingTop     ="wheelOneCardPaddingTop"
      :wheelOneCardPaddingBottom  ="wheelOneCardPaddingBottom"
      :wheelOneCardPaddingWidth   ="wheelOneCardPaddingWidth"
      :wheelOneCardWidth          ="wheelOneCardWidth"
      :wheelOneCardHeight         ="wheelOneCardHeight"
      :wheelOneRetroBorderSize    ="wheelOneRetroBorderSize"
      :wheelOneCardContentPosition ="wheelOneCardContentPosition"
      :themeLocation              ="themeLocation"
      :themeName                  ="themeName"
      :isFinal                    ="isFinish"
      :wheelTwoItem               ="secondWheel"
      :wheelTwoIsCrit             ="secondWheelIsCrit"
      :wheelTwoCSSData            ="secondWheelCSSData"
      :wheelTwoDisplayed          ="displayedSecondWheel"
      :wheelTwoIsButtonDisable    ="isButtonDisableSecondWheel || isWheelButtonDisable"
      :wheelTwoDisplayType        ="wheelTwoDisplayType"
      :wheelTwoDisplaySource      ="wheelTwoDisplaySource"
      :wheelTwoThemeImage         ="wheelTwoImage"
      :wheelTwoIsDisable          ="wheelTwoDisable"
      :wheelTwoPreviousText       ="previousTempSecondWheelValue"
      :wheelTwoCardFontColor      ="wheelTwoCardFontColor"
      :wheelTwoCardFontSize       ="wheelTwoCardFontSize"
      :wheelTwoCardPaddingTop     ="wheelTwoCardPaddingTop"
      :wheelTwoCardPaddingBottom  ="wheelTwoCardPaddingBottom"
      :wheelTwoCardPaddingWidth   ="wheelTwoCardPaddingWidth"
      :wheelTwoCardWidth          ="wheelTwoCardWidth"
      :wheelTwoCardHeight         ="wheelTwoCardHeight"
      :wheelTwoRetroBorderSize    ="wheelTwoRetroBorderSize"
      :wheelTwoCardContentPosition ="wheelTwoCardContentPosition"
      :wheelThreeItem             ="thirdWheel"
      :wheelThreeIsCrit           ="thirdWheelIsCrit"
      :wheelThreeCSSData          ="thirdWheelCSSData"
      :wheelThreeDisplayed        ="displayedThirdWheel"
      :wheelThreeIsButtonDisable  ="isButtonDisableThirdWheel || isWheelButtonDisable"
      :wheelThreeDisplayType      ="wheelThreeDisplayType"
      :wheelThreeDisplaySource    ="wheelThreeDisplaySource"
      :wheelThreeThemeImage       ="wheelThreeImage"
      :wheelThreeIsDisable        ="wheelThreeDisable"
      :wheelThreePreviousText     ="previousTempThirdWheelValue"
      :wheelThreeCardFontColor    ="wheelThreeCardFontColor"
      :wheelThreeCardFontSize     ="wheelThreeCardFontSize"
      :wheelThreeCardPaddingTop   ="wheelThreeCardPaddingTop"
      :wheelThreeCardPaddingBottom ="wheelThreeCardPaddingBottom"
      :wheelThreeCardPaddingWidth  ="wheelThreeCardPaddingWidth"
      :wheelThreeCardWidth         ="wheelThreeCardWidth"
      :wheelThreeCardHeight        ="wheelThreeCardHeight"
      :wheelThreeRetroBorderSize   ="wheelThreeRetroBorderSize"
      :wheelThreeCardContentPosition ="wheelThreeCardContentPosition"
      :assetMediaImageUrl           ="assetMediaImageUrl"
      :wrongBorderColor             ="wrongBorderColor"
      :selectedBorderColor          ="selectedBorderColor"
      :correctBorderColor           ="correctBorderColor"
      :backgroundColorWrong         ="backgroundColorWrong"
      :wheelBorderColor             ="wheelBorderColor"
      :wheelDisableBackgroundColor  ="wheelDisableBackgroundColor"
      :wheelDisableElementOpacity   ="wheelDisableElementOpacity"
      :valueSource                  ="valueSource"
      :numberOfDisable              ="numberOfDisable"
      :wheelFadeData              ="wheelFadeData"
      :style="[
        !isDocumentReady ? 
          {opacity: 0}
        : '',
      ]"
    >
    </AssoA>

    <AssoB
      v-if                        ="templateName === templateAssoB"
      ref                         ="assoA"      
      :isFinish                   ="isFinish"
      :isTwoWheels                ="isTwoWheels"
      :templateName               ="templateName"
      :templateAssoA              ="templateAssoA"
      :templateAssoB              ="templateAssoB"
      :templateAssoC              ="templateAssoC"
      :wheelOneItem               ="firstWheel"
      :wheelOneIsCrit             ="firstWheelIsCrit"
      :wheelOneCSSData            ="firstWheelCSSData"
      :buttonFinishStyle          ="buttonFinishStyle"
      :wheelOneDisplayed          ="displayedFirstWheel"
      :wheelThemeColorBorder      ="wheelThemeColorBorder"
      :wheelOneIsButtonDisable    ="isButtonDisableFirstWheel || isWheelButtonDisable"
      :wheelOneDisplayType        ="wheelOneDisplayType"
      :wheelOneDisplaySource      ="wheelOneDisplaySource"
      :wheelOneThemeImage         ="wheelOneImage"
      :wheelOneIsDisable          ="wheelOneDisable"
      :tagImage                   ="myHome.getThemeImage('tag.svg')"
      :buttonUp                   ="myHome.getThemeImage('fleches1a.svg')"
      :buttonUpDisable            ="myHome.getThemeImage('fleches2a.svg')"
      :buttonDown                 ="myHome.getThemeImage('fleches1b.svg')"
      :buttonDownDisable          ="myHome.getThemeImage('fleches2b.svg')"
      :background                 ="myHome.getThemeImage('roulette-fond2.svg')"
      :overlay                    ="myHome.getThemeImage('roulette-ombrages2.png')"
      :removeAllBorders           ="removeAllBorders"
      :wheelOnePreviousText       ="previousTempFirstWheelValue"
      :wheelOneCardFontColor      ="wheelOneCardFontColor"
      :wheelOneCardFontSize       ="wheelOneCardFontSize"
      :wheelOneCardPaddingTop     ="wheelOneCardPaddingTop"
      :wheelOneCardPaddingBottom  ="wheelOneCardPaddingBottom"
      :wheelOneCardPaddingWidth   ="wheelOneCardPaddingWidth"
      :wheelOneCardWidth          ="wheelOneCardWidth"
      :wheelOneCardHeight         ="wheelOneCardHeight"
      :wheelOneRetroBorderSize    ="wheelOneRetroBorderSize"
      :wheelOneCardContentPosition ="wheelOneCardContentPosition"
      :themeLocation              ="themeLocation"
      :themeName                  ="themeName"
      :isFinal                    ="isFinish"
      :wheelTwoItem               ="secondWheel"
      :wheelTwoIsCrit             ="true"
      :wheelTwoCSSData            ="secondWheelCSSData"
      :wheelTwoDisplayed          ="displayedSecondWheel"
      :wheelTwoIsButtonDisable    ="isButtonDisableSecondWheel || isWheelButtonDisable"
      :wheelTwoDisplayType        ="wheelTwoDisplayType"
      :wheelTwoDisplaySource      ="wheelTwoDisplaySource"
      :wheelTwoThemeImage         ="wheelTwoImage"
      :wheelTwoIsDisable          ="wheelTwoDisable"
      :wheelTwoPreviousText       ="previousTempSecondWheelValue"
      :wheelTwoCardFontColor      ="wheelTwoCardFontColor"
      :wheelTwoCardFontSize       ="wheelTwoCardFontSize"
      :wheelTwoCardPaddingTop     ="wheelTwoCardPaddingTop"
      :wheelTwoCardPaddingBottom  ="wheelTwoCardPaddingBottom"
      :wheelTwoCardPaddingWidth   ="wheelTwoCardPaddingWidth"
      :wheelTwoCardWidth          ="wheelTwoCardWidth"
      :wheelTwoCardHeight         ="wheelTwoCardHeight"
      :wheelTwoRetroBorderSize    ="wheelTwoRetroBorderSize"
      :wheelTwoCardContentPosition ="wheelTwoCardContentPosition"
      :wheelThreeItem             ="thirdWheel"
      :wheelThreeIsCrit           ="thirdWheelIsCrit"
      :wheelThreeCSSData          ="thirdWheelCSSData"
      :wheelThreeDisplayed        ="displayedThirdWheel"
      :wheelThreeIsButtonDisable  ="isButtonDisableThirdWheel || isWheelButtonDisable"
      :wheelThreeDisplayType      ="wheelThreeDisplayType"
      :wheelThreeDisplaySource    ="wheelThreeDisplaySource"
      :wheelThreeThemeImage       ="wheelThreeImage"
      :wheelThreeIsDisable        ="wheelThreeDisable"
      :wheelThreePreviousText     ="previousTempThirdWheelValue"
      :wheelThreeCardFontColor    ="wheelThreeCardFontColor"
      :wheelThreeCardFontSize     ="wheelThreeCardFontSize"
      :wheelThreeCardPaddingTop   ="wheelThreeCardPaddingTop"
      :wheelThreeCardPaddingBottom ="wheelThreeCardPaddingBottom"
      :wheelThreeCardPaddingWidth  ="wheelThreeCardPaddingWidth"
      :wheelThreeCardWidth         ="wheelThreeCardWidth"
      :wheelThreeCardHeight        ="wheelThreeCardHeight"
      :wheelThreeRetroBorderSize   ="wheelThreeRetroBorderSize"
      :wheelThreeCardContentPosition ="wheelThreeCardContentPosition"
      :assetMediaImageUrl           ="assetMediaImageUrl"
      :wrongBorderColor             ="wrongBorderColor"
      :selectedBorderColor          ="selectedBorderColor"
      :correctBorderColor           ="correctBorderColor"
      :backgroundColorWrong         ="backgroundColorWrong"
      :wheelBorderColor             ="wheelBorderColor"
      :wheelDisableBackgroundColor  ="wheelDisableBackgroundColor"
      :wheelDisableElementOpacity   ="wheelDisableElementOpacity"
      :valueSource                  ="valueSource"
      :numberOfDisable              ="numberOfDisable"
      :wheelFadeData                ="wheelFadeData"
      :style="[
        !isDocumentReady ? 
          {opacity: 0}
        : '',
      ]"
    >
    </AssoB> 

    <AssoC
      v-if                        ="templateName === templateAssoC"
      ref                         ="assoA"      
      :isFinish                   ="isFinish"
      :isTwoWheels                ="isTwoWheels"
      :wheelOneItem               ="firstWheel"
      :wheelOneIsCrit             ="firstWheelIsCrit"
      :wheelOneCSSData            ="firstWheelCSSData"
      :buttonFinishStyle          ="buttonFinishStyle"
      :wheelOneDisplayed          ="displayedFirstWheel"
      :wheelThemeColorBorder      ="wheelThemeColorBorder"
      :wheelOneIsButtonDisable    ="isButtonDisableFirstWheel || isWheelButtonDisable"
      :wheelOneDisplayType        ="wheelOneDisplayType"
      :wheelOneDisplaySource      ="wheelOneDisplaySource"
      :wheelOneThemeImage         ="wheelOneImage"
      :wheelOneIsDisable          ="wheelOneDisable"
      :tagImage                   ="myHome.getThemeImage('tag.svg')"
      :buttonUp                   ="myHome.getThemeImage('fleches1a.svg')"
      :buttonUpDisable            ="myHome.getThemeImage('fleches2a.svg')"
      :buttonDown                 ="myHome.getThemeImage('fleches1b.svg')"
      :buttonDownDisable          ="myHome.getThemeImage('fleches2b.svg')"
      :background                 ="myHome.getThemeImage('roulette-fond2.svg')"
      :overlay                    ="myHome.getThemeImage('roulette-ombrages2.png')"
      :removeAllBorders           ="removeAllBorders"
      :wheelOnePreviousText       ="previousTempFirstWheelValue"
      :wheelOneCardFontColor      ="wheelOneCardFontColor"
      :wheelOneCardFontSize       ="wheelOneCardFontSize"
      :wheelOneCardPaddingTop     ="wheelOneCardPaddingTop"
      :wheelOneCardPaddingBottom  ="wheelOneCardPaddingBottom"
      :wheelOneCardPaddingWidth   ="wheelOneCardPaddingWidth"
      :wheelOneCardWidth          ="wheelOneCardWidth"
      :wheelOneCardHeight         ="wheelOneCardHeight"
      :wheelOneRetroBorderSize    ="wheelOneRetroBorderSize"
      :wheelOneCardContentPosition ="wheelOneCardContentPosition"
      :themeLocation              ="themeLocation"
      :themeName                  ="themeName"
      :isFinal                    ="isFinish"
      :wheelTwoItem               ="secondWheel"
      :wheelTwoIsCrit             ="secondWheelIsCrit"
      :wheelTwoCSSData            ="secondWheelCSSData"
      :wheelTwoDisplayed          ="displayedSecondWheel"
      :wheelTwoIsButtonDisable    ="isButtonDisableSecondWheel || isWheelButtonDisable"
      :wheelTwoDisplayType        ="wheelTwoDisplayType"
      :wheelTwoDisplaySource      ="wheelTwoDisplaySource"
      :wheelTwoThemeImage         ="wheelTwoImage"
      :wheelTwoIsDisable          ="wheelTwoDisable"
      :wheelTwoPreviousText       ="previousTempSecondWheelValue"
      :wheelTwoCardFontColor      ="wheelTwoCardFontColor"
      :wheelTwoCardFontSize       ="wheelTwoCardFontSize"
      :wheelTwoCardPaddingTop     ="wheelTwoCardPaddingTop"
      :wheelTwoCardPaddingBottom  ="wheelTwoCardPaddingBottom"
      :wheelTwoCardPaddingWidth   ="wheelTwoCardPaddingWidth"
      :wheelTwoCardWidth          ="wheelTwoCardWidth"
      :wheelTwoCardHeight         ="wheelTwoCardHeight"
      :wheelTwoRetroBorderSize    ="wheelTwoRetroBorderSize"
      :wheelTwoCardContentPosition ="wheelTwoCardContentPosition"
      :wheelThreeItem             ="thirdWheel"
      :wheelThreeIsCrit           ="thirdWheelIsCrit"
      :wheelThreeCSSData          ="thirdWheelCSSData"
      :wheelThreeDisplayed        ="displayedThirdWheel"
      :wheelThreeIsButtonDisable  ="isButtonDisableThirdWheel || isWheelButtonDisable"
      :wheelThreeDisplayType      ="wheelThreeDisplayType"
      :wheelThreeDisplaySource    ="wheelThreeDisplaySource"
      :wheelThreeThemeImage       ="wheelThreeImage"
      :wheelThreeIsDisable        ="wheelThreeDisable"
      :wheelThreePreviousText     ="previousTempThirdWheelValue"
      :wheelThreeCardFontColor    ="wheelThreeCardFontColor"
      :wheelThreeCardFontSize     ="wheelThreeCardFontSize"
      :wheelThreeCardPaddingTop   ="wheelThreeCardPaddingTop"
      :wheelThreeCardPaddingBottom ="wheelThreeCardPaddingBottom"
      :wheelThreeCardPaddingWidth  ="wheelThreeCardPaddingWidth"
      :wheelThreeCardWidth         ="wheelThreeCardWidth"
      :wheelThreeCardHeight        ="wheelThreeCardHeight"
      :wheelThreeRetroBorderSize   ="wheelThreeRetroBorderSize"
      :wheelThreeCardContentPosition ="wheelThreeCardContentPosition"
      :assetMediaImageUrl           ="assetMediaImageUrl"
      :wrongBorderColor             ="wrongBorderColor"
      :selectedBorderColor          ="selectedBorderColor"
      :correctBorderColor           ="correctBorderColor"
      :backgroundColorWrong         ="backgroundColorWrong"
      :wheelBorderColor             ="wheelBorderColor"
      :wheelDisableBackgroundColor  ="wheelDisableBackgroundColor"
      :wheelDisableElementOpacity   ="wheelDisableElementOpacity"
      :valueSource                  ="valueSource"
      :numberOfDisable              ="numberOfDisable"
      :wheelFadeData                ="wheelFadeData"
      :style="[
        !isDocumentReady ? 
          {opacity: 0}
        : '',
      ]"
    >
    </AssoC>

    <FooterC
      :flipClass="flipClass"
      :isDisabled="isDisableButton"
      :isDisableClick="preventClickButton"
      :nextText="actionNextText"
      :validateText="actionValidateText"
      :finalActionNextText="finalActionNextText"
      :actionTextColor="myHome.theme.actionTextColor"
      :activeButtonImg="myHome.getThemeImage('bouton-actif.svg')"
      :inactiveButtonImg="myHome.getThemeImage('bouton-inactif.svg')"
      :isPlayAgainMode="isPlayAgainModeWrong"
      :playAgainText="finalActionNextTextAlternate"
      :isFinal="isFinal"
    >
    </FooterC>
    </div>
</template>

<script>
import AssoModelA from '../models/Asso-a'
import AssoModelB from '../models/Asso-b'
import AssoModelC from '../models/Asso-c'
import AssoA from '../components/AssoA'
import AssoB from '../components/AssoB'
import AssoC from '../components/AssoC'
import HeaderC from '../components/HeaderC'
import FooterC from '../components/FooterC'
import AudioB from '../components/AudioB'
import Util from '../helpers/Util'

export default {
  props: ['config'],
  components: {
    'AssoA': AssoA,
    'AssoB': AssoB,
    'AssoC': AssoC,
    'AudioB': AudioB,
    'HeaderC': HeaderC,
    'FooterC': FooterC,
  },
  data () {
    return {
      configData: this.config.data.config,
      myComponent: this,
      myHome: this.$parent.myHome,
      debug: 0,
      audio: '',
      reset: false,
      audioHtml: '',
      stepsLength: 0,
      thirdWheel: [],
      firstWheel: [],
      secondWheel: [],
      titleStepNum: 0,
      flipClass: false,
      weeks: this.week,
      progressValue: 0,
      templateAssoA: 'asso-a',
      templateAssoB: 'asso-b',
      templateAssoC: 'asso-c',
      templateName: this.config.data.config.templateName,
      rotateProgressValue: 0,
      displayedFirstWheel: [],
      displayedSecondWheel: [],
      displayedThirdWheel: [],
      firstPairedIndexes: {},
      secondPairedIndexes: {},
      thirdPairedIndexes: {},
      firstWheelIndex: 1,
      secondWheelIndex: 1,
      thirdWheelIndex: 1,
      isDisableNext: false,
      isDisableButton: true,
      isDisablePlayAgain: false,
      isButtonDisableFirstWheel: true,
      isButtonDisableSecondWheel: true,
      isButtonDisableThirdWheel: true,
      isWheelButtonDisable: false,
      isFinal: false,
      isFinish: false,
      buttonFinishStyle: [],
      firstWheelCSSData: [],
      secondWheelCSSData: [],
      thirdWheelCSSData: [],
      firstWheelIsCrit: false,
      secondWheelIsCrit: false,
      thirdWheelIsCrit: false,
      previousTempFirstWheelValue: undefined,
      previousTempSecondWheelValue: undefined,
      previousTempThirdWheelValue: undefined,
      preventClickNext: false,
      wheelFinishElements: [],
      isPlayAgainMode: false,
      isPlayAgainModeIndex: 0,
      isPlayAgainModeWrong: false,
      fadeInTitle: false,
      preventClickButton: false,
      wheelThemeColorBorder: '2px solid ' + this.config.data.config.theme.wheelBorderColor,
      isTwoWheels: false,
      removeAllBorders: false,
      themeLocation: false,
      themeName: false,
      disableWheel: false,
      isReRotateStars: false,
      stepsMessageAudioFile: false,
      finalMessage : false,
      messageAudioFile : false,
      messageAudioType : false,
      assetMediaAudioUrl : false,
      isFinal: false,
      finalMessageAudioFile: false,
      flipClass: false,
      isDisableNext: false,
      isDisableClick: false,
      actionNextText: false,
      actionValidateText: false,
      finalActionNextText: false,
      finalActionNextTextAlternate: false,
      finalMessageAlternate: false,
      finalMessageAudioFileAlternate: false,
      wrongBorderColor: false,
      selectedBorderColor: false,
      correctBorderColor: false,
      backgroundColorWrong: false,
      message: '',
      finalType: false,
      wheelOneCardContentPosition: false,
      wheelOneCardFontColor: false,
      wheelOneCardFontSize: false,
      wheelOneCardPaddingBottom: false,
      wheelOneCardPaddingTop: false,
      wheelOneCardPaddingWidth: false,
      wheelOneCardWidth: false,
      wheelOneCardHeight: false,
      wheelOneDisable: false,
      wheelOneDisplaySource: false,
      wheelOneDisplayType: false,
      wheelOneNumberIntruder: false,
      wheelOneRetroBorderSize: false,
      wheelTwoCardContentPosition: false,
      wheelTwoCardFontColor: false,
      wheelTwoCardFontSize: false,
      wheelTwoCardPaddingBottom: false,
      wheelTwoCardPaddingTop: false,
      wheelTwoCardPaddingWidth: false,
      wheelTwoCardWidth: false,
      wheelTwoCardHeight: false,
      wheelTwoDisable: false,
      wheelTwoDisplaySource: false,
      wheelTwoDisplayType: false,
      wheelTwoNumberIntruder: false,
      wheelTwoRetroBorderSize: false,
      wheelThreeCardContentPosition: false,
      wheelThreeCardFontColor: false,
      wheelThreeCardFontSize: false,
      wheelThreeCardPaddingBottom: false,
      wheelThreeCardPaddingTop: false,
      wheelThreeCardPaddingWidth: false,
      wheelThreeCardWidth: false,
      wheelThreeCardHeight: false,
      wheelThreeDisable: false,
      wheelThreeDisplaySource: false,
      wheelThreeDisplayType: false,
      wheelThreeNumberIntruder: false,
      wheelThreeRetroBorderSize: false,
      assetMediaImageUrl: false,
      assetMediaAudioUrl: false,
      wrongBorderColor: false,
      selectedBorderColor: false,
      correctBorderColor: false,
      backgroundColorWrong: false,
      wheelBorderColor: false,
      wheelDisableBackgroundColor: false,
      wheelDisableElementOpacity: false,
      wheelOneImage: false,
      wheelThreeImage: false,
      wheelTwoImage: false,
      valueSource: false,
      isDocumentReady: false,
      wheelLayout: [],
      numberOfDisable: 0,
      wheelFadeData: false,
    }
  },

  methods: {
    initializeSlots: async function() {
      var _this = this;
      let tempDisplayValueType = [], 
          wheelCriteriasSource;
      this.firstPairedIndexes  = {};
      this.secondPairedIndexes = {};
      this.thirdPairedIndexes  = {};
      this.numberOfDisable     = 0;

      if (this.configData.hasOwnProperty('theme')) {
        let theme = this.configData.theme;
        this.themeLocation = theme.hasOwnProperty('themeLocation') ? theme.themeLocation : false;
        this.themeName = theme.hasOwnProperty('themeName') ? theme.themeName : false;
        this.wrongBorderColor = theme.hasOwnProperty("wrongBorderColor") ? theme.wrongBorderColor : false;
        this.selectedBorderColor = theme.hasOwnProperty("selectedBorderColor") ? theme.selectedBorderColor : false;
        this.correctBorderColor = theme.hasOwnProperty("correctBorderColor") ? theme.correctBorderColor : false;
        this.backgroundColorWrong = theme.hasOwnProperty("backgroundColorWrong") ? theme.backgroundColorWrong : false;        
        this.wheelBorderColor = theme.hasOwnProperty("wheelBorderColor") ? theme.wheelBorderColor : false;        
        this.wheelDisableBackgroundColor = theme.hasOwnProperty("wheelDisableBackgroundColor") ? theme.wheelDisableBackgroundColor : false;        
        this.wheelDisableElementOpacity = theme.hasOwnProperty("wheelDisableElementOpacity") ? theme.wheelDisableElementOpacity : false;        
      }

      if (this.configData.hasOwnProperty('steps')) {
        let steps = this.configData.steps;
        this.actionNextText = steps.hasOwnProperty('actionNextText') ? steps.actionNextText : '';
        this.actionValidateText = steps.hasOwnProperty('actionValidateText') ? steps.actionValidateText : '';
        this.message = steps.hasOwnProperty('message') ? steps.message : '';
        this.messageAudioType = steps.hasOwnProperty('messageAudioType') ? steps.messageAudioType : 'mp3';
        this.messageAudioFile = steps.hasOwnProperty('messageAudioFile') ? steps.messageAudioFile : false;
        this.stepsLength = steps.hasOwnProperty('numberSteps') ? parseInt(steps.numberSteps) : 0;
      }

      if (this.configData.hasOwnProperty('final')) {
        let final = this.configData.final;
        this.finalActionNextText = final.hasOwnProperty('actionNextText') ? final.actionNextText : false;
        this.finalMessage = final.hasOwnProperty('message') ? final.message : false;
        this.finalMessageAudioFile = final.hasOwnProperty('messageAudioFile') ? final.messageAudioFile : false;
        this.finalActionNextTextAlternate = final.hasOwnProperty('actionNextTextAlternate') ? final.actionNextTextAlternate : false;
        this.finalMessageAlternate = final.hasOwnProperty('messageAlternate') ? final.messageAlternate : false;
        this.finalMessageAudioFileAlternate = final.hasOwnProperty('messageAudioFileAlternate') ? final.messageAudioFileAlternate : false;
        this.finalType = final.hasOwnProperty('type') ? final.type : 'finalE';     
        this.wheelOneImage = final.hasOwnProperty("wheelOneImage") ? final.wheelOneImage : false;        
        this.wheelThreeImage = final.hasOwnProperty("wheelThreeImage") ? final.wheelThreeImage : false;        
        this.wheelTwoImage = final.hasOwnProperty("wheelTwoImage") ? final.wheelTwoImage : false;   
      }

      if (this.configData.hasOwnProperty('wheelOne')) {
        let wheelOne = this.configData.wheelOne;
        this.wheelOneCardContentPosition = wheelOne.hasOwnProperty('cardContentPosition') ? wheelOne.cardContentPosition : false;
        this.wheelOneCardFontColor = wheelOne.hasOwnProperty('cardFontColor') ? wheelOne.cardFontColor : false;
        this.wheelOneCardFontSize = wheelOne.hasOwnProperty('cardFontSize') ? wheelOne.cardFontSize : false;
        this.wheelOneCardPaddingBottom = wheelOne.hasOwnProperty('cardPaddingBottom') ? wheelOne.cardPaddingBottom : false;
        this.wheelOneCardPaddingTop = wheelOne.hasOwnProperty('cardPaddingTop') ? wheelOne.cardPaddingTop : false;
        this.wheelOneCardPaddingWidth = wheelOne.hasOwnProperty('cardPaddingWidth') ? wheelOne.cardPaddingWidth : false;
        this.wheelOneCardWidth = wheelOne.hasOwnProperty('cardWidth') ? wheelOne.cardWidth : false;
        this.wheelOneCardHeight = wheelOne.hasOwnProperty('cardHeight') ? wheelOne.cardHeight : false;
        this.wheelOneDisable = wheelOne.hasOwnProperty('disable') ? parseInt(wheelOne.disable) : false;
        this.wheelOneDisplaySource = wheelOne.hasOwnProperty('displaySource') ? wheelOne.displaySource : false;
        this.wheelOneDisplayType = wheelOne.hasOwnProperty('displayType') ? wheelOne.displayType : false;
        this.wheelOneNumberIntruder = wheelOne.hasOwnProperty('numberIntruder') ? wheelOne.numberIntruder : false;
        this.wheelOneRetroBorderSize = wheelOne.hasOwnProperty('retroBorderSize') ? wheelOne.retroBorderSize : false;
      }
      if (this.configData.hasOwnProperty('wheelTwo')) {
        let wheelTwo = this.configData.wheelTwo;
        this.wheelTwoCardContentPosition = wheelTwo.hasOwnProperty('cardContentPosition') ? wheelTwo.cardContentPosition : false;
        this.wheelTwoCardFontColor = wheelTwo.hasOwnProperty('cardFontColor') ? wheelTwo.cardFontColor : false;
        this.wheelTwoCardFontSize = wheelTwo.hasOwnProperty('cardFontSize') ? wheelTwo.cardFontSize : false;
        this.wheelTwoCardPaddingBottom = wheelTwo.hasOwnProperty('cardPaddingBottom') ? wheelTwo.cardPaddingBottom : false;
        this.wheelTwoCardPaddingTop = wheelTwo.hasOwnProperty('cardPaddingTop') ? wheelTwo.cardPaddingTop : false;
        this.wheelTwoCardPaddingWidth = wheelTwo.hasOwnProperty('cardPaddingWidth') ? wheelTwo.cardPaddingWidth : false;
        this.wheelTwoCardWidth = wheelTwo.hasOwnProperty('cardWidth') ? wheelTwo.cardWidth : false;
        this.wheelTwoCardHeight = wheelTwo.hasOwnProperty('cardHeight') ? wheelTwo.cardHeight : false;
        this.wheelTwoDisable = wheelTwo.hasOwnProperty('disable') ? parseInt(wheelTwo.disable) : false;
        this.wheelTwoDisplaySource = wheelTwo.hasOwnProperty('displaySource') ? wheelTwo.displaySource : false;
        this.wheelTwoDisplayType = wheelTwo.hasOwnProperty('displayType') ? wheelTwo.displayType : false;
        this.wheelTwoNumberIntruder = wheelTwo.hasOwnProperty('numberIntruder') ? wheelTwo.numberIntruder : false;
        this.wheelTwoRetroBorderSize = wheelTwo.hasOwnProperty('retroBorderSize') ? wheelTwo.retroBorderSize : false;
      }
      if (this.configData.hasOwnProperty('wheelThree')) {
        let wheelThree = this.configData.wheelThree;
        this.wheelThreeCardContentPosition = wheelThree.hasOwnProperty('cardContentPosition') ? wheelThree.cardContentPosition : false;
        this.wheelThreeCardFontColor = wheelThree.hasOwnProperty('cardFontColor') ? wheelThree.cardFontColor : false;
        this.wheelThreeCardFontSize = wheelThree.hasOwnProperty('cardFontSize') ? wheelThree.cardFontSize : false;
        this.wheelThreeCardPaddingBottom = wheelThree.hasOwnProperty('cardPaddingBottom') ? wheelThree.cardPaddingBottom : false;
        this.wheelThreeCardPaddingTop = wheelThree.hasOwnProperty('cardPaddingTop') ? wheelThree.cardPaddingTop : false;
        this.wheelThreeCardPaddingWidth = wheelThree.hasOwnProperty('cardPaddingWidth') ? wheelThree.cardPaddingWidth : false;
        this.wheelThreeCardWidth = wheelThree.hasOwnProperty('cardWidth') ? wheelThree.cardWidth : false;
        this.wheelThreeCardHeight = wheelThree.hasOwnProperty('cardHeight') ? wheelThree.cardHeight : false;
        this.wheelThreeDisable = wheelThree.hasOwnProperty('disable') ? parseInt(wheelThree.disable) : false;
        this.wheelThreeDisplaySource = wheelThree.hasOwnProperty('displaySource') ? wheelThree.displaySource : false;
        this.wheelThreeDisplayType = wheelThree.hasOwnProperty('displayType') ? wheelThree.displayType : false;
        this.wheelThreeNumberIntruder = wheelThree.hasOwnProperty('numberIntruder') ? wheelThree.numberIntruder : false;
        this.wheelThreeRetroBorderSize = wheelThree.hasOwnProperty('retroBorderSize') ? wheelThree.retroBorderSize : false;
      }
      
      this.valueSource = this.config.data.config.hasOwnProperty('valueSource') ? this.config.data.config.valueSource : false;
      this.assetMediaImageUrl = this.configData.hasOwnProperty('assetMediaImageUrl') ? this.configData.assetMediaImageUrl : false;
      this.assetMediaAudioUrl = this.configData.hasOwnProperty('assetMediaAudioUrl') ? this.configData.assetMediaAudioUrl : false;
      this.templateName = this.configData.hasOwnProperty('templateName') ? this.configData.templateName : false;
      wheelCriteriasSource = this.configData.hasOwnProperty('wheelCriteriasSource') ? this.configData.wheelCriteriasSource : false;
      this.isTwoWheels = this.configData.hasOwnProperty('numberWheels') ? 
        parseInt(this.config.data.config.numberWheels) == 2 ? 
          true 
        : false 
      : false; 
      
      switch (wheelCriteriasSource) {
        case 'wheelOne':
          this.firstWheelIsCrit = true;
          break;
      
        case 'wheelTwo':
          this.secondWheelIsCrit = true;
          break;
      
        case 'wheelThree':
          this.thirdWheelIsCrit = true;
          break;
      }

      document.getElementsByTagName("body")[0].classList.remove("finish");
      this.myHome.changeBackground(false);
      Util.clearElements(this.displayedFirstWheel);
      Util.clearElements(this.displayedSecondWheel);
      Util.clearElements(this.displayedThirdWheel);

      this.checkPlayAgainMode();
      this.setWheelsElements();

      this.setWheelsIndex(this.firstWheel, this.firstWheelIsCrit);
      this.setWheelsIndex(this.secondWheel, this.secondWheelIsCrit);
      if (!this.isTwoWheels)
        this.setWheelsIndex(this.thirdWheel, this.thirdWheelIsCrit);

      this.displayedFirstWheel  = this.setDisplayedWheel(this.templateName, this.firstWheel, this.displayedFirstWheel, this.firstWheelCSSData, this.firstWheelIsCrit);
      this.displayedSecondWheel = this.setDisplayedWheel(this.templateName, this.secondWheel, this.displayedSecondWheel, this.secondWheelCSSData, (this.templateName === this.templateAssoB) ? true : this.secondWheelIsCrit);
      if (!this.isTwoWheels)
        this.displayedThirdWheel  = this.setDisplayedWheel(this.templateName, this.thirdWheel, this.displayedThirdWheel, this.thirdWheelCSSData, this.thirdWheelIsCrit);

      if (this.wheelOneDisable || this.wheelTwoDisable || this.wheelThreeDisable) {
        if (this.wheelOneDisable) 
          this.disableWheel = 1;
        else if (this.wheelTwoDisable)
          this.disableWheel = 2;
        else if (this.wheelThreeDisable)
          this.disableWheel = 3;
        
        if (this.wheelOneDisable) 
          this.numberOfDisable++;
        if (this.wheelTwoDisable)
          this.numberOfDisable++;
        if (this.wheelThreeDisable)
          this.numberOfDisable++;
      }

      this.isDocumentReady = true;

      setTimeout(_ => {
        this.wheelFadeData = false;
        setTimeout(_ => {
          this.wheelLayout.$refs.parentFirstWheel.isRemoveWheelBorder  = '';
          this.wheelLayout.$refs.parentSecondWheel.isRemoveWheelBorder = '';
          if (!_this.isTwoWheels)
            this.wheelLayout.$refs.parentThirdWheel.isRemoveWheelBorder  = '';
          this.isFinal = false;
          this.isDisableButton = true;
          this.buttonFinishStyle   = [];
          this.rotateProgressValue = 0;
          this.progressValue = 0;
          this.titleStepNum  = 0;
          this.isFinish = false;

          if (!this.debug)
            this.onRoll(1);
          else
            this.onRoll(0);
        }, this.wheelFadeData ? 600 : 1); 
      }, this.wheelFadeData ? 600 : 0); 
    },
    convertStringToJSON: function(paramData) {
      paramData = JSON.stringify(paramData);
      return JSON.parse(paramData);
    },
    checkPlayAgainMode: function() {
      for (let index = 0; index < this.stepsLength; index++) {
        this.isPlayAgainModeIndex = index;
        if (this.finalType == 'finalF') {
          this.isPlayAgainMode = true;
          break;
        }
        else 
          this.isPlayAgainMode = false;
      }
    },
    setWheelsElements: async function() {
      var currentElements = [];

      if (this.templateName === this.templateAssoA) {
        currentElements = AssoModelA.getElements(6, 1);
        AssoModelA.debug();
      }
      else if (this.templateName === this.templateAssoB) {
        currentElements = AssoModelB.getElements(this.configData.numberElements);
        AssoModelB.debug();
      }
      else {
        currentElements = AssoModelC.getElements();
        AssoModelC.debug();
      }

      currentElements = this.convertStringToJSON(currentElements);

      this.firstWheel  = currentElements.first;
      this.secondWheel = currentElements.second;
      if (!this.isTwoWheels)
        this.thirdWheel  = currentElements.third;
    },
    setWheelsIndex: function(wheel, isCriteria = false) {
      if (isCriteria) {
        for (let index = 0; index < wheel.length; index++) {
          wheel[index] = {
            value: wheel[index],
            index: index,
          }
        }
      }
      else {
        for (let i = 0; i < wheel.length; i++) {
          wheel[i].index = i;
        }
      }
    },
    setDisplayedWheel: function(templateName, wheel, displayedWheel, wheelMeasuring, isCriteria) {
      var position = 0,
          limit = wheel.length,
          start = Math.round((limit - 1) / 2),
          locationIndex = limit - start,
          before, 
          middle, 
          tempData, 
          data;

      if (limit == 2) {
        start = 0;
        locationIndex = limit;
      }

      before = this.setBeforeWheelItem(start, limit, wheel, displayedWheel, locationIndex, position);
      displayedWheel = before[0];
      locationIndex  = before[1];
      position = before[2];

      middle = this.setMiddleWheelItem(limit, wheel, displayedWheel, position);
      displayedWheel = middle[0];
      position = middle[1];

      displayedWheel = this.setAfterWheelItem(start, limit, wheel, displayedWheel, locationIndex, position);
      displayedWheel = this.convertStringToJSON(displayedWheel);

      data = this.setWheelItemSettings(displayedWheel, wheelMeasuring, isCriteria);
      displayedWheel = data[0];
      wheelMeasuring = data[1];

      displayedWheel = this.convertStringToJSON(displayedWheel);
      return displayedWheel;
    },
    setWheelItemSettings: function(displayedWheel, wheelMeasuring, isCriteria) {

      if (!this.reset) {
        var wheelheight = -((Math.round(displayedWheel.length / 2) * 126) - 65);
        var wheelPosition = 0;
        var zIndex = 0;

        for (var l = 0; l < displayedWheel.length; l++) {
          zIndex = (l === 0 || l === (displayedWheel.length - 1)) ? '-1' : (isCriteria)? '1' : '';
          wheelPosition = wheelheight += 126;

          if (isCriteria) {
            wheelMeasuring[l] = {
              css: {
                'top': wheelPosition + 'px',
                'z-index': zIndex,
              }
            }

            if (wheelPosition === 65 || wheelPosition === (-61) || wheelPosition === 191)
              wheelMeasuring[l].css['z-index'] = 99;

            wheelMeasuring[l].isSelected = (wheelPosition === 65) ? true : false;

            displayedWheel[l].counter = l;
            displayedWheel[l].css = wheelMeasuring[l].css;
            displayedWheel[l].isSelected = wheelMeasuring[l].isSelected;
          }
          else {
            wheelMeasuring[l] = {
              css: {
                'top': wheelPosition + 'px',
                'z-index': zIndex,
              }
            };

            var isSelectedVal = (wheelPosition === 65) ? true : false;

            wheelMeasuring[l].isPaired = false;
            wheelMeasuring[l].isSelected = isSelectedVal;
            displayedWheel[l].isSelected = wheelMeasuring[l].isSelected;
            displayedWheel[l].css = wheelMeasuring[l].css;
          }
        }
      }
      else {
        for (var l = 0; l < displayedWheel.length; l++) {
          displayedWheel[l].css = wheelMeasuring[l].css;
          displayedWheel[l].isSelected = wheelMeasuring[l].isSelected;
        }
      }
      return [displayedWheel, wheelMeasuring];
    },
    setBeforeWheelItem: function(start, limit, wheel, displayedWheel, locationIndex, position) {
      for (var i = start; i < limit; i++) {
        if (!this.reset)
          displayedWheel.push(wheel[wheel.length - locationIndex]);
        else
          displayedWheel[position] = (wheel[wheel.length - locationIndex]);

        locationIndex--;
        position++;
      }
      return [displayedWheel, locationIndex, position];
    },
    setMiddleWheelItem: function(limit, wheel, displayedWheel, position) {
      var twiceLength = 1;
      if (limit <= 3)
        twiceLength = 2;

      for (let index = 0; index < twiceLength; index++) {
        for (var j = 0; j < limit; j++) {
          if (!this.reset)
            displayedWheel.push(wheel[j]);
          else
            displayedWheel[position] = (wheel[j]);

          position++;
        }
      }
      return [displayedWheel, position];
    },
    setAfterWheelItem: function(start, limit, wheel, displayedWheel, locationIndex, position) {
      if (limit == 2)
        start = limit;

      for (var k = 0; k < start; k++) {
        if (!this.reset)
          displayedWheel.push(wheel[k]);
        else
          displayedWheel[position] = (wheel[k]);

        position++;
      }
      return displayedWheel;
    },
    setFinishImages: function(wheelName, iteration, limit) {
      var wheel = document.getElementsByClassName(wheelName);
      var start = parseInt(wheel[0].getAttribute('target-index'));
      var targetIndex = start;

      for(var i = 0; i < iteration; i++) {
        if (targetIndex === 0)
          targetIndex = limit;
        else
          targetIndex--;
      }

      if (wheelName == 'selectedFirst')
        this.wheelFinishElements[0] = this.displayedFirstWheel[targetIndex];

      if (wheelName == 'selectedSecond')
        this.wheelFinishElements[1] = this.displayedSecondWheel[targetIndex];

      if (wheelName == 'selectedThird' && !this.isTwoWheels)
        this.wheelFinishElements[2] = this.displayedThirdWheel[targetIndex];
    },
    onRoll: function(numberOfRoll = 2) {
      var enableButtonDelay = 0;
      var lowestValueWheel1 = 0;
      var lowestValueWheel2 = 0;
      var lowestValueWheel3 = 0;
      var countIndexWheel1 = 0;
      var countIndexWheel2 = 0;
      var countIndexWheel3 = 0;
      var _this = this;

      this.isButtonDisableFirstWheel  = true;
      this.isButtonDisableSecondWheel = true;
      this.isButtonDisableThirdWheel  = true;

      var iteration = Math.floor(Math.random() * (20 - 15)) + 10;
      var delayFinishWheel = [0, 0, 0];

      delayFinishWheel = this.prepareWheelFinishItem(delayFinishWheel, iteration, numberOfRoll);
      enableButtonDelay = this.rollEachWheel(iteration, numberOfRoll, enableButtonDelay);

      this.insertWheelFinishItem(delayFinishWheel);
      this.afterRollSettings(enableButtonDelay);
    },
    afterRollSettings: function(enableButtonDelay) {
      var _this = this;

      setTimeout(function(){
        if (_this.progressValue != _this.stepsLength) {
          _this.isButtonDisableFirstWheel  = false;
          _this.isButtonDisableSecondWheel = false;
          _this.isButtonDisableThirdWheel  = false;
        }
        else {
          _this.titleStepNum = _this.progressValue;
          _this.isFinish = true;
          _this.onFinish();
          _this.flipClass = false;
          document.getElementsByTagName("body")[0].classList.add("finish");
          _this.myHome.changeBackground(true);
          setTimeout(function(){
            _this.preventClickButton = false;
          }, 600);
        }

        var tempFirstWheel  = document.getElementsByClassName('selectedFirst');
        var tempSecondWheel = document.getElementsByClassName('selectedSecond');
        if (!_this.isTwoWheels) {
          var tempThirdWheel  = document.getElementsByClassName('selectedThird');
          _this.isDisableButton = (
            !tempFirstWheel[0].classList.contains('paired') &&
            !tempSecondWheel[0].classList.contains('paired') &&
            !tempThirdWheel[0].classList.contains('paired')) ?
              false
            : true;
        }
        else
          _this.isDisableButton = (
            !tempFirstWheel[0].classList.contains('paired') &&
            !tempSecondWheel[0].classList.contains('paired')) ?
              false
            : true;

        _this.preventClickNext = false;
      }, enableButtonDelay + 500);
    },
    prepareWheelFinishItem: function(delayFinishWheel, iteration, numberOfRoll) {
      if (this.progressValue == this.stepsLength) {
        var steps = [];

        for(var i = 0; i < 3; i++) {
          steps[i] = Math.round(((iteration * numberOfRoll) * ((i * 0.3) + 1)));
          delayFinishWheel[i] = (Math.round(((iteration * numberOfRoll) * ((i * 0.3) + 1)))*180)-(180*3);
        }

        this.setFinishImages('selectedFirst', steps[0], this.displayedFirstWheel.length - 1);
        this.setFinishImages('selectedSecond', steps[1], this.displayedSecondWheel.length - 1);
        if (!this.isTwoWheels)
          this.setFinishImages('selectedThird', steps[2], this.displayedThirdWheel.length - 1);
      }
      return delayFinishWheel;
    },
    insertWheelFinishItem: function(delayFinishWheel) {
      for (let index = 0; index < delayFinishWheel.length; index++) {
        this.perWheelInsert(index, delayFinishWheel)
      }
    },
    perWheelInsert: function(index, delayFinishWheel) {
      var _this = this;
      var finishWheelItemImg;

      switch (index) {
        case 0:
          finishWheelItemImg = this.wheelOneImage;
          break;
        case 1:
          finishWheelItemImg = this.wheelTwoImage;
          break;
        default:
          finishWheelItemImg = this.wheelThreeImage;
          break;
      }
      setTimeout(function(){
        if (_this.wheelFinishElements[index]) {
          _this.wheelFinishElements[index].imageSrc = _this.myHome.getThemeImage(finishWheelItemImg);
          _this.wheelFinishElements[index].isPaired = false;
          _this.wheelFinishElements.splice(index, 1, _this.wheelFinishElements[index]);
        }
      }, delayFinishWheel[index]);
    },
    rollEachWheel: function(iteration, numberOfRoll, enableButtonDelay) {
      var _this = this;
      var wheelNum = this.isTwoWheels ? 2 : 3;
      var tempWheelsSelectedIndex = [];
      var data;

      for (let i = 0; i < wheelNum; i++) {
        var delay = 0;
        var addedformulaValue = 0;
        var formula = Math.round(((iteration * numberOfRoll) * ((i * 0.3) + 1)));

        data = this.checkingFormulaForDisableCard(addedformulaValue, formula, i);
        formula = data[0];
        addedformulaValue = data[1];

        delay = this.preparingSpinWheel(addedformulaValue, formula, i, delay, iteration, numberOfRoll);
        enableButtonDelay = delay;
      }

      return enableButtonDelay; 
    },
    checkingFormulaForDisableCard: function(addedformulaValue, formula, i) {
      return this.checkingFormulaProcess(addedformulaValue, formula, i);
    },
    checkingFormulaProcess: function(addedformulaValue, formula, i) {
      if (this.progressValue != this.stepsLength) {
        if (i === 0) {
          addedformulaValue = addedformulaValue + this.checkFormula(this.displayedFirstWheel, formula + addedformulaValue);
          formula = formula + addedformulaValue;
        }
        else if (i == 1) {
          addedformulaValue = addedformulaValue + this.checkFormula(this.displayedSecondWheel, formula + addedformulaValue);
          formula = formula + addedformulaValue;
        }
        else {
          addedformulaValue = addedformulaValue + this.checkFormula(this.displayedThirdWheel, formula + addedformulaValue);
          formula = formula + addedformulaValue;
        }
      }

      return [formula, addedformulaValue];
    },
    checkFormula: function(wheel, formula, action = 0) {
      var formulaValue = formula;
      var wheelLengthValue = wheel.length - 1;
      var selectedIndex = 0;
      var addedSteps = 0;
      var ctr = 0;

      wheel.forEach(function(item, index) {
        if (item.isSelected) {
          selectedIndex = index;
          return;
        }
      });

      while (formulaValue--) {
        if (ctr > wheelLengthValue)
          break;
        else
          selectedIndex = this.checkFormulaConditions(action, selectedIndex, wheelLengthValue);
      }

      while (wheel[selectedIndex].isPaired) {
        if (ctr >= wheelLengthValue) {
          break;
        }
        else {
          selectedIndex = this.checkFormulaConditions(action, selectedIndex, wheelLengthValue);
          addedSteps++;
        }
      }
      return addedSteps;
    },
    checkFormulaConditions: function(action, selectedIndex, wheelLengthValue) {
      if (action) {
        if (selectedIndex == wheelLengthValue)
          selectedIndex = 0;
        else
          selectedIndex++;
      }
      else {
        if (selectedIndex == 0)
          selectedIndex = wheelLengthValue;
        else
          selectedIndex--;
      }
      return selectedIndex;
    },
    preparingSpinWheel: function(addedformulaValue, formula, i, delay, iteration, numberOfRoll) {
      var _this = this;

      for (let index = 0; index < formula; index++) {
        var transitionDelay = 0;
        delay += ((formula) - 1 === index) ? 200 : 180;
        
        if (i === 0) {
          var formula1 = addedformulaValue;
          setTimeout( _ => {
            this.wheelLayout.$refs.parentFirstWheel.spinWheel(1, iteration, numberOfRoll, i, transitionDelay, index, this.displayedFirstWheel, this.firstWheelCSSData, this.myHome.getThemeImage('br.png'), formula1);
          }, delay);
        }
        else if (i == 1) {
          var formula2 = addedformulaValue;
          setTimeout( _ => {
            this.wheelLayout.$refs.parentSecondWheel.spinWheel(2, iteration, numberOfRoll, i, transitionDelay, index, this.displayedSecondWheel, this.secondWheelCSSData, this.myHome.getThemeImage('va.png'), formula2);
          }, delay);
        }
        else {
          var formula3 = addedformulaValue;
          setTimeout( _ => {
            this.wheelLayout.$refs.parentThirdWheel.spinWheel(3, iteration, numberOfRoll, i, transitionDelay, index, this.displayedThirdWheel, this.thirdWheelCSSData, this.myHome.getThemeImage('oi.png'), formula3);
          }, delay);
        }
      }

      return delay;
    },
    onReset: function() {
      this.wheelFadeData = true;
      this.isDisableButton = true;
      setTimeout( _=> {
        this.displayedFirstWheel.splice(0, this.displayedFirstWheel.length);
        this.displayedSecondWheel.splice(0, this.displayedFirstWheel.length);
        this.displayedThirdWheel.splice(0, this.displayedFirstWheel.length);
        this.firstWheelCSSData.splice(0, this.firstWheelCSSData.length);
        this.secondWheelCSSData.splice(0, this.secondWheelCSSData.length);
        this.thirdWheelCSSData.splice(0, this.thirdWheelCSSData.length);
        this.initializeSlots();
      }, 600);
    },
    onFinish: function() {
      var _this = this;

      setTimeout(function(){
        _this.isFinal = true;
        _this.buttonFinishStyle = {
          opacity: '0',
        }
      }, 1);
    },
    onPlayAgain: function() {
      var _this = this;
      this.flipClass = false;
      this.isDisableButton = true;
      this.fadeInTitle  = true;
      this.isDisablePlayAgain = true;
      this.isPlayAgainModeWrong  = false;
      this.previousTempThirdWheelValue  = undefined;
      this.previousTempFirstWheelValue  = undefined;
      this.previousTempSecondWheelValue = undefined;
      this.isButtonDisableFirstWheel  = false;
      this.isButtonDisableSecondWheel = false;
      this.isButtonDisableThirdWheel  = false;
      
      this.resetWheelFields(this.displayedFirstWheel, ['wrong', 'correct'], [false, false]);
      this.resetWheelFields(this.displayedSecondWheel, ['wrong', 'correct'], [false, false]);
      this.resetWheelFields(this.displayedThirdWheel, ['wrong', 'correct'], [false, false]);
      
      this.wheelLayout.$refs.parentFirstWheel.isRemoveWheelBorder  = '';
      this.wheelLayout.$refs.parentSecondWheel.isRemoveWheelBorder = '';
      if (!this.isTwoWheels)
        this.wheelLayout.$refs.parentThirdWheel.isRemoveWheelBorder  = '';

      setTimeout(function(){
        _this.isDisablePlayAgain = false;
      }, 1000);
      setTimeout(function(){
        _this.fadeInTitle = false;
      }, 100);
      this.onRoll(1);
    },
    resetWheelFields: function(wheel, field, value) {
      for (let i = 0; i < wheel.length; i++) {
        for (let j = 0; j < field.length; j++) {
        if (wheel[i].hasOwnProperty(field[j])) 
          wheel[i][field[j]] = value[j];
        }
        wheel.splice(i, 1, wheel[i]);
      }
    },
    onNext: function() {
      this.wheelLayout.$refs.parentFirstWheel.isRemoveWheelBorder  = '';
      this.wheelLayout.$refs.parentSecondWheel.isRemoveWheelBorder = '';
      if (!this.isTwoWheels)
        this.wheelLayout.$refs.parentThirdWheel.isRemoveWheelBorder  = '';

      if (this.preventClickNext) {
        return false;
      }
      else {
        this.preventClickNext = true;
      }

      this.onNextAddingPairedClass();
      this.onNextRemovingCorrectClass();
      this.afterOnNext();
    },
    afterOnNext: function() {
      var _this = this;

      if (this.progressValue != this.stepsLength) {
        this.flipClass = false;
        this.titleStepNum = this.progressValue;
        this.isButtonDisableFirstWheel  = false;
        this.isButtonDisableSecondWheel = false;
        this.isButtonDisableThirdWheel  = false;
        this.isDisableButton = true;
      }
      else {
        this.rotateProgressValue = 0;
        var transitionSpeed = 0;

        for (let index = 0; index <= this.progressValue; index++) {
          transitionSpeed = transitionSpeed + 300;

          setTimeout(function() {
            _this.rotateProgressValue++;
          }, transitionSpeed);
        }
        this.preventClickButton = true;
      }

      this.previousTempFirstWheelValue  = undefined;
      this.previousTempSecondWheelValue = undefined;
      this.previousTempThirdWheelValue  = undefined;

      if (!this.debug)
        this.onRoll(1);
      else
        this.onRoll(0);
    },
    onNextRemovingCorrectClass: function() {
      var correctSlot = document.getElementsByClassName('correct');

      correctSlot = document.getElementsByClassName('item');
      for (let index = 0; index < correctSlot.length; index++) {
        correctSlot[index].classList.remove('correct');
      }
    },
    onNextAddingPairedClass: function() {
      var correctSlot = document.getElementsByClassName('correct');

      for (let index = 0; index < correctSlot.length; index++) {
        var itemWheel = correctSlot[index];

        if (!itemWheel.classList.contains('skip-paired')) {
          var targetIndex = itemWheel.attributes['index'].value;
          itemWheel.classList.add('paired');

          this.setWheelItemPairedAttribute(itemWheel.attributes['target-wheel-type'].value, targetIndex, false);
        }
        else {
          this.setWheelItemPairedAttribute(itemWheel.attributes['target-wheel-type'].value, targetIndex, true);
        }
      }
    },
    setWheelItemPairedAttribute: function(attribute, targetIndex, criteria) {
      if (attribute === 'first')
        this.pairedWheelItemByIndex(this.displayedFirstWheel, this.firstPairedIndexes, targetIndex, criteria);
      else if (attribute === 'second')
        this.pairedWheelItemByIndex(this.displayedSecondWheel, this.secondPairedIndexes, targetIndex, criteria);
      else if (attribute === 'third' && !this.isTwoWheels)
        this.pairedWheelItemByIndex(this.displayedThirdWheel, this.thirdPairedIndexes, targetIndex, criteria);

    },
    pairedWheelItemByIndex: function(displayedWheel, pairedIndexes, targetIndex, criteria) {
      displayedWheel.forEach(function(item, index) {
        if (item.index == targetIndex && !criteria) {
          item.isPaired = true;
          pairedIndexes[targetIndex] = targetIndex;
        }
        item.wrong = false;
        item.correct = false;
      });
    },
    onSubmit: function(event) {
      if (this.preventClickButton)
        return false;
      else
        this.preventClickButton = true;

      var _this = this;
      var pair1 = false;
      var pair2 = false;
      var pair3 = false;
      var ifPair = false;
      var transitionDelay = 0;
      var delay = (!this.debug) ? 600 : 0;
      var firstWheel  = this.wheelLayout.$refs.parentFirstWheel.$refs.firstWheel;
      var secondWheel = this.wheelLayout.$refs.parentSecondWheel.$refs.secondWheel;
      var thirdWheel  = !this.isTwoWheels ? this.wheelLayout.$refs.parentThirdWheel.$refs.thirdWheel : false;
      var data, tempFirstWheel, tempSecondWheel, tempThirdWheel,
          tempFirstWheelValue, tempSecondWheelValue, tempThirdWheelValue;

      tempFirstWheel  = this.getWheelItemValueFromAttribute(firstWheel, tempFirstWheel);
      tempSecondWheel = this.getWheelItemValueFromAttribute(secondWheel, tempSecondWheel);
      if (!this.isTwoWheels)
        tempThirdWheel  = this.getWheelItemValueFromAttribute(thirdWheel, tempThirdWheel);

      this.firstWheelIndex  = tempFirstWheel.attributes.index.value
      this.secondWheelIndex = tempSecondWheel.attributes.index.value
      if (!this.isTwoWheels)
        this.thirdWheelIndex  = tempThirdWheel.attributes.index.value

      data = this.getPairedWheelItemByAssoType(pair1, pair2, pair3, tempFirstWheel, tempSecondWheel, tempThirdWheel);

      pair1 = data[0];
      pair2 = data[1];
      pair3 = data[2];

      tempFirstWheelValue  = this.checkingPairingWheel(pair1, pair2, pair3, 1);
      tempSecondWheelValue = this.checkingPairingWheel(pair1, pair2, pair3, 2);
      if (!this.isTwoWheels)
        tempThirdWheelValue  = this.checkingPairingWheel(pair1, pair2, pair3, 3);

      this.isWheelButtonDisable = true;
      
      transitionDelay = this.checkingPairingWheelStatus(tempFirstWheelValue, tempFirstWheel, tempSecondWheelValue, tempSecondWheel, tempThirdWheelValue, tempThirdWheel, transitionDelay, delay);

      this.previousTempFirstWheelValue   = tempFirstWheelValue;
      this.previousTempSecondWheelValue  = tempSecondWheelValue;
      if (!this.isTwoWheels)
        this.previousTempThirdWheelValue = tempThirdWheelValue;

      transitionDelay += delay;

      setTimeout(function() {
        _this.isWheelButtonDisable = false;
        ifPair = !_this.isTwoWheels ?
          pair1 && pair2 && pair3 ? true: false
        : pair1;

        if (ifPair) {
          _this.progressValue++;
          _this.rotateProgressValue = _this.progressValue;

          setTimeout(function() {
            _this.flipClass = true;
          }, delay);
          setTimeout(function() {
            _this.preventClickButton = false;
          }, delay + 800);
        }
        else {
          if (_this.isPlayAgainMode) {
            _this.isPlayAgainModeWrong = true;
            _this.isButtonDisableFirstWheel  = true;
            _this.isButtonDisableSecondWheel = true;
            _this.isButtonDisableThirdWheel  = true;
            setTimeout(function() {
              _this.flipClass = true;
              _this.preventClickButton = false;
            }, delay - 1000);
          }
          else 
            _this.preventClickButton = false;
          
        }
      }, transitionDelay);
    },
    getPairedWheelItemByAssoType: function(pair1, pair2, pair3, tempFirstWheel, tempSecondWheel, tempThirdWheel) {
      if (this.templateName === this.templateAssoA) {
        var firstValue  = '';
        var secondValue = '';
        var thirdValue  = '';
        var firstSoundValue, secondSoundValue, thirdSoundValue, data;

        data = this.getWheelsValue(firstValue, secondValue, thirdValue, firstSoundValue, secondSoundValue, thirdSoundValue, tempFirstWheel, tempSecondWheel, tempThirdWheel);
        firstValue  = data[0];
        secondValue = data[1];
        thirdValue  = data[2];
        firstSoundValue  = data[3];
        secondSoundValue = data[4];
        thirdSoundValue  = data[5];

        data = this.getPairedWheelItem(firstValue, secondValue, thirdValue, firstSoundValue, secondSoundValue, thirdSoundValue);
        pair1 = data[0];
        pair2 = data[1];
        pair3 = data[2];
      }
      else if (this.templateName === this.templateAssoB) {
        try {
          if (
            eval( 
                  this.firstWheel[this.firstWheelIndex].element[this.valueSource] + " " +
                  this.secondWheel[this.secondWheelIndex].element[this.valueSource] + " " +
                  this.thirdWheel[this.thirdWheelIndex].element[this.valueSource]
                )
            ) {

            pair1 = true;
            pair2 = true;
            pair3 = true;
          }
        } catch (err) {
          console.error(err);
        }
      }
      else {
        var tempWheel1 = this.firstWheel[this.firstWheelIndex].element[this.valueSource];
        var tempWheel2 = this.secondWheel[this.secondWheelIndex].element[this.valueSource];

        pair1 = (tempWheel1 == tempWheel2) ? true : false;

        if (!this.isTwoWheels) {
          var tempWheel3 = this.thirdWheel[this.thirdWheelIndex].element[this.valueSource];
          pair2 = (tempWheel3 == tempWheel2) ? true : false;
          pair3 = (tempWheel3 == tempWheel1) ? true : false;
        }
      }
      return [pair1, pair2, pair3];
    },
    getWheelsValue: function(firstValue, secondValue, thirdValue, firstSoundValue, secondSoundValue, thirdSoundValue, tempFirstWheel, tempSecondWheel, tempThirdWheel) {
      firstSoundValue   = this.firstWheel[this.firstWheelIndex].hasOwnProperty('element') && 
                          this.firstWheel[this.firstWheelIndex].element.hasOwnProperty('criterias') ? 
                            this.firstWheel[this.firstWheelIndex].element.criterias
                          : '';
      secondSoundValue  = this.secondWheel[this.secondWheelIndex].hasOwnProperty('element') && 
                          this.secondWheel[this.secondWheelIndex].element.hasOwnProperty('criterias') ? 
                            this.secondWheel[this.secondWheelIndex].element.criterias
                          : '';
      
      if (!this.isTwoWheels)
        thirdSoundValue   = this.thirdWheel[this.thirdWheelIndex].hasOwnProperty('element') && 
                            this.thirdWheel[this.thirdWheelIndex].element.hasOwnProperty('criterias') ? 
                              this.thirdWheel[this.thirdWheelIndex].element.criterias
                            : '';

      if (tempFirstWheel.classList.contains('criteria')){
        firstValue  = tempFirstWheel.getAttribute('data-value');
        secondValue = this.secondWheel[this.secondWheelIndex].element[this.valueSource];
        firstSoundValue = firstValue;
        if (!this.isTwoWheels) {
          thirdValue  = this.thirdWheel[this.thirdWheelIndex].element[this.valueSource];
          thirdSoundValue = this.checkCriteria(thirdSoundValue, firstSoundValue);
        }
        secondSoundValue = this.checkCriteria(secondSoundValue, firstSoundValue);
      }
      else if (tempSecondWheel.classList.contains('criteria')){
        firstValue  = this.firstWheel[this.firstWheelIndex].element[this.valueSource];
        secondValue = tempSecondWheel.getAttribute('data-value');
        secondSoundValue = secondValue;
        if (!this.isTwoWheels) {
          thirdValue  = this.thirdWheel[this.thirdWheelIndex].element[this.valueSource];
          thirdSoundValue = this.checkCriteria(thirdSoundValue, secondValue);
        }
        firstSoundValue = this.checkCriteria(firstSoundValue, secondValue);
      }
      else if (!this.isTwoWheels) {
        firstValue  = this.firstWheel[this.firstWheelIndex].element[this.valueSource];
        secondValue = this.secondWheel[this.secondWheelIndex].element[this.valueSource];
        thirdValue  = tempThirdWheel.getAttribute('data-value');

        thirdSoundValue = thirdValue;
        firstSoundValue = this.checkCriteria(firstSoundValue, thirdSoundValue);
        secondSoundValue = this.checkCriteria(secondSoundValue, thirdSoundValue);
        
      }

      return [firstValue, secondValue, thirdValue, firstSoundValue, secondSoundValue, thirdSoundValue];
    },

    checkCriteria(criteria, value) {
      let retVal = false;
      for (let index = 0; index < criteria.length; index++) {
        if(criteria[index] == value)
          retVal = criteria[index];
      }

      return retVal;
    },

    getPairedWheelItem: function(firstValue, secondValue, thirdValue, firstSoundValue, secondSoundValue, thirdSoundValue) {
      var pair1, pair2, pair3;

      if (this.firstWheelIsCrit || this.secondWheelIsCrit)
        pair1 = (firstSoundValue == secondSoundValue) ? true : false;
      else
        pair1 = (firstValue == secondValue) ? true : false;

      if (!this.isTwoWheels) {
        if (this.thirdWheelIsCrit || this.secondWheelIsCrit)
          pair2 = (thirdSoundValue == secondSoundValue) ? true : false;
        else
          pair2 = (thirdValue == secondValue) ? true : false;

        if (this.thirdWheelIsCrit || this.firstWheelIsCrit)
          pair3 = (thirdSoundValue == firstSoundValue) ? true : false;
        else
          pair3 = (thirdValue == firstValue) ? true : false;
      }


      return [pair1, pair2, pair3];
    },
    getWheelItemValueFromAttribute: function(wheel, tempWheel) {
      for (let index = 0; index < wheel.children.length; index++) {
        if (wheel.children[index].attributes.isselected)
          tempWheel = wheel.children[index];
      }

      return tempWheel;
    },
    checkingPairingWheelStatus: function(tempFirstWheelValue, tempFirstWheel, tempSecondWheelValue, tempSecondWheel, tempThirdWheelValue, tempThirdWheel, transitionDelay, delay) {

      transitionDelay = this.pairingWheelStatus(this.displayedFirstWheel, tempFirstWheelValue, tempFirstWheel, this.previousTempFirstWheelValue, this.wheelLayout.$refs.parentFirstWheel.isRemoveWheelBorder, this.isButtonDisableFirstWheel, this.wheelOneDisable, transitionDelay, delay, 1);
      transitionDelay = this.pairingWheelStatus(this.displayedSecondWheel, tempSecondWheelValue, tempSecondWheel, this.previousTempSecondWheelValue, this.wheelLayout.$refs.parentSecondWheel.isRemoveWheelBorder, this.isButtonDisableSecondWheel, this.wheelTwoDisable, transitionDelay, delay, 2);
      if (!this.isTwoWheels)
        transitionDelay = this.pairingWheelStatus(this.displayedThirdWheel, tempThirdWheelValue, tempThirdWheel, this.previousTempThirdWheelValue, this.wheelLayout.$refs.parentThirdWheel.isRemoveWheelBorder, this.isButtonDisableThirdWheel, this.wheelThreeDisable, transitionDelay, delay, 3);

      return transitionDelay;
    },
    pairingWheelStatus: function(displayedWheel, tempWheelValue, tempWheel, previousTempWheelValue, isRemoveWheelBorder, isButtonDisableWheel, wheelDisable, transitionDelay, delay, wheelType) {
      var _this = this,
          index = tempWheel.getAttribute('target-index');

      if (tempWheelValue != previousTempWheelValue ||
          (previousTempWheelValue === false &&
          (tempWheelValue === false &&
          isRemoveWheelBorder != 'active'))) {
        var tempItem = previousTempWheelValue;

        setTimeout(function() {
          isButtonDisableWheel = (tempWheelValue === false && (tempWheelValue != tempItem || (tempItem === false || tempItem === ""))) ? false : true;

          if (tempWheelValue != tempItem) {
            displayedWheel[index].correct = false;
            displayedWheel[index].wrong = false;

            displayedWheel.splice(index, 1, displayedWheel[index]);
          }

          if (wheelType == 2) {
            _this.wheelLayout.$refs.parentSecondWheel.isRemoveWheelBorder = 'active';
            
            if (tempWheelValue) 
              displayedWheel[index].correct = true;
            else
              displayedWheel[index].wrong = true;

            displayedWheel.splice(index, 1, displayedWheel[index]);
          }
          else if (_this.templateName === _this.templateAssoA ||
                   _this.templateName === _this.templateAssoC ||
                   tempWheelValue === true) {
            if (wheelType == 1)
              _this.wheelLayout.$refs.parentFirstWheel.isRemoveWheelBorder = 'active';
            else {
              _this.wheelLayout.$refs.parentThirdWheel.isRemoveWheelBorder = 'active';
              transitionDelay += delay;
            }
            
            if (tempWheelValue) 
              displayedWheel[index].correct = true;
            else
              displayedWheel[index].wrong = true;

            displayedWheel.splice(index, 1, displayedWheel[index]);
          }

          switch (wheelType) {
            case 1:
              _this.isButtonDisableFirstWheel = isButtonDisableWheel;
              break;
            case 2:
              _this.isButtonDisableSecondWheel = isButtonDisableWheel;
              break;
            default:
              _this.isButtonDisableThirdWheel = isButtonDisableWheel;
              break;
          }
        }, transitionDelay);

        if (wheelType == 2 && !(_this.numberOfDisable == 1 && wheelDisable && !tempWheelValue))
          transitionDelay += delay;
        else if ( (_this.templateName === _this.templateAssoA ||
                  _this.templateName === _this.templateAssoC ||
                  tempWheelValue) && !(_this.numberOfDisable == 1 && wheelDisable && !tempWheelValue) )
          transitionDelay += delay;
      }
      return transitionDelay;
    },
    checkingPairingWheel: function(pair1, pair2, pair3, wheelType) {
      var tempFirstWheelValue, tempSecondWheelValue, tempThirdWheelValue;

      if (pair1 && pair2 && pair3) {
        tempFirstWheelValue  = true;
        tempSecondWheelValue = true;
        tempThirdWheelValue  = true;
      }
      else if (pair1 && (this.disableWheel == 1 || this.disableWheel == 2 || this.disableWheel == false)) {
        tempFirstWheelValue  = true;
        tempSecondWheelValue = true;
        tempThirdWheelValue  = false;
      }
      else if (pair2 && (this.disableWheel == 2 || this.disableWheel == 3 || this.disableWheel == false)) {
        tempFirstWheelValue  = false;
        tempSecondWheelValue = true;
        tempThirdWheelValue  = true;
      }
      else if (pair3 && (this.disableWheel == 3 || this.disableWheel == 1 || this.disableWheel == false)) {
        tempFirstWheelValue  = true;
        tempSecondWheelValue = false;
        tempThirdWheelValue  = true;
      }
      else {
        tempFirstWheelValue  = false;
        tempSecondWheelValue = false;
        tempThirdWheelValue  = false;
      }

      switch (wheelType) {
        case 1:
          return tempFirstWheelValue;
          break;
        case 2:
          return tempSecondWheelValue;
          break;
        default:
          return tempThirdWheelValue;
          break;
      }
    },
    afterScrollButton: function(newFormula) {
      var ctr = 0;
      var delay;
      while (newFormula--) {
        delay = 290 * ctr;
        this.checkIfPaired(delay);
        ctr++;
      }
    },
    checkIfPaired: function(delay) {
      var _this = this;
        setTimeout(function() {
          var containedPaired = false;
          var tempFirstWheel  = document.getElementsByClassName('selectedFirst');
          var tempSecondWheel = document.getElementsByClassName('selectedSecond');
          if (!_this.isTwoWheels) {
            var tempThirdWheel  = document.getElementsByClassName('selectedThird');
            containedPaired = !tempFirstWheel[0].classList.contains('paired') &&
                              !tempSecondWheel[0].classList.contains('paired') &&
                              !tempThirdWheel[0].classList.contains('paired') ? true : false;
          }
          else {
            containedPaired = !tempFirstWheel[0].classList.contains('paired') &&
                              !tempSecondWheel[0].classList.contains('paired') ? true : false;
          }

          if (containedPaired){
            _this.preventClickButton = false;
            _this.wheelLayout.$refs.parentFirstWheel.isSmoothEffect   = false;
            _this.wheelLayout.$refs.parentSecondWheel.isSmoothEffect  = false;
            if (!_this.isTwoWheels)
              _this.wheelLayout.$refs.parentThirdWheel.isSmoothEffect   = false;

            setTimeout(function() {
              _this.preventSwipe = false;
            }, 300);
          }
          else {
            _this.preventClickButton = true;
            setTimeout(function() {
              _this.preventSwipe = true;
            }, 300);
          }
        }, delay);
    },

    loadThemeImages: function() {
      var images = [
       'br.png',
       'va.png',
       'oi.png',
       'bouton-actif.svg',
       'bouton-inactif.svg',
      ];

      // Preloading images for smooth animations
      for(var i = 0; i < images.length; i++) {
        var img = new Image();
        img.src = this.myHome.getThemeImage(images[i]);
      }
    },
  },
  created: async function () {
    try {
      if (this.templateName === this.templateAssoA)
        await AssoModelA.populateElements(this.config);
      else if (this.templateName === this.templateAssoB)
        await AssoModelB.populateElements(this.config);
      else
        await AssoModelC.populateElements(this.config);
    }
    catch (err) { // need to catch error or rejected promise
      console.error(err);
    }

    this.loadThemeImages();
    this.initializeSlots();
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.association {
  height: 100%;
  display: flex;
  justify-content: normal;
  align-items: center;
  flex-direction: column;
}
</style>
