<template>
  <div id="app" v-if="isDocumentReady">
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModelConfig from './models/Config'
import I18n from './models/I18n'

export default {
  name: 'App',
  data () {
    return {
      isDocumentReady: false,
    }
  },
  async created () {
    this.initStorage('SET_APP_CONFIG');
    let response = await ModelConfig.initAppConfig();
    this.setAppConfig(response.data.Items[0]);

    let product = this.findProduct(this.getProduct);
    await I18n.initialize(product.language);

    this.isDocumentReady = true;
  },
  methods: {
    ...mapActions(['initStorage', 'setAppConfig'])
  },
  computed: {
    ...mapGetters(['findProduct', 'getProduct'])
  },
}
</script>

<style scoped>
</style>
