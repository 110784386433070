const Mutations = {
	SET_APP_CONFIG(state, data) {
		state.appConfig = data
  },
  SET_NAVIGATION_DATA(state, data) {
    let navigationData = {};
		Object.assign(navigationData, state.navigationData);
		Object.assign(navigationData, data);
    state.navigationData = navigationData;
	},
  SET_ORTHO_ARRAY_DATA(state, data) {
    state.orthoArrayData = data;
  },
  SET_ORTHO_ARRAY_DATA_BY_ELEM(state, data) {
    let arrayData = {};
		Object.assign(arrayData, state.orthoArrayData);
    arrayData[data.index][data.element] = data.value
    state.orthoArrayData = arrayData;
  },
  SET_ORTHO_ARRAY_DATA_RESET_ISSELECT(state) {
    let arrayData = [];
    Object.assign(arrayData, state.orthoArrayData);
    for (let index = 0; index < arrayData.length; index++) {
      arrayData[index].isSelected = false; 
    }
    state.orthoArrayData = arrayData;
  },
  SET_MEMO_LEFT_ARRAY_DATA(state, data) {
    state.memoLeftArrayData = data;
  },
  SET_MEMO_LEFT_ARRAY_DATA_BY_ELEM(state, data) {
    let arrayData = {};
		Object.assign(arrayData, state.memoLeftArrayData);
    arrayData[data.index][data.element] = data.value
    state.memoLeftArrayData = arrayData;
  },
  SET_MEMO_LEFT_ARRAY_DATA_RESET_ISSELECT(state) {
    let arrayData = [];
    Object.assign(arrayData, state.memoLeftArrayData);
    for (let index = 0; index < arrayData.length; index++) {
      arrayData[index].isSelected = false; 
    }
    state.memoLeftArrayData = arrayData;
  },
  SET_MEMO_RIGHT_ARRAY_DATA(state, data) {
    state.memoRightArrayData = data;
  },
  SET_MEMO_RIGHT_ARRAY_DATA_BY_ELEM(state, data) {
    let arrayData = {};
		Object.assign(arrayData, state.memoRightArrayData);
    arrayData[data.index][data.element] = data.value
    state.memoRightArrayData = arrayData;
  },
  SET_MEMO_RIGHT_ARRAY_DATA_RESET_ISSELECT(state) {
    let arrayData = [];
    Object.assign(arrayData, state.memoRightArrayData);
    for (let index = 0; index < arrayData.length; index++) {
      arrayData[index].isSelected = false; 
    }
    state.memoRightArrayData = arrayData;
  },
  SET_MULTI_OBJECT_DATA(state, data) {
    state.multiObjectData = data;
  },
  SET_I18N(state, data) {
    state.i18n = data;
  },
  SET_LOCALE(state, data) {
    state.locale = data;
  },
  SET_PRODUCT(state, data) {
    state.product = data;
  }
}

export default Mutations
