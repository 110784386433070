var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"final",class:{'board-item-wrap': _vm.finalBoardAnswers.length > 5}},[_c('div',{staticClass:"ribbon",style:({
      'backgroundImage': 'url(' + ((_vm.finalBoardAnswers.length > 5) ? _vm.bannerImg1 : _vm.bannerImg2) + ')',
      'color': _vm.bannerTextColor
    })},[_c('span',[_vm._v(" "+_vm._s(_vm.boardTitle)+" ")])]),_c('div',{staticClass:"board",style:({
      'background-color': _vm.boardBackgroundColor,
      'border-color': _vm.boardBorderColor
    })},[_c('ul',_vm._l((_vm.finalBoardAnswers),function(word,index){return _c('li',{key:word + index,style:({
          'border-color': _vm.boardBorderColor
        })},[(_vm.cardAudioType === 'mp3' && _vm.cardAudioSource)?_c('AudioA',{attrs:{"messageAudioFile":word[_vm.cardAudioSource],"classAdded":"final-audio","isDataSound":true,"assetMediaAudioUrl":_vm.assetMediaAudioUrl}}):(_vm.cardAudioType && _vm.cardAudioSource)?_c('AudioB',{attrs:{"classAdded":"final-audio","correctWord":word[_vm.cardAudioSource],"audioType":_vm.cardAudioType}}):_vm._e(),(_vm.finalDisplaySource)?_c('div',{staticClass:"answer-word-list"},[_vm._v(" "+_vm._s(word[_vm.finalDisplaySource])+" ")]):_c('div',{staticClass:"answer-word-list"},[_vm._v(" "+_vm._s(word.value)+" ")])],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }