<template>
    <div class="select">
      <HeaderA
        :audioHtml="audioHtml"
        :stepsLength="stepsLength"
        :progressValue="progressValue"
        :isReRotateStars="isReRotateStars"
        :fadeIn="fadeIn"
        :message="message"
        :finalMessage="finalMessage"
        :messageAudioFile="messageAudioFile"
        :messageAudioType="messageAudioType"
        :isFinal="isFinal"
        :finalMessageAudioFile="finalMessageAudioFile"
      >
      </HeaderA>
      <Select
        :cards="cards"
        :cardsLayout="cardsLayout"
        :cardWidth="cardWidth"
        :cardHeight="cardHeight"
        :displayType="displayType"
        :displaySource="displaySource"
        :numberCorrectElements="numberCorrectElements"
        :numOfSelected="numOfSelected"
        :correctBorderColor="correctBorderColor"
        :selectedBorderColor="selectedBorderColor"
        :wrongBorderColor="wrongBorderColor"
        :backgroundColor="backgroundColor"
        :cardBorderColor="cardBorderColor"
        :cardContentPosition="cardContentPosition"
        :retroBorderSize="retroBorderSize"
        :cardFontSize="cardFontSize"
        :cardFontColor="cardFontColor"
        :isFinal="isFinal"
        :finalImages="finalImages"
        :finalType="finalType"
        :boardTitle="boardTitle"
        :numSteps="numSteps"
        :themeName="config.data.config.theme.themeName"
        :themeLocation="config.data.config.theme.themeLocation"
        :numberCards="numberCards"
        :assetMediaImageUrl="assetMediaImageUrl"
        :cardPaddingWidth="cardPaddingWidth"
        :cardPaddingHeight="cardPaddingHeight"
        :backgroundColorCard="backgroundColorCard"
        :backgroundColorDisable="backgroundColorDisable"
        :backgroundColorWrong="backgroundColorWrong"
      >
      </Select>
      <FooterA
        :flipClass="flipClass"
        :isDisabled="isDisableNext"
        :isDisableClick="isDisableClick"
        :nextText="config.data.config.steps.actionNextText"
        :validateText="config.data.config.steps.actionValidateText"
        :finalActionNextText="finalActionNextText"
        :actionTextColor="myHome.theme.actionTextColor"
        :activeButtonImg="myHome.getThemeImage('bouton-actif.svg')"
        :inactiveButtonImg="myHome.getThemeImage('bouton-inactif.svg')"
        :isFinal="isFinalButton"
      >
      </FooterA>
    </div>
</template>

<script>
import HeaderA from '../components/HeaderA'
import ActionA from '../components/ActionA'
import FooterA from '../components/FooterA'
import Select from '../components/SelectA'
import SelectModel from '../models/Select'
import Config from '../models/Config'
import Velocity from 'velocity-animate'
import Util from '../helpers/Util'

export default {
  props: ['config'],
  components: {
    'HeaderA': HeaderA,
    'ActionA': ActionA,
    'FooterA': FooterA,
    'Select': Select
  },
  data () {
    return {
      myComponent: this,
      myHome: this.$parent.myHome,
      cards: [],
      stepsLength: 0,
      rotatestar: false,
      progressValue: 0,
      isReRotateStars: false,
      message: '',
      finalActionNextText: '',
      finalImages: [],
      finalMessage: false,
      finalType: false,
      boardTitle: false,
      numSteps: 0,
      fadeIn: false,
      isFinal: false,
      isFinalButton: false,
      flipClass: false,
      isDisableNext: true,
      isDisableClick: false,
      audioHtml: '',
      audio: false,
      loopExample: 12,
      numOfSelected: 0,
      correctBorderColor: '',
      selectedBorderColor: '',
      wrongBorderColor: '',
      backgroundColor: '',
      backgroundColorCard: 'transparent',
      backgroundColorDisable: false,
      backgroundColorWrong: false,
      cardBorderColor: '',
      cardWidth: '',
      cardHeight: '',
      displaySource: false,
      displayType: false,
      cardFontSize: false,
      cardFontColor: false,
      cardContentPosition: 'center',
      retroBorderSize: "",
      limitAnimation: "",
      numberCorrectElements: "",
      numberCards: "",
      numberAnimatedCards: 2,
      cardsLayout: "",
      isCompleted: false,
      messageAudioType: 'mp3',
      messageAudioFile: '',
      assetMediaImageUrl: false,
      cardPaddingWidth: false,
      cardPaddingHeight: false,
      finalMessageAudioFile: false,
      isSubmit: false,
    }
  },
  methods: {
    initialize: function() {
      if (this.config.data.config.hasOwnProperty('theme')) {
        this.correctBorderColor = this.config.data.config.theme.hasOwnProperty('correctBorderColor') ? this.config.data.config.theme.correctBorderColor : false;
        this.selectedBorderColor = this.config.data.config.theme.hasOwnProperty('selectedBorderColor') ? this.config.data.config.theme.selectedBorderColor : false;
        this.wrongBorderColor = this.config.data.config.theme.hasOwnProperty('wrongBorderColor') ? this.config.data.config.theme.wrongBorderColor : false;
        this.cardBorderColor = this.config.data.config.theme.hasOwnProperty('cardBorderColor') ? this.config.data.config.theme.cardBorderColor : false;
        this.backgroundColor = this.config.data.config.theme.hasOwnProperty('backgroundColor') ? this.config.data.config.theme.backgroundColor : false;
        this.backgroundColorCard = this.config.data.config.theme.hasOwnProperty('backgroundColorCard') ? this.config.data.config.theme.backgroundColorCard : 'transparent';
        this.backgroundColorDisable = this.config.data.config.theme.hasOwnProperty('backgroundColorDisable') ? this.config.data.config.theme.backgroundColorDisable : false;
        this.backgroundColorWrong = this.config.data.config.theme.hasOwnProperty('backgroundColorWrong') ? this.config.data.config.theme.backgroundColorWrong : false;
      }
      if (this.config.data.config.hasOwnProperty('layout')) {
        this.cardWidth = this.config.data.config.layout.hasOwnProperty('cardWidth') ? this.config.data.config.layout.cardWidth : false;
        this.cardHeight = this.config.data.config.layout.hasOwnProperty('cardHeight') ? this.config.data.config.layout.cardHeight : false;
        this.displaySource = this.config.data.config.layout.hasOwnProperty('displaySource') ? this.config.data.config.layout.displaySource : 'value';
        this.displayType = this.config.data.config.layout.hasOwnProperty('displayType') ? this.config.data.config.layout.displayType : 'text';
        this.cardFontSize = this.config.data.config.layout.hasOwnProperty('cardFontSize') ? this.config.data.config.layout.cardFontSize : false;
        this.cardFontColor = this.config.data.config.layout.hasOwnProperty('cardFontColor') ? this.config.data.config.layout.cardFontColor : false;
        this.cardContentPosition = this.config.data.config.layout.hasOwnProperty('cardContentPosition') ? this.config.data.config.layout.cardContentPosition : 'center';
        this.retroBorderSize = this.config.data.config.layout.hasOwnProperty('retroBorderSize') ? this.config.data.config.layout.retroBorderSize : false;
        this.limitAnimation = this.config.data.config.layout.hasOwnProperty('limitAnimation') ? this.config.data.config.layout.limitAnimation : 'bounce';
        this.cardsLayout = this.config.data.config.layout.hasOwnProperty('cardsLayout') ? this.config.data.config.layout.cardsLayout : 'column';
        this.cardPaddingWidth = this.config.data.config.layout.hasOwnProperty('cardPaddingWidth') ? this.config.data.config.layout.cardPaddingWidth : false;
        this.cardPaddingHeight = this.config.data.config.layout.hasOwnProperty('cardPaddingHeight') ? this.config.data.config.layout.cardPaddingHeight : false;
      }
      if (this.config.data.config.hasOwnProperty('steps')) {
        this.stepsLength = this.config.data.config.steps.hasOwnProperty('numberSteps') ? parseInt(this.config.data.config.steps.numberSteps)  : 0;
        this.message = this.config.data.config.steps.hasOwnProperty('message') ? this.config.data.config.steps.message : '';
        this.messageAudioType = this.config.data.config.steps.hasOwnProperty('messageAudioType') ? this.config.data.config.steps.messageAudioType : 'mp3';
        this.messageAudioFile = this.config.data.config.steps.hasOwnProperty('messageAudioFile') ? this.config.data.config.steps.messageAudioFile : '';
      }
      if (this.config.data.config.hasOwnProperty('final')) {
        this.finalImages = this.config.data.config.final.hasOwnProperty('finalImages') ? this.config.data.config.final.finalImages : [];
        this.finalActionNextText = this.config.data.config.final.hasOwnProperty('actionNextText') ? this.config.data.config.final.actionNextText : '';
        this.finalMessage = this.config.data.config.final.hasOwnProperty('message') ? this.config.data.config.final.message : '';
        this.finalType = this.config.data.config.final.hasOwnProperty('type') ? this.config.data.config.final.type : 'finalb';
        this.boardTitle = this.config.data.config.final.hasOwnProperty('boardTitle') ? this.config.data.config.final.boardTitle : '';
        this.finalMessageAudioFile = this.config.data.config.final.hasOwnProperty('messageAudioFile') ? this.config.data.config.final.messageAudioFile : '';
      }
      this.numberCorrectElements = this.config.data.config.hasOwnProperty('numberCorrectElements') ? parseInt(this.config.data.config.numberCorrectElements) : 1;
      this.numberCards = this.config.data.config.hasOwnProperty('numberCards') ? parseInt(this.config.data.config.numberCards) : 12;
      this.assetMediaImageUrl = this.config.data.config.hasOwnProperty('assetMediaImageUrl') ? this.config.data.config.assetMediaImageUrl : '';
      
      switch (this.numberCards) {
        case 12:
          this.numberAnimatedCards = 4;
          break;
        case 8:
          this.numberAnimatedCards = 4;
          break;
        case 2:
          this.numberAnimatedCards = 1;
          break;
      }
      
      document.getElementsByTagName("body")[0].classList.remove("finish");
    },

    onSubmit: async function() {
    var _this = this,
        selectedItem = [],
        counter = 0,
        correctCounter = 0,
        correctItem = [];
      this.isDisableClick = true;
      this.numOfSelected = 0;
      this.isSubmit = true;
      
      selectedItem = document.getElementsByClassName('selected');
      for (let index = 0; index < selectedItem.length; index++) {
        var itemIndex = selectedItem[index].getAttribute('itemindex');
        if (this.cards[itemIndex].isCorrect) {
          this.cards[itemIndex].isCorrectBorder = true;
          this.cards[itemIndex].isWrongBorder = false;
          correctCounter++;
        }
        else { 
          this.cards[itemIndex].isCorrectBorder = false;
          this.cards[itemIndex].isWrongBorder = true;
          counter++;
        }
        this.cards[itemIndex].isSelectedBorder = false;
      }

      setTimeout(function() {
        correctItem = document.getElementsByClassName('correct');
      }, 50);

      setTimeout(function() {
        _this.numOfSelected = correctItem.length;
        
        if (counter && _this.numOfSelected != _this.numberCorrectElements) {
          _this.isDisableNext = true;
          _this.isDisableClick = false;
        }

        if (correctItem.length === _this.numberCorrectElements) {
          _this.isCompleted = true;
          setTimeout(function() {
            _this.progressValue++;
          }, 1000);
          setTimeout(function() {
            _this.flipClass = true;
            _this.isDisableNext = false;
            setTimeout(function() {
              _this.isDisableClick = false;
            }, 300);
          }, 2000);
        }
        else  {
          _this.flipClass = false;
          _this.isDisableNext = true;
        }
      }, 100);
    },

    onReset: function() {
      this.reInitialize();
      this.progressValue = 0;
      this.numSteps = 0;
      this.isFinal = false;
      this.isFinalButton = false;
      this.isCompleted = false;
      this.getElements();
      this.initializeCardsElements();
      document.getElementsByTagName("body")[0].classList.remove("finish");
    },

    onNext: function() {
      var _this = this;
      this.initializeCardsElements();
      setTimeout(function() {
        _this.hideAnimation();
        setTimeout(function() {
          _this.flipClass = false;
          _this.isDisableNext = true;
        }, 300);
      }, 1);
      setTimeout(function() {
        setTimeout(function() {
          _this.reInitialize(true);
        }, 1000);
        _this.numSteps++;
        
          if (_this.progressValue === _this.stepsLength) {
            _this.isCompleted = true;
            _this.isFinalButton = true;
            _this.isDisableClick = true;
            
            setTimeout(function() {
              _this.isDisableNext = false;
            }, 50);

            setTimeout(function() {
              _this.isFinal = true;
              setTimeout(function() {
                document.getElementsByTagName("body")[0].classList.add("finish");
                _this.myHome.changeBackground(true);
              }, 100);
              setTimeout(function() {
                _this.isReRotateStars = true;
              }, 300);
              setTimeout(function() {
                _this.isDisableClick = false;
                _this.isReRotateStars = false;
              }, 2000);
            }, 1000);
          }
          else {
            setTimeout(function() {
              _this.isCompleted = false;
              _this.getElements();
            }, 1000);
          }

      }, 300);
    },

    initializeCardsElements: function() {
      for (let index = 0; index < this.cards.length; index++) {
        this.cards[index].isCorrectBorder = false;
        this.cards[index].isSelectedBorder = false;
        this.cards[index].isWrongBorder = false;
      }
    },

    reInitialize: function(isNext = false) {
      if (!isNext) {
        this.flipClass = false;
        this.isDisableNext = true;
      }
      this.numOfSelected = 0;
      if (!this.isFinalButton) 
        this.isDisableClick = false;
      this.callShowAnimation();
    },
    
    removeWrong: function() {
      var wrongItem = [];
      wrongItem = document.getElementsByClassName('wrong');
      for (let index = 0; index < wrongItem.length; index++) {
        var itemIndex = wrongItem[index].getAttribute('itemindex');
        this.cards[itemIndex].isWrongBorder = false;
        this.cards[itemIndex].isSelectedBorder = false;
      }
    },
    showAnimation: function() {
      var el = '',
        ctrGroup = 0,
        ctrTimer = 0,
        cards = document.getElementsByClassName('card');

      for (let index = 0; index < cards.length; index++) {
        if (ctrGroup < this.numberAnimatedCards) {
          setTimeout(function() {
            el = cards[index];
            Velocity(el, {
              scale: 1,
              opacity: 1
            }, { duration: 6 });
          }, 200 * ctrTimer);
          if (ctrGroup == (this.numberAnimatedCards - 1)) {
            ctrTimer++;
            ctrGroup = 0;
          }
          else
            ctrGroup++;
        }
      }
    },

    hideAnimation: function() {
      var el = '',
        cards = document.getElementsByClassName('card');

      for (let index = 0; index < cards.length; index++) {
        el = cards[index];
        Velocity(el, {
          scale: 0,
          opacity: 0
        }, { duration: 600 });
      }
    },

    callShowAnimation: function() {
      var _this = this;
      setTimeout(function() {
        _this.showAnimation();
      }, 100);
    },

    getElements: function() {
      this.cards = SelectModel.getElements(parseInt(this.numberCorrectElements), parseInt(this.numberCards));
    },
  },
  created: async function () {
    this.initialize();
    try {
      await SelectModel.populateElements(this.config);
    }
    catch (err) { // need to catch error or rejected promise
      console.error(err);
    }
    this.getElements();
    SelectModel.debug();
    this.initializeCardsElements();
    this.callShowAnimation();
  },
  watch: {
    numOfSelected: function(data) {
      var selectedItem = [],
          _this = this,
          correctItems = document.getElementsByClassName('correct');
      
      if (data > this.numberCorrectElements) {
        selectedItem = document.getElementsByClassName('card-border');
        for (let index = 0; index < selectedItem.length; index++) {
          selectedItem[index].classList.add(this.limitAnimation + '-animation');
        }
        setTimeout(function() {
          for (let index = 0; index < selectedItem.length; index++) {
            selectedItem[index].classList.remove(_this.limitAnimation + '-animation');
          }
        }, 900);
        this.numOfSelected--;
      }

      if (data === this.numberCorrectElements) 
        this.isDisableNext = false;
      
      if (!data && !this.isCompleted && !this.isSubmit || (data != this.numberCorrectElements && !this.isSubmit && !this.isCompleted)) 
        this.isDisableNext = true;

      if(this.isSubmit)
        this.isSubmit = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.select {
  height: 100%;
  display: flex;
  justify-content: normal;
  align-items: center;
  flex-direction: column;

  .footer-container {
    min-height: 90px;
    display: flex;
  }
}
@media (max-width: 1090px) {
  .select {
    .body-container {
      .cards {
        ul {
          li {
            width: 201px;
            height: 103px;
          }
        }
      }
    }
  }
}
</style>
