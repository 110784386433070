import Api from './Api';

const GOOGLE_VOICE_ENDPOINT = process.env.VUE_APP_GOOGLE_VOICE_ENDPOINT;
const GOOGLE_VOICE_KEY = process.env.VUE_APP_GOOGLE_VOICE_KEY;
const AUDIO_ENCODING = process.env.VUE_APP_AUDIO_ENCODING;
const GoogleVoice = {};

GoogleVoice.synthesize = function (text, config) {
    let url = GOOGLE_VOICE_ENDPOINT;
    let params = {"key": GOOGLE_VOICE_KEY};
    let body = {
        "audioConfig": {
            "audioEncoding": AUDIO_ENCODING,
            "pitch": config.pitch,
            "speakingRate": config.speakingRate
        },
        "input": {
            "text": text
        },
        "voice": {
            "languageCode": config.languageCode,
            "name": config.name
        }
    };

    return Api.post(url, body, params);
}

GoogleVoice.getAudioURL = function (text, config) {
    return this.synthesize(text, config).then(resp => {
        let buffer = Buffer.from(resp.body.audioContent, 'base64');
        let blob = new Blob([buffer], {type: 'audio/mpeg'});
        let url = ('webkitURL' in window) ? webkitURL.createObjectURL(blob) : URL.createObjectURL(blob);
        return url;
    });
}

export default GoogleVoice;
