import Vue from 'vue'

export default {
    /**
    * `url` is a string
    * `params` is an object
    * @return a Promise
    */
    get: function (url, params) {
        const config = {params: params};
        return Vue.http.get(url, config);
    },

    /**
    * `url` is a string
    * `body` is an object
    * `params` is an object
    * @return a Promise
    */
    post: function (url, body, params) {
        const config = {params: params};
        return Vue.http.post(url, body, config);
    }
}