var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title",class:{fadeIn: _vm.fadeIn}},[(!_vm.isPlayAgainMode)?_c('div',{staticClass:"title-text"},[((_vm.messageAudioType === 'mp3' && _vm.messageAudioFile && !_vm.isFinal) || (_vm.isFinal && _vm.finalMessageAudioFile))?_c('AudioA',{attrs:{"messageAudioFile":_vm.isFinal ? _vm.finalMessageAudioFile : _vm.messageAudioFile,"classAdded":"title-audio","isDataSound":false,"assetMediaAudioUrl":_vm.assetMediaAudioUrl}}):(_vm.messageAudioType === 'googleVoiceSteps' && !_vm.isFinal)?_c('AudioB',{attrs:{"classAdded":"title-audio","correctWord":_vm.isFinal ? 
        _vm.finalMessage 
      : _vm.isPlayAgainMode ? 
          _vm.playAgainMessage 
        : _vm.message,"audioType":_vm.messageAudioType}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isFinal ? _vm.finalMessage : _vm.isPlayAgainMode ? _vm.playAgainMessage : _vm.message)+" ")],1):_vm._e(),(_vm.isPlayAgainMode)?_c('div',{staticClass:"title-text"},[((_vm.messageAudioType === 'mp3' && _vm.messageAudioFile && !_vm.isFinal) || (_vm.isFinal && _vm.finalMessageAudioFile))?_c('AudioA',{attrs:{"messageAudioFile":_vm.isFinal ? _vm.finalMessageAudioFile : _vm.messageAudioFile,"classAdded":"title-audio","isDataSound":false,"assetMediaAudioUrl":_vm.assetMediaAudioUrl}}):(_vm.messageAudioType === 'googleVoiceSteps' && !_vm.isFinal)?_c('AudioB',{attrs:{"classAdded":"title-audio","correctWord":_vm.isFinal ? 
        _vm.finalMessage 
      : _vm.isPlayAgainMode ? 
          _vm.playAgainMessage 
        : _vm.message,"audioType":_vm.messageAudioType}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isFinal ? _vm.finalMessage : _vm.isPlayAgainMode ? _vm.playAgainMessage : _vm.message)+" ")],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }