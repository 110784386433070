var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-content",style:([
    _vm.backgroundColorCard ? 
      {backgroundColor: _vm.backgroundColorCard}
    : '',
    _vm.sink ?
      {transform: 'scale('+ _vm.setReducePercentage + ')'}
    : '',
    _vm.cardBorderSize ?
      {padding: _vm.cardBorderSize}
    : '',
    !_vm.isShowCard ?
      {transform: 'scale(0)'}
    : '',
    _vm.backgroundDisable || _vm.isCorrect ? 
      {backgroundColor: _vm.backgroundColorDisable}
    : '' ]),on:{"mousedown":_vm.mouseDown,"mouseover":_vm.mouseOver,"mouseout":_vm.mouseOut}},[_c('div',{staticClass:"border",style:([
      {borderColor: _vm.borderColor[1]},
      _vm.cardBorderSize ?
        {borderWidth: _vm.cardBorderSize}
      : '',
      _vm.isCorrect ?
        {boxShadow: 'none'}
      : '' ])}),_c('div',{staticClass:"value",style:([
      _vm.cardContentPosition === 'left' ? 
        {justifyContent: 'flex-start', textAlign: 'left'}
      : '',
      _vm.cardContentPosition === 'right' ? 
        {justifyContent: 'flex-end', textAlign: 'right'}
      : '',
      _vm.cardPaddingHeight ?
        {paddingTop: _vm.cardPaddingHeight, paddingBottom: _vm.cardPaddingHeight}
      : '',
      _vm.cardPaddingWidth ?
        {paddingLeft: _vm.cardPaddingWidth, paddingRight: _vm.cardPaddingWidth}
      : '',
      _vm.cardBorderSize ?
        {borderWidth: _vm.cardBorderSize}
      : '' ])},[(_vm.displayType === 'image')?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],attrs:{"draggable":"false","src":_vm.assetMediaImageUrl + _vm.item[_vm.displaySource]}}):_c('span',[_vm._v(" "+_vm._s(_vm.item[_vm.displaySource])+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }