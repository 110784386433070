<template>
  <div
    class="title"
    v-bind:class="{fadeIn: fadeIn}"
  >
    <div class="title-text" v-if="!isPlayAgainMode">
      <AudioA
        v-if="(messageAudioType === 'mp3' && messageAudioFile && !isFinal) || (isFinal && finalMessageAudioFile)"
        :messageAudioFile="isFinal ? finalMessageAudioFile : messageAudioFile"
        classAdded="title-audio"
        :isDataSound="false"
        :assetMediaAudioUrl="assetMediaAudioUrl"
      >
      </AudioA>
      <AudioB
        v-else-if="messageAudioType === 'googleVoiceSteps' && !isFinal"
        classAdded="title-audio"
        :correctWord="isFinal ? 
          finalMessage 
        : isPlayAgainMode ? 
            playAgainMessage 
          : message"
        :audioType="messageAudioType"
      >
      </AudioB>
      {{
        isFinal ? 
          finalMessage 
        : isPlayAgainMode ? 
            playAgainMessage 
          : message
      }}
    </div>

    <div class="title-text" v-if="isPlayAgainMode">
      <AudioA
        v-if="(messageAudioType === 'mp3' && messageAudioFile && !isFinal) || (isFinal && finalMessageAudioFile)"
        :messageAudioFile="isFinal ? finalMessageAudioFile : messageAudioFile"
        classAdded="title-audio"
        :isDataSound="false"
        :assetMediaAudioUrl="assetMediaAudioUrl"
      >
      </AudioA>
      <AudioB
        v-else-if="messageAudioType === 'googleVoiceSteps' && !isFinal"
        classAdded="title-audio"
        :correctWord="isFinal ? 
          finalMessage 
        : isPlayAgainMode ? 
            playAgainMessage 
          : message"
        :audioType="messageAudioType"
      >
      </AudioB>
      {{
        isFinal ? 
          finalMessage 
        : isPlayAgainMode ? 
            playAgainMessage 
          : message
      }}
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import AudioA from '../components/AudioA'
  import AudioB from '../components/AudioB'
  import GoogleVoice from '../models/GoogleVoice'

  export default {
    name: 'TitleB',
    components: {
      'AudioA': AudioA,
      'AudioB': AudioB
    },
    props: [
      "fadeIn",
      "message",
      "messageGoogleVoice",
      "messageAudioFile",
      "messageAudioType",
      "assetMediaAudioUrl",
      "finalMessage",
      "isFinal",
      "finalMessageAudioFile",
      "tag",
      "isShowTag",
      "isPlayAgainMode",
      "playAgainMessage",
    ],
    data () {
      return {
        myComponent: this.$parent.myComponent,
        myHome: this.$parent.myHome
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.title {
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  .title-text {
    color: #000;
    text-align: center;
    position: relative;
    display: inline-block;
    line-height: 54px;
    animation-name: fadeIn;
    animation-duration: 1s;
  }
  .title-sound {
    margin-left: -2px;
    display: inline-block;
    right: 0;
    width: 73.02px;
    height: 62px;
    position: relative;
    text-align: right;

    > span {
      padding: 12px;
      display: inline-block;
      width: 63px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      text-align: center;
      font-weight: 700;
      line-height: 39px;
      height: 62px;
      margin-right: -10px;
      position: absolute;
      left: 0;
      top: -5px;
      justify-content: center;
      display: inline-flex;
    }
  }
}
</style>
