<template>
  <div class="interactive">
    <NavbarLayout
      :jsonConfig="jsonConfig"
      :theme="theme"
      :weeks="weeks"
      :productName="productName"
      :isRealIpadOrIphone="isRealIpadOrIphone"
      :themeLocation="themeLocation"
      :themeName="themeName"
      :isDocumentReady="isDocumentReady"
    >
    </NavbarLayout>
    <PageLayout
      :theme="theme"
      :backgroundImageSelected="backgroundImageSelected"
      :backgroundImageFinalSelected="backgroundImageFinalSelected"
      :backgroundImageSignature="backgroundImageSignature"
    >
    </PageLayout>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ModelConfig from '../models/Config'
import Util from '../helpers/Util'
import NavbarLayout from '../components/NavbarLayout'
import Navigation from '../models/Navigation'
import PageLayout from '../views/PageLayout'

export default {
  name: 'Page',
  components: {
    'NavbarLayout': NavbarLayout,
    'PageLayout': PageLayout
  },
  data () {
    return {
      myHome: this,
      configName: '',
      isRefresh: true,
      jsonConfig: [],
      weeks: '1',
      applyFinishBackground: false,
      backgroundImageToDisplay: '',
      backgroundImageSelected: '',
      backgroundImageSignature: '',
      backgroundImageFinalSelected: '',
      theme: {},
      isIpadOrIphone: false,
      isRealIpadOrIphone: false,
      productName: this.$route.params.product,
      themeLocation: '',
      themeName: '',
      isHome: (this.$route.name === 'home') ? true : false,
      isDocumentReady: false
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapGetters(['getAppConfig'])
  },
  methods: {
    onResize() {
      var getBodyTag = document.getElementsByTagName("body");
      if(getBodyTag[0].clientWidth <= 1090) {
          getBodyTag[0].classList.add('ipad-version');
          this.isIpadOrIphone = true;
      }
      else {
          this.isIpadOrIphone = false;
          getBodyTag[0].classList.remove('ipad-version');
      }
    },
    getThemeImage: function(imageName) {
      return this.themeLocation + this.themeName + '/' + imageName;
    },
    changeBackground: function(applyBackgroundImageFinal) {
      this.backgroundImageToDisplay = (applyBackgroundImageFinal) ? this.backgroundImageFinalSelected : this.backgroundImageSelected;
    },
    loadTheme: function() {
      this.theme = this.jsonConfig.data.config.theme;

      var bi = new Image();
      var bif = new Image();
      var n = Util.getRandom(this.theme.backgroundImages.length);
      var m = Util.getRandom(this.theme.backgroundImagesFinal.length);

      bi.src = this.theme.themeLocation + this.theme.themeName + '/' + this.theme.backgroundImages[n];
      bif.src = this.theme.themeLocation + this.theme.themeName + '/' + this.theme.backgroundImagesFinal[m];

      this.backgroundImageSelected = this.backgroundImageToDisplay = bi.src;
      this.backgroundImageFinalSelected = bif.src;

      this.themeLocation = this.jsonConfig.data.config.theme.hasOwnProperty('themeLocation') ? this.jsonConfig.data.config.theme.themeLocation : '';
      this.themeName = this.jsonConfig.data.config.theme.hasOwnProperty('themeName') ? this.jsonConfig.data.config.theme.themeName : '';

    },
    loadHome: async function() {
      let appConfig = this.getAppConfig;
      let backgroundImage = new Image();
      let productImage = new Image();

      backgroundImage.src = appConfig.products[this.productName].productBackgroundImageUrl;
      productImage.src = appConfig.products[this.productName].productImageUrl;

      this.backgroundImageSelected = backgroundImage.src;
      this.backgroundImageSignature = productImage.src;
      this.themeLocation = appConfig.products[this.productName].themeLocation;
      this.themeName = appConfig.products[this.productName].themeName;
    }
  },
  created: async function() {
    let userAgent = window.navigator.userAgent,
        getHTMLTag = document.getElementsByTagName("html");

    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        this.isIpadOrIphone = true;
        this.isRealIpadOrIphone = true;
        getHTMLTag[0].classList.add('ipad');
    }
    else if (navigator.platform.indexOf('Mac') > -1) {
        getHTMLTag[0].classList.add('mac');
        this.isRealIpadOrIphone = false;
    }
    else {
        this.isIpadOrIphone = false;
        this.isRealIpadOrIphone = false;
        getHTMLTag[0].classList.remove('ipad');
        getHTMLTag[0].classList.remove('mac');
    }

    if (this.$route.query.s) {
        ModelConfig.setWeeks(this.$route.query.s);
        this.weeks = ModelConfig.getWeeks();
    }

    this.onResize();

    if (!this.isHome) {
      this.configName = ModelConfig.getConfigName();
      this.jsonConfig = ModelConfig.getConfig();
      this.loadTheme();
    }
    else {
      this.loadHome();
    }

    this.isDocumentReady = true;
  }
}
</script>

<style scoped>
  * {
      box-sizing: border-box;
  }
</style>

<style>
    @font-face {
      font-family: "report";
      src:url("../assets/fonts/report-regular.woff2") format("woff2"),
      url("../assets/fonts/report-regular.woff") format("woff"),
      url("../assets/fonts/report-regular.otf") format("opentype");
      font-display: auto;
      font-style: normal;
      font-weight: 400;
      font-stretch: normal;
    }

    @font-face {
      font-family: "report";
      src:url("../assets/fonts/report-bold.woff2") format("woff2"),
      url("../assets/fonts/report-bold.woff") format("woff"),
      url("../assets/fonts/report-bold.otf") format("opentype");
      font-display: auto;
      font-style: normal;
      font-weight: 700;
      font-stretch: normal;
    }

    @font-face {
        font-family: "Open Sans Regular";
        src: url('../assets/fonts/opensans-regular.ttf')  format('truetype');
    }
    @font-face {
        font-family: "Open Sans Semibold";
        src: url('../assets/fonts/opensans-semibold.ttf')  format('truetype');
    }
    @font-face {
        font-family: "Open Sans Bold";
        src: url('../assets/fonts/opensans-bold.ttf')  format('truetype');
    }
    * {
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none; /* Webkit */
        -moz-user-select: none;    /* Firefox */
        -ms-user-select: none;     /* IE 10  */
        /* Currently not supported in Opera but will be soon */
        -o-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    img {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    html {
        font-family: "report";
        font-weight: 400;
        letter-spacing: 1px;
        min-height: 610px;
        min-width: 1024px;

    }
    html.ipad {
        position: fixed;
    }
    .interactive, .right-container {
        display: flex;
    }
    body, #app {
        display: flex;
    }
    body {
        min-width: 1024px;
    }
    .final-background {
        width: 100%;
        position: absolute;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: all 0.5s ease-in-out;
        opacity: 0;
    }
    .finish .final-background {
        opacity: 1;
        transition: opacity .5s ease-in-out;
    }
    .interactive, html, body, #app {
        height: 100%;
        width: 100%;
    }
    .right-container {
        height: 100%;
        width: calc(100% - 50px);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: background 0.5s ease-in-out;
        position: relative;
    }
    .container {
        width: 1280px;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
    }
    img {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
    .ipad .btn {
        width: 182px;
        height: 69px;
        padding-left: 0;
        padding-right: 0;
    }
    .footer-container {
        perspective: 1000px;
    }
    @media (max-width: 1345px) and (min-width: 1091px) {
        .container {
            width: 1024px;
        }
    }
    @media (max-width: 1090px) {
        .container {
            width: 974px;
            transition: 0.6s;
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
</style>
