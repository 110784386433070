export default {
    saveLocalStorage: function(paramData, paraName) {
        localStorage.setItem(paraName, JSON.stringify(paramData));
    },
    getLocalStorage: function(paraName) {
        return JSON.parse(localStorage.getItem(paraName));
    },
    deleteLocalStorage: function(paraName) {
        localStorage.removeItem(paraName);
    },
}
