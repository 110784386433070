<template>
	<div class="ortho">
    <HeaderA
      v-if="isDocumentReady"
      :stepsLength="stepsLength"
      :progressValue="progressValue"
      :isReRotateStars="isReRotateStars"
      :message="message"
      :finalMessage="finalMessage"
      :messageAudioFile="messageAudioFile"
      :messageAudioType="messageAudioType"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isFinal="isFinal"
      :finalMessageAudioFile="finalMessageAudioFile"
    >
    </HeaderA>
    
    <OrthoA
      :isFinal="isFinal"
      :isCorrect="isCorrect"
      :backgroundColorAnswerContainer="backgroundColorAnswerContainer"
      :isAnswerContainerShow="isAnswerContainerShow"
      :isShowCheck="isShowCheck"
      :answerSoundFile="answerSoundFile"
      :answer="answerWord"
      :finalAnswers="answerWords"
      :themeName="themeName"
      :themeLocation="themeLocation"
      :boardBorderColor="boardBorderColor"
      :boardBackgroundColor="boardBackgroundColor"
      :bannerTextColor="bannerTextColor"
      :boardTitle="boardTitle"
      :audioImg="myHome.getThemeImage('audio.svg')"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :finalDisplaySource="finalDisplaySource"
      :isDocumentReady="isDocumentReady"
      :backgroundColorWrong="backgroundColorWrong"
      :correctBorderColor="correctBorderColor"
      :selectedBorderColor="selectedBorderColor"
      :wrongBorderColor="wrongBorderColor"
      :cardBorderColor="cardBorderColor"
      :cardAudioSource="cardAudioSource"
      :cardAudioType="cardAudioType"
      :cardFontColor="cardFontColor"
      :cardFontSize="cardFontSize"
      :cardHeight="cardHeight"
      :cardPaddingTop="cardPaddingTop"
      :cardPaddingBottom="cardPaddingBottom"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :cardWidth="cardWidth"
      :displaySource="displaySource"
      :displayType="displayType"
      :retroBorderSize="retroBorderSize"
      :rotateAnswerContainer="rotateAnswerContainer"
      :keyboardFadeIn="keyboardFadeIn"
      :cardMarginWidth="cardMarginWidth"
    >
    </OrthoA>
    
    <FooterA
      :flipClass="flipClass"
      :isDisabled="isDisabledButton"
      :isDisableClick="isDisableClick"
      :nextText="actionNextText"
      :validateText="actionValidateText"
      :finalActionNextText="finalActionNextText"
      :actionTextColor="myHome.theme.actionTextColor"
      :activeButtonImg="myHome.getThemeImage('bouton-actif.svg')"
      :inactiveButtonImg="myHome.getThemeImage('bouton-inactif.svg')"
      :isFinal="isFinal"
    >
    </FooterA>

	</div>
</template>

<script>
import Vue from 'vue'
import Velocity from 'velocity-animate'
import OrthoModel from '../models/Ortho'
import Util from '../helpers/Util'
import HeaderA from '../components/HeaderA'
import FooterA from '../components/FooterA'
import OrthoA from '../components/OrthoA'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['config', 'week', 'database'],
  components: {
    'HeaderA': HeaderA,
    'FooterA': FooterA,
    'OrthoA': OrthoA
  },
  data () {
    return {
      myHome: this.$parent.myHome,
      myComponent: this,
      isDocumentReady: false,
      audio: false,
      isDisabledButton: true,
      progressValue: 0,
      stepsLength: 0,
      flipClass: false,
      numSteps: 0,
      isDisableClick: false,
      answerWord: '',
      elements: [],
      answerWordArray: [],
      answerWords: [],
      answerSoundFile: '',
      isCorrect: false,
      isShowCheck: false,
      isAnswerContainerShow: false,
      themeName: false,
      themeLocation: false,
      backgroundColorWrong: false,
      isReRotateStars: false,
      isFinal: false,
      assetMediaAudioUrl: '',
      images: '',
      boardBorderColor: false,
      boardBackgroundColor: false,
      bannerTextColor: false,
      finalActionNextText: false,
      finalMessage: false,
      finalType: 'finalc',
      finalMessageAudioFile: false,
      finalDisplaySource: 'value',
      boardTitle: false,
      correctBorderColor: false,
      selectedBorderColor: false,
      wrongBorderColor: false,
      cardBorderColor: false,
      backgroundColorAnswerContainer: false,
      message: '',
      messageAudioFile: false,
      actionNextText: false,
      actionValidateText: false,
      messageAudioType: 'mp3',
      cardAudioSource: false,
      cardAudioType: false,
      cardFontColor: false,
      cardFontSize: false,
      cardHeight: false,
      cardPaddingTop: false,
      cardPaddingBottom: false,
      cardPaddingHeight: false,
      cardPaddingWidth: false,
      cardWidth: false,
      displaySource: false,
      displayType: false,
      retroBorderSize: false,
      rotateAnswerContainer: true,
      keyboardFadeIn: false,
      cardMarginWidth: false,
    }
  },

  mounted() {
    window.onmouseup = function() {
      var clickedKey = document.getElementsByClassName('clicked');
      if(clickedKey.length) {
        clickedKey[0].children[0].classList.remove('hide');
        clickedKey[0].children[1].classList.remove('show');
        clickedKey[0].classList.remove('clicked');
      }
    };
  },

  computed: {
    ...mapGetters(['getOrthoArrayData'])
  },
  methods: {
    ...mapActions([
      'setOrthoArrayData',
      'setOrthoArrayDataByElem',
      'setOrthoArrayDataResetIsSelected'
    ]),

    onInitialize: async function() {
      var _this = this;
      this.answerWord = [];
      this.answerSoundFile = '';
      if (this.config.data.config.hasOwnProperty('theme')) {
        this.themeName = this.config.data.config.theme.hasOwnProperty('themeName') ? this.config.data.config.theme.themeName : false;
        this.themeLocation = this.config.data.config.theme.hasOwnProperty('themeLocation') ? this.config.data.config.theme.themeLocation : false;
        this.backgroundColorWrong = this.config.data.config.theme.hasOwnProperty('backgroundColorWrong') ? this.config.data.config.theme.backgroundColorWrong : false;
        this.boardBorderColor = this.config.data.config.theme.hasOwnProperty('boardBorderColor') ? this.config.data.config.theme.boardBorderColor : false;
        this.boardBackgroundColor = this.config.data.config.theme.hasOwnProperty('boardBackgroundColor') ? this.config.data.config.theme.boardBackgroundColor : false;
        this.bannerTextColor = this.config.data.config.theme.hasOwnProperty('bannerTextColor') ? this.config.data.config.theme.bannerTextColor : false;
        this.correctBorderColor = this.config.data.config.theme.hasOwnProperty('correctBorderColor') ? this.config.data.config.theme.correctBorderColor : false;
        this.selectedBorderColor = this.config.data.config.theme.hasOwnProperty('selectedBorderColor') ? this.config.data.config.theme.selectedBorderColor : false;
        this.wrongBorderColor = this.config.data.config.theme.hasOwnProperty('wrongBorderColor') ? this.config.data.config.theme.wrongBorderColor : false;
        this.cardBorderColor = this.config.data.config.theme.hasOwnProperty('cardBorderColor') ? this.config.data.config.theme.cardBorderColor : false;
        this.backgroundColorAnswerContainer = this.config.data.config.theme.hasOwnProperty('backgroundColorAnswerContainer') ? this.config.data.config.theme.backgroundColorAnswerContainer : false;
      }

      if (this.config.data.config.hasOwnProperty('steps')) {
        this.stepsLength = this.config.data.config.steps.hasOwnProperty('numberSteps') ? parseInt(this.config.data.config.steps.numberSteps) : 0;
        this.message = this.config.data.config.steps.hasOwnProperty('message') ? this.config.data.config.steps.message : '';
        this.messageAudioFile = this.config.data.config.steps.hasOwnProperty('messageAudioFile') ? this.config.data.config.steps.messageAudioFile : false;
        this.actionNextText = this.config.data.config.steps.hasOwnProperty('actionNextText') ? this.config.data.config.steps.actionNextText : false;
        this.actionValidateText = this.config.data.config.steps.hasOwnProperty('actionValidateText') ? this.config.data.config.steps.actionValidateText : false;
        this.messageAudioType = this.config.data.config.steps.hasOwnProperty('messageAudioType') ? this.config.data.config.steps.messageAudioType : 'mp3';
      }

      if (this.config.data.config.hasOwnProperty('final')) {
        this.finalActionNextText = this.config.data.config.final.hasOwnProperty('actionNextText') ? this.config.data.config.final.actionNextText : '';
        this.finalMessage = this.config.data.config.final.hasOwnProperty('message') ? this.config.data.config.final.message : '';
        this.finalType = this.config.data.config.final.hasOwnProperty('type') ? this.config.data.config.final.type : 'finalc';
        this.finalMessageAudioFile = this.config.data.config.final.hasOwnProperty('messageAudioFile') ? this.config.data.config.final.messageAudioFile : '';
        this.boardTitle = this.config.data.config.final.hasOwnProperty('boardTitle') ? this.config.data.config.final.boardTitle : '';
        this.finalDisplaySource = this.config.data.config.final.hasOwnProperty('displaySource') ? this.config.data.config.final.displaySource : 'value';
      }
      
      if (this.config.data.config.hasOwnProperty('layout')) {
        this.cardAudioSource = this.config.data.config.layout.hasOwnProperty('cardAudioSource') ? this.config.data.config.layout.cardAudioSource : false;
        this.cardAudioType = this.config.data.config.layout.hasOwnProperty('cardAudioType') ? this.config.data.config.layout.cardAudioType : false;
        this.cardFontColor = this.config.data.config.layout.hasOwnProperty('cardFontColor') ? this.config.data.config.layout.cardFontColor : false;
        this.cardFontSize = this.config.data.config.layout.hasOwnProperty('cardFontSize') ? this.config.data.config.layout.cardFontSize : false;
        this.cardHeight = this.config.data.config.layout.hasOwnProperty('cardHeight') ? this.config.data.config.layout.cardHeight : false;
        this.cardPaddingTop = this.config.data.config.layout.hasOwnProperty('cardPaddingTop') ? this.config.data.config.layout.cardPaddingTop : false;
        this.cardPaddingBottom = this.config.data.config.layout.hasOwnProperty('cardPaddingBottom') ? this.config.data.config.layout.cardPaddingBottom : false;
        this.cardPaddingHeight = this.config.data.config.layout.hasOwnProperty('cardPaddingHeight') ? this.config.data.config.layout.cardPaddingHeight : false;
        this.cardPaddingWidth = this.config.data.config.layout.hasOwnProperty('cardPaddingWidth') ? this.config.data.config.layout.cardPaddingWidth : false;
        this.cardWidth = this.config.data.config.layout.hasOwnProperty('cardWidth') ? this.config.data.config.layout.cardWidth : false;
        this.displaySource = this.config.data.config.layout.hasOwnProperty('displaySource') ? this.config.data.config.layout.displaySource : false;
        this.displayType = this.config.data.config.layout.hasOwnProperty('displayType') ? this.config.data.config.layout.displayType : false;
        this.retroBorderSize = this.config.data.config.layout.hasOwnProperty('retroBorderSize') ? this.config.data.config.layout.retroBorderSize : false;
        this.cardMarginWidth = this.config.data.config.layout.hasOwnProperty('cardMarginWidth') ? this.config.data.config.layout.cardMarginWidth : false;
      }

      this.assetMediaAudioUrl = this.config.data.config.hasOwnProperty('assetMediaAudioUrl') ? this.config.data.config.assetMediaAudioUrl : '';
      this.assetMediaImageUrl = this.config.data.config.hasOwnProperty('assetMediaImageUrl') ? this.config.data.config.assetMediaImageUrl : '';

      await Util.setWait(function () {
        _this.getElement(true);
      }, 1);
      
      await Util.setWait(function () {
        _this.isAnswerContainerShow = true;
        _this.rotateAnswerContainer = false;
      }, 10);
      await Util.setWait(function () {
        _this.keyboardFadeIn = true;
      }, 600);
      document.getElementsByTagName("body")[0].classList.remove("finish");
    },
    onReset: async function() {
      var _this = this;
      document.getElementsByTagName("body")[0].classList.remove("finish");
      this.keyboardFadeIn = false;
      this.answerWords = [];
      this.elements = [];
      this.getElement(true);
      this.isDisabledButton = true;
      this.progressValue = 0;
      this.isReRotateStars = false;
      this.isFinal = false;
      this.flipClass = false;
      this.numSteps = 0;
      await Util.setWait(function () {
        _this.isAnswerContainerShow = true;
        _this.rotateAnswerContainer = false;
      }, 10);
      await Util.setWait(function () {
        _this.keyboardFadeIn = true;
      }, 600);
    },

    getElement: function(isStart = false) {
      let ctr = 0;
      this.elements = OrthoModel.getElements(1, isStart);
      this.answerSoundFile = this.cardAudioSource ? this.elements[0].element[this.cardAudioSource] : '';
      this.answerWord = this.displaySource ? this.elements[0].element[this.displaySource] : [];
      this.answerWordArray = [];
      for (let index = 0; index < this.answerWord.length; index++) {
        this.answerWordArray.push({
          "value": this.answerWord[index],
          "isSelected": false,
          "isWrong": false,
          "isCorrect": false,
          "isComplete": false,
          "index": this.answerWord[index] != ' ' ? ctr : '',
        });

        ctr = this.answerWord[index] != ' ' ? ctr + 1 : ctr;
      }
      OrthoModel.debug();

      if (this.answerWordArray.length) {
        this.setOrthoArrayData(this.answerWordArray);
        this.setOrthoArrayDataResetIsSelected();
        this.setOrthoArrayDataByElem({
          'index': 0, 
          'element': 'isSelected',
          'value': true
        });
      }
      this.isDocumentReady = true;
    },

    onSubmit: async function() {
      var _this = this,
          answerLetters = document.getElementsByClassName('card'),
          counter = 0,
          timeOut = 0,
          correct = [];
      this.isDisableClick = true;

      for (let index = 0; index < answerLetters.length; index++) {
        if (answerLetters[index].attributes['data'].value.toLowerCase() === answerLetters[index].children[0].children[1].children[0].innerText.toLowerCase()) {
          this.setOrthoArrayDataByElem({
            'index': answerLetters[index].getAttribute('index'), 
            'element': 'isCorrect',
            'value': true
          });
          this.setOrthoArrayDataByElem({
            'index': answerLetters[index].getAttribute('index'), 
            'element': 'isWrong',
            'value': false
          });
        }
        else {
          this.setOrthoArrayDataByElem({
            'index': answerLetters[index].getAttribute('index'), 
            'element': 'isWrong',
            'value': true
          });
          counter++;
        }

        this.setOrthoArrayDataByElem({
          'index': answerLetters[index].getAttribute('index'), 
          'element': 'isSelected',
          'value': false
        });
      }
      timeOut = (counter) ? timeOut = 3000: timeOut = 750;

      await Util.setWait(function () {
        correct = document.getElementsByClassName('correct');
      }, 10);
      
      if (!counter) {
        setTimeout(function() {
          _this.isShowCheck = true;
        }, 200);
        for (let index = 0; index < correct.length; index++) {
          _this.setOrthoArrayDataByElem({
            'index': answerLetters[index].getAttribute('index'), 
            'element': 'isComplete',
            'value': true
          });
        }
        _this.isCorrect = true;
      }
      
      setTimeout(function() {
        var wrong = document.getElementsByClassName('wrong');
        if (this.isCorrect) {
          for (let index = 0; index < correct.length; index++) {
            _this.setOrthoArrayDataByElem({
              'index': answerLetters[index].getAttribute('index'), 
              'element': 'isComplete',
              'value': true
            });
          }
        }
        if (wrong.length) {
          _this.setOrthoArrayDataByElem({
            'index': wrong[0].getAttribute("index"), 
            'element': 'isSelected',
            'value': true
          });
          _this.isDisabledButton = true;

          for (let index = 0; index < answerLetters.length; index++) {
            if (answerLetters[index].classList.contains('wrong')) {
              _this.setOrthoArrayDataByElem({
                'index': answerLetters[index].getAttribute('index'), 
                'element': 'isWrong',
                'value': false
              });
              answerLetters[index].children[0].children[1].children[0].innerHTML = '';
            }
          }
          
          setTimeout(function() {
            _this.isDisableClick = false;
          }, 300);
        }
        else {
          setTimeout(function() {
            _this.progressValue++;

            setTimeout(function() {
              _this.flipClass = true;
              setTimeout(function() {
                _this.isDisableClick = false;
              }, 300);
            }, 1000);

          }, 800);
        }
      }, timeOut);
    },

    rotateBackAnswerContainer() {
      let el = document.getElementsByClassName('answer-container');

      Velocity(el, {
        rotateX: "180deg",
        opacity: 1
      }, { duration: 6 });
    },

    rotateFrontAnswerContainer() {
      let el = document.getElementsByClassName('answer-container');

      Velocity(el, {
        rotateX: "0deg",
      }, { duration: 6 });
    },

    onNext: async function() {
      var _this = this;
      this.numSteps++;

      if (this.numSteps === this.stepsLength) {

        this.rotateAnswerContainer = true;
        this.isAnswerContainerShow = false;
        this.isDisableClick = true;
        await Util.setWait(function () {
          _this.resetAnswerContainer();
          _this.isCorrect = false;
          _this.isShowCheck = false;
        }, 900);

        this.isFinal = true;
        this.isReRotateStars = true;
        this.inStep = this.numSteps;
        this.flipClass = false;
        this.isDisabledButton = true;
        document.getElementsByTagName("body")[0].classList.add("finish");
        await Util.setWait(function () {
          _this.isDisabledButton = false;
        }, 300);
        await Util.setWait(function () {
          _this.isDisableClick = false;
        }, 300);
      }
      else {
        this.rotateAnswerContainer = true;
        this.isAnswerContainerShow = false;
        this.flipClass = false;
        this.isDisableClick = true;
        await Util.setWait(function () {
          _this.isDisabledButton = true;
        }, 100);
        await Util.setWait(function () {
          _this.resetAnswerContainer();
          _this.getElement();
          _this.isCorrect = false;
          _this.isShowCheck = false;
        }, 600);
        
        await Util.setWait(function () {
          _this.isDisableClick = false;
          _this.isAnswerContainerShow = true;
          _this.rotateAnswerContainer = false;
        }, 300);
      }
    },

    resetAnswerContainer: function() {
      var answerLetters = document.getElementsByClassName('card');
      
      this.answerWords.push(
        {
          [this.finalDisplaySource]: this.elements[0].element[this.finalDisplaySource],
          soundFile: this.answerSoundFile
        }
      );

      for (let index = 0; index < answerLetters.length; index++) {
        this.setOrthoArrayDataByElem({
          'index': index, 
          'element': 'isCorrect',
          'value': false
        });
        this.setOrthoArrayDataByElem({
          'index': index, 
          'element': 'isCompleted',
          'value': false
        });
        answerLetters[index].children[0].children[1].children[0].innerHTML = '';
      }
    },
  },

  created: async function() {
    try {
      await OrthoModel.populateElements(this.config);
    }
    catch (err) { // need to catch error or rejected promise
      console.error(err);
    }
    this.onInitialize();
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.ortho {
  height: 100%;
  display: flex;
  justify-content: normal;
  align-items: center;
  flex-direction: column;
}
</style>

