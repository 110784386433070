<template>
  <div 
    class="cards"
  >
    <ul
      :style="[
        cardsLayout === 'row' && numberCards == 2 ?
          {flexDirection: 'column'}
        : '',
        numberCards == 6 || numberCards == 4 ? 
          {display: 'inline-grid', gridTemplateColumns: 'auto auto'}
        : ''
      ]"
    >
      <li 
        class="card"
        v-for="(item, index) in cards"
        :key="index"
        :style="[
          cardWidth ?
            {width: cardWidth} :
            '',
          cardHeight ?
            {height: cardHeight} :
            ''
        ]"
      >
        <CardB
          :displayType="displayType"
          :value="item.element[displaySource]"
          :isCorrectBorder="item.isCorrectBorder"
          :isWrongBorder="item.isWrongBorder"
          :isSelectedBorder="item.isSelectedBorder"
          :numberCorrectElements="numberCorrectElements"
          :numOfSelected="numOfSelected"
          :itemIndex="index"
          :correctBorderColor="correctBorderColor"
          :selectedBorderColor="selectedBorderColor"
          :wrongBorderColor="wrongBorderColor"
          :backgroundColor="backgroundColor"
          :cardBorderColor="cardBorderColor"
          :cardContentPosition="cardContentPosition"
          :retroBorderSize="retroBorderSize"
          :cardFontSize="cardFontSize"
          :cardFontColor="cardFontColor"
          :assetMediaImageUrl="assetMediaImageUrl"
          :cardPaddingWidth="cardPaddingWidth"
          :cardPaddingHeight="cardPaddingHeight"
          :backgroundColorCard="backgroundColorCard"
          :backgroundColorDisable="backgroundColorDisable"
          :backgroundColorWrong="backgroundColorWrong"
          :themeName="themeName"
          :themeLocation="themeLocation"
        >
        </CardB>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import CardB from '../components/CardB'

export default {
  name: 'SelectLayoutA',
  components: {
    'CardB': CardB
  },
  props: [
    "cards",
    "cardsLayout",
    "cardWidth",
    "cardHeight",
    "displayType",
    "displaySource",
    "numberCorrectElements",
    "numOfSelected",
    "correctBorderColor",
    "selectedBorderColor",
    "wrongBorderColor",
    "backgroundColor",
    "cardBorderColor",
    "cardContentPosition",
    "retroBorderSize",
    "cardFontSize",
    "cardFontColor",
    "numberCards",
    "assetMediaImageUrl",
    "cardPaddingWidth",
    "cardPaddingHeight",
    "backgroundColorCard",
    "backgroundColorDisable",
    "backgroundColorWrong",
    "themeName",
    "themeLocation",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.cards {
    display: flex;
    justify-content: center;
    min-height: 380px;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      max-height: 100%;
      width: 100%;
      align-items: center;
      place-content: center;
      justify-content: center;

      li {
          display: inline-block;
          width: 237px;
          height: 121px;
          background-size: 100%;
          background-repeat: no-repeat;
          transition: all 0.6s cubic-bezier(0.33, 0.66, 0.36, 1.19);
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          position: relative;
          font-size: 30px;
          margin: 2px 7px;
          transform: scale(0);
      }
    }
  }
</style>
