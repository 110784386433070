<template>
  <div
    class="right-content"
    :style="[
      isShowRightContent ?
        {width: '582px', maxWidth: '582px', paddingRight: '6px'}
      : '',
      isShowRightContent && speedCounter == null ?
        {transition: 'all 0.6s ease'}
      : '',
      addBoxShadow ?
        {boxShadow: '5px 0px 10px 0px #c6c6c6'}
      : '',
    ]"
  >
    <div class="empty-bar"></div>
    <div class="scroll-content">
      <!-- container of individual theme's activities -->
      <div
        v-for="(item, index) in themes"
        :key="'themeContent' + index"
        class="lessons"
        :id="item.SK1"
      >
        <div
          v-for="(section, sectionIndex) in item.sections"
          :key="'section' + sectionIndex"
          class="group-list"
        >
          <div
            :data-target="'collapse-' + index + '-' + sectionIndex"
            class="collapse-bar"
            v-on:click="$parent.collapseToggle($event, index)"
            :class="{active: !section.active}"
          >
            {{section.categoryValueA}}
            <div class="collapse-arrow"></div>
          </div>
          <vue-slide-up-down
            :active="section.active"
            :id="'collapse-' + index + '-' + sectionIndex"
            class="collapse-content"
            :index-collapse="sectionIndex"
          >
            <div class="collapse-container">
              <template
                v-for="(activity, activityIndex) in section.activities"
              >
                <MenuRightContentActivities
                  :activities="activity"
                  :productName="productName"
                  :addedId="section.SK1 + 'nonSub-list'"
                  :activityIndex="activityIndex"
                  :key="'list' + activityIndex + activity.SK1 + section.SK1 + 'nonSub-list'"
                  :configName="configName"
                >
                </MenuRightContentActivities>
              </template>

              <template
                v-for="(subSection, subSectionsIndex) in section.subSections"
              >
                <div
                  v-if="subSection"
                  class="title list"
                  :key="'title' + subSectionsIndex + subSection.SK1"
                >
                  {{subSection.categoryValueA}}
                </div>
                <template
                  v-for="(activity, activityIndex) in subSection.activities"
                >
                  <MenuRightContentActivities
                    :activities="activity"
                    :productName="productName"
                    :addedId="section.SK1 + subSection.SK1"
                    :activityIndex="activityIndex"
                    :key="'list' + activityIndex + activity.SK1 + section.SK1 + subSection.SK1"
                    :configName="configName"
                  >
                  </MenuRightContentActivities>
                </template>

              </template>
            </div>
          </vue-slide-up-down>
        </div>
      </div>

      <!-- container of all theme's activities -->
      <div
        class="lessons"
        id="all-list"
      >
        <template
          v-for="(section) in sections"
        >
          <template
            v-for="(sectionItem, sectionItemIndex) in section"
          >
            <div
              v-for="(classLvl3, classLvl3Index) in sectionItem"
              :key="'sectionItem-' + classLvl3Index + sectionItem.SK1 + classLvl3.SK1 + classLvl3.now + now"
              class="group-list"
            >
              <div
                :data-target="'collapse-' + 'all-list' + '-' + classLvl3Index"
                class="collapse-bar"
                v-on:click="$parent.collapseToggle($event, classLvl3Index, false)"
                :class="{active: !classLvl3.active}"
                :sectionItemIndex="sectionItemIndex"
              >
                {{classLvl3.categoryValueA}}
                <div class="collapse-arrow"></div>
              </div>
              <vue-slide-up-down
                :active="classLvl3.active"
                :id="'collapse-' + 'all-list' + '-' + classLvl3Index"
                class="collapse-content"
                :index-collapse="classLvl3Index"
              >
                <div class="collapse-container">
                  
                  <template
                    v-for="(activityLvl3, activityIndex) in classLvl3.activities"
                  >
                    <MenuRightContentActivities
                      :activities="activityLvl3"
                      :productName="productName"
                      :addedId="'withoutlvl4lvl5_' + sectionItem.SK1 + classLvl3.SK1"
                      :activityIndex="activityIndex"
                      :key="'list_withoutlvl4lvl5_' + activityIndex + activityLvl3.SK1 + sectionItem.SK1 + classLvl3.SK1"
                      :configName="configName"
                    >
                    </MenuRightContentActivities>
                  </template>

                  <template
                    v-for="(classLvl4, classLvl4Index) in classLvl3.classLvl4"
                  >
                    <div
                      v-if="classLvl4"
                      class="title list"
                      :key="'title' + classLvl4Index + classLvl4.SK1"
                    >
                      {{classLvl4.categoryValueA}}
                    </div>

                    <template
                      v-for="(activityLvl4, activityIndex) in classLvl4.activities"
                    >
                      <MenuRightContentActivities
                        :activities="activityLvl4"
                        :productName="productName"
                        :addedId="sectionItem.SK1 + classLvl3.SK1 + classLvl4.SK1"
                        :activityIndex="activityIndex"
                        :key="'list' + activityIndex + activityLvl4.SK1 + sectionItem.SK1 + classLvl3.SK1 + classLvl4.SK1"
                        :configName="configName"
                      >
                      </MenuRightContentActivities>
                    </template>

                    <template
                      v-for="(classLvl5, classLvl5Index) in classLvl4.classLvl5"
                    >
                      <div
                        class="class-lvl-5 list"
                        :key="'classLvl5' + classLvl5Index + classLvl5.SK1"
                      >
                        {{classLvl5.categoryValueA}}
                      </div>

                      <template
                        v-for="(activityLvl5, activityIndex) in classLvl5.activities"
                      >
                        <MenuRightContentActivities
                          :activities="activityLvl5"
                          :productName="productName"
                          :addedId="sectionItem.SK1 + classLvl3.SK1 + classLvl5.SK1"
                          addedClass="class-lvl-5"
                          :activityIndex="activityIndex"
                          :key="'list_' + activityIndex + activityLvl5.SK1 + sectionItem.SK1 + classLvl3.SK1 + classLvl4.SK1 + classLvl5.SK1"
                          :configName="configName"
                        >
                        </MenuRightContentActivities>
                      </template>

                    </template>
                  </template>
                </div>
              </vue-slide-up-down>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueSlideUpDown from 'vue-slide-up-down'
import MenuRightContentActivities from '../components/MenuRightContentActivities'
import Util from '../helpers/Util'

export default {
  name: 'MenuRightContent',
  components: {
    'MenuRightContentActivities': MenuRightContentActivities,
    'vue-slide-up-down': VueSlideUpDown
  },
  props: [
    "isShowRightContent",
    "speedCounter",
    "addBoxShadow",
    "themes",
    "sections",
    "productName",
    "now",
    "configName"
  ]
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.right-content {
  width: 0;
  max-width: 0;
  transition: all 0.45s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .empty-bar {
    min-height: 52px;
    width: 100%;
  }

  .scroll-content {
    overflow: auto;
    width: 575px;
    height: 100%;

    .lessons {
      width: 100%;
      height: auto;
      padding-left: 13px;
      padding-right: 7px;
      display: none;
      opacity: 0;
      transition: opacity 0.3s ease;

      &.show {
        display: block;
        opacity: 1;
      }

      .group-list {

        .collapse-bar {
          position: relative;
          height: 56px;
          font-size: 19px;
          font-family: "Open Sans Bold";
          font-weight: bold;
          background-color: #e6e6e6;
          cursor: pointer;
          padding: 20px 13px 13px 13px;
          color: #45454E;
          display: flex;
          align-items: center;

          &.active {

            .collapse-arrow {
              &:after {
                border-width: 11px 10px 0 10px;
              }
            }
          }

          .collapse-arrow {
            pointer-events: none;

            &:after {
              content: "";
              width: 0;
              height: 0;
              position: absolute;
              right: 16px;
              top: 50%;
              margin-top: -3px;
              border-width: 0 10px 10px 10px;
              border-style: solid;
              border-color: grey transparent;
            }
          }
        }

        .collapse-content {
          padding-left: 8px;
          font-family: "Open Sans Semibold";
          font-size: 18px;
          padding-top: 12px;
          transition: all 0.45s ease-out;

          .collapse-container {
            top: -8px;
            position: relative;
          }

          .list {
            display: flex;
            height: 40px;
            border-bottom: solid 1px #d9d9d9;
            padding-bottom: 1px;
            align-items: center;
            padding-left: 7px;

            &.class-lvl-5 {
              color: #45454E;
              font-size: 18px;
              font-family: "Open Sans Regular";
              padding-left: 25px;
            }

            &.item {
              color: #1A85BC;
              font-family: "Open Sans Semibold";

              &[active="true"] {
                background: #fae56b;
              }
            }

            &:last-of-type {
              border: none;
            }

            .order {
              width: 74px;
            }

            .text {
              a {
                text-decoration: none;
                color: inherit;
              }
            }
          }

          .title {
            color: #45454E;
            font-family: "Open Sans Semibold";
          }
        }
      }
    }
  }
}
</style>
