<template>
  <div class="body-container">
    <OrthoLayoutA
      v-if="!isFinal"
      :isFinal="isFinal"
      :isCorrect="isCorrect"
      :backgroundColorAnswerContainer="backgroundColorAnswerContainer"
      :isAnswerContainerShow="isAnswerContainerShow"
      :isShowCheck="isShowCheck"
      :answerSoundFile="answerSoundFile"
      :answer="answer"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isDocumentReady="isDocumentReady"
      :backgroundColorWrong="backgroundColorWrong"
      :correctBorderColor="correctBorderColor"
      :selectedBorderColor="selectedBorderColor"
      :wrongBorderColor="wrongBorderColor"
      :cardBorderColor="cardBorderColor"
      :cardAudioSource="cardAudioSource"
      :cardAudioType="cardAudioType"
      :cardFontColor="cardFontColor"
      :cardFontSize="cardFontSize"
      :cardHeight="cardHeight"
      :cardPaddingTop="cardPaddingTop"
      :cardPaddingBottom="cardPaddingBottom"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :cardWidth="cardWidth"
      :displaySource="displaySource"
      :displayType="displayType"
      :retroBorderSize="retroBorderSize"
      :rotateAnswerContainer="rotateAnswerContainer"
      :keyboardFadeIn="keyboardFadeIn"
      :cardMarginWidth="cardMarginWidth"
    >
    </OrthoLayoutA>
    
    <FinalC
      v-if="isFinal"
      :themeName="themeName"
      :themeLocation="themeLocation"
      :finalBoardAnswers="finalAnswers"
      :boardBorderColor="boardBorderColor"
      :boardBackgroundColor="boardBackgroundColor"
      :bannerTextColor="bannerTextColor"
      :boardTitle="boardTitle"
      :audioImg="audioImg"
      :cardAudioSource="'soundFile'"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :cardAudioType="cardAudioType"
      :bannerImg1="myHome.getThemeImage('banner-2-col.svg')"
      :bannerImg2="myHome.getThemeImage('banner.svg')"
      :finalDisplaySource="finalDisplaySource"
    >
    </FinalC>
  </div>
</template>

<script>
import Vue from 'vue'
import OrthoLayoutA from '../components/OrthoLayoutA'
import FinalC from '../components/FinalC'

export default {
  name: 'OrthoA',
  components: {
    'FinalC': FinalC,
    'OrthoLayoutA': OrthoLayoutA,
  },
  props: [
    "isFinal",
    "isCorrect",
    "backgroundColorAnswerContainer",
    "isAnswerContainerShow",
    "isShowCheck",
    "answerSoundFile",
    "answer",
    "finalAnswers",
    "themeName",
    "themeLocation",
    "boardBorderColor",
    "boardBackgroundColor",
    "bannerTextColor",
    "boardTitle",
    "audioImg",
    "assetMediaAudioUrl",
    "finalDisplaySource",
    "isDocumentReady",
    "backgroundColorWrong",
    "correctBorderColor",
    "selectedBorderColor",
    "wrongBorderColor",
    "cardBorderColor",
    "cardAudioSource",
    "cardAudioType",
    "cardFontColor",
    "cardFontSize",
    "cardHeight",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "cardWidth",
    "displaySource",
    "displayType",
    "retroBorderSize",
    "rotateAnswerContainer",
    "keyboardFadeIn",
    "cardMarginWidth",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.body-container {
  min-height: 340px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
