<template>
  <div
    class="title"
    v-bind:class="{fadeIn: fadeIn}"
  >
    <div class="title-text"
    >
      <AudioA
        v-if="(messageAudioType === 'mp3' && messageAudioFile) || (isFinal && finalMessageAudioFile)"
        :messageAudioFile="isFinal ? finalMessageAudioFile : messageAudioFile"
        classAdded="title-audio"
        :isDataSound="false"
        :assetMediaAudioUrl="assetMediaAudioUrl"
      >
      </AudioA>
      <AudioB
        v-else-if="messageAudioType === 'googleVoiceSteps' && !isFinal"
        classAdded="title-audio"
        :correctWord="isFinal ? finalMessage : message"
        :audioType="messageAudioType"
      >
      </AudioB>
      {{isFinal ? finalMessage : message}}
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import AudioA from '../components/AudioA'
  import AudioB from '../components/AudioB'
  import GoogleVoice from '../models/GoogleVoice'

  export default {
    name: 'TitleA',
    components: {
      'AudioA': AudioA,
      'AudioB': AudioB
    },
    props: [
      "fadeIn",
      "message",
      "messageAudioFile",
      "messageAudioType",
      "assetMediaAudioUrl",
      "finalMessage",
      "isFinal",
      "finalMessageAudioFile",
    ],
    data () {
      return {
        myComponent: this.$parent.myComponent,
        myHome: this.$parent.myHome
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.title {
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  .title-text {
    color: #000;
    text-align: center;
    position: relative;
    display: inline-block;
    line-height: 54px;
  }
}
</style>
