<template>
  <div
    class="item list"
    :class="[(addedClass ? addedClass : ''), {active: activities.configName === configName}]"
    :key="'list' + activityIndex + activities.SK1 + addedId"
    :id="'list' + activityIndex + activities.SK1 + addedId"
  >
    <div
      class="order"
      :key="'order' + activityIndex + activities.SK1"
    >
      {{activities.navActivityLinkTextPrefix}}
    </div>
    <div
      class="text"
      :key="'text' + activityIndex + activities.SK1"
    >
      <a
        :href="getLink()"
        :key="'link' + activityIndex + activities.SK1"
        @click="navigate($event)"
      >
        {{activities.navActivityLinkText}}
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Util from '../helpers/Util'

export default {
  name: 'MenuRightContentActivities',
  props: [
    "activities",
    "productName",
    "addedId",
    "addedClass",
    "activityIndex",
    "configName"
  ],
  methods: {
    getLink (includeRouterBaseUrl = true) {
      let link = this.productName + '/' + this.activities.template + '/' + this.activities.configName;
      let queryString = this.activities.paramWeekSection;

      link = (includeRouterBaseUrl) ? Util.getRouterBaseUrl() + link : '/' + link;

      if (queryString)
        link += '?s=' + queryString;

      return link;
    },
    navigate (event) {
      event.preventDefault();
      let link = this.getLink(false);
      this.$router.push(link);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";


.active {
  background: #fae56b;
}

.list {
  display: flex;
  height: 40px;
  border-bottom: solid 2px #f5f5f5;
  align-items: center;
  padding-left: 3px;

  &:last-of-type {
    border: none;
  }

  .order {
    width: 73px;
  }

  .text {
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
</style>
