<template>
  <div class="body-container">
    <div
      class="cards fadeIn"
      v-if="!secondFinalPage"
    >
      <div
        class="cards-pair"
        :class="{'default-cursor': preventClickCard, 'final-col1': (!formatTwoCol && isFinished)}"
      >
          <MemoLayout
            :container               ="isLayoutActive?
                                        formatTwoCol ?
                                          'left-card'
                                        : 'merged-card'
                                      : 'left-card'"
            showCards               ="show-left"
            :isShowCards            ="showCardsLeft"
            :collectionCards        ="getMemoLeftArrayData"
            :pairingSource          ="valueSource"
            cardType                ="left"
            cardBindID              ="set1"
            hideDisable             ="hide-disable"
            :isShowDisabled          ="showDisabled"
            flip                    ="leftflip"
            :startUpDisplayCards    ="startupDisplayLeftCards"
            :cardDataTarget         =" isLayoutActive?
                                        formatTwoCol ?
                                          'leftcard'
                                        : 'mergedcard'
                                      : 'leftcard'"
            :cssColor               ="myHome.theme.cardIdentifierTextColor"
            :cssBGColorA            ="leftBGColorA"
            :cssBorderColorA        ="leftBorderColorA"
            :frontIMG               ="myHome.getThemeImage('carte.png')"
            :cardIdentifiers        ="leftCardIdentifiers"
            :cssBackCardColor       ="textColorLeft"
            :isFinished             ="isFinished"
            :cssBGColorB            ="leftBGColorB"
            :cssBGColorOptionB      ="leftBGColorB"
            :cssBorderColorB        ="(!formatTwoCol && isFinished) ? leftBorderColorB : rightBorderColorB"
            :cssBorderColorOptionB  ="leftBorderColorB"
            :cssBorderDefaultColor  ="leftBorderColorB"
            :cssBGColorDisable      ="leftBGColorC"
            :cssBorderColorDisable  ="leftBorderColorC"
            :assetMediaImageUrl     ="assetMediaImageUrl"
            :preventClickCard       ="preventClickCard"
            :formatTwoCol           ="formatTwoCol"
            :themeName              ="themeName"
            :themeLocation          ="themeLocation"
            :isCardClickable        ="isCardClickable"
            :displaySource          ="colOneDisplaySource"
            :displayType            ="colOneDisplayType"
            :cardFontColor          ="colOneCardFontColor"
            :cardFontSize           ="colOneCardFontSize"
            :backgroundColorWrong   ="backgroundColorWrong"
            :finalFirstDisplaySource    ="colOneDisplaySource"
            :finalFirstDisplayType      ="colOneDisplayType"
            :finalFirstCardFontColor    ="colOneCardFontColor"
            :finalFirstCardFontSize     ="colOneCardFontSize"
            :finalSecondDisplaySource    ="(!formatTwoCol && isFinished) ? colOneDisplaySource : colTwoDisplaySource"
            :finalSecondDisplayType      ="(!formatTwoCol && isFinished) ? colOneDisplayType : colTwoDisplayType"
            :finalSecondCardFontColor    ="(!formatTwoCol && isFinished) ? colOneCardFontColor : colTwoCardFontColor"
            :finalSecondCardFontSize     ="(!formatTwoCol && isFinished) ? colOneCardFontSize : colTwoCardFontSize"
            :wrongBorderColor            ="wrongBorderColor"
            :selectedBorderColor         ="selectedBorderColor"
            :correctBorderColor          ="correctBorderColor"
            :cardHeight                  ="colOneCardHeight"
            :cardWidth                   ="colOneCardWidth"
            :cardPaddingTop              ="colOneCardPaddingTop"
            :cardPaddingBottom           ="colOneCardPaddingBottom"
            :cardPaddingWidth            ="colOneCardPaddingWidth"
            :retroBorderSize             ="colOneRetroBorderSize"
            :finalCardHeightLeft         ="colOneCardHeight"
            :finalCardWidthLeft          ="colOneCardWidth"
            :finalCardPaddingTopLeft     ="colOneCardPaddingTop"
            :finalCardPaddingBottomLeft  ="colOneCardPaddingBottom"
            :finalCardPaddingWidthLeft   ="colOneCardPaddingWidth"
            :finalCardContentPositionLeft="colOneCardContentPosition"
            :finalRetroBorderSizeLeft    ="colOneRetroBorderSize"
            :finalCardHeightRight        ="colTwoCardHeight"
            :finalCardWidthRight         ="colTwoCardWidth"
            :finalCardPaddingTopRight    ="colTwoCardPaddingTop"
            :finalCardPaddingBottomRight ="colTwoCardPaddingBottom"
            :finalCardPaddingWidthRight  ="colTwoCardPaddingWidth"
            :finalCardContentPositionRight="colTwoCardContentPosition"
            :finalRetroBorderSizeRight   ="colTwoRetroBorderSize"
            :isFinal                     ="isFinal"
            :cardContentPosition         ="colOneCardContentPosition"
            :rotateCard                  ="rotateCard"
            :finalFirstBGColorFinal      ="leftBGColorFinal"
            :finalFirstBorderColorFinal  ="leftBorderColorFinal"
            :finalSecondBGColorFinal     ="(!formatTwoCol && isFinished) ? leftBGColorFinal : rightBGColorFinal"
            :finalSecondBorderColorFinal ="(!formatTwoCol && isFinished) ? leftBorderColorFinal : rightBorderColorFinal"
          >
          </MemoLayout>

          <div v-if="formatTwoCol" class="separator"></div>

          <MemoLayout
            v-if                    ="formatTwoCol || !isLayoutActive || (!formatTwoCol && isFinished)"
            :container               ="isLayoutActive?
                                        formatTwoCol ?
                                          'right-card'
                                        : 'merged-card'
                                      : 'right-card'"
            showCards               ="show-right"
            :isShowCards            ="showCardsRight"
            :collectionCards        ="getMemoRightArrayData"
            :pairingSource          ="valueSource"
            cardType                ="right"
            cardBindID              ="set2"
            hideDisable             ="hide-disable"
            :isShowDisabled          ="showDisabled"
            flip                    ="rightflip"
            :startUpDisplayCards    ="startupDisplayRightCards"
            cardDataTarget          ="rightcard"
            :cssColor               ="myHome.theme.cardIdentifierTextColor"
            :cssBGColorA            ="rightBGColorA"
            :cssBorderColorA        ="rightBorderColorA"
            :frontIMG               ="myHome.getThemeImage('carte.png')"
            :cardIdentifiers        ="rightCardIdentifiers"
            :cssBackCardColor       ="textColorRight"
            :isFinished             ="isFinished"
            :cssBGColorB            ="(!formatTwoCol && isFinished) ? leftBGColorB : rightBGColorB"
            :cssBGColorOptionB      ="rightBGColorB"
            :cssBorderColorB        ="(!formatTwoCol && isFinished) ? leftBorderColorB : rightBorderColorB"
            :cssBorderColorOptionB  ="leftBorderColorB"
            :cssBorderDefaultColor  ="rightBorderColorB"
            :cssBGColorDisable      ="rightBGColorC"
            :cssBorderColorDisable  ="rightBorderColorC"
            :assetMediaImageUrl     ="assetMediaImageUrl"
            :preventClickCard       ="preventClickCard"
            :formatTwoCol           ="formatTwoCol"
            :themeName              ="themeName"
            :themeLocation          ="themeLocation"
            :isCardClickable        ="isCardClickable"
            :displaySource          ="(!formatTwoCol && isFinished) ? colOneDisplaySource : colTwoDisplaySource"
            :displayType            ="(!formatTwoCol && isFinished) ? colOneDisplayType : colTwoDisplayType"
            :cardFontColor          ="(!formatTwoCol && isFinished) ? colOneCardFontColor : colTwoCardFontColor"
            :cardFontSize           ="(!formatTwoCol && isFinished) ? colOneCardFontSize : colTwoCardFontSize"
            :backgroundColorWrong   ="backgroundColorWrong"
            :finalFirstDisplaySource    ="colOneDisplaySource"
            :finalFirstDisplayType      ="colOneDisplayType"
            :finalFirstCardFontColor    ="colOneCardFontColor"
            :finalFirstCardFontSize     ="colOneCardFontSize"
            :finalSecondDisplaySource    ="(!formatTwoCol && isFinished) ? colOneDisplaySource : colTwoDisplaySource"
            :finalSecondDisplayType      ="(!formatTwoCol && isFinished) ? colOneDisplayType : colTwoDisplayType"
            :finalSecondCardFontColor    ="(!formatTwoCol && isFinished) ? colOneCardFontColor : colTwoCardFontColor"
            :finalSecondCardFontSize     ="(!formatTwoCol && isFinished) ? colOneCardFontSize : colTwoCardFontSize"
            :wrongBorderColor            ="wrongBorderColor"
            :selectedBorderColor         ="selectedBorderColor"
            :correctBorderColor          ="correctBorderColor"
            :cardHeight                  ="(!formatTwoCol && isFinished) ? colOneCardHeight : colTwoCardHeight"
            :cardWidth                   ="(!formatTwoCol && isFinished) ? colOneCardWidth : colTwoCardWidth"
            :cardPaddingTop              ="(!formatTwoCol && isFinished) ? colOneCardPaddingTop : colTwoCardPaddingTop"
            :cardPaddingBottom           ="(!formatTwoCol && isFinished) ? colOneCardPaddingBottom : colTwoCardPaddingBottom"
            :cardPaddingWidth            ="(!formatTwoCol && isFinished) ? colOneCardPaddingWidth : colTwoCardPaddingWidth"
            :retroBorderSize             ="(!formatTwoCol && isFinished) ? colOneRetroBorderSize : colTwoRetroBorderSize"
            :finalCardHeightLeft         ="colOneCardHeight"
            :finalCardWidthLeft          ="colOneCardWidth"
            :finalCardPaddingTopLeft     ="colOneCardPaddingTop"
            :finalCardPaddingBottomLeft  ="colOneCardPaddingBottom"
            :finalCardPaddingWidthLeft   ="colOneCardPaddingWidth"
            :finalCardContentPositionLeft="colOneCardContentPosition"
            :finalRetroBorderSizeLeft    ="colOneRetroBorderSize"
            :finalCardHeightRight        ="colTwoCardHeight"
            :finalCardWidthRight         ="colTwoCardWidth"
            :finalCardPaddingTopRight    ="colTwoCardPaddingTop"
            :finalCardPaddingBottomRight ="colTwoCardPaddingBottom"
            :finalCardPaddingWidthRight  ="colTwoCardPaddingWidth"
            :finalCardContentPositionRight="colTwoCardContentPosition"
            :finalRetroBorderSizeRight   ="colTwoRetroBorderSize"
            :isFinal                     ="isFinal"
            :cardContentPosition         ="(!formatTwoCol && isFinished) ? colOneCardContentPosition : colTwoCardContentPosition"
            :rotateCard                  ="rotateCard"
            :finalFirstBGColorFinal      ="leftBGColorFinal"
            :finalFirstBorderColorFinal  ="leftBorderColorFinal"
            :finalSecondBGColorFinal     ="(!formatTwoCol && isFinished) ? leftBGColorFinal : rightBGColorFinal"
            :finalSecondBorderColorFinal ="(!formatTwoCol && isFinished) ? leftBorderColorFinal : rightBorderColorFinal"
          >
          </MemoLayout>
      </div>
    </div>

    <FinalC
      v-if="secondFinalPage"
      :themeName="themeName"
      :themeLocation="themeLocation"
      :finalBoardAnswers="collectionCorrectCards"
      :boardBorderColor="boardBorderColor"
      :boardBackgroundColor="boardBackgroundColor"
      :bannerTextColor="bannerTextColor"
      :boardTitle="boardTitle"
      :audioImg="audioImg"
      :cardAudioSource="cardAudioSource"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :cardAudioType="cardAudioType"
      :bannerImg1="myHome.getThemeImage('banner-2-col.svg')"
      :bannerImg2="myHome.getThemeImage('banner.svg')"
      :finalDisplaySource="finalDisplaySource"
    >
    </FinalC>
  </div>
</template>

<script>
import Vue from 'vue'
import MemoLayout from '../components/MemoLayout'
import FinalC from '../components/FinalC'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MemoA',
  components: {
    'MemoLayout': MemoLayout,
    'FinalC': FinalC
  },
  props: [
    "secondFinalPage",
    "preventClickCard",
    "formatTwoCol",
    "isFinished",
    "isLayoutActive",
    "showCardsLeft",
    "collectionCards1",
    "showDisabled",
    "startupDisplayLeftCards",
    "leftBGColorA",
    "leftBorderColorA",
    "leftCardIdentifiers",
    "textColorLeft",
    "leftBGColorB",
    "rightBorderColorB",
    "leftBorderColorB",
    "isCardClickable",
    "colOneDisplaySource",
    "colOneDisplayType",
    "colOneCardFontColor",
    "colOneCardFontSize",
    "colOneCardAudioSource",
    "colOneCardAudioType",
    "colOneCardHeight",
    "colOneCardPaddingTop",
    "colOneCardPaddingBottom",
    "colOneCardPaddingHeight",
    "colOneCardPaddingWidth",
    "colOneCardWidth",
    "colOneRetroBorderSize",
    "colTwoDisplaySource",
    "colTwoDisplayType",
    "colTwoCardFontColor",
    "colTwoCardFontSize",
    "colTwoCardAudioSource",
    "colTwoCardAudioType",
    "colTwoCardHeight",
    "colTwoCardPaddingTop",
    "colTwoCardPaddingBottom",
    "colTwoCardPaddingHeight",
    "colTwoCardPaddingWidth",
    "colTwoCardWidth",
    "colTwoRetroBorderSize",
    "backgroundColorWrong",
    "themeName",
    "themeLocation",
    "collectionCorrectCards",
    "boardBorderColor",
    "boardBackgroundColor",
    "bannerTextColor",
    "boardTitle",
    "audioImg",
    "cardAudioSource",
    "assetMediaAudioUrl",
    "cardAudioType",
    "finalDisplaySource",
    "assetMediaImageUrl",
    "showCardsRight",
    "collectionCards2",
    "startupDisplayRightCards",
    "rightBGColorA",
    "rightBorderColorA",
    "rightCardIdentifiers",
    "textColorRight",
    "rightBGColorB",
    "isFinal",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "leftBGColorC",
    "leftBorderColorC",
    "rightBGColorC",
    "rightBorderColorC",
    "colOneCardContentPosition",
    "colTwoCardContentPosition",
    "rotateCard",
    "leftBGColorFinal",
    "leftBorderColorFinal",
    "rightBGColorFinal",
    "rightBorderColorFinal",
    "valueSource",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
  computed: {
    ...mapGetters(['getMemoLeftArrayData', 'getMemoRightArrayData'])
  },
  methods: {
    ...mapActions([ 
      'setMemoLeftArrayData',
      'setMemoLeftArrayDataByElem',
      'setMemoLeftArrayDataResetIsSelected',
      'setMemoRightArrayData',
      'setMemoRightArrayDataByElem',
      'setMemoRightArrayDataResetIsSelected'
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.body-container {
  min-height: 380px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .cards {
    display: flex;
    justify-content: center;
    min-height: 380px;
    align-items: center;
    width: 100%;

    .cards-pair {
      display: flex;
      width: 100%;
      padding-top: 5px;
      perspective: 1000px;
      justify-content: center;

      .separator {
        width: 1px;
        border: 3px solid #fff;
        display: flex;
        position: absolute;
        border-radius: 3px;
        animation-name: expand;
        animation-delay: 0s;
        animation-duration: 1.6s; 
        max-height: calc(100% - 17px);
        height: 100%;
      }
    }
  }
}

@keyframes expand {
  0% {
    height: 0;
  }
  10% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

</style>
