import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const Ortho = Object.create(Base);

Ortho.correctBlacklist = [];
Ortho.correctElements = new Map();

Ortho.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.correctBlacklist, "correct_blacklist");
    LocalStorage.saveLocalStorage(this.correctElements, "correct_elements");
}

Ortho.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.correctBlacklist);
    this.correctElements.clear();

    var config = this.configuration.data.config;
    var valueSource = config.valueSource;
    this.database = await this.populateCollections(config.collections);

    for (var element of this.database)
        this.setMapValue(this.correctElements, element, valueSource);
}

Ortho.getElements = function (n, reset) {
    if (n < 0)
        throw new Error("Negative argument is invalid.");

    var elements = this.fetchElements(n, this.correctElements, this.correctBlacklist);

    if (reset)
        Util.clearElements(this.currentBlacklist);

    var beginning = this.correctBlacklist.length - elements.length;
    var current = this.correctBlacklist.slice(beginning);
    Util.addElements(this.currentBlacklist, current);

    Util.clearElements(this.currentElements);
    Util.addElements(this.currentElements, elements);

    return this.currentElements;
}

export default Ortho;
