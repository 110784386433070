<template>
  <div class="body-container">
    <AssoBLayoutA
      ref                     ="assoLayout"  
      :isFinish               ="isFinish"
      :isTwoWheels            ="isTwoWheels"
      :templateName           ="templateName"
      :templateAssoA          ="templateAssoA"
      :templateAssoB          ="templateAssoB"
      :templateAssoC          ="templateAssoC"
      :wheelOneItem           ="wheelOneItem"
      :wheelOneIsCrit         ="wheelOneIsCrit"
      :wheelOneCSSData        ="wheelOneCSSData"
      :buttonFinishStyle      ="buttonFinishStyle"
      :wheelOneDisplayed      ="wheelOneDisplayed"
      :wheelThemeColorBorder  ="wheelThemeColorBorder"
      :wheelOneIsButtonDisable="wheelOneIsButtonDisable"
      :wheelOneDisplayType    ="wheelOneDisplayType"
      :wheelOneDisplaySource  ="wheelOneDisplaySource"
      :wheelOneThemeImage     ="wheelOneThemeImage"
      :wheelOneIsDisable      ="wheelOneIsDisable"
      :tagImage               ="tagImage"
      :buttonUp               ="buttonUp"
      :buttonUpDisable        ="buttonUpDisable"
      :buttonDown             ="buttonDown"
      :buttonDownDisable      ="buttonDownDisable"
      :background             ="background"
      :overlay                ="overlay"
      :removeAllBorders       ="removeAllBorders"
      :wheelOnePreviousText   ="wheelOnePreviousText"
      :wheelOneCardFontColor      ="wheelOneCardFontColor"
      :wheelOneCardFontSize       ="wheelOneCardFontSize"
      :wheelOneCardPaddingTop     ="wheelOneCardPaddingTop"
      :wheelOneCardPaddingBottom  ="wheelOneCardPaddingBottom"
      :wheelOneCardPaddingWidth   ="wheelOneCardPaddingWidth"
      :wheelOneCardWidth          ="wheelOneCardWidth"
      :wheelOneCardHeight         ="wheelOneCardHeight"
      :wheelOneRetroBorderSize    ="wheelOneRetroBorderSize"
      :wheelOneCardContentPosition ="wheelOneCardContentPosition"
      :themeLocation          ="themeLocation"
      :themeName              ="themeName"
      :isFinal                ="isFinal"
      :wheelTwoItem           ="wheelTwoItem"
      :wheelTwoIsCrit         ="wheelTwoIsCrit"
      :wheelTwoCSSData        ="wheelTwoCSSData"
      :wheelTwoDisplayed      ="wheelTwoDisplayed"
      :wheelTwoIsButtonDisable="wheelTwoIsButtonDisable"
      :wheelTwoDisplayType    ="wheelTwoDisplayType"
      :wheelTwoDisplaySource  ="wheelTwoDisplaySource"
      :wheelTwoThemeImage     ="wheelTwoThemeImage"
      :wheelTwoIsDisable      ="wheelTwoIsDisable"
      :wheelTwoPreviousText   ="wheelTwoPreviousText"
      :wheelTwoCardFontColor      ="wheelTwoCardFontColor"
      :wheelTwoCardFontSize       ="wheelTwoCardFontSize"
      :wheelTwoCardPaddingTop     ="wheelTwoCardPaddingTop"
      :wheelTwoCardPaddingBottom  ="wheelTwoCardPaddingBottom"
      :wheelTwoCardPaddingWidth   ="wheelTwoCardPaddingWidth"
      :wheelTwoCardWidth          ="wheelTwoCardWidth"
      :wheelTwoCardHeight         ="wheelTwoCardHeight"
      :wheelTwoRetroBorderSize    ="wheelTwoRetroBorderSize"
      :wheelTwoCardContentPosition ="wheelTwoCardContentPosition"
      :wheelThreeItem         ="wheelThreeItem"
      :wheelThreeIsCrit       ="wheelThreeIsCrit"
      :wheelThreeCSSData      ="wheelThreeCSSData"
      :wheelThreeDisplayed    ="wheelThreeDisplayed"
      :wheelThreeIsButtonDisable="wheelThreeIsButtonDisable"
      :wheelThreeDisplayType    ="wheelThreeDisplayType"
      :wheelThreeDisplaySource  ="wheelThreeDisplaySource"
      :wheelThreeThemeImage     ="wheelThreeThemeImage"
      :wheelThreeIsDisable      ="wheelThreeIsDisable"
      :wheelThreePreviousText   ="wheelThreePreviousText"
      :wheelThreeCardFontColor    ="wheelThreeCardFontColor"
      :wheelThreeCardFontSize     ="wheelThreeCardFontSize"
      :wheelThreeCardPaddingTop   ="wheelThreeCardPaddingTop"
      :wheelThreeCardPaddingBottom ="wheelThreeCardPaddingBottom"
      :wheelThreeCardPaddingWidth  ="wheelThreeCardPaddingWidth"
      :wheelThreeCardWidth         ="wheelThreeCardWidth"
      :wheelThreeCardHeight        ="wheelThreeCardHeight"
      :wheelThreeRetroBorderSize   ="wheelThreeRetroBorderSize"
      :wheelThreeCardContentPosition ="wheelThreeCardContentPosition"
      :assetMediaImageUrl           ="assetMediaImageUrl"
      :wrongBorderColor             ="wrongBorderColor"
      :selectedBorderColor          ="selectedBorderColor"
      :correctBorderColor           ="correctBorderColor"
      :backgroundColorWrong         ="backgroundColorWrong"
      :wheelBorderColor             ="wheelBorderColor"
      :wheelDisableBackgroundColor  ="wheelDisableBackgroundColor"
      :wheelDisableElementOpacity   ="wheelDisableElementOpacity"
      :valueSource                  ="valueSource"
      :numberOfDisable              ="numberOfDisable"
      :wheelFadeData                ="wheelFadeData"
    >
    </AssoBLayoutA>
  </div>
</template>

<script>
import Vue from 'vue'
import AssoBLayoutA from '../components/AssoBLayoutA'
import Velocity from 'velocity-animate'

export default {
  name: 'AssoB',
  components: {
    'AssoBLayoutA': AssoBLayoutA,
  },
  props: [
    "isFinish",
    "isTwoWheels",
    "templateName",
    "templateAssoA",
    "templateAssoB",
    "templateAssoC",
    "wheelOneItem",
    "wheelOneIsCrit",
    "wheelOneCSSData",
    "buttonFinishStyle",
    "wheelOneDisplayed",
    "wheelThemeColorBorder",
    "wheelOneIsButtonDisable",
    "wheelOneDisplayType",
    "wheelOneDisplaySource",
    "wheelOneThemeImage",
    "wheelOneIsDisable",
    "tagImage",
    "buttonUp",
    "buttonUpDisable",
    "buttonDown",
    "buttonDownDisable",
    "background",
    "overlay",
    "removeAllBorders",
    "wheelOnePreviousText",
    "wheelOneCardFontColor",
    "wheelOneCardFontSize",
    "wheelOneCardPaddingTop",
    "wheelOneCardPaddingBottom",
    "wheelOneCardPaddingWidth",
    "wheelOneCardWidth",
    "wheelOneCardHeight",
    "wheelOneRetroBorderSize",
    "wheelOneCardContentPosition",
    "themeLocation",
    "themeName",
    "isFinal",
    "wheelTwoItem",
    "wheelTwoIsCrit",
    "wheelTwoCSSData",
    "wheelTwoDisplayed",
    "wheelTwoIsButtonDisable",
    "wheelTwoDisplayType",
    "wheelTwoDisplaySource",
    "wheelTwoThemeImage",
    "wheelTwoIsDisable",
    "wheelTwoPreviousText",
    "wheelTwoCardFontColor",
    "wheelTwoCardFontSize",
    "wheelTwoCardPaddingTop",
    "wheelTwoCardPaddingBottom",
    "wheelTwoCardPaddingWidth",
    "wheelTwoCardWidth",
    "wheelTwoCardHeight",
    "wheelTwoRetroBorderSize",
    "wheelTwoCardContentPosition",
    "wheelThreeItem",
    "wheelThreeIsCrit",
    "wheelThreeCSSData",
    "wheelThreeDisplayed",
    "wheelThreeIsButtonDisable",
    "wheelThreeDisplayType",
    "wheelThreeDisplaySource",
    "wheelThreeThemeImage",
    "wheelThreeIsDisable",
    "wheelThreePreviousText",
    "wheelThreeCardFontColor",
    "wheelThreeCardFontSize",
    "wheelThreeCardPaddingTop",
    "wheelThreeCardPaddingBottom",
    "wheelThreeCardPaddingWidth",
    "wheelThreeCardWidth",
    "wheelThreeCardHeight",
    "wheelThreeRetroBorderSize",
    "wheelThreeCardContentPosition",
    "assetMediaImageUrl",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "backgroundColorWrong",
    "wheelBorderColor",
    "wheelDisableBackgroundColor",
    "wheelDisableElementOpacity",
    "valueSource",
    "numberOfDisable",
    "wheelFadeData",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";


.body-container {
  min-height: 340px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 0.6s ease;
}
</style>
