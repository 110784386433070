var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cards completed-cards",class:[_vm.addedClass, {'max-card': _vm.correctCards.length == 5}]},_vm._l((_vm.correctCards),function(correctCard,index){return _c('div',{key:'step' + index + '-' + correctCard.id,staticClass:"card",class:{'numerik': _vm.templateName != _vm.templateMultiA, 'no-audio': !_vm.isShowAudioButton},attrs:{"id":'card'+index}},[_c('div',{staticClass:"card-box"},[(correctCard.sound)?_c('div',{staticClass:"card-sound"},[_c('span',[_vm._v(_vm._s(correctCard.sound))])]):_vm._e(),_c('div',{staticClass:"card-img",style:([
          _vm.cardHeight ?
            ''
          : '',
          _vm.cardPaddingTop ?
            {paddingTop: _vm.cardPaddingTop}
          : '',
          _vm.cardPaddingBottom ?
            {paddingBottom: _vm.cardPaddingBottom}
          : '',
          _vm.cardPaddingWidth ?
            {
              paddingLeft: 'calc(' + _vm.cardPaddingWidth + ' / 2)', 
              paddingRight: 'calc(' + _vm.cardPaddingWidth + ' / 2)'
            }
          : '' ])},[(_vm.displayType == 'image')?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],attrs:{"src":_vm.assetMediaImageUrl + correctCard.element[_vm.displaySource],"alt":_vm.displaySource ? correctCard.element[_vm.displaySource] : '',"draggable":"false"}}):(_vm.displayType == 'text')?_c('span',{style:([
            _vm.cardFontColor ?
              {color: _vm.cardFontColor}
            : '',
            _vm.cardFontSize ?
              {fontSize: _vm.cardFontSize}
            : '',
            _vm.cardContentPosition ?
              {textAlign: _vm.cardContentPosition == 'flex-end' ? 'right' : (_vm.cardContentPosition == 'baseline' ? 'left' : 'center') }
            : ''
          ])},[_vm._v(" "+_vm._s(correctCard.element[_vm.displaySource])+" ")]):_vm._e()]),_c('div',{staticClass:"card-title",class:[_vm.answerDisplaySource && correctCard.element.hasOwnProperty(_vm.answerDisplaySource) ? (correctCard.element[_vm.answerDisplaySource].length >= 10? 'exceed-text' : '') : ''],style:([
          _vm.answerDisplayFontSize ?
            {fontSize: _vm.answerDisplayFontSize}
          : '' ])},[_vm._v(" "+_vm._s(_vm.answerDisplaySource ? correctCard.element[_vm.answerDisplaySource] : '')+" ")])]),(_vm.templateName === _vm.templateMultiA && _vm.isShowAudioButton && _vm.cardAudioType === 'mp3' && _vm.cardAudioSource)?_c('AudioA',{attrs:{"messageAudioFile":correctCard.element[_vm.cardAudioSource],"classAdded":'multi',"isDataSound":true,"assetMediaAudioUrl":_vm.assetMediaAudioUrl}}):(_vm.templateName === _vm.templateMultiA && _vm.cardAudioType && _vm.isShowAudioButton && _vm.cardAudioSource)?_c('AudioB',{attrs:{"classAdded":'multi',"correctWord":_vm.cardAudioSource ? correctCard.element[_vm.cardAudioSource] : '',"audioType":_vm.cardAudioType}}):_vm._e(),_c('div',{staticClass:"clear"})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }