var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.className,attrs:{"setIsCorrect":_vm.setIsCorrect + ' setIsCorrect'}},[(_vm.isCorrect)?_c('Notify',{style:([
      _vm.fadeOutContent ?
        {opacity: '0', transition: 'all 0.6s ease'}
      : '' ]),attrs:{"addedClass":"calendar","hasBorder":true}}):_vm._e(),_c('div',{staticClass:"calendar-default-background",style:([
      _vm.setIsCorrect == null || _vm.fadeOutContent ?
        _vm.myHome.isIpadOrIphone ?
          {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/class5.svg)'}
        : {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/class1.svg)'}
      : {opacity: '0', transition: 'all 0.6s ease'} ])}),_c('div',{staticClass:"calendar-hover-background",style:([
      _vm.dropContent ?
        {opacity: '1'}
      : {opacity: '0'},
      _vm.myHome.isIpadOrIphone ?
        {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/class6.svg)'}
      : {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/class2.svg)'}
    ])}),_c('div',{staticClass:"calendar-correct-background",style:([
      _vm.setIsCorrect && !_vm.myHome.isIpadOrIphone ?
        {opacity: '1'}
      : {opacity: '0'},
      _vm.fadeOutContent ?
        {opacity: '0', transition: 'all 0.6s ease'}
      : '',
      {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/class3.svg)'}
    ])}),_c('div',{staticClass:"calendar-correct-background",style:([
      _vm.setIsCorrect && _vm.myHome.isIpadOrIphone ?
        {opacity: '1'}
      : {opacity: '0'},
      _vm.fadeOutContent ?
        {opacity: '0', transition: 'all 0.6s ease'}
      : '',
      {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/class7.svg)'}
    ])}),_c('div',{staticClass:"calendar-wrong-background",style:([
      _vm.setIsCorrect === false && !_vm.myHome.isIpadOrIphone ?
        {opacity: '1'}
      : {opacity: '0'},
      {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/class4.svg)'}
    ])}),_c('div',{staticClass:"calendar-wrong-background",style:([
      _vm.setIsCorrect === false && _vm.myHome.isIpadOrIphone ?
        {opacity: '1'}
      : {opacity: '0'},
      {backgroundImage: 'url(' + _vm.themeLocation + _vm.themeName + '/class8.svg)'}
    ])}),_c('div',{staticClass:"title",style:([
      _vm.setFontColor ?
        {color: _vm.setFontColor}
      : '',
      _vm.setFontSize?
        {fontSize: _vm.setFontSize}
      : '' ])},[_vm._v(" "+_vm._s(_vm.setTitle)+" ")]),_c('div',{staticClass:"content"},_vm._l((_vm.setItems),function(item,index){return _c('div',{key:index,staticClass:"guide-card",class:'guide-card-' + _vm.guidCardAddedClass,style:([
        item.show ?
          {opacity: 1, transition: 'all 300ms ease'}
        : {opacity: 0},
        _vm.removeTrans ?
          {transition: 'none 0s ease'}
        : ''
      ])},[_c('div',{staticClass:"border",style:([
          {borderColor: _vm.borderColor[1]},
          item.hideBorder ?
            {borderWidth: '0'}
          : ''
        ])})])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }