<template>
  <div class="memori">
    
    <HeaderA
      v-if="isDocumentReady"
      :stepsLength="stepsLength"
      :progressValue="progressValue"
      :isReRotateStars="false"
      :message="message"
      :finalMessage="finalMessage"
      :messageAudioFile="messageAudioFile"
      :messageAudioType="messageAudioType"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isFinal="isFinished"
      :finalMessageAudioFile="finalMessageAudioFile"
    >
    </HeaderA>

    <MemoA
      v-if="isDocumentReady && isElementsReady"
      :secondFinalPage="secondFinalPage"
      :preventClickCard="preventClickCard"
      :formatTwoCol="formatTwoCol"
      :isFinished="isFinished"
      :isLayoutActive="isLayoutActive"
      :showCardsLeft="showCardsLeft"
      :collectionCards1="collectionCards1"
      :showDisabled="showDisabled"
      :startupDisplayLeftCards="startupDisplayLeftCards"
      :leftBGColorA="leftBGColorA"
      :leftBorderColorA="leftBorderColorA"
      :leftCardIdentifiers="leftCardIdentifiers"
      :textColorLeft="textColorLeft"
      :leftBGColorB="leftBGColorB"
      :leftBGColorC="leftBGColorC"
      :leftBorderColorC="leftBorderColorC"
      :rightBorderColorB="rightBorderColorB"
      :leftBorderColorB="leftBorderColorB"
      :isCardClickable="isCardClickable"
      :backgroundColorWrong="backgroundColorWrong"
      :themeName="themeName"
      :themeLocation="themeLocation"
      :collectionCorrectCards="collectionCorrectCards"
      :boardBorderColor="boardBorderColor"
      :boardBackgroundColor="boardBackgroundColor"
      :bannerTextColor="bannerTextColor"
      :boardTitle="boardTitle"
      :audioImg="myHome.getThemeImage('audio.svg')"
      :cardAudioSource="cardAudioSource"
      :cardAudioType="cardAudioType"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :finalDisplaySource="finalDisplaySource"
      :assetMediaImageUrl="assetMediaImageUrl"
      :colOneDisplaySource="colOneDisplaySource"
      :colOneDisplayType="colOneDisplayType"
      :colOneCardFontColor="colOneCardFontColor"
      :colOneCardFontSize="colOneCardFontSize"
      :colTwoDisplaySource="colTwoDisplaySource"
      :colTwoDisplayType="colTwoDisplayType"
      :colOneCardAudioSource="colOneCardAudioSource"
      :colOneCardAudioType="colOneCardAudioType"
      :colOneCardHeight="colOneCardHeight"
      :colOneCardPaddingTop="colOneCardPaddingTop"
      :colOneCardPaddingBottom="colOneCardPaddingBottom"
      :colOneCardPaddingHeight="colOneCardPaddingHeight"
      :colOneCardPaddingWidth="colOneCardPaddingWidth"
      :colOneCardWidth="colOneCardWidth"
      :colOneRetroBorderSize="colOneRetroBorderSize"
      :colTwoCardFontColor="colTwoCardFontColor"
      :colTwoCardFontSize="colTwoCardFontSize"
      :colTwoCardAudioSource="colTwoCardAudioSource"
      :colTwoCardAudioType="colTwoCardAudioType"
      :colTwoCardHeight="colTwoCardHeight"
      :colTwoCardPaddingTop="colTwoCardPaddingTop"
      :colTwoCardPaddingBottom="colTwoCardPaddingBottom"
      :colTwoCardPaddingHeight="colTwoCardPaddingHeight"
      :colTwoCardPaddingWidth="colTwoCardPaddingWidth"
      :colTwoCardWidth="colTwoCardWidth"
      :colTwoRetroBorderSize="colTwoRetroBorderSize"
      :showCardsRight="showCardsRight"
      :collectionCards2="collectionCards2"
      :startupDisplayRightCards="startupDisplayRightCards"
      :rightBGColorA="rightBGColorA"
      :rightBorderColorA="rightBorderColorA"
      :rightCardIdentifiers="rightCardIdentifiers"
      :textColorRight="textColorRight"
      :rightBGColorB="rightBGColorB"
      :rightBGColorC="rightBGColorC"
      :rightBorderColorC="rightBorderColorC"
      :leftBGColorFinal="leftBGColorFinal"
      :leftBorderColorFinal="leftBorderColorFinal"
      :rightBGColorFinal="rightBGColorFinal"
      :rightBorderColorFinal="rightBorderColorFinal"
      :isFinal="isFinal"
      :wrongBorderColor="wrongBorderColor"
      :selectedBorderColor="selectedBorderColor"
      :correctBorderColor="correctBorderColor"
      :colOneCardContentPosition="colOneCardContentPosition"
      :colTwoCardContentPosition="colTwoCardContentPosition"
      :rotateCard="rotateCard"
      :valueSource="valueSource"
    >
    </MemoA>
  
    <FooterB
      v-if="isDocumentReady && isElementsReady"
      :flipClass="flipClass"
      :isDisabled="isDisabledButton"
      :isDisableClick="isDisableClick"
      :nextText="actionNextText"
      :validateText="actionNextText"
      :finalActionNextText="finalActionNextText"
      :actionTextColor="myHome.theme.actionTextColor"
      :activeButtonImg="myHome.getThemeImage('bouton-actif.svg')"
      :inactiveButtonImg="myHome.getThemeImage('bouton-inactif.svg')"
      :isFinal="isFinal"
    >
    </FooterB>
  </div>
</template>

<script>
import Memo from '../models/Memo'
import MemoA from '../components/MemoA'
import MemoLayout from '../components/MemoLayout'
import HeaderA from '../components/HeaderA'
import FooterB from '../components/FooterB'
import Util from '../helpers/Util'
import Velocity from 'velocity-animate'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['config', 'week', 'database'],
    components: {
      'HeaderA': HeaderA,
      'FooterB': FooterB,
      'MemoA': MemoA,
      'MemoLayout': MemoLayout,
    },
  data () {
    return {
      myHome: this.$parent.myHome,
      myComponent: this,
      templateMemoA: 'memo-a',
      templateName: this.config.data.config.templateName,
      stepsLength: 0,
      progressValue: 0,
      isReRotateStars: false,
      message: '',
      finalMessage: '',
      messageAudioFile: false,
      messageAudioType: 'mp3',
			audioHtml: '',
      collectionCards1: [],
      collectionCards2: [],
      collectionCorrectCards: [],
      weeks: this.week,
      firstCardValue: '',
      secondCardValue: '',
      numPaired: 0,
			isDisableNext: false,
			isCardClickable: true,
      ifProgressAddFilledClass: 0,
      flipClass: '',
      allStepsCompleted: false,
      fadeIn: true,
      audio: '',
      isLayoutActive: false,
      formatTwoCol: false,
      leftCardIdentifiers: [],
      rightCardIdentifiers: [],
      textColorLeft: false,
      textColorRight: false,
      currentCards: {},
      secondFinalPage: false,
      isFinished: false,
      showCardsLeft: false,
      showCardsRight: false,
      hideAllCards: false,
      isResetFlip: false,
      hideCardsDelay: 0,
      showDisabled: true,
      leftBGColorA: '',
      leftBGColorB: '',
      leftBGColorC: '',
      rightBGColorA: '',
      rightBGColorB: '',
      rightBGColorC: '',
      leftBorderColorA: '',
      leftBorderColorB: '',
      leftBorderColorC: '',
      leftBGColorFinal: '',
      leftBorderColorFinal: '',
      rightBorderColorA: '',
      rightBorderColorB: '',
      rightBorderColorC: '',
      rightBGColorFinal: '',
      rightBorderColorFinal: '',
      wrongBorderColor: '',
      selectedBorderColor: '',
      correctBorderColor: '',
      preventClickCard: false,
      startupDisplayRightCards: false,
      startupDisplayRightCardsDelay: 0,
      startupDisplayLeftCards: false,
      startupDisplayLeftCardsDelay: 0,
      themeName: false,
      themeLocation: false,
      colOneDisplaySource: 'value',
      colOneDisplayType: 'text',
      colTwoDisplaySource: 'value',
      colTwoDisplayType: 'text',
      colOneCardFontColor: false,
      colOneCardFontSize: false,
      colOneCardAudioSource: false,
      colOneCardAudioType: false,
      colOneCardHeight: false,
      colOneCardPaddingTop: false,
      colOneCardPaddingBottom: false,
      colOneCardPaddingHeight: false,
      colOneCardPaddingWidth: false,
      colOneCardWidth: false,
      colOneRetroBorderSize: false,
      colOneCardContentPosition: false,
      colTwoCardContentPosition: false,
      colTwoCardFontColor: false,
      colTwoCardFontSize: false,
      colTwoCardAudioSource: false,
      colTwoCardAudioType: false,
      colTwoCardHeight: false,
      colTwoCardPaddingTop: false,
      colTwoCardPaddingBottom: false,
      colTwoCardPaddingHeight: false,
      colTwoCardPaddingWidth: false,
      colTwoCardWidth: false,
      colTwoRetroBorderSize: false,
      backgroundColorWrong: false,
      isDocumentReady: false,
      isElementsReady: false,
      assetMediaAudioUrl: false,
      isFinal: false,
      isDisabledButton: true,
      isDisableClick: false,
      actionNextText: false,
      actionValidateText: false,
      finalActionNextText: false,
      finalMessage: false,
      finalType: false,
      finalMessageAudioFile: false,
      boardTitle: false,
      finalDisplaySource: false,
      boardBorderColor: false,
      boardBackgroundColor: false,
      bannerTextColor: false,
      assetMediaImageUrl: false,
      cardAudioSource: false,
      cardAudioType: false,
      rotateCard: true,
      valueSource: false,
    }
  },
  computed: {
    ...mapGetters(['getMemoLeftArrayData', 'getMemoRightArrayData'])
  },
  methods: {
    ...mapActions([ 
      'setMemoLeftArrayData',
      'setMemoLeftArrayDataByElem',
      'setMemoLeftArrayDataResetIsSelected',
      'setMemoRightArrayData',
      'setMemoRightArrayDataByElem',
      'setMemoRightArrayDataResetIsSelected'
    ]),

    async resetCards() {
      this.isDisabledButton = true
      this.isCardClickable = true;
      document.getElementsByTagName("body")[0].classList.remove("finish");
      this.myHome.changeBackground(false);
      
      var currentCard = Memo.getElements(6);
      Memo.debug();
      
      this.currentCards = currentCard;
      this.collectionCards1 = [];
      this.collectionCards2 = [];
      this.collectionCorrectCards = [];
      this.numPaired = 0;
      this.isDisabledButton = true;
      this.secondFinalPage = false;

      await Util.setWait( _ => {
        for (let index = 0; index < currentCard.left.length; index++) {
          this.collectionCorrectCards.push(currentCard.left[index].element)
        }

        if (this.isLayoutActive) {
          if (this.formatTwoCol) {
            this.collectionCards1 = currentCard.left;
            this.collectionCards2 = currentCard.right;
          }
          else
            this.collectionCards1 = currentCard.merged;
        }
        else {
          this.collectionCards1 = currentCard.left;
          this.collectionCards2 = currentCard.right;
        }
        
        this.collectionCards1 = this.addingElement(this.collectionCards1);
        this.setMemoLeftArrayData(this.collectionCards1);

        this.collectionCards2 = this.addingElement(this.collectionCards2);
        this.setMemoRightArrayData(this.collectionCards2);
      }, 1);

      this.isDocumentReady = true;
      this.isElementsReady = true;
    },

    onReset() {
      this.fadeIn = false;
      setTimeout( _ => {
        this.isDocumentReady = false;
        this.secondFinalPage = false;
        this.isFinished = false;
        this.fadeIn = true;
        this.isFinal = false;
        this.isReRotateStars = false;
        this.progressValue = 0;
        this.start();
        this.allStepsCompleted = false;
        this.flipClass = false;
        this.complete = false;
        this.myHome.loadTheme();
      }, 100);
    },

    onSubmit() {
      this.fadeIn = false;

      setTimeout( _ => {
        this.rotateCard = true;
        this.isElementsReady = false;
        this.resetCards();
        this.secondFinalPage = false;
        this.fadeIn = true;
        this.isFinished = false;
      }, 100);
    },

    classesBordersSettings(e, selectedCard, showCard, addedClass) {
      if (this.formatTwoCol)
        this.removePreviousSelectedClasses(selectedCard, addedClass, showCard);
    },

    async removePreviousSelectedClasses(cardType, classType, showCards) {
      if (cardType.length) {
        let index = cardType[0].getAttribute("data-index");
        await Util.setWait( _ => {
          if (classType == 'left') {
            this.setMemoLeftArrayDataByElem({
              'index': index, 
              'element': 'isSelected',
              'value': false
            });
          }
          else {
            this.setMemoRightArrayDataByElem({
              'index': index, 
              'element': 'isSelected',
              'value': false
            });
          }
        },1);

        if (!showCards) {
          if (classType == 'left') {
            this.setMemoLeftArrayDataByElem({
              'index': index, 
              'element': 'isFlip',
              'value': false
            });
          }
          else {
            this.setMemoRightArrayDataByElem({
              'index': index, 
              'element': 'isFlip',
              'value': false
            });
          }
        }
      }
    },

    async checkSecondPicked(e, isSecondPicked, leftcard, mergedcard) {
      if (!this.formatTwoCol)
        leftcard = mergedcard;
       
      if (isSecondPicked) {
        var correct = document.getElementsByClassName('correct');
        this.secondCardValue = e.target.parentElement.children[2].attributes['data-value'].value;

        if (this.secondCardValue === this.firstCardValue) {
          this.correctPairedCard();
          
          await Util.setWait( _ => {
            this.setupDisableCard();
          }, 1000 + this.hideCardsDelay);
        }
        else
          this.wrongPairedCard();
          
      }
      else
        this.firstCardValue = e.target.parentElement.children[2].attributes['data-value'].value;

      return isSecondPicked;
    },

    async removeSelectedClasses() {  
      this.updateArrayElement([{'item': 'isFlip', 'value': false}]);
      this.setMemoLeftArrayDataResetIsSelected();
      this.setMemoRightArrayDataResetIsSelected();
    },

    async correctPairedCard() {
      this.isCardClickable = false;

      await Util.setWait( _ => {
        let selectedCards = document.getElementsByClassName('selected');
        let indexLeft = selectedCards[0].getAttribute("data-index");
        let indexRight = selectedCards[1].getAttribute("data-index");
        this.isCardClickable = false;

        this.updateArrayElement([{'item': 'isCorrect', 'value': true}]);

        this.numPaired++;
        var tempLength = this.formatTwoCol ?
          this.collectionCards1.length
        : (this.collectionCards1.length) / 2;

        if (tempLength === this.numPaired)
          this.finalPair();
        else {
          setTimeout( _ => {
            this.isCardClickable = true;
          }, 500 + this.hideCardsDelay);
          this.isResetFlip = true;
        }
      }, 500);
    },
   
    async wrongPairedCard() {
      this.preventClickCard = true;

      await Util.setWait( _ => {
        this.updateArrayElement([{'item': 'isWrong', 'value': true}]);
      }, 500);
      
      await Util.setWait( _ => {
        this.isResetFlip = true;
        this.preventClickCard = false;
      }, 100);
    },

    removeWrongBorder() {
      this.updateArrayElement([{'item': 'isWrong', 'value': false}]);
      this.preventClickCard = false;
    },

    setupDisableCard() {
      this.preventClickCard = false;
      
      this.updateArrayElement([{'item': 'isComplete', 'value': true}, {'item': 'isCorrect', 'value': false}]);
      this.removeSelectedClasses();
    },

    updateArrayElement(element) {
      var selectedCards = document.getElementsByClassName('selected'),
      items = selectedCards.length;
      for (let index = 0; index < items; index++) {
        let cardIndex = selectedCards[index].getAttribute("data-index")
        let type = selectedCards[index].getAttribute("cardtype")

        if (type == 'left') {
          for (let i = 0; i < element.length; i++) {
            this.setMemoLeftArrayDataByElem({
              'index': cardIndex, 
              'element': element[i].item,
              'value': element[i].value
            });
          }
        }
        else {
          for (let i = 0; i < element.length; i++) {
            this.setMemoRightArrayDataByElem({
              'index': cardIndex, 
              'element': element[i].item,
              'value': element[i].value
            });
          }
        }
      }
    },

    finalPair() {
      var _this = this;
      setTimeout( _ => {
        this.progressValue++;
        this.ifProgressAddFilledClass++;
        this.fadeIn = false;

        setTimeout( _ => {
          if (this.finalType === 'finalC') {
            this.secondFinalPage = true;

            this.collectionCards1.sort(function(a, b) {
              var textA = a.element[_this.valueSource].toUpperCase();
              var textB = b.element[_this.valueSource].toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
          }
          else {
            this.secondFinalPage = false;
            this.pairCards();
          }
          
          this.isFinished = true;
          this.fadeIn = true;
          document.getElementsByTagName("body")[0].classList.add("finish");
          this.myHome.changeBackground(true);

          setTimeout( _ => {
            this.isDisabledButton = false;
            if (this.progressValue === this.stepsLength) {
              this.allStepsCompleted = true;
              this.flipClass = true;
              setTimeout( _ => {
                this.isFinal = true;
              }, 100);
              setTimeout( _ => {
                this.isDisabledButton = false;
                this.isReRotateStars = true;
              }, 300);
            }
          }, 500);
        }, 500);
        this.fadeIn = false;
      }, 750 + this.hideCardsDelay);
    },
    
    pairCards() {
      this.collectionCards1 = [];
      this.collectionCards2 = [];
      this.setMemoLeftArrayData(this.collectionCards1);
      this.setMemoRightArrayData(this.collectionCards2);

      setTimeout( _ => {
        this.currentCards.left.sort((a, b) => {
          return a.element[this.valueSource].localeCompare(b.element[this.valueSource], 'fr', {ignorePunctuation: true});
        });

        this.currentCards.right.sort((a, b) => {
          return a.element[this.valueSource].localeCompare(b.element[this.valueSource], 'fr', {ignorePunctuation: true});
        });

        for (var i = 0; i < this.currentCards.left.length; ++i) {
          if (i & 1) {
            this.collectionCards2.push(this.currentCards.left[i]);
            this.collectionCards2.push(this.currentCards.right[i]);
          }
          else {
            this.collectionCards1.push(this.currentCards.left[i]);
            this.collectionCards1.push(this.currentCards.right[i]);
          }
        }
        this.collectionCards1 = this.addingElement(this.collectionCards1);
        this.setMemoLeftArrayData(this.collectionCards1);
        
        this.collectionCards2 = this.addingElement(this.collectionCards2);
        this.setMemoRightArrayData(this.collectionCards2);
      }, 1);
    },

    addingElement(collection) {
      for (let index = 0; index < collection.length; index++) {
        collection[index].isSelected = false;
        collection[index].isWrong = false;
        collection[index].isCorrect = false;
        collection[index].isComplete = false;
        collection[index].isFlip = false;
        collection.splice(index, 1, collection[index])
      }
      return collection;
    },

    loadThemeImages() {
      var images = [
       'audio.svg',
       'bouton-actif.svg',
       'bouton-inactif.svg',
       'carte.png',
       'etoile-inactive.svg',
       'etoile-active.svg'
      ];

      // Preloading images for smooth animations
      for(var i = 0; i < images.length; i++) {
        var img = new Image();
        img.src = this.myHome.getThemeImage(images[i]);
      }
    },

    start() {
      this.rotateCard = true;
      this.initializeStartupVariable();
      this.initializeThemeVariable();

      this.resetCards();
    },

    startupFlip() {
      var leftCardDelay, rightCardDelay;

      if (this.isLayoutActive) {
        var displayLeftCards = this.config.data.config.layout.col1.startupDisplayCards === 'yes' ? true : false;
            leftCardDelay = this.config.data.config.layout.col1.startupDisplayCardsDelay;
        if (this.formatTwoCol) {
          var displayRightCards = this.config.data.config.layout.col2.startupDisplayCards === 'yes' ? true : false;
              rightCardDelay = this.config.data.config.layout.col2.startupDisplayCardsDelay;
        }
      }
      else {
        var displayLeftCards = this.config.data.config.left.startupDisplayCards === 'yes' ? true : false,
            displayRightCards = this.config.data.config.right.startupDisplayCards === 'yes' ? true : false;
            leftCardDelay = this.config.data.config.left.startupDisplayCardsDelay;
            rightCardDelay = this.config.data.config.right.startupDisplayCardsDelay;
      }

      var startupLeftCards = displayLeftCards && !this.showCardsLeft ? true : false;
      if (this.formatTwoCol)
        var startupRightCards = displayRightCards && !this.showCardsRight ? true : false;

      if (startupLeftCards || startupRightCards)
        this.preventClickCard = true;

      this.implementStartupFlip(leftCardDelay, rightCardDelay, startupLeftCards, startupRightCards);
    },

    implementStartupFlip(leftCardDelay, rightCardDelay, startupLeftCards, startupRightCards) {
        this.startupDisplayLeftCards = startupLeftCards;
        this.startupDisplayLeftCardsDelay = leftCardDelay;

        if (this.formatTwoCol) {
          this.startupDisplayRightCards = startupRightCards;
          this.startupDisplayRightCardsDelay = this.formatTwoCol ? rightCardDelay : false;
        }

        if (this.startupDisplayLeftCards) {
          setTimeout( _ => {
            this.startupDisplayLeftCards = false;
          }, this.startupDisplayLeftCardsDelay * 1000);
        }

        if (this.startupDisplayRightCards) {
          setTimeout( _ => {
            this.startupDisplayRightCards = false;
          }, this.startupDisplayRightCardsDelay * 1000);
        }

        if (this.startupDisplayLeftCardsDelay > this.startupDisplayRightCardsDelay) {
          setTimeout( _ => {
            this.preventClickCard = false;
          }, this.startupDisplayLeftCardsDelay * 1000);
        }
        else {
          setTimeout( _ => {
            this.preventClickCard = false;
          }, this.startupDisplayRightCardsDelay * 1000);
        }
    },

    initializeStartupVariable() {
      this.isLayoutActive = this.config.data.config.hasOwnProperty("layout") ? true : false;
      
      this.formatTwoCol = this.isLayoutActive ?
        this.config.data.config.layout.format == '2col' ?
          true
        : false
      : true;

      this.leftCardIdentifiers = this.isLayoutActive ?
        this.config.data.config.layout.col1.cardIdentifiers
      : this.config.data.config.left.cardIdentifiers ?
          this.config.data.config.left.cardIdentifiers
        : [];

      this.rightCardIdentifiers = this.isLayoutActive ?
        this.formatTwoCol ?
          this.config.data.config.layout.col2.cardIdentifiers
        : []
      : this.config.data.config.right.cardIdentifiers?
          this.config.data.config.right.cardIdentifiers
        : [];
    },

    initializeThemeVariable() {
      this.showDisabled = this.config.data.config.showDisabled === 'yes' || typeof(this.config.data.config.showDisabled) === 'undefined' ? true : false;
      if (this.config.data.config.hasOwnProperty("theme")) {
        this.boardBorderColor = this.config.data.config.theme.hasOwnProperty('boardBorderColor') ? this.config.data.config.theme.boardBorderColor : false;
        this.boardBackgroundColor = this.config.data.config.theme.hasOwnProperty('boardBackgroundColor') ? this.config.data.config.theme.boardBackgroundColor : false;
        this.bannerTextColor = this.config.data.config.theme.hasOwnProperty('bannerTextColor') ? this.config.data.config.theme.bannerTextColor : false;
        this.wrongBorderColor = this.config.data.config.theme.hasOwnProperty("wrongBorderColor") ? this.config.data.config.theme.wrongBorderColor : false;
        this.selectedBorderColor = this.config.data.config.theme.hasOwnProperty("selectedBorderColor") ? this.config.data.config.theme.selectedBorderColor : false;
        this.correctBorderColor = this.config.data.config.theme.hasOwnProperty("correctBorderColor") ? this.config.data.config.theme.correctBorderColor : false;
        this.themeName = this.config.data.config.theme.hasOwnProperty("themeName") ? this.config.data.config.theme.themeName : false;
        this.themeLocation = this.config.data.config.theme.hasOwnProperty("themeLocation") ? this.config.data.config.theme.themeLocation : false;
        this.backgroundColorWrong = this.config.data.config.theme.hasOwnProperty("backgroundColorWrong") ? this.config.data.config.theme.backgroundColorWrong : false;
        
        if (this.config.data.config.theme.hasOwnProperty("col1")) {
          this.leftBGColorA = this.config.data.config.theme.col1.hasOwnProperty("backgroundColorA") ? this.config.data.config.theme.col1.backgroundColorA : false;
          this.leftBorderColorA = this.config.data.config.theme.col1.hasOwnProperty("borderColorA") ? this.config.data.config.theme.col1.borderColorA : false;
          this.leftBGColorB = this.config.data.config.theme.col1.hasOwnProperty("backgroundColorB") ? this.config.data.config.theme.col1.backgroundColorB : false;
          this.leftBorderColorB = this.config.data.config.theme.col1.hasOwnProperty("borderColorB") ? this.config.data.config.theme.col1.borderColorB : false;
          this.leftBGColorC = this.config.data.config.theme.col1.hasOwnProperty("backgroundColorC") ? this.config.data.config.theme.col1.backgroundColorC : false;
          this.leftBorderColorC = this.config.data.config.theme.col1.hasOwnProperty("borderColorC") ? this.config.data.config.theme.col1.borderColorC : false;
          this.leftBGColorFinal = this.config.data.config.theme.col1.hasOwnProperty("backgroundColorFinal") ? this.config.data.config.theme.col1.backgroundColorFinal : false;
          this.leftBorderColorFinal = this.config.data.config.theme.col1.hasOwnProperty("borderColorFinal") ? this.config.data.config.theme.col1.borderColorFinal : false;
        }
        if (this.config.data.config.theme.hasOwnProperty("col2")) {
          this.rightBGColorA = this.config.data.config.theme.col2.hasOwnProperty("backgroundColorA") ? this.config.data.config.theme.col2.backgroundColorA : false;
          this.rightBorderColorA = this.config.data.config.theme.col2.hasOwnProperty("borderColorA") ? this.config.data.config.theme.col2.borderColorA : false;
          this.rightBGColorB = this.config.data.config.theme.col2.hasOwnProperty("backgroundColorB") ? this.config.data.config.theme.col2.backgroundColorB : false;
          this.rightBorderColorB = this.config.data.config.theme.col2.hasOwnProperty("borderColorB") ? this.config.data.config.theme.col2.borderColorB : false;
          this.rightBGColorC = this.config.data.config.theme.col2.hasOwnProperty("backgroundColorC") ? this.config.data.config.theme.col2.backgroundColorC : false;
          this.rightBorderColorC = this.config.data.config.theme.col2.hasOwnProperty("borderColorC") ? this.config.data.config.theme.col2.borderColorC : false;
          this.rightBGColorFinal = this.config.data.config.theme.col2.hasOwnProperty("backgroundColorFinal") ? this.config.data.config.theme.col2.backgroundColorFinal : false;
          this.rightBorderColorFinal = this.config.data.config.theme.col2.hasOwnProperty("borderColorFinal") ? this.config.data.config.theme.col2.borderColorFinal : false;
        }
      }
      
      if (this.config.data.config.hasOwnProperty("layout")) {
        if (this.config.data.config.layout.hasOwnProperty("col1")) {
          this.colOneDisplaySource = this.config.data.config.layout.col1.hasOwnProperty("displaySource") ? this.config.data.config.layout.col1.displaySource : 'value';
          this.colOneDisplayType = this.config.data.config.layout.col1.hasOwnProperty("displayType") ? this.config.data.config.layout.col1.displayType : 'text';
          this.colOneCardFontColor = this.config.data.config.layout.col1.hasOwnProperty("cardFontColor") ? this.config.data.config.layout.col1.cardFontColor : false;
          this.colOneCardFontSize = this.config.data.config.layout.col1.hasOwnProperty("cardFontSize") ? this.config.data.config.layout.col1.cardFontSize : false;

          this.colOneCardContentPosition = this.config.data.config.layout.col1.hasOwnProperty("cardContentPosition") ? this.config.data.config.layout.col1.cardContentPosition : false;
          this.colOneCardAudioSource = this.config.data.config.layout.col1.hasOwnProperty('cardAudioSource') ? this.config.data.config.layout.col1.cardAudioSource : false;
          this.colOneCardAudioType = this.config.data.config.layout.col1.hasOwnProperty('cardAudioType') ? this.config.data.config.layout.col1.cardAudioType : false;
          this.colOneCardHeight = this.config.data.config.layout.col1.hasOwnProperty('cardHeight') ? this.config.data.config.layout.col1.cardHeight : false;
          this.colOneCardPaddingTop = this.config.data.config.layout.col1.hasOwnProperty('cardPaddingTop') ? this.config.data.config.layout.col1.cardPaddingTop : false;
          this.colOneCardPaddingBottom = this.config.data.config.layout.col1.hasOwnProperty('cardPaddingBottom') ? this.config.data.config.layout.col1.cardPaddingBottom : false;
          this.colOneCardPaddingHeight = this.config.data.config.layout.col1.hasOwnProperty('cardPaddingHeight') ? this.config.data.config.layout.col1.cardPaddingHeight : false;
          this.colOneCardPaddingWidth = this.config.data.config.layout.col1.hasOwnProperty('cardPaddingWidth') ? this.config.data.config.layout.col1.cardPaddingWidth : false;
          this.colOneCardWidth = this.config.data.config.layout.col1.hasOwnProperty('cardWidth') ? this.config.data.config.layout.col1.cardWidth : false;
          this.colOneRetroBorderSize = this.config.data.config.layout.col1.hasOwnProperty('retroBorderSize') ? this.config.data.config.layout.col1.retroBorderSize : false;
          this.showCardsLeft = this.config.data.config.layout.col1.hasOwnProperty('showCards') ? 
            this.config.data.config.layout.col1.showCards === 'yes' ? true : false
          : false;
        }
        if (this.config.data.config.layout.hasOwnProperty("col2")) {
          this.colTwoDisplaySource = this.config.data.config.layout.col2.hasOwnProperty("displaySource") ? this.config.data.config.layout.col2.displaySource : 'value';
          this.colTwoDisplayType = this.config.data.config.layout.col2.hasOwnProperty("displayType") ? this.config.data.config.layout.col2.displayType : 'text';
          this.colTwoCardFontColor = this.config.data.config.layout.col2.hasOwnProperty("cardFontColor") ? this.config.data.config.layout.col2.cardFontColor : false;
          this.colTwoCardFontSize = this.config.data.config.layout.col2.hasOwnProperty("cardFontSize") ? this.config.data.config.layout.col2.cardFontSize : false;

          this.colTwoCardContentPosition = this.config.data.config.layout.col2.hasOwnProperty("cardContentPosition") ? this.config.data.config.layout.col2.cardContentPosition : false;
          this.colTwoCardAudioSource = this.config.data.config.layout.col2.hasOwnProperty('cardAudioSource') ? this.config.data.config.layout.col2.cardAudioSource : false;
          this.colTwoCardAudioType = this.config.data.config.layout.col2.hasOwnProperty('cardAudioType') ? this.config.data.config.layout.col2.cardAudioType : false;
          this.colTwoCardHeight = this.config.data.config.layout.col2.hasOwnProperty('cardHeight') ? this.config.data.config.layout.col2.cardHeight : false;
          this.colTwoCardPaddingTop = this.config.data.config.layout.col2.hasOwnProperty('cardPaddingTop') ? this.config.data.config.layout.col2.cardPaddingTop : false;
          this.colTwoCardPaddingBottom = this.config.data.config.layout.col2.hasOwnProperty('cardPaddingBottom') ? this.config.data.config.layout.col2.cardPaddingBottom : false;
          this.colTwoCardPaddingHeight = this.config.data.config.layout.col2.hasOwnProperty('cardPaddingHeight') ? this.config.data.config.layout.col2.cardPaddingHeight : false;
          this.colTwoCardPaddingWidth = this.config.data.config.layout.col2.hasOwnProperty('cardPaddingWidth') ? this.config.data.config.layout.col2.cardPaddingWidth : false;
          this.colTwoCardWidth = this.config.data.config.layout.col2.hasOwnProperty('cardWidth') ? this.config.data.config.layout.col2.cardWidth : false;
          this.colTwoRetroBorderSize = this.config.data.config.layout.col2.hasOwnProperty('retroBorderSize') ? this.config.data.config.layout.col2.retroBorderSize : false;
          this.showCardsRight = this.config.data.config.layout.col2.hasOwnProperty('showCards') ? 
            this.config.data.config.layout.col2.showCards === 'yes' ? true : false
          : false;
        }
          this.cardAudioSource = this.config.data.config.layout.hasOwnProperty('cardAudioSource') ? this.config.data.config.layout.cardAudioSource : false;
          this.cardAudioType = this.config.data.config.layout.hasOwnProperty('cardAudioType') ? this.config.data.config.layout.cardAudioType : false;
      }

      if (this.config.data.config.hasOwnProperty('steps')) {
        this.stepsLength = this.config.data.config.steps.hasOwnProperty('numberSteps') ? parseInt(this.config.data.config.steps.numberSteps) : 0;
        this.message = this.config.data.config.steps.hasOwnProperty('message') ? this.config.data.config.steps.message : '';
        this.messageAudioFile = this.config.data.config.steps.hasOwnProperty('messageAudioFile') ? this.config.data.config.steps.messageAudioFile : false;
        this.actionNextText = this.config.data.config.steps.hasOwnProperty('actionNextText') ? this.config.data.config.steps.actionNextText : false;
        this.actionValidateText = this.config.data.config.steps.hasOwnProperty('actionValidateText') ? this.config.data.config.steps.actionValidateText : false;
        this.messageAudioType = this.config.data.config.steps.hasOwnProperty('messageAudioType') ? this.config.data.config.steps.messageAudioType : 'mp3';
      }

      if (this.config.data.config.hasOwnProperty('final')) {
        this.finalActionNextText = this.config.data.config.final.hasOwnProperty('actionNextText') ? this.config.data.config.final.actionNextText : '';
        this.finalMessage = this.config.data.config.final.hasOwnProperty('message') ? this.config.data.config.final.message : '';
        this.finalType = this.config.data.config.final.hasOwnProperty('type') ? this.config.data.config.final.type : 'finalc';
        this.finalMessageAudioFile = this.config.data.config.final.hasOwnProperty('messageAudioFile') ? this.config.data.config.final.messageAudioFile : '';
        this.boardTitle = this.config.data.config.final.hasOwnProperty('boardTitle') ? this.config.data.config.final.boardTitle : '';
        this.finalDisplaySource = this.config.data.config.final.hasOwnProperty('displaySource') ? this.config.data.config.final.displaySource : 'value';
      }

      this.assetMediaImageUrl = this.config.data.config.hasOwnProperty('assetMediaImageUrl') ? this.config.data.config.assetMediaImageUrl : false;
      this.assetMediaAudioUrl = this.config.data.config.hasOwnProperty('assetMediaAudioUrl') ? this.config.data.config.assetMediaAudioUrl : false;
      this.valueSource = this.config.data.config.hasOwnProperty('valueSource') ? this.config.data.config.valueSource : false;
      this.hideAllCards = !this.showCardsLeft && !this.showCardsRight ? true : false;
      this.hideCardsDelay = this.config.data.config.hideCardsDelay && !this.hideAllCards ? this.config.data.config.hideCardsDelay * 1000 : 1000;
    },
  },

  async created() {
    this.loadThemeImages();
    
    try {
      await Memo.populateElements(this.config);
    }
    catch (err) { // need to catch error or rejected promise
      console.error(err);
    }

    this.start();
  },
  
  watch: {
    rotateCard(data) {
      if (!data) {
        setTimeout( _ => {
          this.startupFlip();
        }, 100);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.memori {
  height: 100%;
  display: flex;
  justify-content: normal;
  align-items: center;
  flex-direction: column;

  .head-container {
    min-height: 140px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 24px;

    .stars-container {
      display: flex;
      justify-content: center;

      .star {
        margin: 0 7px;

        img {
          width: 58px;
          height: 57px;
        }
      }
    }
    .title {
      font-size: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;

      .title-text {
        color: #000;
        text-align: center;
        position: relative;
        display: inline-block;
        line-height: 54px;

        .title-sound-example {
          display: inline-flex;
          vertical-align: -7px;
          cursor: pointer;
          height: 40px;
          width: 40px;

          img {
            width: 40px;
            height: 48px;
            border-radius: 20px;
            position: absolute;
          }
        }
      }
    }
  }
}

@media (max-width: 1090px) {
  .memori {
    .body-container {
      .cards {
        .cards-pair {
          & > div {
            ul {
              li {
                width: 201px;
                height: 103px;

                .card-title.back {
                  span {
                    img {
                      max-height: 78px;
                      width: auto;
                    }
                  }
                }
                .card-img.front {
                  img {
                    max-height: 78px;
                    width: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
  .cards-pair.default-cursor .flip-cards ul li{
    cursor: default !important;
  }
  .front {
    transform: rotateY(0deg);
    position: absolute;
    backface-visibility: hidden;
  }
  .back {
    transform: rotateY(180deg) translateZ(1px);
    position: absolute;
    backface-visibility: hidden;
  }

  .finish {
    .memori {
      .body-container {
        .cards {
          animation-name: fadeInPairedCards;
          animation-duration: 1s;

          .cards-pair {
            & > div {
              ul {
                li {
                  @extend .rotate-180;

                  &:nth-of-type(even) {
                    left: -14px;
                    top: 9px;
                    z-index: -1;
                  }
                  &:nth-of-type(odd) {
                    right: -14px;
                  }
                  .back {
                    box-shadow:none;
                  }
                }
              }
            }
            .show-left, .show-right {
              .card-title.back {
                -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
              }
            }
          }
        }
      }
    }
  }
  @keyframes fadeInPairedCards {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  .rotate-180 {
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg); /* IE 9 */
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -o-transform: rotateY(180deg); /* Opera */
    -moz-transform: rotateY(180deg);
    pointer-events: none;
  }
</style>
