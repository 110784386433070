import { render, staticRenderFns } from "./SetA.vue?vue&type=template&id=c03dc368&scoped=true&"
import script from "./SetA.vue?vue&type=script&lang=js&"
export * from "./SetA.vue?vue&type=script&lang=js&"
import style0 from "./SetA.vue?vue&type=style&index=0&id=c03dc368&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c03dc368",
  null
  
)

export default component.exports