<template>
  <div 
    class="flip-cards"
    :class="[
      container, 
      isShowCards && !isFinished ? showCards : '',
      isFinished ? cardType : '' , 
      {
        'two-col': formatTwoCol, 
        rotate: rotateCard, 
        'final-layout': isFinished,
      }
    ]"
  >
    <ul
      :class="[!isShowDisabled ? hideDisable : '']"
    >
      <li
        v-for="(collectionCard, index) in collectionCards"
        v-bind:key="cardBindID + '-' + index"
        :class="[
          startUpDisplayCards ? flip : '', 
          collectionCard.isFlip ? 
            !formatTwoCol ?
              'merged' + 'flip'
            : cardType + 'flip' 
          : '',
          collectionCard.isSelected ? 
            !formatTwoCol ?
              'merged' + '-selected'
            : cardType + '-selected' 
          : '',
          {
            selected: collectionCard.isSelected,
            correct: collectionCard.isCorrect,
            wrong: collectionCard.isWrong,
            pairedflip: collectionCard.isComplete,
            rotate: rotateCard,
            'fade-out': rotateCard,
          }
        ]"
        :id="container + '-' + index"
        :data-index="index"
        :cardType="cardType"
        :style="[
          cardHeight ?
            {height: cardHeight}
          : '',
          cardWidth ?
            {width: cardWidth}
          : '',
          finalCardHeightLeft && formatTwoCol && isFinished === true && (index + 1) % 2 == 1 ?
            {height: finalCardHeightLeft}
          : '',
          finalCardHeightRight && formatTwoCol && isFinished === true && (index + 1) % 2 == 0 ?
            {height: finalCardHeightRight}
          : '',
          finalCardWidthLeft && formatTwoCol && isFinished === true && (index + 1) % 2 == 1 ?
            {width: finalCardWidthLeft}
          : '',
          finalCardWidthRight && formatTwoCol && isFinished === true && (index + 1) % 2 == 0 ?
            {width: finalCardWidthRight}
          : '',
        ]"
      >
        <CardA
          :container             ="container"
          :collectionCard        ="collectionCard"
          :pairingSource         ="pairingSource"
          :index                 ="index"
          :isShowCards           ="isShowCards"
          :cardDataTarget        ="cardDataTarget"
          :cssColor              ="cssColor"
          :cssBGColorA           ="cssBGColorA"
          :cssBorderColorA       ="cssBorderColorA"
          :frontIMG              ="frontIMG"
          :cardIdentifiers       ="cardIdentifiers"
          :cssBackCardColor      ="cssBackCardColor"
          :cssBGColorB           ="cssBGColorB"
          :cssBGColorOptionB     ="cssBGColorOptionB"
          :cssBorderColorB       ="cssBorderColorB"
          :cssBorderColorOptionB ="cssBorderColorOptionB"
          :cssBorderDefaultColor ="cssBorderDefaultColor"
          :assetMediaImageUrl    ="assetMediaImageUrl"
          :isFinished            ="isFinished"
          :isCorrect             ="collectionCard.isCorrect"
          :isSelected            ="collectionCard.isSelected"
          :isWrong               ="collectionCard.isWrong"
          :themeName             ="themeName"
          :themeLocation         ="themeLocation"
          :displaySource         ="displaySource"
          :displayType           ="displayType"
          :cardFontColor         ="cardFontColor"
          :cardFontSize          ="cardFontSize"
          :backgroundColorWrong  ="backgroundColorWrong"
          :formatTwoCol          ="formatTwoCol"
          :finalFirstDisplaySource  ="finalFirstDisplaySource"
          :finalFirstDisplayType    ="finalFirstDisplayType"
          :finalFirstCardFontColor  ="finalFirstCardFontColor"
          :finalFirstCardFontSize   ="finalFirstCardFontSize"
          :finalSecondDisplaySource ="finalSecondDisplaySource"
          :finalSecondDisplayType   ="finalSecondDisplayType"
          :finalSecondCardFontColor ="finalSecondCardFontColor"
          :finalSecondCardFontSize  ="finalSecondCardFontSize"
          :wrongBorderColor         ="wrongBorderColor"
          :selectedBorderColor      ="selectedBorderColor"
          :correctBorderColor       ="correctBorderColor"
          :cssBGColorDisable        ="cssBGColorDisable"
          :cssBorderColorDisable    ="cssBorderColorDisable"
          :cardPaddingTop           ="cardPaddingTop"
          :cardPaddingBottom        ="cardPaddingBottom"
          :cardPaddingWidth         ="cardPaddingWidth"
          :retroBorderSize          ="retroBorderSize"
          :cardContentPosition      ="cardContentPosition"
          :preventClickCard         ="preventClickCard"
          :rotateCard               ="rotateCard"
          :finalFirstBGColorFinal       ="finalFirstBGColorFinal"
          :finalFirstBorderColorFinal   ="finalFirstBorderColorFinal"
          :finalSecondBGColorFinal      ="finalSecondBGColorFinal"
          :finalSecondBorderColorFinal  ="finalSecondBorderColorFinal"
          :finalCardPaddingTopLeft      ="finalCardPaddingTopLeft"
          :finalCardPaddingBottomLeft   ="finalCardPaddingBottomLeft"
          :finalCardPaddingWidthLeft    ="finalCardPaddingWidthLeft"
          :finalCardContentPositionLeft ="finalCardContentPositionLeft"
          :finalRetroBorderSizeLeft     ="finalRetroBorderSizeLeft"
          :finalCardPaddingTopRight     ="finalCardPaddingTopRight"
          :finalCardPaddingBottomRight  ="finalCardPaddingBottomRight"
          :finalCardPaddingWidthRight   ="finalCardPaddingWidthRight"
          :finalCardContentPositionRight="finalCardContentPositionRight"
          :finalRetroBorderSizeRight    ="finalRetroBorderSizeRight"
        >
        </CardA>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import CardA from '../components/CardA'
import Util from '../helpers/Util'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MemoLayout',
  components: {
    'CardA': CardA,
  },
  props: [
    'container',
    'showCards',
    'isShowCards',
    'collectionCards',
    'pairingSource',
    'cardType',
    'cardBindID',
    'hideDisable',
    'isShowDisabled',
    'flip',
    'startUpDisplayCards',
    'flipOverlay',
    'cardDataTarget',
    'cssColor',
    'cssBGColorA',
    'cssBorderColorA',
    'frontIMG',
    'cardIdentifiers',
    'cssBackCardColor',
    'isFinished',
    'cssBGColorB',
    'cssBGColorOptionB',
    'cssBorderColorB',
    'cssBorderColorOptionB',
    "cssBorderDefaultColor",
    'assetMediaImageUrl',
    "preventClickCard",
    "formatTwoCol",
    "themeName",
    "themeLocation",
    "isCardClickable",
    "displaySource",
    "displayType",
    "cardFontColor",
    "cardFontSize",
    "backgroundColorWrong",
    "finalFirstDisplaySource",
    "finalFirstDisplayType",
    "finalFirstCardFontColor",
    "finalFirstCardFontSize",
    "finalSecondDisplaySource",
    "finalSecondDisplayType",
    "finalSecondCardFontColor",
    "finalSecondCardFontSize",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "cssBGColorDisable",
    "cssBorderColorDisable",
    "cardHeight",
    "cardWidth",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingWidth",
    "retroBorderSize",
    "finalCardHeightLeft",
    "finalCardWidthLeft",
    "finalCardPaddingTopLeft",
    "finalCardPaddingBottomLeft",
    "finalCardPaddingWidthLeft",
    "finalCardContentPositionLeft",
    "finalRetroBorderSizeLeft",
    "finalCardHeightRight",
    "finalCardWidthRight",
    "finalCardPaddingTopRight",
    "finalCardPaddingBottomRight",
    "finalCardPaddingWidthRight",
    "finalCardContentPositionRight",
    "finalRetroBorderSizeRight",
    "isFinal",
    "cardContentPosition",
    "rotateCard",
    "finalFirstBGColorFinal",
    "finalFirstBorderColorFinal",
    "finalSecondBGColorFinal",
    "finalSecondBorderColorFinal",
  ],
  data () {
    return {
      myHome: this.$parent.myHome,
      myComponent: this.$parent.myComponent,
    }
  },
  computed: {
    ...mapGetters(['getMemoLeftArrayData, getMemoRightArrayData'])
  },
  methods: {
    ...mapActions([ 
      'setMemoLeftArrayData',
      'setMemoLeftArrayDataByElem',
      'setMemoLeftArrayDataResetIsSelected',
      'setMemoRightArrayData',
      'setMemoRightArrayDataByElem',
      'setMemoRightArrayDataResetIsSelected'
    ]),

    async onFlip(e) {
      if (!this.isCardClickable) 
        return;
      
      if (!this.myComponent.isCardClickable || 
          e.target.parentElement.parentElement.classList.contains('pairedflip') || 
          e.target.parentElement.parentElement.classList.contains('selected')) {
        this.myComponent.isResetFlip = true;
        this.resetFlip();
        return;
      }
      this.resetFlip();
        
      var leftcard = document.getElementsByClassName('leftflip'),
          rightcard = document.getElementsByClassName('rightflip'),
          mergedcard = document.getElementsByClassName('mergedflip'),
          index = e.target.parentElement.parentElement.getAttribute("data-index"),
          isSecondPicked = false, leftBack, rightBack;
          
      if (this.myComponent.showCardsLeft) 
        leftcard = document.getElementsByClassName('left-selected');

      if (this.myComponent.showCardsRight) 
        rightcard = document.getElementsByClassName('right-selected');

      if (!this.formatTwoCol && this.myComponent.showCardsLeft) 
        mergedcard = document.getElementsByClassName('selected');

      await Util.setWait( _ => {
        isSecondPicked = this.checkDataTarget(e, leftcard, leftBack, rightcard, rightBack, isSecondPicked, mergedcard);
      }, 100);

      await Util.setWait( _ => {
        if (this.cardType == 'left') {
          this.setMemoLeftArrayDataByElem({
            'index': index, 
            'element': 'isSelected',
            'value': true
          });
          if (!this.myComponent.showCardsLeft) {
            this.setMemoLeftArrayDataByElem({
              'index': index, 
              'element': 'isFlip',
              'value': true
            });
          }
        }
        else {
          this.setMemoRightArrayDataByElem({
            'index': index, 
            'element': 'isSelected',
            'value': true
          });
          if (!this.myComponent.showCardsRight) {
            this.setMemoRightArrayDataByElem({
              'index': index, 
              'element': 'isFlip',
              'value': true
            });
          }
        }
      },1);
    
      this.myComponent.checkSecondPicked(e, isSecondPicked, leftcard, mergedcard);
    },
    resetFlip() {
      if (this.myComponent.isResetFlip) {
        this.myComponent.removeWrongBorder();
        this.myComponent.removeSelectedClasses();
        this.myComponent.isResetFlip = false;
      }
    },
    checkDataTarget(e, leftcard, leftBack, rightcard, rightBack, isSecondPicked, mergedcard) {
      var showCard, defaultBorderColor, selectedCard, selectedBack, firstCard, addedClass;
      
      switch (e.target.attributes['data-target'].value) {
        case 'leftcard':
          showCard = this.myComponent.showCardsLeft
          defaultBorderColor = this.myComponent.leftBorderColorB;
          selectedCard = leftcard;
          selectedBack = leftBack;
          firstCard = rightcard;
          addedClass = 'left';
          break;
        case 'rightcard':
          showCard = this.myComponent.showCardsRight
          defaultBorderColor = this.myComponent.rightBorderColorB;
          selectedCard = rightcard;
          selectedBack = rightBack;
          firstCard = leftcard;
          addedClass = 'right';
          break;
        default:
          showCard = this.myComponent.showCardsLeft
          defaultBorderColor = this.myComponent.leftBorderColorB;
          selectedCard = mergedcard;
          selectedBack = leftBack;
          firstCard = mergedcard;
          addedClass = 'merged';
          break;
      }   

      return this.afterClickedCard(e, showCard, selectedCard, firstCard, isSecondPicked, addedClass);
    },
    afterClickedCard(e, showCard, selectedCard, firstCard, isSecondPicked, addedClass) { 
      isSecondPicked = this.updateSecondPickedVar(firstCard);
      this.myComponent.classesBordersSettings(e, selectedCard, showCard, addedClass);

      return isSecondPicked;
    },
    updateSecondPickedVar(cardType) {
      if (typeof cardType[0] != 'undefined' && !cardType[0].classList.contains('pairedflip')) 
        return true;
      else
        return false;
    },

    showAnimation() {
      var _this = this,
          ctr = -1,
          counter = 0,
          limit = this.container == 'merged-card' ? 4 : 2;
      this.collectionCards.forEach(function(paramCard, index) {
        let el = [];
        var i = index + 1;

        if (counter == limit) 
          counter = 0;

        if(!counter)
          ctr++;
        setTimeout( _ => {
          el = document.getElementById(_this.container + '-' + index);
          el.classList.remove("rotate");
          el.classList.add("fade-in");
          setTimeout( _ => {
            if (_this.collectionCards.length - 1 == index) 
              _this.myComponent.rotateCard = false;
          }, 1000 );
        }, 250 * ctr );
        counter++;
      });
    },
  },
  created: function () {
    this.showCard = this.showCards;
    setTimeout( _ => {
      this.showAnimation();
    }, 100 );
  },
  
  watch: {
    rotateCard(data) {
      if (data) {
        setTimeout( _ => {
          this.showAnimation();
        }, 100 );
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.flip-cards {
  &.rotate {
    ul {
      li {
        transition: 0.8s;
        pointer-events: none;
      }
    }
  }
  &.show-right  {
    ul {
      li {
        &:not(.rotate) {
          transform: rotateY(-180deg);
        }
      }
    }
  } 
  &.show-left  {
    ul {
      li {
        &:not(.rotate) {
          transform: rotateY(180deg);
        }
      }
    }
  } 
  ul {
    li {
      &.pairedflip, &.wrong {
        &.selected {
          .card-title.back {
            border-width: 6px;
            transition: all 0.3s ease;
          }
        }
        .back {
          background-repeat: no-repeat;
        }
      }
      &.pairedflip {
        &:not(.selected) {
          .card-title.back {
            box-shadow: none;
          }
        }
      }
    }
  }
  &.merged-card {
    width: 100%;
    text-align: center;

    ul {
      display: grid;
      grid-template-columns: auto auto auto auto;
      justify-content: center;
      
      li {
        margin-right: 14px;
        margin-bottom: 4px;

        &:nth-of-type(4n) {
          margin-right: 0px;
        }
      }
    }
  }
  &.left.final-layout, &.right.final-layout {
    &:not(.two-col) {
      width: 50%;
      display: inline-block;
      ul {
        display: grid;
        grid-template-columns: auto auto;
      }
    }
  }
  &.left.final-layout {
    &:not(.two-col) {
      ul {
        justify-content: flex-end;
        li {
          justify-self: end;
          
          &:nth-of-type(even) {
            margin-right: 0px;
          }
        }
      }
    }
  }
  &.right.final-layout {
    &:not(.two-col) {
      ul {
        justify-content: flex-start;
      }
    }
  }
  &.left-card, &.right-card {
    width: 50%;
    display: inline-block;

    ul {
      display: grid;
      grid-template-columns: auto auto;

      li {
        margin-bottom: 4px;

        &:nth-of-type(odd) {
          margin-right: 14px;
        }
      }
    }
  }
  &.left-card {
    margin-right: 20px;
    text-align: right;

    &.two-col {
      margin-right: 23px;

      ul {
        justify-content: flex-end;
      }
    }
  }
  &.right-card {
    margin-left: 20px;

    &.two-col {
      margin-left: 23px;

      ul {
        justify-content: flex-start;
      }
    }
    ul {
      li {
        &.rotate {
          .card-img {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
  ul {
    list-style: none;

    li {
      display: inline-block;
      width: 237px;
      height: 121px;
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;
      font-size: 30px;

      &.rightflip, &.leftflip, &.mergedflip, &.pairedflip {
        @extend .rotate-180;
      }
      &.pairedflip {
        pointer-events: none;
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        margin-top: 0;
      }
    }
  }
}
.rotate-180 {
  transform: rotateY(180deg);
  -ms-transform: rotateY(180deg); /* IE 9 */
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -o-transform: rotateY(180deg); /* Opera */
  -moz-transform: rotateY(180deg);
}

@media (max-width: 1090px) {
  .memori {
    .body-container {
      .cards {
        .cards-pair {
          & > div {
            ul {
              li {
                width: 201px;
                height: 103px;
              }
            }
          }
        }
      }
    }
  }
}

.front {
  transform: rotateY(0deg);
  position: absolute;
  backface-visibility: hidden;
}
.back {
  transform: rotateY(180deg);
  position: absolute;
  backface-visibility: hidden;
}
</style>
<style lang="scss">
.flip-cards {
  &.show-left, &.show-right {
    .selected {

      .card-title.back {
        border-width: 6px;
        transition: all 0.3s ease;
      }
    }
  }
}
.flip-cards {
  &.right-card {
    ul {
      li {
        &.rotate {
          .front {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
  &.show-left, &.show-right  {
    &.rotate {
      ul {
        li {
          .front {
            transform: rotateY(0deg);
            backface-visibility: hidden !important;
          }

          &.fade-in {
            .front, .back {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.rotate {
    li {
      .front {
        transition: 0.8s;
        backface-visibility: visible !important;
      }
    }
  }
  ul {
    &.hide-disable {
      .pairedflip:not(.selected) {
        opacity: 0;
        .back, .front {
          -webkit-backface-visibility: visible;
          backface-visibility: visible;
        }
        .front {
          opacity: 0;
        }
      }
    }

    li {
      &.fade-out {
        .front, .back {
          opacity: 0;
        }
      }

      &.fade-in {
        .front {
          opacity: 1;
          transition: 0.8s;
        }
      }

      &.rotate {
        .front {
          transition: all 0.8s;
          opacity: 0;
          backface-visibility: visible !important;
          transform: rotateY(-180deg);
        }
        .back {
          opacity: 0;
          transition: all 0.8s;
        }
      }
    }
  }
}
</style>
