var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body",class:[_vm.addedClass, {wrong: _vm.isWrong, correct: _vm.isCorrect && _vm.isDone}],style:([
    _vm.cardHeight ? 
      {height: _vm.cardHeight}
    : '',
    _vm.cardWidth ? 
      {width: _vm.cardWidth}
    : '' ]),attrs:{"index":_vm.index},on:{"click":function($event){return _vm.onSelect(_vm.index)}}},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card-border",style:([
        (_vm.isCorrect && _vm.isDone) || _vm.isSelected || _vm.isWrong ?
          {borderWidth: '6px'}
        : '',
        _vm.isSelected ?
          {borderColor: _vm.selectedBorderColor}
        : '',
        _vm.isWrong ?
          {borderColor: _vm.wrongBorderColor}
        : '',
        _vm.isCorrect && _vm.isDone ?
          {borderColor: _vm.correctBorderColor}
        : '',
        ((_vm.isCorrect && _vm.isDone) || _vm.isSelected || _vm.isWrong) && _vm.retroBorderSize ?
          {borderWidth: _vm.retroBorderSize}
        : '' ])}),_c('div',{staticClass:"card-content",style:([
        _vm.isWrong ?
          {backgroundColor: _vm.backgroundColorWrong, boxShadow: 'none'}
        : '' ])},[(_vm.isDone && _vm.isSelected)?_c('Notify',{attrs:{"hasBorder":false,"addedClass":"multi-a"}}):_vm._e(),_c('div',{staticClass:"content",class:[{'bounce': _vm.isConfirmBump}],style:([
          _vm.cardFontColor ?
            {color: _vm.cardFontColor}
          : '',
          _vm.cardFontSize ?
            {fontSize: _vm.cardFontSize}
          : '',
          _vm.cardPaddingTop ? 
            {paddingTop: _vm.cardPaddingTop}
          : '',
          _vm.cardPaddingBottom ? 
            {paddingBottom: _vm.cardPaddingBottom}
          : '',
          _vm.cardPaddingWidth ? 
            {
              paddingLeft: 'calc(' + _vm.cardPaddingWidth + ' / 2)', 
              paddingRight: 'calc(' + _vm.cardPaddingWidth + ' / 2)'
            }
          : '',
          _vm.cardContentPosition ?
            {justifyContent: _vm.cardContentPosition}
          : '' ])},[(_vm.displayTypeAlt && _vm.displaySourceAlt && _vm.alternateEnable)?_c('AlternateDataA',{class:[{'fade-in': (_vm.isCorrect && _vm.isDone) || _vm.isWrong || _vm.isUsed}],style:([
            _vm.cardPaddingTop && ((_vm.isCorrect && _vm.isDone) || _vm.isWrong || _vm.isUsed) ? 
              {paddingTop: _vm.cardPaddingTop}
            : '',
            _vm.cardPaddingBottom && ((_vm.isCorrect && _vm.isDone) || _vm.isWrong || _vm.isUsed) ? 
              {paddingBottom: _vm.cardPaddingBottom}
            : '',
            _vm.cardPaddingWidth && ((_vm.isCorrect && _vm.isDone) || _vm.isWrong || _vm.isUsed) ? 
              {
                paddingLeft: 'calc(' + _vm.cardPaddingWidth + ' / 2)', 
                paddingRight: 'calc(' + _vm.cardPaddingWidth + ' / 2)'
              }
            : '' ]),attrs:{"data":_vm.data,"isDone":_vm.isDone,"isWrong":_vm.isWrong,"isCorrect":_vm.isCorrect,"cardFontSize":_vm.cardFontSize,"cardFontColor":_vm.cardFontColor,"displayTypeAlt":_vm.displayTypeAlt,"displaySourceAlt":_vm.displaySourceAlt,"assetMediaImageUrl":_vm.assetMediaImageUrl,"cardContentPosition":_vm.cardContentPosition}}):_vm._e(),(_vm.displayType == 'image')?_c('div',{staticClass:"card-img",class:[{'fade-out': ((_vm.isCorrect && _vm.isDone) || _vm.isWrong || _vm.isUsed) && _vm.alternateEnable && (_vm.displayTypeAlt && _vm.displaySourceAlt)}],style:([
            _vm.cardContentPosition ?
              {justifyContent: _vm.cardContentPosition}
            : '' ])},[(_vm.displaySource)?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],style:([
              (_vm.isSelected || _vm.isWrong) && _vm.isSelectBump ?
                {transform: 'scale(0.8)', transition: 'all .3s ease-in-out'}
              : '',
              _vm.isSvg(_vm.data) ?
                {height: '-webkit-fill-available'}
              : '' ]),attrs:{"draggable":"false","src":_vm.assetMediaImageUrl + _vm.data.element[_vm.displaySource],"width":_vm.isSvg(_vm.data) ? '': '210',"height":_vm.isSvg(_vm.data) ? '': '160'}}):_vm._e()]):_vm._e(),(_vm.displayType == 'text')?_c('div',{staticClass:"card-title",class:[{'fade-out': ((_vm.isCorrect && _vm.isDone) || _vm.isWrong || _vm.isUsed) && _vm.alternateEnable && (_vm.displayTypeAlt && _vm.displaySourceAlt)}],style:([
            (_vm.isSelected || _vm.isWrong) && _vm.isSelectBump ? 
              {transform: 'scale(0.8)', transition: 'all .3s ease-in-out'}
            : '',
            _vm.cardFontSize ?
              {fontSize: _vm.cardFontSize} 
            : '',
            _vm.cardContentPosition ?
              {textAlign: _vm.cardContentPosition == 'flex-end' ? 'right' : (_vm.cardContentPosition == 'start' ? 'left' : 'center')}
            : '' ])},[_vm._v(" "+_vm._s(_vm.displaySource ? _vm.data.element[_vm.displaySource] : '')+" ")]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }