import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const MultiC = Object.create(Base);

MultiC.correctBlacklist = [];
MultiC.incorrectBlacklist = [];
MultiC.collections = new Map();

MultiC.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.correctBlacklist, "correct_blacklist");
    LocalStorage.saveLocalStorage(this.incorrectBlacklist, "incorrect_blacklist");
    LocalStorage.saveLocalStorage(this.collections, "collections");
}

MultiC.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.correctBlacklist);
    Util.clearElements(this.incorrectBlacklist);
    this.collections.clear();

    let config = this.configuration.data.config;
    let collections = config.collections;
    let valueSource = config.valueSource;

    this.database = await this.populateCollections(collections);

    for (let element of this.database)
        this.setMapValue(this.collections, element, valueSource);
}

MultiC.getElements = function (n, m, i) {
    if (n < 0 || m < 0 || i < 0)
        throw new Error("Negative argument is invalid.");

    if (Number.isInteger(i))
        return changeElements(i, this.collections, this.incorrectBlacklist);

    this.releaseBlacklist(this.collections, this.incorrectBlacklist);

    let correct = this.fetchElements(n, this.collections, this.correctBlacklist).map(obj => Util.assign(obj, {isCorrect: true}));
    let incorrect = pickSameElements(m, this.collections, this.incorrectBlacklist).map(obj => Util.assign(obj, {isCorrect: false}));

    let elements = correct.concat(incorrect);
    Util.shuffle(elements);

    Util.clearElements(this.currentElements);
    Util.addElements(this.currentElements, elements);

    return this.currentElements;
}

const changeElements = function (i, elements, blacklist) {
    let size = MultiC.currentElements.length - 1;
    let incorrect = pickSameElements(size, elements, blacklist).map(obj => Util.assign(obj, {isCorrect: false}));

    let correct = [MultiC.currentElements[i]];
    let keys = [...MultiC.currentElements.keys()];
    keys.splice(i, 1);

    let key = Util.getRandom(keys.length);
    let j = keys[key];

    let currentElements = correct.concat(incorrect);
    Util.swap(currentElements, 0, i);
    Util.swap(currentElements, i, j);

    Util.clearElements(MultiC.currentElements);
    Util.addElements(MultiC.currentElements, currentElements);

    return MultiC.currentElements;
}

const pickSameElements = function (n, elements, blacklist) {
    const ret = [];

    if (0 === elements.size)
        return ret;

    const keys = Array.from(elements.keys());
    const index = Util.getRandom(keys.length);
    const key = keys[index];
    const val = elements.get(key);
    const values = val.slice(0);
    const m = n > values.length ? values.length : n;

    for (let i = 0; i < m; ++i) {
        let j = Util.getRandom(values.length);
        ret.push({element: values[j]});
        values.splice(j, 1);
    }

    if (ret.length > 0) {
        blacklist.push(val);
        elements.delete(key);
    }

    return ret;
}

export default MultiC;
