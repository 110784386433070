import { render, staticRenderFns } from "./MultiALayoutB.vue?vue&type=template&id=956d0e90&scoped=true&"
import script from "./MultiALayoutB.vue?vue&type=script&lang=js&"
export * from "./MultiALayoutB.vue?vue&type=script&lang=js&"
import style0 from "./MultiALayoutB.vue?vue&type=style&index=0&id=956d0e90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "956d0e90",
  null
  
)

export default component.exports