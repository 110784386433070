var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cards-container",class:{'two-line-title': _vm.titleStyle, 'secondary-theme': _vm.templateName === _vm.templateMultiC}},[(!_vm.isFinal)?_c('div',{staticClass:"displayCards cards",class:{correct: _vm.isCorrect, 'display-info': _vm.isDisplayInfo}},_vm._l((_vm.displayedCards),function(card,index){return (card.isShow)?_c('div',{key:card.id,staticClass:"card",class:{selected: card.isSelected, wrong: card.isWrongPick, 'disabled-click': _vm.disableCards, used: card.isUsed},attrs:{"id":'card'+index,"css":false}},[_c('div',{staticClass:"card-box",class:{
            'no-audio': !_vm.isShowAudioButton
          },style:([
            card.isWrongPick ?
              {backgroundColor: _vm.backgroundColorWrong}
            : '' ]),on:{"click":function($event){return _vm.clickCard(card.id)}}},[(_vm.isCorrect && card.isSelected)?_c('Notify',{attrs:{"hasBorder":false,"addedClass":"multi-a"}}):_vm._e(),_c('div',{staticClass:"card-border",style:([
              card.isSelected ?
                {borderColor: _vm.selectedBorderColor}
              : '',
              card.isWrongPick ?
                {borderColor: _vm.wrongBorderColor}
              : '',
              _vm.isCorrect && card.isCorrect ?
                {borderColor: _vm.correctBorderColor}
              : '',
              (_vm.isCorrect && card.isCorrect) || card.isSelected || card.isWrongPick ?
                {borderWidth: '6px'}
              : '',
              ((_vm.isCorrect && card.isCorrect) || card.isSelected || card.isWrongPick) && _vm.retroBorderSize ?
                {borderWidth: _vm.retroBorderSize}
              : '' ])}),_c('div',{staticClass:"card-content"},[( _vm.tag && card.isCorrect)?_c('div',{staticClass:"card-sound"},[_c('span',[_vm._v(_vm._s(_vm.tag))])]):_vm._e(),_c('div',{staticClass:"card-img-border",style:([
                ((_vm.isCorrect && card.isCorrect) || card.isWrongPick || _vm.isDisplayInfo) && _vm.retroBorderSize ?
                  {
                    width: 'calc(100% - 25px - ' + _vm.retroBorderSize + ' - ' + _vm.retroBorderSize + ')',
                    height: 'calc(100% - 65px - ' + _vm.retroBorderSize + ' - ' + _vm.retroBorderSize + ')',
                    marginBottom: 'calc(40px + ' + _vm.retroBorderSize + ')',
                  }
                : '' ])}),_c('div',{staticClass:"card-img",style:([
                _vm.cardContentPosition ?
                  {justifyContent: _vm.cardContentPosition, textAlign: _vm.cardContentPosition}
                : '',
                _vm.cardPaddingTop ?
                  {paddingTop: _vm.cardPaddingTop}
                : '',
                _vm.cardPaddingBottom ?
                  {paddingBottom: _vm.cardPaddingBottom}
                : '',
                _vm.cardPaddingWidth ?
                  {
                    paddingLeft: 'calc(' + _vm.cardPaddingWidth + ' / 2)', 
                    paddingRight: 'calc(' + _vm.cardPaddingWidth + ' / 2)'
                  }
                : '',
                (_vm.isCorrect && card.isCorrect) || card.isWrongPick ?
                  {
                    width: 'calc(100% - 25px - ' + _vm.retroBorderSize + ' - ' + _vm.retroBorderSize + ')',
                    height: 'calc(100% - 65px - ' + _vm.retroBorderSize + ' - ' + _vm.retroBorderSize + ')',
                    marginBottom: '18px',
                    transform: 'scale(0.9)'
                  }
                : '' ])},[(_vm.displayType == 'image')?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],style:([
                  _vm.isSvg(card) ?
                    {height: '-webkit-fill-available'}
                  : '' ]),attrs:{"src":_vm.assetMediaImageUrl + card.element[_vm.displaySource],"draggable":"false","width":_vm.isSvg(card) ? '': '225',"height":_vm.isSvg(card) ? '': '231'}}):(_vm.displayType == 'text')?_c('span',{style:([
                  _vm.cardFontColor ?
                    {color: _vm.cardFontColor}
                  : '',
                  _vm.cardFontSize ?
                    {fontSize: _vm.cardFontSize}
                  : '',
                  _vm.cardContentPosition ?
                    {textAlign: _vm.cardContentPosition == 'flex-end' ? 'right' : (_vm.cardContentPosition == 'start' ? 'left' : 'center') }
                  : '' ])},[_vm._v(" "+_vm._s(card.element[_vm.displaySource])+" ")]):_vm._e()]),_c('div',{staticClass:"card-title",style:([
                _vm.answerDisplayFontSize ?
                  {fontSize: _vm.answerDisplayFontSize}
                  : '',
                !card.isCorrect && (_vm.isCorrect || card.isWrongPick) ?
                  {color: _vm.wrongBorderColor}
                : '',
                ((_vm.isCorrect && card.isCorrect) || card.isWrongPick || _vm.isDisplayInfo) && _vm.retroBorderSize ?
                  {
                    marginBottom: 'calc(2px + ' + _vm.retroBorderSize + ')',
                  }
                : '',
                _vm.isCorrect && card.isCorrect ?
                  {color: _vm.correctBorderColor}
                : '' ])},[_vm._v(" "+_vm._s(_vm.answerDisplaySource ? card.element[_vm.answerDisplaySource] : '')+" ")])])],1)]):_vm._e()}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }