import Vue from 'vue'
import LocalStorage from './LocalStorage'
import Api from './Api'
const LOCAL_STORAGE_APP_CONFIG = "APP_CONFIG";
const LOCAL_STORAGE_CONFIG_NAME = "CONFIG";
const LOCAL_STORAGE_DATABASE_NAME = "DATABASE";
var configName = '';
var configData = [];
var database = [];
var weeksData = 1;
var assetsJson = process.env.VUE_APP_CONFIG_URL;

export default {
    getDatabase: function() {
        return LocalStorage.getLocalStorage(LOCAL_STORAGE_DATABASE_NAME);
    },
    setDatabase: function(paramDatabaseData) {
        LocalStorage.saveLocalStorage(paramDatabaseData, LOCAL_STORAGE_DATABASE_NAME);
    },
    getWeeks: function() {
        return weeksData;
    },
    setWeeks: function(paramWeeksData) {
        weeksData = paramWeeksData;
    },
    getConfigName: function() {
        return configName;
    },
    setConfigName: function(name) {
        configName = name;
    },
    getConfig: function() {
        return LocalStorage.getLocalStorage(LOCAL_STORAGE_CONFIG_NAME);
    },
    setConfig: function(paramConfigData) {
        LocalStorage.saveLocalStorage(paramConfigData, LOCAL_STORAGE_CONFIG_NAME);
    },
    getVerifyConfig: function(paramConfigName) {
        configName = paramConfigName;
        return Vue.http.get( assetsJson + configName + '.config.json')
    },
    getVerifyDatabase: function(paramDatabase) {
        return Vue.http.get( paramDatabase );
    },
    getConfiguration: function (configName) {
        const url =  process.env.VUE_APP_API_URL + process.env.VUE_APP_READ_PATH;
        const params = {
            tname: process.env.VUE_APP_TABLE_NAME,
            pkname: 'configName',
            pkvalue: configName,
            idxname: 'configName-index'
        };

        return Api.get(url, params);
    },
    getTheme: function (themeId) {
        const url =  process.env.VUE_APP_API_URL + process.env.VUE_APP_READ_PATH;
        const params = {
            tname: process.env.VUE_APP_TABLE_NAME,
            pkname: 'PK',
            pkvalue: 'activityTheme',
            fname: 'SK1',
            fvalue: themeId
        };

        return Api.get(url, params);
    },
    initAppConfig () {
        const url =  process.env.VUE_APP_API_URL + process.env.VUE_APP_READ_PATH;
        const params = {
            tname: process.env.VUE_APP_TABLE_NAME,
            pkname: 'PK',
            pkvalue: 'iaAppConfig'
        };

        return Api.get(url, params);
    }
}
