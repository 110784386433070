<template>
  <div 
    class="audio"
    :class="classAdded" 
    v-on:click="onPlayAudio(messageAudioFile, isDataSound)"
  >
    <img draggable="false" :src="myHome.getThemeImage('audio.svg')" alt="">
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'AudioA',
  props: [
    "messageAudioFile",
    "classAdded",
    "isDataSound",
    "assetMediaAudioUrl"
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },

  methods: {
    
    onPlayAudio: function(sound, isDataSound = true) {
      if(typeof this.myComponent.audio.ended === 'undefined' || this.myComponent.audio.ended || this.myComponent.audio.error) {
        var audio;
        var source = (isDataSound) ? this.assetMediaAudioUrl : '';
        
        audio = new Audio(source + sound);
        this.myComponent.audio = audio;
        audio.play();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.audio {
  cursor: pointer;
  height: 40px;
  width: 40px;

  &.final {
    margin-bottom: 2px;
  }

  &.content-audio {
    align-self: flex-end;
    margin-top: -3px;
    margin-right: 6px;
    animation-name: bounce-in;
    animation-duration: 0.3s;
  }

  &.title-audio {
    display: inline-flex;
    vertical-align: -7px;

    img {
      width: 40px;
      height: 48px;
      border-radius: 20px;
      position: absolute;
    }
  }

  &.multi {
    display: flex;
    justify-content: center;
    height: 48px;
    align-items: center;
    width: 100%;

    img {
      width: 40px;
      height: 48px;
    }
  }

  &.multia-layoutb-audio, &.multib-question-audio {
    margin: 0 15px 0 0;
    cursor: pointer;
    line-height: 0;
    width: 41px;
    height: 40px;
    padding-left: 1px;
  }

  &.multia-layoutb-audio {
    position: absolute;
    left: 0;
  }

  &.ortho {
    cursor: pointer;
    height: 40px;
    width: 40px;
    z-index: 1;

    img {
      width: 40px;
      height: 48px;
      z-index: 1;
    }
  }
}
@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  90% {
    transform: scale(0.9);
  }
}
</style>
