import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const Memo = Object.create(Base);

Memo.currentElements = {left: [], right: [], merged: []};
Memo.commonBlacklist = [];
Memo.commonElements = new Map();
Memo.leftElements = new Map();
Memo.rightElements = new Map();

const pickElementsByKeys = function (map, keys) {
    const ret = [];

    for (let key of keys)
        ret.push({element: Memo.getMapValue(map, key)});

    return ret;
}

Memo.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.commonBlacklist, "common_blacklist");
    LocalStorage.saveLocalStorage(this.commonElements, "common_elements");
    LocalStorage.saveLocalStorage(this.leftElements, "left_elements");
    LocalStorage.saveLocalStorage(this.rightElements, "right_elements");
}

Memo.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements.left);
    Util.clearElements(this.currentElements.right);
    Util.clearElements(this.currentElements.merged);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.commonBlacklist);
    this.commonElements.clear();
    this.leftElements.clear();
    this.rightElements.clear();

    let config = this.configuration.data.config;
    let valueSource = config.valueSource;
    let leftCollections = config.leftCollections;
    let rightCollections = config.rightCollections;

    let right = await this.populateCollections(rightCollections);

    for (let element of right)
        this.setMapValue(this.rightElements, element, valueSource);

    let left = await this.populateCollections(leftCollections);
    this.database = left.concat(right);

    for (let element of left) {
        this.setMapValue(this.leftElements, element, valueSource);

        if ( this.rightElements.has(element[valueSource]) )
            this.setMapValue(this.commonElements, element, valueSource);
    }
}

Memo.getElements = function (n) {
    if (n < 0)
        throw new Error("Negative argument is invalid.");

    let config = this.configuration.data.config;
    let valueSource = config.valueSource;
    let format = config.layout.format;

    let left = this.fetchElements(n, this.commonElements, this.commonBlacklist);
    let keys = [];

    for (let obj of left)
        keys.push(obj.element[valueSource]);

    let right = pickElementsByKeys(this.rightElements, keys);

    Util.shuffle(left);
    Util.shuffle(right);

    Util.clearElements(this.currentElements.left);
    Util.clearElements(this.currentElements.right);

    Util.addElements(this.currentElements.left, left);
    Util.addElements(this.currentElements.right, right);

    if ('1col' === format) {
        let merged = left.concat(right);
        Util.shuffle(merged);
        Util.clearElements(this.currentElements.merged);
        Util.addElements(this.currentElements.merged, merged);
    }

    return this.currentElements;
}

export default Memo;
