<template>
  
  <div class="wheels" :class="{finish: isFinish}">
    <WheelB
      wheelName                   ="first"
      :wheelItem                  ="wheelOneItem"
      :templateName               ="templateName"
      :templateAssoA              ="templateAssoA"
      :templateAssoB              ="templateAssoB"
      :templateAssoC              ="templateAssoC"
      selectedClassName           ="selectedFirst"
      :ref                         ="'parentFirstWheel'"
      :wheelIsCrit                ="wheelOneIsCrit"
      :wheelCSSData               ="wheelOneCSSData"
      :buttonFinishStyle          ="buttonFinishStyle"
      :displayedWheel             ="wheelOneDisplayed"
      :wheelThemeColorBorder      ="wheelThemeColorBorder"
      :isButtonDisableWheel       ="wheelOneIsButtonDisable"
      :wheelDisplayType           ="wheelOneDisplayType"
      :wheelDisplaySource         ="wheelOneDisplaySource"
      :wheelTypeThemeImage        ="wheelOneThemeImage"
      :isWheelDisableFromConfig   ="wheelOneIsDisable"
      :tagImage                   ="tagImage"
      :buttonUp                   ="buttonUp"
      :buttonUpDisable            ="buttonUpDisable"
      :buttonDown                 ="buttonDown"
      :buttonDownDisable          ="buttonDownDisable"
      :background                 ="background"
      :overlay                    ="overlay"
      :removeAllBorders           ="removeAllBorders"
      :previousText               ="wheelOnePreviousText"
      :themeLocation              ="themeLocation"
      :themeName                  ="themeName"
      :isFinal                    ="isFinish"
      :assetMediaImageUrl         ="assetMediaImageUrl"
      :wrongBorderColor           ="wrongBorderColor"
      :selectedBorderColor        ="selectedBorderColor"
      :correctBorderColor         ="correctBorderColor"
      :backgroundColorWrong       ="backgroundColorWrong"
      :wheelBorderColor           ="wheelBorderColor"
      :wheelDisableBackgroundColor="wheelDisableBackgroundColor"
      :wheelDisableElementOpacity ="wheelDisableElementOpacity"
      :valueSource                ="valueSource"
      :numberOfDisable            ="numberOfDisable"
      :wheelCardFontColor         ="wheelOneCardFontColor"
      :wheelCardFontSize          ="wheelOneCardFontSize"
      :wheelCardPaddingTop        ="wheelOneCardPaddingTop"
      :wheelCardPaddingBottom     ="wheelOneCardPaddingBottom"
      :wheelCardPaddingWidth      ="wheelOneCardPaddingWidth"
      :wheelCardWidth             ="wheelOneCardWidth"
      :wheelCardHeight            ="wheelOneCardHeight"
      :wheelRetroBorderSize       ="wheelOneRetroBorderSize"
      :wheelCardContentPosition   ="wheelOneCardContentPosition"
      :wheelFadeData              ="wheelFadeData"
    >
    </WheelB>

    <WheelB
      wheelName                   ="second"
      :wheelItem                  ="wheelTwoItem"
      :templateName               ="templateName"
      :templateAssoA              ="templateAssoA"
      :templateAssoB              ="templateAssoB"
      :templateAssoC              ="templateAssoC"
      selectedClassName           ="selectedSecond"
      :ref                         ="'parentSecondWheel'"
      :wheelIsCrit                ="(this.templateName === this.templateAssoB)? true : wheelTwoIsCrit"
      :wheelCSSData               ="wheelTwoCSSData"
      :buttonFinishStyle          ="buttonFinishStyle"
      :displayedWheel             ="wheelTwoDisplayed"
      :wheelThemeColorBorder      ="wheelThemeColorBorder"
      :isButtonDisableWheel       ="wheelTwoIsButtonDisable"
      :wheelDisplayType           ="wheelTwoDisplayType"
      :wheelDisplaySource         ="wheelTwoDisplaySource"
      :wheelTypeThemeImage        ="wheelTwoThemeImage"
      :isWheelDisableFromConfig   ="wheelTwoIsDisable"
      :tagImage                   ="tagImage"
      :buttonUp                   ="buttonUp"
      :buttonUpDisable            ="buttonUpDisable"
      :buttonDown                 ="buttonDown"
      :buttonDownDisable          ="buttonDownDisable"
      :background                 ="background"
      :overlay                    ="overlay"
      :removeAllBorders           ="removeAllBorders"
      :previousText               ="wheelTwoPreviousText"
      :themeLocation              ="themeLocation"
      :themeName                  ="themeName"
      :isFinal                    ="isFinish"
      :assetMediaImageUrl         ="assetMediaImageUrl"
      :wrongBorderColor           ="wrongBorderColor"
      :selectedBorderColor        ="selectedBorderColor"
      :correctBorderColor         ="correctBorderColor"
      :backgroundColorWrong       ="backgroundColorWrong"
      :wheelBorderColor           ="wheelBorderColor"
      :wheelDisableBackgroundColor="wheelDisableBackgroundColor"
      :wheelDisableElementOpacity ="wheelDisableElementOpacity"
      :valueSource                ="valueSource"
      :numberOfDisable            ="numberOfDisable"
      :wheelCardFontColor         ="wheelTwoCardFontColor"
      :wheelCardFontSize          ="wheelTwoCardFontSize"
      :wheelCardPaddingTop        ="wheelTwoCardPaddingTop"
      :wheelCardPaddingBottom     ="wheelTwoCardPaddingBottom"
      :wheelCardPaddingWidth      ="wheelTwoCardPaddingWidth"
      :wheelCardWidth             ="wheelTwoCardWidth"
      :wheelCardHeight            ="wheelTwoCardHeight"
      :wheelRetroBorderSize       ="wheelTwoRetroBorderSize"
      :wheelCardContentPosition   ="wheelTwoCardContentPosition"
      :wheelFadeData              ="wheelFadeData"
    >
    </WheelB>

    <WheelB
      v-if="!isTwoWheels"
      wheelName                   ="third"
      :wheelItem                  ="wheelThreeItem"
      :templateName               ="templateName"
      :templateAssoA              ="templateAssoA"
      :templateAssoB              ="templateAssoB"
      :templateAssoC              ="templateAssoC"
      selectedClassName           ="selectedThird"
      :ref                        ="'parentThirdWheel'"
      :wheelIsCrit                ="wheelThreeIsCrit"
      :wheelCSSData               ="wheelThreeCSSData"
      :buttonFinishStyle          ="buttonFinishStyle"
      :displayedWheel             ="wheelThreeDisplayed"
      :wheelThemeColorBorder      ="wheelThemeColorBorder"
      :isButtonDisableWheel       ="wheelThreeIsButtonDisable"
      :wheelDisplayType           ="wheelThreeDisplayType"
      :wheelDisplaySource         ="wheelThreeDisplaySource"
      :wheelTypeThemeImage        ="wheelThreeThemeImage"
      :isWheelDisableFromConfig   ="wheelThreeIsDisable"
      :tagImage                   ="tagImage"
      :buttonUp                   ="buttonUp"
      :buttonUpDisable            ="buttonUpDisable"
      :buttonDown                 ="buttonDown"
      :buttonDownDisable          ="buttonDownDisable"
      :background                 ="background"
      :overlay                    ="overlay"
      :removeAllBorders           ="removeAllBorders"
      :previousText               ="wheelThreePreviousText"
      :themeLocation              ="themeLocation"
      :themeName                  ="themeName"
      :isFinal                    ="isFinish"
      :assetMediaImageUrl         ="assetMediaImageUrl"
      :wrongBorderColor           ="wrongBorderColor"
      :selectedBorderColor        ="selectedBorderColor"
      :correctBorderColor         ="correctBorderColor"
      :backgroundColorWrong       ="backgroundColorWrong"
      :wheelBorderColor           ="wheelBorderColor"
      :wheelDisableBackgroundColor="wheelDisableBackgroundColor"
      :wheelDisableElementOpacity ="wheelDisableElementOpacity"
      :valueSource                ="valueSource"
      :numberOfDisable            ="numberOfDisable"
      :wheelCardFontColor         ="wheelThreeCardFontColor"
      :wheelCardFontSize          ="wheelThreeCardFontSize"
      :wheelCardPaddingTop        ="wheelThreeCardPaddingTop"
      :wheelCardPaddingBottom     ="wheelThreeCardPaddingBottom"
      :wheelCardPaddingWidth      ="wheelThreeCardPaddingWidth"
      :wheelCardWidth             ="wheelThreeCardWidth"
      :wheelCardHeight            ="wheelThreeCardHeight"
      :wheelRetroBorderSize       ="wheelThreeRetroBorderSize"
      :wheelCardContentPosition   ="wheelThreeCardContentPosition"
      :wheelFadeData              ="wheelFadeData"
    >
    </WheelB>
  </div>
</template>

<script>
import Vue from 'vue'
import WheelB from '../components/WheelB'
import Velocity from 'velocity-animate'

export default {
  name: 'AssoBLayoutA',
  components: {
    'WheelB': WheelB,
  },
  props: [
    "isFinish",
    "isTwoWheels",
    "templateName",
    "templateAssoA",
    "templateAssoB",
    "templateAssoC",
    "wheelOneItem",
    "wheelOneIsCrit",
    "wheelOneCSSData",
    "buttonFinishStyle",
    "wheelOneDisplayed",
    "wheelThemeColorBorder",
    "wheelOneIsButtonDisable",
    "wheelOneDisplayType",
    "wheelOneDisplaySource",
    "wheelOneThemeImage",
    "wheelOneIsDisable",
    "tagImage",
    "buttonUp",
    "buttonUpDisable",
    "buttonDown",
    "buttonDownDisable",
    "background",
    "overlay",
    "removeAllBorders",
    "wheelOnePreviousText",
    "wheelOneCardFontColor",
    "wheelOneCardFontSize",
    "wheelOneCardPaddingTop",
    "wheelOneCardPaddingBottom",
    "wheelOneCardPaddingWidth",
    "wheelOneCardWidth",
    "wheelOneCardHeight",
    "wheelOneRetroBorderSize",
    "wheelOneCardContentPosition",
    "themeLocation",
    "themeName",
    "isFinal",
    "wheelTwoItem",
    "wheelTwoIsCrit",
    "wheelTwoCSSData",
    "wheelTwoDisplayed",
    "wheelTwoIsButtonDisable",
    "wheelTwoDisplayType",
    "wheelTwoDisplaySource",
    "wheelTwoThemeImage",
    "wheelTwoIsDisable",
    "wheelTwoPreviousText",
    "wheelTwoCardFontColor",
    "wheelTwoCardFontSize",
    "wheelTwoCardPaddingTop",
    "wheelTwoCardPaddingBottom",
    "wheelTwoCardPaddingWidth",
    "wheelTwoCardWidth",
    "wheelTwoCardHeight",
    "wheelTwoRetroBorderSize",
    "wheelTwoCardContentPosition",
    "wheelThreeItem",
    "wheelThreeIsCrit",
    "wheelThreeCSSData",
    "wheelThreeDisplayed",
    "wheelThreeIsButtonDisable",
    "wheelThreeDisplayType",
    "wheelThreeDisplaySource",
    "wheelThreeThemeImage",
    "wheelThreeIsDisable",
    "wheelThreePreviousText",
    "wheelThreeCardFontColor",
    "wheelThreeCardFontSize",
    "wheelThreeCardPaddingTop",
    "wheelThreeCardPaddingBottom",
    "wheelThreeCardPaddingWidth",
    "wheelThreeCardWidth",
    "wheelThreeCardHeight",
    "wheelThreeRetroBorderSize",
    "wheelThreeCardContentPosition",
    "assetMediaImageUrl",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "backgroundColorWrong",
    "wheelBorderColor",
    "wheelDisableBackgroundColor",
    "wheelDisableElementOpacity",
    "valueSource",
    "numberOfDisable",
    "wheelFadeData",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
  created() {
    this.$parent.myComponent.wheelLayout = this;
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.wheels {
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}
</style>
