window.$ = require('jquery');

import Vue from 'vue'
import App from './App'
import router from './router/index'
import VueResource from 'vue-resource'
import AWS from 'aws-sdk'
import VueFlip from 'vue-flip'
import retina from 'retinajs'
import VueRetina from 'vue-retina'
import store from './store/Vuex'

Vue.use(VueResource);
Vue.use(VueRetina, {retina});
Vue.use(AWS);
Vue.use(VueFlip);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
