<template>
  <svg version="1.1" id="el_81MCHfCMO" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">

    <g id="el_rQ2t0VYlcM_an_Nh79gMRee" data-animator-group="true" data-animator-type="2">
      <circle cx="30" cy="30" r="20" :class="{border: hasBorder}" id="el_rQ2t0VYlcM"/>
    </g>
    <polyline points="18.6,29.1 26.8,37.4 41.4,22.6 " id="el_rUeGBLR5Sd"/>
    <rect width="60" height="60" id="el_HmZpNDw-Mn"/>
  </svg>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'GreenCheck',
  props: [
    "hasBorder",
  ],
}
</script>

<style scoped>
@-webkit-keyframes kf_el_rUeGBLR5Sd_an_nvhh_KWthf {
  52.38% {
      stroke-dasharray: 32.46;
  }
  0% {
      stroke-dasharray: 32.46;
  }
  100% {
      stroke-dasharray: 32.46;
  }
}

@keyframes kf_el_rUeGBLR5Sd_an_nvhh_KWthf {
  52.38% {
      stroke-dasharray: 32.46;
  }
  0% {
      stroke-dasharray: 32.46;
  }
  100% {
      stroke-dasharray: 32.46;
  }
}

@-webkit-keyframes kf_el_rUeGBLR5Sd_an_dljelk7PZ {
  52.38% {
      stroke-dashoffset: 32.46;
  }
  85.71% {
      stroke-dashoffset: 0;
  }
  0% {
      stroke-dashoffset: 32.46;
  }
  100% {
      stroke-dashoffset: 0;
  }
}

@keyframes kf_el_rUeGBLR5Sd_an_dljelk7PZ {
  52.38% {
      stroke-dashoffset: 32.46;
  }
  85.71% {
      stroke-dashoffset: 0;
  }
  0% {
      stroke-dashoffset: 32.46;
  }
  100% {
      stroke-dashoffset: 0;
  }
}

@-webkit-keyframes kf_el_rQ2t0VYlcM_an_Nh79gMRee {
  0% {
      -webkit-transform: translate(30px, 30px) scale(0.1, 0.1) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(0.1, 0.1) translate(-30px, -30px);
  }
  23.81% {
      -webkit-transform: translate(30px, 30px) scale(1.25, 1.25) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(1.25, 1.25) translate(-30px, -30px);
  }
  38.10% {
      -webkit-transform: translate(30px, 30px) scale(0.75, 0.75) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(0.75, 0.75) translate(-30px, -30px);
  }
  52.38% {
      -webkit-transform: translate(30px, 30px) scale(1, 1) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(1, 1) translate(-30px, -30px);
  }
  100% {
      -webkit-transform: translate(30px, 30px) scale(1, 1) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(1, 1) translate(-30px, -30px);
  }
}

@keyframes kf_el_rQ2t0VYlcM_an_Nh79gMRee {
  0% {
      -webkit-transform: translate(30px, 30px) scale(0.1, 0.1) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(0.1, 0.1) translate(-30px, -30px);
  }
  23.81% {
      -webkit-transform: translate(30px, 30px) scale(1.25, 1.25) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(1.25, 1.25) translate(-30px, -30px);
  }
  38.10% {
      -webkit-transform: translate(30px, 30px) scale(0.75, 0.75) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(0.75, 0.75) translate(-30px, -30px);
  }
  52.38% {
      -webkit-transform: translate(30px, 30px) scale(1, 1) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(1, 1) translate(-30px, -30px);
  }
  100% {
      -webkit-transform: translate(30px, 30px) scale(1, 1) translate(-30px, -30px);
      transform: translate(30px, 30px) scale(1, 1) translate(-30px, -30px);
  }
}

#el_81MCHfCMO * {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_rQ2t0VYlcM.border {
  stroke: #FFFFFF;
  stroke-width: 1;
}

#el_rQ2t0VYlcM {
  fill: #429A35;
}

#el_rUeGBLR5Sd {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 5;
  stroke-miterlimit: 10;
  -webkit-animation-fill-mode: forwards, forwards;
  animation-fill-mode: forwards, forwards;
  -webkit-animation-name: kf_el_rUeGBLR5Sd_an_dljelk7PZ, kf_el_rUeGBLR5Sd_an_nvhh_KWthf;
  animation-name: kf_el_rUeGBLR5Sd_an_dljelk7PZ, kf_el_rUeGBLR5Sd_an_nvhh_KWthf;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1), cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1), cubic-bezier(0, 0, 1, 1);
}

#el_HmZpNDw-Mn {
  fill: none;
}

#el_rQ2t0VYlcM_an_Nh79gMRee {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: kf_el_rQ2t0VYlcM_an_Nh79gMRee;
  animation-name: kf_el_rQ2t0VYlcM_an_Nh79gMRee;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
</style>
