<template>
  <div
    class="audio"
    :class="classAdded"
    v-on:click="googleVoice()"
  >
    <img draggable="false" :src="myHome.getThemeImage('audio.svg')" alt="">
  </div>
</template>

<script>
import Vue from 'vue'
import GoogleVoice from '../models/GoogleVoice'

export default {
  name: 'AudioB',
  props: [
    "classAdded",
    "correctWord",
    "audioType",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
      audioFile: '',
    }
  },
  methods: {
    googleVoice: async function() {
      if(typeof this.myComponent.audio.ended === 'undefined' || this.myComponent.audio.ended || this.myComponent.audio.error) {
        if (this.audioFile) {
          let sound = new Audio(this.audioFile);
          this.myComponent.audio = sound;
          sound.play();
        }
        return;
      }
    }
  },
  created: async function () {
    this.audioFile = await GoogleVoice.getAudioURL(this.correctWord, this.myComponent.config.data.config[this.audioType]);
  },
  watch: {
    async correctWord(data) {
      this.audioFile = await GoogleVoice.getAudioURL(data, this.myComponent.config.data.config[this.audioType]);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.audio {
  cursor: pointer;
  height: 40px;
  width: 40px;

  &.final {
    margin-bottom: 2px;
  }

  &.content-audio {
    align-self: flex-end;
    margin-top: -3px;
    margin-right: 6px;
    animation-name: bounce-in;
    animation-duration: 0.3s;
  }

  &.multi {
    display: flex;
    justify-content: center;
    height: 48px;
    align-items: center;
    width: 100%;

    img {
      width: 40px;
      height: 48px;
    }
  }

  &.title-audio {
    display: inline-flex;
    vertical-align: -7px;

    img {
      width: 40px;
      height: 48px;
      border-radius: 20px;
      position: absolute;
    }
  }

  &.multia-layoutb-audio, &.multib-question-audio {
    margin: 0 15px 0 0;
    cursor: pointer;
    line-height: 0;
    width: 41px;
    height: 40px;
    padding-left: 1px;
  }

  &.multia-layoutb-audio {
    position: absolute;
    left: 0;
  }

  &.ortho {
    cursor: pointer;
    height: 40px;
    width: 40px;
    z-index: 1;

    img {
      width: 40px;
      height: 48px;
      z-index: 1;
    }
  }
}
@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  90% {
    transform: scale(0.9);
  }
}
</style>
