<template>
  <div
    class="right-container"
    :class="{'home': $route.name == 'home'}"
    v-bind:style="{'background-image': 'url(' + backgroundImageSelected + ')', 'background-color': theme.backgroundColor}"
  >
    <div
      v-if="$route.name != 'home'"
      class="final-background"
      v-bind:style="{'background-image': 'url(' + backgroundImageFinalSelected + ')', 'background-color': theme.backgroundColor}"
    >
    </div>

    <div
      v-else
      class="signature"
      v-bind:style="{'background-image': 'url(' + backgroundImageSignature + ')'}"
    >
    </div>
    <div class="container">
        <router-view :key="$route.fullPath"/>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'PageLayout',
    props: [
      "theme",
      "backgroundImageSelected",
      "backgroundImageFinalSelected",
      "backgroundImageSignature",
    ],
    data () {
      return {
        myHome: this.$parent.myHome,
      }
    }
  }
</script>

<style lang="scss" scoped>
.right-container.home {
    justify-content: center;
    background-position: right;

    .signature {
      width: 573px;
      height: 182px;
      background-repeat: no-repeat;
      position: absolute;
      align-self: center;
      top: 100px;
    }
}
</style>
