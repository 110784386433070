const Getters = {
	getAppConfig(state) {
		return state.appConfig
  },
  getNavigationData(state) {
		return state.navigationData
  },
  getOrthoArrayData(state) {
		return state.orthoArrayData
  },
  getMemoLeftArrayData(state) {
		return state.memoLeftArrayData
  },
  getMemoRightArrayData(state) {
		return state.memoRightArrayData
  },
  getMultiObjectData(state) {
		return state.multiObjectData
  },
  getI18n(state) {
    return key => {
      if (key in state.i18n) {
        let value = state.i18n[key];
        return value[state.locale];
      }

      return key;
    }
  },
  getLocale(state) {
    return state.locale;
  },
  getProduct(state) {
    return state.product;
  },
  findProduct(state) {
    return name => {
      let product = ('products' in state.appConfig) ? state.appConfig.products[name] : null;
      return product || {};
    }
  }
}

export default Getters
