<template>
  <div class="head-container">
    <div id="container_audio" v-html="audioHtml"></div>
    <StarsA
      :stepsLength="stepsLength"
      :progressValue="progressValue"
      :isReRotateStars="isReRotateStars"
    >
    </StarsA>
    <TitleA
      :fadeIn="fadeIn"
      :message="message"
      :finalMessage="finalMessage"
      :finalMessageAudioFile="finalMessageAudioFile"
      :messageAudioFile="messageAudioFile"
      :messageAudioType="messageAudioType"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isFinal="isFinal"
    >
    </TitleA>
  </div>
</template>

<script>
  import Vue from 'vue'
  import StarsA from '../components/StarsA'
  import TitleA from '../components/TitleA'

  export default {
    name: 'HeaderA',
    props: [
      "audioHtml",
      "stepsLength",
      "progressValue",
      "fadeIn",
      "message",
      "messageAudioFile",
      "messageAudioType",
      "assetMediaAudioUrl",
      "finalMessage",
      "isFinal",
      "finalMessageAudioFile",
      "isReRotateStars"
    ],
    components: {
      'StarsA': StarsA,
      'TitleA': TitleA,
    },
    data () {
      return {
        myComponent: this.$parent.myComponent,
        myHome: this.$parent.myHome
      }
    },
    methods: {
      loadThemeImages: function() {
        var images = [
          'etoile-active.svg',
          'etoile-inactive.svg',
          'audio.svg',
        ];

        // Preloading images for smooth animations
        for(var i = 0; i < images.length; i++) {
          var img = new Image();
          img.src = this.myHome.getThemeImage(images[i]);
        }
      },
    },
    created: function() {
      this.loadThemeImages()
      this.now = Date.now();
    }
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.head-container {
  min-height: 140px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
}
</style>
