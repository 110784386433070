import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const Sizes = [0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4];
const Class = Object.create(Base);

Class.setOneElementsBlacklist = [];
Class.setTwoElementsBlacklist = [];
Class.setOneElements = new Map();
Class.setTwoElements = new Map();

Class.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.setOneElementsBlacklist, "set_one_elements_blacklist");
    LocalStorage.saveLocalStorage(this.setTwoElementsBlacklist, "set_two_elements_blacklist");
    LocalStorage.saveLocalStorage(this.setOneElements, "set_one_elements_remaining");
    LocalStorage.saveLocalStorage(this.setTwoElements, "set_two_elements_remaining");
}

Class.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.setOneElementsBlacklist);
    Util.clearElements(this.setTwoElementsBlacklist);
    this.setOneElements.clear();
    this.setTwoElements.clear();
    Util.shuffle(Sizes);

    let config = configuration.data.config;
    let excludeCriteriasSetOne = config.excludeCriteriasSetOne;
    let excludeCriteriasSetTwo = config.excludeCriteriasSetTwo;
    let includeCriteriasSetOne = config.includeCriteriasSetOne;
    let includeCriteriasSetTwo = config.includeCriteriasSetTwo;
    let criteriasField = config.criteriasField;
    let valueSource = config.valueSource;

    this.database = await this.populateCollections(config.collections);

    for (let element of this.database) {
        if ( this.hasCriteria(element[criteriasField], excludeCriteriasSetOne) )
            continue;

        else if ( this.hasCriteria(element[criteriasField], includeCriteriasSetOne) )
            this.setMapValue(this.setOneElements, element, valueSource);
    }

    for (let element of this.database) {
        if ( this.hasCriteria(element[criteriasField], excludeCriteriasSetTwo) )
            continue;

        else if ( this.hasCriteria(element[criteriasField], includeCriteriasSetTwo) )
            this.setMapValue(this.setTwoElements, element, valueSource);
    }
}

Class.getElements = function (n) {
    if (n < 1)
        throw new Error("Non-positive argument is invalid.");

    let index = Util.getRandom(Sizes.length);
    let setOneSize = Sizes[index];
    let setOne = this.fetchElements(setOneSize, this.setOneElements, this.setOneElementsBlacklist, {set: 'one'});

    let setTwoSize = n - setOne.length;
    let setTwo = this.fetchElements(setTwoSize, this.setTwoElements, this.setTwoElementsBlacklist, {set: 'two'});

    let elements = setOne.concat(setTwo);
    Util.shuffle(elements);

    Util.clearElements(this.currentElements);
    Util.addElements(this.currentElements, elements);

    return this.currentElements;
}

export default Class;
