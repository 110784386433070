var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alternate-data",style:([
    _vm.cardContentPosition ?
      {justifyContent: _vm.cardContentPosition, textAlign: _vm.cardContentPosition == 'flex-end' ? 'right' : (_vm.cardContentPosition == 'start' ? 'left' : 'center')}
    : '',
    _vm.cardFontSize ?
      {fontSize: _vm.cardFontSize}
    : '',
    _vm.displaySourceAlt && _vm.cardFontSize && _vm.data.element.hasOwnProperty(_vm.displaySourceAlt) ?
      _vm.data.element[_vm.displaySourceAlt].length > 10 && parseInt(_vm.cardFontSize.substring(0, _vm.cardFontSize.length - 2)) > 24 ?
        {fontSize: '24px'}
      : {fontSize: _vm.cardFontSize}
    : '' ])},[(_vm.displayTypeAlt == 'image' && _vm.displaySourceAlt)?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],style:([
      _vm.data.element[_vm.displaySourceAlt].indexOf('.svg') == (_vm.data.element[_vm.displaySourceAlt].length - 4) ?
        {height: '-webkit-fill-available'}
      : '' ]),attrs:{"src":_vm.assetMediaImageUrl + _vm.data.element[_vm.displaySourceAlt],"alt":_vm.data.element[_vm.displaySourceAlt],"draggable":"false","width":"210","height":"160"}}):_vm._e(),(_vm.displayTypeAlt == 'image' && !_vm.displaySourceAlt)?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],style:([
      _vm.isSvg(_vm.data) ?
        {height: '-webkit-fill-available'}
      : '' ]),attrs:{"src":_vm.assetMediaImageUrl + _vm.data.element[_vm.displaySourceAlt],"alt":_vm.data.element[_vm.displaySourceAlt],"draggable":"false","width":_vm.isSvg(_vm.data) ? '': '210',"height":_vm.isSvg(_vm.data) ? '': '160'}}):_vm._e(),(_vm.displayTypeAlt == 'text')?_c('span',[_vm._v(" "+_vm._s(_vm.displaySourceAlt ? _vm.data.element[_vm.displaySourceAlt] : '')+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }