import LocalStorage from './LocalStorage'
import Util from '../helpers/Util'
import Base from './Base'

const AssoC = Object.create(Base);

AssoC.currentElements = {first: [], second: [], third: []};
AssoC.wheelOneBlacklist = [];
AssoC.wheelTwoBlacklist = [];
AssoC.wheelThreeBlacklist = [];
AssoC.wheelOneElements = new Map();
AssoC.wheelTwoElements = new Map();
AssoC.wheelThreeElements = new Map();

AssoC.debug = function() {
    LocalStorage.saveLocalStorage(this.configuration, "CONFIG");
    LocalStorage.saveLocalStorage(this.database, "DATABASE");
    LocalStorage.saveLocalStorage(this.currentElements, "current_elements");
    LocalStorage.saveLocalStorage(this.currentBlacklist, "current_blacklist");
    LocalStorage.saveLocalStorage(this.wheelOneElements, "wheel_one_elements");
    LocalStorage.saveLocalStorage(this.wheelOneBlacklist, "wheel_one_blacklist");
    LocalStorage.saveLocalStorage(this.wheelTwoElements, "wheel_two_elements");
    LocalStorage.saveLocalStorage(this.wheelTwoBlacklist, "wheel_two_blacklist");
    LocalStorage.saveLocalStorage(this.wheelThreeElements, "wheel_three_elements");
    LocalStorage.saveLocalStorage(this.wheelThreeBlacklist, "wheel_three_blacklist");
}

AssoC.populateElements = async function (configuration) {
    this.configuration = configuration;
    Util.clearElements(this.database);
    Util.clearElements(this.currentElements.first);
    Util.clearElements(this.currentElements.second);
    Util.clearElements(this.currentElements.third);
    Util.clearElements(this.currentBlacklist);
    Util.clearElements(this.wheelOneBlacklist);
    Util.clearElements(this.wheelTwoBlacklist);
    Util.clearElements(this.wheelThreeBlacklist);
    this.wheelOneElements.clear();
    this.wheelTwoElements.clear();
    this.wheelThreeElements.clear();

    let config = this.configuration.data.config;

    if ( !(config.wheelThree && config.wheelThree.collections) )
        config.wheelThree = Object.create(config.wheelTwo);

    let criteriasField = config.criteriasField;
    let valueSource = config.valueSource;
    let wheelOneCollections = config.wheelOne.collections;
    let wheelTwoCollections = config.wheelTwo.collections;
    let wheelThreeCollections = config.wheelThree.collections;

    let wheelOne = await this.populateCollections(wheelOneCollections);
    let wheelTwo = await this.populateCollections(wheelTwoCollections);
    let wheelThree = await this.populateCollections(wheelThreeCollections);

    for (let element of wheelOne) {
        this.setMapValue(this.wheelOneElements, element, valueSource);
    }

    for (let element of wheelTwo) {
        this.setMapValue(this.wheelTwoElements, element, valueSource);
    }

    for (let element of wheelThree) {
        this.setMapValue(this.wheelThreeElements, element, valueSource);
    }

    this.database = wheelOne.concat(wheelTwo, wheelThree);
}

AssoC.getElements = function () {
    const config = this.configuration.data.config;
    const valueSource = config.valueSource;
    const sameKeys = [], intruderKeys = [];
    let intruderSize, sameSize;

    // base on the rule, each collection on every wheel have the same values
    // therefore, we can get the common elements on first wheel
    sameSize = config.numberSameElements || 0;
    const firstSameElements = this.fetchElements(sameSize, this.wheelOneElements, this.wheelOneBlacklist).map(obj => Util.assign(obj, {isGoodElement: true}));
    firstSameElements.forEach(obj => sameKeys.push(obj.element[valueSource]));

    intruderSize = config.wheelOne.numberIntruder || 0;
    const firstIntruder = this.fetchElements(intruderSize, this.wheelOneElements, this.wheelOneBlacklist).map(obj => Util.assign(obj, {isGoodElement: false}));
    firstIntruder.forEach(obj => intruderKeys.push(obj.element[valueSource]));

    const secondSameElements = this.pickElementsByKeys(sameKeys.concat(intruderKeys), this.wheelTwoElements, this.wheelTwoBlacklist).map(obj => Util.assign(obj, {isGoodElement: true}));
    secondSameElements.splice(secondSameElements.length - intruderKeys.length);

    intruderSize = config.wheelTwo.numberIntruder || 0;
    const secondIntruder = this.fetchElements(intruderSize, this.wheelTwoElements, this.wheelTwoBlacklist).map(obj => Util.assign(obj, {isGoodElement: false}));
    secondIntruder.forEach(obj => intruderKeys.push(obj.element[valueSource]));

    const thirdSameElements = this.pickElementsByKeys(sameKeys.concat(intruderKeys), this.wheelThreeElements, this.wheelThreeBlacklist).map(obj => Util.assign(obj, {isGoodElement: true}));
    thirdSameElements.splice(thirdSameElements.length - intruderKeys.length);

    intruderSize = config.wheelThree.numberIntruder || 0;
    const thirdIntruder = this.fetchElements(intruderSize, this.wheelThreeElements, this.wheelThreeBlacklist).map(obj => Util.assign(obj, {isGoodElement: false}));

    const first = firstIntruder.concat(firstSameElements);
    const second = secondIntruder.concat(secondSameElements);
    const third = thirdIntruder.concat(thirdSameElements);

    Util.shuffle(first);
    Util.shuffle(second);
    Util.shuffle(third);

    Util.clearElements(this.currentElements.first);
    Util.clearElements(this.currentElements.second);
    Util.clearElements(this.currentElements.third);

    Util.addElements(this.currentElements.first, first);
    Util.addElements(this.currentElements.second, second);
    Util.addElements(this.currentElements.third, third);

    return this.currentElements;
}

export default AssoC;
