var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cards"},[_c('ul',{style:([
      _vm.cardsLayout === 'row' && _vm.numberCards == 2 ?
        {flexDirection: 'column'}
      : '',
      _vm.numberCards == 6 || _vm.numberCards == 4 ? 
        {display: 'inline-grid', gridTemplateColumns: 'auto auto'}
      : ''
    ])},_vm._l((_vm.cards),function(item,index){return _c('li',{key:index,staticClass:"card",style:([
        _vm.cardWidth ?
          {width: _vm.cardWidth} :
          '',
        _vm.cardHeight ?
          {height: _vm.cardHeight} :
          ''
      ])},[_c('CardB',{attrs:{"displayType":_vm.displayType,"value":item.element[_vm.displaySource],"isCorrectBorder":item.isCorrectBorder,"isWrongBorder":item.isWrongBorder,"isSelectedBorder":item.isSelectedBorder,"numberCorrectElements":_vm.numberCorrectElements,"numOfSelected":_vm.numOfSelected,"itemIndex":index,"correctBorderColor":_vm.correctBorderColor,"selectedBorderColor":_vm.selectedBorderColor,"wrongBorderColor":_vm.wrongBorderColor,"backgroundColor":_vm.backgroundColor,"cardBorderColor":_vm.cardBorderColor,"cardContentPosition":_vm.cardContentPosition,"retroBorderSize":_vm.retroBorderSize,"cardFontSize":_vm.cardFontSize,"cardFontColor":_vm.cardFontColor,"assetMediaImageUrl":_vm.assetMediaImageUrl,"cardPaddingWidth":_vm.cardPaddingWidth,"cardPaddingHeight":_vm.cardPaddingHeight,"backgroundColorCard":_vm.backgroundColorCard,"backgroundColorDisable":_vm.backgroundColorDisable,"backgroundColorWrong":_vm.backgroundColorWrong,"themeName":_vm.themeName,"themeLocation":_vm.themeLocation}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }