<template>
  <div 
    class="alternate-data"
    :style="[
      cardContentPosition ?
        {justifyContent: cardContentPosition, textAlign: cardContentPosition == 'flex-end' ? 'right' : (cardContentPosition == 'start' ? 'left' : 'center')}
      : '',
      cardFontSize ?
        {fontSize: cardFontSize}
      : '',
      displaySourceAlt && cardFontSize && data.element.hasOwnProperty(displaySourceAlt) ?
        data.element[displaySourceAlt].length > 10 && parseInt(cardFontSize.substring(0, cardFontSize.length - 2)) > 24 ?
          {fontSize: '24px'}
        : {fontSize: cardFontSize}
      : '',
    ]"
  >
    <img 
      v-if="displayTypeAlt == 'image' && displaySourceAlt" 
      v-bind:src="assetMediaImageUrl + data.element[displaySourceAlt]" 
      v-bind:alt="data.element[displaySourceAlt]" v-rjs="2"
      draggable="false"
      :style="[
        data.element[displaySourceAlt].indexOf('.svg') == (data.element[displaySourceAlt].length - 4) ?
          {height: '-webkit-fill-available'}
        : '',
      ]"
      width="210"
      height="160"
    >
    <img 
      v-if="displayTypeAlt == 'image' && !displaySourceAlt" 
      v-bind:src="assetMediaImageUrl + data.element[displaySourceAlt]" 
      v-bind:alt="data.element[displaySourceAlt]" v-rjs="2"
      draggable="false"
      :style="[
        isSvg(data) ?
          {height: '-webkit-fill-available'}
        : '',
      ]"
      :width="isSvg(data) ? '': '210'"
      :height="isSvg(data) ? '': '160'"
    >
    <span
      v-if="displayTypeAlt == 'text'"
    >
      {{ displaySourceAlt ? data.element[displaySourceAlt] : '' }}
    </span>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'AlternateDataA',
    props: [
      "data",
      "isDone",
      "isWrong",
      "isCorrect",
      "cardFontSize",
      "cardFontColor",
      "displayTypeAlt",
      "displaySourceAlt",
      "assetMediaImageUrl",
      "cardContentPosition",
    ],
    data () {
      return {
        myComponent: this.$parent.myComponent
      }
    },
    methods: {
      isSvg(data) {
        return data.element[this.displaySource].indexOf('.svg') == (data.element[this.displaySource].length - 4);
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.alternate-data {
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transition: all .3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  &.fade-in {
    opacity: 1;
    transition: opacity .6s ease-in-out 0.2s;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

@keyframes fade_in {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
