<template>
  <div class="body-container">
    <MultiCLayoutA
      v-if="!isFinal && themeLayout == 'layoutA'"
      :templateName="templateName"
      :templateMultiA="templateMultiA"
      :templateMultiB="templateMultiB"
      :templateMultiC="templateMultiC"
      :titleStyle="titleStyle"
      :isFinal="isFinal"
      :themeLayout="themeLayout"
      :cardQuestionWidth="cardQuestionWidth"
      :cardQuestionHeight="cardQuestionHeight"
      :cardQuestionBackgroundColor="cardQuestionBackgroundColor"
      :assetMediaImageUrl="assetMediaImageUrl"
      :cards="cards"
      :isDisplayInfo="isDisplayInfo"
      :cardWidth="cardWidth"
      :cardHeight="cardHeight"
      :cardFontSize="cardFontSize"
      :cardContentPosition="cardContentPosition"
      :displayedCards="displayedCards"
      :tag="tag"
      :progressValue="progressValue"
      :isCorrect="isCorrect"
      :disableCards="disableCards"
      :isShowAudioButton="isShowAudioButton"
      :finalImages="finalImages"
      :displayType="displayType"
      :answerDisplaySource="answerDisplaySource"
      :answerDisplayFontSize="answerDisplayFontSize"
      :cardAudioType="cardAudioType"
      :cardAudioSource="cardAudioSource"
      :displaySource="displaySource"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isDisableSubmit="isDisableSubmit"
      :wrongBorderColor="wrongBorderColor"
      :selectedBorderColor="selectedBorderColor"
      :correctBorderColor="correctBorderColor"
      :backgroundColorWrong="backgroundColorWrong"
      :retroBorderSize="retroBorderSize"
      :cardFontColor="cardFontColor"
      :cardPaddingTop="cardPaddingTop"
      :cardPaddingBottom="cardPaddingBottom"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :showAnimation="showAnimation"
      :hideAnimation="hideAnimation"
    >
    </MultiCLayoutA>

    <FinalA
      v-if="isFinal && finalType.toLowerCase() === 'finala'"
      :correctCards="correctCards"
      :themeLayout="themeLayout"
      :themeName="themeName"
      :themeLocation="themeLocation"
      :templateName="templateName"
      :templateMultiA="templateMultiA"
      :assetMediaImageUrl="assetMediaImageUrl"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isShowAudioButton="isShowAudioButton"
      :cardAudioType="cardAudioType"
      :cardAudioSource="cardAudioSource"
      :addedClass="'multiC'"
      :cardWidth="cardWidth"
      :cardHeight="cardHeight"
      :displayType="displayType"
      :displaySource="displaySource"
      :cardFontSize="cardFontSize"
      :cardContentPosition="cardContentPosition"
      :cardFontColor="cardFontColor"
      :answerDisplaySource="answerDisplaySource"
      :answerDisplayFontSize="answerDisplayFontSize"
      :cardPaddingTop="cardPaddingTop"
      :cardPaddingBottom="cardPaddingBottom"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
    >
    </FinalA>

    <FinalB
      v-if="isFinal && finalType.toLowerCase() === 'finalb'"
      :finalImages="finalImages"
      :themeName="themeName"
      :themeLocation="themeLocation"
    >
    </FinalB>
  </div>
</template>

<script>
import Vue from 'vue'
import MultiCLayoutA from '../components/MultiCLayoutA'
import { mapGetters, mapActions } from 'vuex'
import FinalB from '../components/FinalB'
import FinalA from '../components/FinalA'

export default {
  name: 'MultiA',
  components: {
    'MultiCLayoutA': MultiCLayoutA,
    'FinalA': FinalA,
    'FinalB': FinalB,
  },
  props: [
    "secondFinalPage",
    "templateName",
    "templateMultiA",
    "templateMultiB",
    "templateMultiC",
    "titleStyle",
    "isFinal",
    "themeLayout",
    "cardQuestionWidth",
    "cardQuestionHeight",
    "cardQuestionBackgroundColor",
    "assetMediaImageUrl",
    "cards",
    "isDisplayInfo",
    "cardWidth",
    "cardHeight",
    "cardFontSize",
    "cardFontColor",
    "cardContentPosition",
    "displayedCards",
    "tag",
    "progressValue",
    "isCorrect",
    "disableCards",
    "isShowAudioButton",
    "finalImages",
    "displayType",
    "answerDisplaySource",
    "answerDisplayFontSize",
    "cardAudioType",
    "cardAudioSource",
    "displaySource",
    "assetMediaAudioUrl",
    "themeLocation",
    "themeName",
    "isDisableSubmit",
    "finalType",
    "correctCards",
    "wrongBorderColor",
    "selectedBorderColor",
    "correctBorderColor",
    "backgroundColorWrong",
    "retroBorderSize",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "showAnimation",
    "hideAnimation",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.body-container {
  min-height: 340px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
