import Vue from 'vue'
import Vuex from 'vuex'
import State from './State'
import Mutations from './Mutations'
import Actions from './Actions'
import Getters from './Getters'
import StoragePlugin from './StoragePlugin'

Vue.use(Vuex)

//const debug = process.env.NODE_ENV !== 'production'

// created this error is set to true: Error: [vuex] Do not mutate vuex store state outside mutation handlers
// clear localstorage and reload app to reproduce the error
const debug = false

const store = new Vuex.Store({
  strict: debug,
  state: State,
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
  plugins: [StoragePlugin(['SET_APP_CONFIG', 'SET_I18N'])]
})

export default store
