<template>
  <div class="home">
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'Home',
}
</script>

<style scoped>
</style>
