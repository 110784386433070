var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-a",class:{correct: _vm.isCorrect},attrs:{"data-index":_vm.index}},[_c('div',{staticClass:"flip-overlay",class:{'prevent-click': _vm.preventClickCard},attrs:{"data-target":_vm.cardDataTarget,"id-target":_vm.container + '-' + _vm.index},on:{"click":_vm.$parent.onFlip}}),_c('div',{staticClass:"card-img front",style:([
      _vm.cssColor ?
        {color: _vm.cssColor}
      : '',
      _vm.isShowCards && !_vm.rotateCard ?
        ''
      : {display: _vm.isShowCards},
      _vm.cssBGColorA ?
        {backgroundColor: _vm.cssBGColorA}
      : '',
      _vm.cssBorderColorA ?
        {borderColor: _vm.cssBorderColorA}
      : '' ])},[_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],attrs:{"draggable":"false","src":_vm.frontIMG}}),_c('span',[_vm._v(_vm._s(_vm.cardIdentifiers ? _vm.cardIdentifiers[_vm.index] : ''))])]),_c('div',{staticClass:"card-title back",style:([
      _vm.isFinished === true && (_vm.index + 1) % 2 == 0 ?
        {backgroundColor: _vm.cssBGColorB}
      : {backgroundColor: _vm.cssBGColorOptionB},
      _vm.finalFirstBGColorFinal && _vm.isFinished === true && (_vm.index + 1) % 2 == 1 ?
        {backgroundColor: _vm.finalFirstBGColorFinal}
      : '',
      _vm.finalSecondBGColorFinal && _vm.isFinished === true && (_vm.index + 1) % 2 == 0 ?
        {backgroundColor: _vm.finalSecondBGColorFinal}
      : '',
      _vm.isWrong ?
        {
          backgroundColor: _vm.backgroundColorWrong,
        }
      : '',
      _vm.collectionCard.isComplete ?
        {
          backgroundColor: _vm.cssBGColorDisable,
          boxShadow: 'none'
        }
      : '',
      _vm.retroBorderSize ?
        {padding: _vm.retroBorderSize}
      : '',
      _vm.finalRetroBorderSizeLeft && _vm.isFinished === true && (_vm.index + 1) % 2 == 1 ?
        {padding: _vm.finalRetroBorderSizeLeft}
      : '',

      _vm.finalRetroBorderSizeRight && _vm.isFinished === true && (_vm.index + 1) % 2 == 0 ?
        {padding: _vm.finalRetroBorderSizeRight}
      : '' ]),attrs:{"data-value":_vm.collectionCard.element[_vm.pairingSource]}},[(_vm.collectionCard.isCorrect)?_c('Notify',{attrs:{"addedClass":"memo","hasBorder":false}}):_vm._e(),_c('div',{staticClass:"card-back-border-default",style:([
        _vm.isFinished === true ?
          (_vm.index + 1) % 2 == 0 ?
            {borderColor: _vm.cssBorderColorB}
          : {borderColor: _vm.cssBorderColorOptionB}
        : {borderColor: _vm.cssBorderDefaultColor},
        _vm.finalFirstBorderColorFinal && _vm.isFinished === true && (_vm.index + 1) % 2 == 1 ?
          {borderColor: _vm.finalFirstBorderColorFinal}
        : '',
        _vm.finalSecondBorderColorFinal && _vm.isFinished === true && (_vm.index + 1) % 2 == 0 ?
          {borderColor: _vm.finalSecondBorderColorFinal}
        : '' ])}),_c('div',{staticClass:"card-back-border",style:([
        _vm.isSelected && _vm.isShowCards ?
          {
            borderColor: _vm.selectedBorderColor
          }
        : '',
        _vm.isWrong ?
          {
            borderColor: _vm.wrongBorderColor
          }
        : '',
        _vm.collectionCard.isCorrect ?
          {
            borderColor: _vm.correctBorderColor
          }
        : '',
        _vm.collectionCard.isComplete ?
          {
            borderColor: _vm.cssBorderColorDisable, 
          }
        : '',
        _vm.retroBorderSize && !_vm.collectionCard.isComplete ?
          {borderWidth: _vm.retroBorderSize}
        : '' ])}),_c('span',{staticClass:"card-back-content",style:([
        _vm.cardPaddingWidth ?
          {
            paddingLeft: 'calc(' + _vm.cardPaddingWidth + ' / 2)', 
            paddingRight: 'calc(' + _vm.cardPaddingWidth + ' / 2)'
          }
        : '',
        _vm.cardPaddingTop ?
          {paddingTop: _vm.cardPaddingTop}
        : '',
        _vm.cardPaddingBottom ?
          {paddingBottom: _vm.cardPaddingBottom}
        : '',
        _vm.cardContentPosition ? 
          {
            textAlign: _vm.cardContentPosition,
            justifyContent: _vm.cardContentPosition == 'left' ? 
              'start' 
            : _vm.cardContentPosition == 'right' ? 
                'flex-end' 
              : _vm.cardContentPosition
          }
        : '',
        _vm.finalCardContentPositionLeft && _vm.isFinished === true && (_vm.index + 1) % 2 == 1 ?
          {
            textAlign: _vm.finalCardContentPositionLeft,
            justifyContent: _vm.finalCardContentPositionLeft == 'left' ? 
              'start' 
            : _vm.finalCardContentPositionLeft == 'right' ? 
                'flex-end' 
              : _vm.finalCardContentPositionLeft
          }
        : '',
        _vm.finalCardContentPositionRight && _vm.isFinished === true && (_vm.index + 1) % 2 == 0 ?
          {
            textAlign: _vm.finalCardContentPositionRight,
            justifyContent: _vm.finalCardContentPositionRight == 'left' ? 
              'start' 
            : _vm.finalCardContentPositionRight == 'right' ? 
                'flex-end' 
              : _vm.finalCardContentPositionRight
          }
        : '',
        _vm.finalCardPaddingTopLeft && _vm.isFinished === true && (_vm.index + 1) % 2 == 1 ?
          {paddingTop: _vm.finalCardPaddingTopLeft}
        : '',
        _vm.finalCardPaddingBottomLeft && _vm.isFinished === true && (_vm.index + 1) % 2 == 1 ?
          {paddingBottom: _vm.finalCardPaddingBottomLeft}
        : '',
        _vm.finalCardPaddingWidthLeft && _vm.isFinished === true && (_vm.index + 1) % 2 == 1 ?
          {
            paddingLeft: 'calc(' + _vm.finalCardPaddingWidthLeft + ' / 2)', 
            paddingRight: 'calc(' + _vm.finalCardPaddingWidthLeft + ' / 2)'
          }
        : '',
        _vm.finalCardPaddingTopRight && _vm.isFinished === true && (_vm.index + 1) % 2 == 0 ?
          {paddingTop: _vm.finalCardPaddingTopRight}
        : '',
        _vm.finalCardPaddingBottomRight && _vm.isFinished === true && (_vm.index + 1) % 2 == 0 ?
          {paddingBottom: _vm.finalCardPaddingBottomRight}
        : '',
        _vm.finalCardPaddingWidthRight && _vm.isFinished === true && (_vm.index + 1) % 2 == 0 ?
          {
            paddingLeft: 'calc(' + _vm.finalCardPaddingWidthRight + ' / 2)', 
            paddingRight: 'calc(' + _vm.finalCardPaddingWidthRight + ' / 2)'
          }
        : '' ])},[(_vm.isFinished && (_vm.index + 1) % 2 == 1 && _vm.finalFirstDisplayType === 'text')?_c('span',{style:([
          _vm.finalFirstCardFontSize ?
            {fontSize: _vm.finalFirstCardFontSize}
          : '',
          _vm.finalFirstCardFontColor ?
            {color: _vm.finalFirstCardFontColor}
          : {color: _vm.collectionCard.color} ])},[_vm._v(" "+_vm._s(_vm.collectionCard.element[_vm.finalFirstDisplaySource])+" ")]):(_vm.isFinished && (_vm.index + 1) % 2 == 1 && _vm.finalFirstDisplayType === 'image')?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],attrs:{"draggable":"false","src":_vm.assetMediaImageUrl + _vm.collectionCard.element[_vm.finalFirstDisplaySource]}}):_vm._e(),(_vm.isFinished && (_vm.index + 1) % 2 == 0 && _vm.finalSecondDisplayType === 'text')?_c('span',{style:([
          _vm.finalSecondCardFontSize ?
            {fontSize: _vm.finalSecondCardFontSize}
          : '',
          _vm.finalSecondCardFontColor ?
            {color: _vm.finalSecondCardFontColor}
          : {color: _vm.collectionCard.color}
        ])},[_vm._v(" "+_vm._s(_vm.collectionCard.element[_vm.finalSecondDisplaySource])+" ")]):(_vm.isFinished && (_vm.index + 1) % 2 == 0 && _vm.finalSecondDisplayType === 'image')?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],attrs:{"draggable":"false","src":_vm.assetMediaImageUrl + _vm.collectionCard.element[_vm.finalSecondDisplaySource]}}):(!_vm.isFinished && _vm.displayType === 'text')?_c('span',{style:([
          _vm.cardFontSize ?
            {fontSize: _vm.cardFontSize}
          : '',
          _vm.cardFontColor ?
            {color: _vm.cardFontColor}
          : {color: _vm.collectionCard.color} ])},[_vm._v(" "+_vm._s(_vm.collectionCard.element[_vm.displaySource])+" ")]):(!_vm.isFinished)?_c('img',{directives:[{name:"rjs",rawName:"v-rjs",value:(2),expression:"2"}],attrs:{"draggable":"false","src":_vm.assetMediaImageUrl + _vm.collectionCard.element[_vm.displaySource]}}):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }