var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ortho-layout"},[(_vm.isDocumentReady)?_c('div',{staticClass:"answer-container",class:{rotate: _vm.rotateAnswerContainer},style:([
      {'background-color': _vm.backgroundColorAnswerContainer},
      _vm.isAnswerContainerShow ?
        {opacity: 1}
      : {opacity: 0} ])},[(_vm.isShowCheck)?_c('Notify',{attrs:{"addedClass":"ortho","hasBorder":true}}):_vm._e(),_c('div',{staticClass:"correct-bg",style:([
        _vm.isCorrect ? 
          {backgroundColor: _vm.correctBorderColor, opacity: 1}
        : {opacity: 0} ])}),(_vm.cardAudioType === 'mp3' && _vm.cardAudioSource)?_c('AudioA',{attrs:{"messageAudioFile":_vm.answerSoundFile,"classAdded":"ortho","isDataSound":true,"assetMediaAudioUrl":_vm.assetMediaAudioUrl}}):(_vm.cardAudioType && _vm.cardAudioSource)?_c('AudioB',{attrs:{"classAdded":"ortho","correctWord":_vm.answerSoundFile,"audioType":_vm.cardAudioType}}):_vm._e(),_vm._l((_vm.getOrthoArrayData),function(data,index){return [(data.value != ' ')?_c('CardE',{key:'letter' + data.index,attrs:{"addedClass":"ortho","data":data.value,"index":index,"dataIndex":data.index,"isSelected":data.isSelected,"isWrong":data.isWrong,"isCorrect":data.isCorrect,"isComplete":data.isComplete,"backgroundColorWrong":_vm.backgroundColorWrong,"correctBorderColor":_vm.correctBorderColor,"selectedBorderColor":_vm.selectedBorderColor,"wrongBorderColor":_vm.wrongBorderColor,"cardBorderColor":_vm.cardBorderColor,"cardFontColor":_vm.cardFontColor,"cardFontSize":_vm.cardFontSize,"cardHeight":_vm.cardHeight,"cardPaddingTop":_vm.cardPaddingTop,"cardPaddingBottom":_vm.cardPaddingBottom,"cardPaddingHeight":_vm.cardPaddingHeight,"cardPaddingWidth":_vm.cardPaddingWidth,"cardWidth":_vm.cardWidth,"displaySource":_vm.displaySource,"displayType":_vm.displayType,"retroBorderSize":_vm.retroBorderSize,"cardMarginWidth":_vm.cardMarginWidth},on:{"click":function($event){return _vm.onSelect('answer-letter-' + index)}}}):_c('div',{key:'space' + index,staticClass:"empty-box",style:([
          _vm.cardHeight ? 
            {height: _vm.cardHeight}
          : '',
          _vm.cardWidth ? 
            {width: _vm.cardWidth}
          : '' ])})]})],2):_vm._e(),(_vm.isDocumentReady)?_c('Keyboard',{attrs:{"keyboardFadeIn":_vm.keyboardFadeIn}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }