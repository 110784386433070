import Api from './Api'

const Navigation = {};

Navigation.products = new Map();
Navigation.categories = new Map();
Navigation.activities = [];

const getItems = function (tableName, pkName, pkValue, filterName, filterValue, projectionExpression, indexName) {
    const url =  process.env.VUE_APP_API_URL + process.env.VUE_APP_READ_PATH;
    const params = {
        tname: tableName,
        pkname: pkName,
        pkvalue: pkValue
    };

    if (filterName && filterValue) {
        params.fname = filterName;
        params.fvalue = filterValue;
    }

    if (projectionExpression)
        params.projexp = projectionExpression;

    if (indexName)
        params.idxname = indexName;

    return Api.get(url, params);
}

const setCategories = async function (categories = []) {
    let items;

    if (categories.length > 0) {
        items = categories;
    }
    else {
        let projExp = 'categoryName, categoryType, categoryUrlName, categoryValueA, categoryValueB, PK, SK1, SK5';
        let response = await getItems(process.env.VUE_APP_TABLE_NAME, 'PK', 'category', null, null, projExp);
        items = response.body.Items;
    }

    for (let item of items) {
        Navigation.categories.set(item.SK1, item); // distinct the items by 'SK1'

        if ('product' === item.categoryType)
            Navigation.products.set(item.categoryUrlName, item.SK1); // distinct the items by 'categoryUrlName'
    }

    return items;
}

const getCategories = function (prop, filterName, filterValue) {
    let ret = [], keys = new Set();
    let items = getActivities(filterName, filterValue);

    for (let item of items)
        keys.add(item[prop]); // distinct the items by 'prop'

    for (let key of keys) {
        if (Navigation.categories.has(key))
            ret.push(Navigation.categories.get(key));
    }

    return ret;
}

const setActivities = async function (productName, activities = []) {
    let productId = Navigation.getProductId(productName);

    if (activities.length > 0) {
        Navigation.activities = activities;
    }
    else {
        let projExp = 'activityName, configName, navActivityLinkText, navActivityLinkTextPrefix, navClassLvl1, navClassLvl2, navClassLvl3, navClassLvl4, navClassLvl5, navProduct, navSection, navSubSection, navTheme, paramWeekSection, PK, SK1, SK5, template';
        let response = await getItems(process.env.VUE_APP_TABLE_NAME, 'navProduct', productId, null, null, projExp, 'navProduct-index');
        Navigation.activities = response.body.Items;
    }

    return Navigation.activities;
}

const getActivities = function (filterName, filterValue) {
    return Navigation.activities.filter(activity => activity[filterName] === filterValue);
}

const compareCategories = function (categoryA, categoryB) {
    let a = (categoryA.SK5 || '0') + '-' + (categoryA.categoryValueA || '0') + '-' + (categoryA.categoryValueB || '');
    let b = (categoryB.SK5 || '0') + '-' + (categoryB.categoryValueA || '0') + '-' + (categoryB.categoryValueB || '');

    return a.localeCompare(b, 'fr');
}

const compareActivities = function (activityA, activityB) {
    let a = (activityA.SK5 || '0') + '-' + (activityA.navActivityLinkTextPrefix || '0') + '-' + (activityA.navActivityLinkText || '');
    let b = (activityB.SK5 || '0') + '-' + (activityB.navActivityLinkTextPrefix || '0') + '-' + (activityB.navActivityLinkText || '');

    return a.localeCompare(b, 'fr');
}

Navigation.debug = function () {
    console.log('Product', this.products);
    console.log('Categories', this.categories);
    console.log('Activities', this.activities);
}

Navigation.init = async function (productName, categories, activities) {
    let productCategories = await setCategories(categories);
    let productActivities = await setActivities(productName, activities);

    return {
      categories: productCategories,
      activities: productActivities
    };
}

Navigation.getProductId = function (productName) {
    if ( !this.products.has(productName) )
        throw new Error('Product name not found.');

    return this.products.get(productName);
}

Navigation.getThemes = function (filterName, filterValue) {
    return getCategories('navTheme', filterName, filterValue).sort(compareCategories);
}

Navigation.getSections = function (filterName, filterValue) {
    return getCategories('navSection', filterName, filterValue).sort(compareCategories);
}

Navigation.getSubSections = function (filterName, filterValue) {
    return getCategories('navSubSection', filterName, filterValue).sort(compareCategories);
}

Navigation.getClassLvl3 = function (filterName, filterValue) {
    return getCategories('navClassLvl3', filterName, filterValue).sort(compareCategories);
}

Navigation.getClassLvl4 = function (filterName, filterValue) {
    return getCategories('navClassLvl4', filterName, filterValue).sort(compareCategories);
}

Navigation.getClassLvl5 = function (filterName, filterValue) {
    return getCategories('navClassLvl5', filterName, filterValue).sort(compareCategories);
}

Navigation.getActivities = function (filterName, filterValue, filterObject = {}) {
    let activities = getActivities(filterName, filterValue);

    for (let prop in filterObject)
        activities = activities.filter(activity => activity[prop] === filterObject[prop]);

    return activities.sort(compareActivities);
}

export default Navigation;
