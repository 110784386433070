var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:[
    _vm.addedClass, 
    'answer-letter-' + _vm.dataIndex, 
    {
      selected: _vm.isSelected, 
      wrong: _vm.isWrong, 
      correct: _vm.isCorrect, 
      done: _vm.isComplete
    }
  ],style:([
    _vm.cardHeight ? 
      {height: _vm.cardHeight}
    : '',
    _vm.cardWidth ? 
      {width: _vm.cardWidth}
    : '',
    _vm.cardMarginWidth ?
      {margin: '0 calc(' + _vm.cardMarginWidth + ' / 2)'}
    : '' ]),attrs:{"index":_vm.index,"data":_vm.data,"isSelected":_vm.isSelected},on:{"click":function($event){return _vm.onSelect()}}},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card-border",style:([
        _vm.isWrong ?
          {borderColor: _vm.wrongBorderColor}
        : '',
        _vm.isSelected && !_vm.isCorrect ?
          {borderColor: _vm.selectedBorderColor}
        : '',
        _vm.isCorrect ?
          {borderColor: _vm.correctBorderColor}
        : '',
        _vm.retroBorderSize ?
          {borderWidth: _vm.retroBorderSize}
        : '' ])}),_c('div',{staticClass:"card-content",style:([
        _vm.retroBorderSize ?
          {padding: _vm.retroBorderSize}
        : '' ])},[_c('div',{staticClass:"content",style:([
          _vm.isWrong ?
            {backgroundColor: _vm.backgroundColorWrong}
          : '',
          _vm.cardFontColor ?
            {color: _vm.cardFontColor}
          : '',
          _vm.cardFontSize ?
            {fontSize: _vm.cardFontSize}
          : '',
          _vm.cardPaddingTop ? 
            {paddingTop: _vm.cardPaddingTop}
          : '',
          _vm.cardPaddingBottom ? 
            {paddingBottom: _vm.cardPaddingBottom}
          : '',
          _vm.cardPaddingWidth ? 
            {paddingLeft: _vm.cardPaddingWidth, paddingRight: _vm.cardPaddingWidth}
          : '' ])})])])])}
var staticRenderFns = []

export { render, staticRenderFns }