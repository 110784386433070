<template>
  <div class="ordo">
    <HeaderA
      v-if="isParentReady"
      :audioHtml="audioHtml"
      :stepsLength="stepsLength"
      :progressValue="progressValue"
      :isReRotateStars="isReRotateStars"
      :fadeIn="fadeIn"
      :message="message"
      :finalMessage="finalMessage"
      :messageAudioFile="stepsMessageAudioFile"
      :messageAudioType="messageAudioType"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :isFinal="isFinal"
      :finalMessageAudioFile="finalMessageAudioFile"
    >
    </HeaderA>
    <Ordo
      v-if="isParentReady"
      :elements="elements"
      :question="question"
      :cardWidth="cardWidth"
      :cardHeight="cardHeight"
      :cardPaddingHeight="cardPaddingHeight"
      :cardPaddingWidth="cardPaddingWidth"
      :cardFontColor="cardFontColor"
      :cardFontSize="cardFontSize"
      :standWidth="standWidth"
      :isCardMoved="isCardMoved"
      :reinitialize="reinitialize"
      :callHideAnimation="callHideAnimation"
      :isFinal="isFinal"
      :finalImages="finalImages"
      :themeName="themeName"
      :boardBorderColor="boardBorderColor"
      :boardBackgroundColor="boardBackgroundColor"
      :boardTitle="boardTitle"
      :bannerTextColor="bannerTextColor"
      :backgroundColorWrong="backgroundColorWrong"
      :backgroundColorCard="backgroundColorCard"
      :backgroundColorDisable="backgroundColorDisable"
      :themeLocation="themeLocation"
      :retroBorderSize="retroBorderSize"
      :borderColor="borderColor"
      :disableCard="disableCard"
      :finalType="finalType"
      :finalBoardAnswers="finalBoardAnswers"
      :audioImg="myHome.getThemeImage('audio.svg')"
      :bannerImg1="myHome.getThemeImage('banner-2-col.svg')"
      :bannerImg2="myHome.getThemeImage('banner.svg')"
      :isShowCardAudioButton="isShowCardAudioButton"
      :isCorrect="isCorrect"
      :hideAudioAndNotify="hideAudioAndNotify"
      :cardAudioType="cardAudioType"
      :cardAudioSource="cardAudioSource"
      :correctWord="correctWord"
      :assetMediaImageUrl="assetMediaImageUrl"
      :assetMediaAudioUrl="assetMediaAudioUrl"
      :cardQuestionWidth="cardQuestionWidth"
      :cardQuestionHeight="cardQuestionHeight"
      :cardQuestionPaddingWidth="cardQuestionPaddingWidth"
      :cardQuestionPaddingHeight="cardQuestionPaddingHeight"
      :cardQuestionBottomMargin="cardQuestionBottomMargin"
      :cardQuestionSource="cardQuestionSource"
      :cardQuestionSourceValue="cardQuestionSourceValue"
      :cardQuestionBackgroundColor="cardQuestionBackgroundColor"
      :displaySource="displaySource"
      :finalDisplaySource="finalDisplaySource"
      :displayType="displayType"
      :cardRelation="cardRelation"
    >
    </Ordo>
    <FooterA
      v-if="isParentReady"
      :flipClass="flipClass"
      :isDisabled="isDisableNext"
      :isDisableClick="isDisableClick"
      :nextText="actionNextText"
      :validateText="actionValidateText"
      :finalActionNextText="finalActionNextText"
      :actionTextColor="myHome.theme.actionTextColor"
      :activeButtonImg="myHome.getThemeImage('bouton-actif.svg')"
      :inactiveButtonImg="myHome.getThemeImage('bouton-inactif.svg')"
      :isFinal="isFinal"
    >
    </FooterA>
  </div>
</template>

<script>
import OrdoModel from '../models/Ordo'
import HeaderA from '../components/HeaderA'
import FooterA from '../components/FooterA'
import Ordo from '../components/OrdoA'
import OrdoLayoutA from '../components/OrdoLayoutA'

export default {
  props: ['config'],
  components: {
    'HeaderA': HeaderA,
    'FooterA': FooterA,
    'Ordo': Ordo,
  },
  data () {
    return {
      myComponent: this,
      myHome: this.$parent.myHome,
      isParentReady: false,
      question: [],
      answer: [],
      elements: [],
      cards: [],
      isCorrect: false,
      themeLocation: false,
      themeName: false,
      stepsLength: 0,
      rotatestar: false,
      progressValue: 0,
      isReRotateStars: false,
      message: '',
      finalMessage: '',
      fadeIn: false,
      isFinal: false,
      finalImages: [],
      actionNextText: '',
      actionValidateText: '',
      finalActionNextText: '',
      flipClass: false,
      isDisableNext: true,
      isDisableClick: false,
      audioHtml: '',
      cardWidth: false,
      cardHeight: false,
      audio: false,
      audioType: false,
      cardFontColor: false,
      cardFontSize: "45px",
      cardPaddingHeight: false,
      cardPaddingWidth: false,
      cardsLayout: false,
      cardWidth: false,
      displaySource: false,
      displayType: false,
      retroBorderSize: false,
      showCardAudioButton: false,
      showCardAudioButtonDelay: false,
      isShowCardAudioButton: false,
      wrongCounter: 0,
      retroBorderSize: '',
      standWidth: false,
      isCardMoved: false,
      reinitialize: false,
      callHideAnimation: false,
      borderColor: ["transparent", "#1A85BC", "#409924", "#DB0020"],
      disableCard: false,
      finalType: '',
      finalBoardAnswers: [],
      cardBorderColor: false,
      correctBorderColor: false,
      wrongBorderColor: false,
      boardBorderColor: false,
      boardBackgroundColor: false,
      bannerTextColor: false,
      backgroundColorWrong: false,
      backgroundColorCard: 'transparent',
      backgroundColorDisable: false,
      cardQuestionBackgroundColor: false,
      boardTitle: '',
      hideAudioAndNotify: false,
      cardAudioType: false,
      correctWord: false,
      assetMediaAudioUrl: false,
      assetMediaImageUrl: false,
      messageAudioType: false,
      finalDisplaySource: false,
      finalMessageAudioFile: false,
      messageAudioFile: false,
      stepsMessageAudioFile: false,
      cardQuestionWidth: false,
      cardQuestionHeight: false,
      cardQuestionPaddingWidth: false,
      cardQuestionPaddingHeight: false,
      cardQuestionBottomMargin: false,
      cardQuestionSource: false,
      cardQuestionSourceValue: false,
      cardAudioSource: false,
      cardRelation: [],
    }
  },
  methods: {
    initialize: function() {
      if (this.config.data.config.hasOwnProperty('layout')) {
        this.audioType     = this.config.data.config.layout.hasOwnProperty('audioType') ? this.config.data.config.layout.audioType : false;
        this.cardFontColor = this.config.data.config.layout.hasOwnProperty('cardFontColor') ? this.config.data.config.layout.cardFontColor : false;
        this.cardFontSize  = this.config.data.config.layout.hasOwnProperty('cardFontSize') ? this.config.data.config.layout.cardFontSize : false;
        this.cardHeight    = this.config.data.config.layout.hasOwnProperty('cardHeight') ? this.config.data.config.layout.cardHeight : false;
        this.cardsLayout   = this.config.data.config.layout.hasOwnProperty('cardsLayout') ? this.config.data.config.layout.cardsLayout : false;
        this.cardWidth     = this.config.data.config.layout.hasOwnProperty('cardWidth') ? this.config.data.config.layout.cardWidth : false;
        this.displaySource = this.config.data.config.layout.hasOwnProperty('displaySource') ? this.config.data.config.layout.displaySource : false;
        this.displayType   = this.config.data.config.layout.hasOwnProperty('displayType') ? this.config.data.config.layout.displayType : false;
        this.standWidth    = this.config.data.config.layout.hasOwnProperty('standWidth') ? this.config.data.config.layout.standWidth : false;
        this.cardPaddingHeight = this.config.data.config.layout.hasOwnProperty('cardPaddingHeight') ? this.config.data.config.layout.cardPaddingHeight : false;
        this.cardPaddingWidth  = this.config.data.config.layout.hasOwnProperty('cardPaddingWidth') ? this.config.data.config.layout.cardPaddingWidth : false;
        this.retroBorderSize   = this.config.data.config.layout.hasOwnProperty('retroBorderSize') ? this.config.data.config.layout.retroBorderSize : false;
        this.showCardAudioButtonDelay = this.config.data.config.layout.hasOwnProperty('showCardAudioButtonDelay') ? this.config.data.config.layout.showCardAudioButtonDelay : 0;
        this.retroBorderSize = this.config.data.config.layout.hasOwnProperty('retroBorderSize') ? this.config.data.config.layout.retroBorderSize : '8px';
        this.cardAudioType = this.config.data.config.layout.hasOwnProperty('cardAudioType') ? this.config.data.config.layout.cardAudioType : 'mp3';
        this.cardQuestionWidth = this.config.data.config.layout.hasOwnProperty('cardQuestionWidth') ? this.config.data.config.layout.cardQuestionWidth : false;
        this.cardQuestionHeight = this.config.data.config.layout.hasOwnProperty('cardQuestionHeight') ? this.config.data.config.layout.cardQuestionHeight : false;
        this.cardAudioSource = this.config.data.config.layout.hasOwnProperty('cardAudioSource') ? this.config.data.config.layout.cardAudioSource : false;
        this.cardQuestionPaddingWidth = this.config.data.config.layout.hasOwnProperty('cardQuestionPaddingWidth') ? this.config.data.config.layout.cardQuestionPaddingWidth : false;
        this.cardQuestionPaddingHeight = this.config.data.config.layout.hasOwnProperty('cardQuestionPaddingHeight') ? this.config.data.config.layout.cardQuestionPaddingHeight : false;
        this.cardQuestionBottomMargin = this.config.data.config.layout.hasOwnProperty('cardQuestionBottomMargin') ? this.config.data.config.layout.cardQuestionBottomMargin : false;
        this.showCardAudioButton = this.config.data.config.layout.hasOwnProperty('showCardAudioButton') ? 
          this.config.data.config.layout.showCardAudioButton === "yes" ? 
            this.config.data.config.layout.showCardAudioButton 
          : false
        : false;
        if (this.cardQuestionPaddingWidth) 
          this.cardQuestionPaddingWidth = parseInt(this.cardQuestionPaddingWidth.slice(0, this.cardQuestionPaddingWidth.length - 2)) * 2;
        if (this.cardQuestionPaddingHeight) 
          this.cardQuestionPaddingHeight = parseInt(this.cardQuestionPaddingHeight.slice(0, this.cardQuestionPaddingHeight.length - 2)) * 2;
      }
      
      if (this.config.data.config.hasOwnProperty('theme')) {
        this.themeLocation = this.config.data.config.theme.hasOwnProperty('themeLocation') ? this.config.data.config.theme.themeLocation : false;
        this.themeName = this.config.data.config.theme.hasOwnProperty('themeName') ? this.config.data.config.theme.themeName : false;
        this.cardBorderColor = this.config.data.config.theme.hasOwnProperty('cardBorderColor') ? this.config.data.config.theme.cardBorderColor : false;
        this.correctBorderColor = this.config.data.config.theme.hasOwnProperty('correctBorderColor') ? this.config.data.config.theme.correctBorderColor : false;
        this.wrongBorderColor = this.config.data.config.theme.hasOwnProperty('wrongBorderColor') ? this.config.data.config.theme.wrongBorderColor : false;
        this.boardBorderColor = this.config.data.config.theme.hasOwnProperty('boardBorderColor') ? this.config.data.config.theme.boardBorderColor : false;
        this.boardBackgroundColor = this.config.data.config.theme.hasOwnProperty('boardBackgroundColor') ? this.config.data.config.theme.boardBackgroundColor : false;
        this.bannerTextColor = this.config.data.config.theme.hasOwnProperty('bannerTextColor') ? this.config.data.config.theme.bannerTextColor : false;
        this.cardQuestionBackgroundColor = this.config.data.config.theme.hasOwnProperty('cardQuestionBackgroundColor') ? this.config.data.config.theme.cardQuestionBackgroundColor : false;
        this.backgroundColorWrong = this.config.data.config.theme.hasOwnProperty('backgroundColorWrong') ? this.config.data.config.theme.backgroundColorWrong : false;
        this.backgroundColorCard = this.config.data.config.theme.hasOwnProperty('backgroundColorCard') ? this.config.data.config.theme.backgroundColorCard : 'transparent';
        this.backgroundColorDisable = this.config.data.config.theme.hasOwnProperty('backgroundColorDisable') ? this.config.data.config.theme.backgroundColorDisable : false;

        this.borderColor = ["transparent", this.cardBorderColor, this.correctBorderColor, this.wrongBorderColor]
      }
      if (this.config.data.config.hasOwnProperty('steps')) {
        this.message = this.config.data.config.steps.hasOwnProperty('message') ? this.config.data.config.steps.message : '';
        this.stepsLength = this.config.data.config.steps.hasOwnProperty('numberSteps') ? parseInt(this.config.data.config.steps.numberSteps) : 0;
        this.actionNextText = this.config.data.config.steps.hasOwnProperty('actionNextText') ? this.config.data.config.steps.actionNextText : '';
        this.actionValidateText = this.config.data.config.steps.hasOwnProperty('actionValidateText') ? this.config.data.config.steps.actionValidateText : '';
        this.messageAudioType = this.config.data.config.steps.hasOwnProperty('messageAudioType') ? this.config.data.config.steps.messageAudioType : 'mp3';
        this.stepsMessageAudioFile = this.config.data.config.steps.hasOwnProperty('messageAudioFile') ? this.config.data.config.steps.messageAudioFile : '';
      }
      if (this.config.data.config.hasOwnProperty('final')) {
        this.finalImages = this.config.data.config.final.hasOwnProperty('finalImages') ? this.config.data.config.final.finalImages : [];
        this.finalActionNextText = this.config.data.config.final.hasOwnProperty('actionNextText') ? this.config.data.config.final.actionNextText : '';
        this.finalMessage = this.config.data.config.final.hasOwnProperty('message') ? this.config.data.config.final.message : '';
        this.finalType = this.config.data.config.final.hasOwnProperty('type') ? this.config.data.config.final.type : 'finalb';
        this.boardTitle = this.config.data.config.final.hasOwnProperty('boardTitle') ? this.config.data.config.final.boardTitle : '';
        this.finalMessageAudioFile = this.config.data.config.final.hasOwnProperty('messageAudioFile') ? this.config.data.config.final.messageAudioFile : '';
        this.finalDisplaySource = this.config.data.config.final.hasOwnProperty('displaySource') ? this.config.data.config.final.displaySource : false;
      }

      this.assetMediaAudioUrl = this.config.data.config.hasOwnProperty('assetMediaAudioUrl') ? this.config.data.config.assetMediaAudioUrl : '';
      this.assetMediaImageUrl = this.config.data.config.hasOwnProperty('assetMediaImageUrl') ? this.config.data.config.assetMediaImageUrl : '';
      this.cardQuestionSource = this.config.data.config.hasOwnProperty('cardQuestionSource') ? this.config.data.config.cardQuestionSource : false;

      if (this.cardQuestionSource) 
        this.cardQuestionSourceValue = this.elements[0].element[this.cardQuestionSource];
      
      document.getElementsByTagName("body")[0].classList.remove("finish");
      this.checkAudioButtonDelay();
    },

    onNext: async function() {
      var _this = this,
          addedDelay = 0;
      this.hideAudioAndNotify = true;
      this.isCardMoved = false;
      this.isDisableClick = true;
      this.changeBorder(0);
      setTimeout(function() {
        _this.callHideAnimation = true;
      }, 600);
      setTimeout(function() {
        _this.hideAudioAndNotify = false;
        _this.isCorrect = false;
        _this.wrongCounter = 0;
        _this.reInitialize();

        if (_this.progressValue === _this.stepsLength) {
          _this.isDisableNext = false;
          _this.isFinal = true;
          _this.isDisableClick = true;
          
          if (this.cardQuestionSource) {
            addedDelay = 800;
          }

          setTimeout(function() {
            document.getElementsByTagName("body")[0].classList.add("finish");
            _this.myHome.changeBackground(true);
          }, 100 + addedDelay);
          setTimeout(function() {
            _this.isReRotateStars = true;
          }, 300 + addedDelay);
          setTimeout(function() {
            _this.isDisableClick = false;
            _this.isReRotateStars = false;
          }, 2000 + addedDelay);
        }
        else {
          _this.getElements(false);

          _this.reinitialize = true;
          setTimeout(function() {
            _this.reinitialize = false;
          }, 1);
        }

        _this.callHideAnimation = false;
        _this.checkAudioButtonDelay();
      }, 2200);
    },

    onSubmit: function() {
       var _this = this,
          counter = false;
      this.isDisableClick = true;
      this.isCardMoved = false;
      for (let index = 0; index < this.answer.length; index++) {
        var newIndex = this.findItemIndex(index);
        if (this.answer[index].text != this.question[newIndex].text) {
          this.question[newIndex].border = 3;
          counter++;
        }
        else 
          this.question[newIndex].border = 2;

        this.question.splice(newIndex, 1, this.question[newIndex]);
      }

      if (!counter) {
        if (this.finalType.toLowerCase() === 'finalc') 
          this.finalBoardAnswers.push(this.elements[0].element)
        
        setTimeout(function() {
          _this.isCorrect = true;
        }, 300);
        setTimeout(function() {
          _this.progressValue++;
        }, 1600);

        setTimeout(function() {
          _this.flipClass = true;
          _this.isDisableNext = false;
          setTimeout(function() {
            _this.isDisableClick = false;
          }, 300);
        }, 2600);
      }
      else {
        this.isDisableClick = false;
        this.isDisableNext = true;
        setTimeout(function() {
          _this.wrongCounter++;
        }, 300);
      }
      
      setTimeout(function() {
        _this.checkAudioButtonDelay();
      }, 800);
    },

    checkAudioButtonDelay: function() {
      if (this.showCardAudioButton && this.wrongCounter >= this.showCardAudioButtonDelay) 
        this.isShowCardAudioButton = true;
      else
        this.isShowCardAudioButton = false;
    },

    changeBorder: function(border) {
      for (let index = 0; index < this.answer.length; index++) {
        this.question[index].border = border;
        this.question.splice(index, 1, this.question[index]);
      }
    },

    onReset: function() {
      var _this = this;
      this.reInitialize();
      this.progressValue = 0;
      this.isFinal = false;
      this.finalBoardAnswers = [];
      this.wrongCounter = 0;
      this.getElements();
      document.getElementsByTagName("body")[0].classList.remove("finish");
    },

    reInitialize: function() {
      this.flipClass = false;
      this.isDisableNext = true;
      this.numOfSelected = 0;
      this.isDisableClick = false;
    },

    findItemIndex: function(index) {
      return this.question.findIndex(obj => {
        return obj.index === index
      })
    },

    getElements: async function(isStart = true) {
      var _this = this;
      this.disableCard = true;
      this.question = [];
      this.answer = [];
      this.elements = [];
      this.cardRelation = [];
      
      this.elements = await OrdoModel.getElements(1, isStart);

      for (let index = 0; index < this.elements[0].question.length; index++) {
        this.question.push({'text': this.elements[0].question[index]});
        this.answer.push({'text': this.elements[0].answer[index]});
      }

      if (this.elements[0].hasOwnProperty('relation')) 
        this.cardRelation = Array.from(this.elements[0].relation);

      if (this.cardQuestionSource) 
        this.cardQuestionSourceValue = this.elements[0].element[this.cardQuestionSource];

      this.correctWord = this.elements[0].element[this.cardAudioSource];
      OrdoModel.debug();

      return isStart;
    },
  },
  created: async function () {
    var _this = this;
    try {
      await OrdoModel.populateElements(this.config);
    }
    catch (err) { // need to catch error or rejected promise
      console.error(err);
    }
    await this.getElements();
    this.initialize();
    this.isParentReady = true;
  },
  watch: {
    isCardMoved: function(data) {
      if (data) {
        this.isDisableNext = false;
        for (let index = 0; index < this.question.length; index++) {
          this.question[index].border = 0;
          this.question.splice(index, 1, this.question[index]);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";
.ordo {
  height: 100%;
  display: flex;
  justify-content: normal;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 1090px) {
  .select {
    .body-container {
      .cards {
        ul {
          li {
            width: 201px;
            height: 103px;
          }
        }
      }
    }
  }
}
</style>
