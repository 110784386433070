<template>
  <div class="ortho-layout">
    <div
      v-if="isDocumentReady"
      class="answer-container"
      :class="{rotate: rotateAnswerContainer}"
      :style="[
        {'background-color': backgroundColorAnswerContainer},
        isAnswerContainerShow ?
          {opacity: 1}
        : {opacity: 0},
      ]"
    >
      <Notify
        v-if="isShowCheck"
        addedClass="ortho"
        :hasBorder="true"
      >
      </Notify>
      <div 
        class="correct-bg"
        :style="[
          isCorrect ? 
            {backgroundColor: correctBorderColor, opacity: 1}
          : {opacity: 0},
        ]"
      ></div>

      <AudioA
        v-if="cardAudioType === 'mp3' && cardAudioSource"
        :messageAudioFile="answerSoundFile"
        classAdded="ortho"
        :isDataSound="true"
        :assetMediaAudioUrl="assetMediaAudioUrl"
      >
      </AudioA>
      <AudioB
        v-else-if="cardAudioType && cardAudioSource"
        classAdded="ortho"
        :correctWord="answerSoundFile"
        :audioType="cardAudioType"
      >
      </AudioB>
      
      <template v-for="(data, index) in getOrthoArrayData">
        <CardE
          v-if="data.value != ' '"
          addedClass="ortho"
          :key="'letter' + data.index"
          :data="data.value"
          :index="index"
          :dataIndex="data.index"
          :isSelected="data.isSelected"
          :isWrong="data.isWrong"
          :isCorrect="data.isCorrect"
          :isComplete="data.isComplete"
          :backgroundColorWrong="backgroundColorWrong"
          :correctBorderColor="correctBorderColor"
          :selectedBorderColor="selectedBorderColor"
          :wrongBorderColor="wrongBorderColor"
          :cardBorderColor="cardBorderColor"
          :cardFontColor="cardFontColor"
          :cardFontSize="cardFontSize"
          :cardHeight="cardHeight"
          :cardPaddingTop="cardPaddingTop"
          :cardPaddingBottom="cardPaddingBottom"
          :cardPaddingHeight="cardPaddingHeight"
          :cardPaddingWidth="cardPaddingWidth"
          :cardWidth="cardWidth"
          :displaySource="displaySource"
          :displayType="displayType"
          :retroBorderSize="retroBorderSize"
          v-on:click="onSelect('answer-letter-' + index)"
          :cardMarginWidth="cardMarginWidth"
        >
        </CardE>
        <div
          v-else class="empty-box"
          :key="'space' + index"
          :style="[
            cardHeight ? 
              {height: cardHeight}
            : '',
            cardWidth ? 
              {width: cardWidth}
            : '',
          ]"
        ></div>
      </template>
    </div>

    <Keyboard
      v-if="isDocumentReady"
      :keyboardFadeIn="keyboardFadeIn"
    >
    </Keyboard>
    
  </div>
</template>

<script>
import Vue from 'vue'
import Notify from '../components/Notify'
import Keyboard from '../components/Keyboard'
import CardE from '../components/CardE'
import AudioA from '../components/AudioA'
import AudioB from '../components/AudioB'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OrthoLayoutA',
  components: {
    'Notify': Notify,
    'Keyboard': Keyboard,
    'CardE': CardE,
    'AudioA': AudioA,
    'AudioB': AudioB,
  },
  props: [
    "isCorrect",
    "backgroundColorAnswerContainer",
    "isAnswerContainerShow",
    "isShowCheck",
    "answerSoundFile",
    "answer",
    "assetMediaAudioUrl",
    "isDocumentReady",
    "backgroundColorWrong",
    "correctBorderColor",
    "selectedBorderColor",
    "wrongBorderColor",
    "cardBorderColor",
    "cardAudioSource",
    "cardAudioType",
    "cardFontColor",
    "cardFontSize",
    "cardHeight",
    "cardPaddingTop",
    "cardPaddingBottom",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "cardWidth",
    "displaySource",
    "displayType",
    "retroBorderSize",
    "rotateAnswerContainer",
    "keyboardFadeIn",
    "cardMarginWidth",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
      counter: 0,
    }
  },
  computed: {
    ...mapGetters(['getOrthoArrayData']),
  },
  methods: {
    ...mapActions([
      'setOrthoArrayData',
      'setOrthoArrayDataByElem',
      'setOrthoArrayDataResetIsSelected'
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.ortho-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .answer-container {
    min-height: 90px;
    padding-top: 9px;
    padding-bottom: 9px;
    height: auto;
    padding-left: 24px;
    padding-right: 19px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-top: -4px;
    position: relative;
    transition: all 0.6s ease-in-out;

    &:not(.rotate) {
      animation-name: rotate_bounce;
      animation-duration: 0.4s;
      animation-delay: 0.4s;
    }
    
    &.rotate {
      transform: rotateX(180deg);
    }
    .correct-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 50px;
      transition: opacity 0.6s ease-in-out;
    }
    .answer-letter {
      width: 72px;
      height: 72px;
      font-size: 45px;
      margin: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      &:nth-of-type(2) {
        margin-left: 16px;
      }

      &:last-of-type {
        margin-right: 6px;
      }

      &.completed, &.done, &.correct.disable {
        .letter-value {
          box-shadow: none;
        }
      }

      &.correct.disable .letter-value {
        background-color: #c5eaf9;
      }

      &.selected .letter-value {
        border-color: #45454e;
      }

      &.correct .letter-value {
        border-color: #409924;
        cursor: default;
      }

      &.wrong .letter-value {
        border-color: #db0020;
      }

      &.done .letter-value {
        pointer-events: none;
        cursor: default;
        border: none !important;
      }
    
      &.correct, &.wrong, &.selected {
        .letter-value {
          border-style: solid;
          border-width: 6px;
          transition: all 0.3s ease;
        }
      }

      .letter-value {
        width: 59px;
        height: 56px;
        padding-left: 1px;
        padding-bottom: 4px;
        padding-top: 0px;
        margin-bottom: 8px;
        background-color: white;
        box-sizing: content-box;
        border-radius: 15px;
        box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        cursor: pointer;
        align-items: flex-start;
      }

      .empty-box {
        width: 72px;
        height: 72px;
        z-index: 1;
      }
    }
      
    .answer-sound {
      &.sound {
        cursor: pointer;
        height: 40px;
        width: 40px;
        z-index: 1;

        img {
          width: 40px;
          height: 48px;
          z-index: 1;
        }
      }
    }
  }
  .show {
    display: block !important;
  }
  .hide {
    display: none !important;
  }
}

.ipad-version {
  .answer-container {
    min-height: 72px;
    padding-left: 17px;
    padding-right: 12px;

    .answer-letter {
      width: 61px;
      height: 61px;
      font-size: 35px;
      margin: 0 2.5px;

      &:nth-of-type(2) {
        margin-left: 5px;
      }

      .letter-value {
        width: 49px;
        height: 48px;
        align-items: baseline;
        padding: 1px 0 0 0;
        margin-bottom: 7px;
      }
    }
  }
}
.ipad {
  .ipad-version {
    .answer-container {
      .answer-letter {
        .letter-value {
          height: 46px;
          padding: 3px 0 0 0;
        }
      }
    }
  }

  .answer-container {
    .answer-letter {
      .letter-value {
        height: 54px;
        padding-top: 2px;
      }
    }
  }
}

.mac {
  .ipad-version {
    .answer-container {
      .answer-letter {
        .letter-value {
          height: 45px;
          padding: 4px 0 0 0;
        }
      }
    }
  }

  .answer-container {
    .answer-letter {
      .letter-value {
        height: 53px;
        padding-top: 3px;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate_bounce {
  0% {
    transform: rotateX(-45deg);
  }
  50% {
    transform: rotateX(30deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
</style>
