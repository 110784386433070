<template>
  <div class="class-layout">
    <SetLayout
      :dropLeftContent="dropLeftContent"
      :dropRightContent="dropRightContent"
      :themeLocation="themeLocation"
      :themeName="themeName"
      :leftSetIsCorrect="leftSetIsCorrect"
      :rightSetIsCorrect="rightSetIsCorrect"
      :fadeOutContent="fadeOutContent"
      :leftSetItems="leftSetItems"
      :rightSetItems="rightSetItems"
      :cardSinkWidth="cardSinkWidth"
      :cardSinkHeight="cardSinkHeight"
      :titleSetOne="titleSetOne"
      :titleSetTwo="titleSetTwo"
      :setFontColor="setFontColor"
      :setFontSize="setFontSize"
      :removeTrans="removeTrans"
      :borderColor="borderColor"
      :isFinalFadein="isFinalFadein"
    >
    </SetLayout>
    <div class="cards">
      <div 
        class="card"
        v-for="(item, index) in items"
        :key="index"
        :class="{disable: item.disable}"
        :currentIndex="index"
        :postion-x="item.x"
        :postion-y="item.y"
        :default-pos="item.d"
        :default-x="item.defaultX"
        :default-y="item.defaultY"
        :prev-guide-type="item.prevGuideType"
        :prev-guide-index="item.prevGuideIndex"
        @mousedown="mouseDown"
        @mouseup="mouseUp"
        @touchstart="touchStart"
        @touchend="touchEnd"
        @touchmove="touchMove"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
        :style="[
          {transform: 'translate3d(' + item.x + 'px, ' + item.y + 'px, 0px)'},
          cardFontColor ?
            {color: cardFontColor}
          : '',
          cardFontSize ?
            {fontSize: cardFontSize}
          : '',
          fadeOutContent ? 
            {opacity: '0', transition: 'all 0.6s ease'}
          : '',
          removeTrans ?
            {transition: 'none 0s ease'}
          : '',
          leftSetIsCorrect && rightSetIsCorrect ? 
            {pointerEvents: 'none'} 
          : '',
        ]"
      >
        <CardD
          :sink="item.sink"
          :cardPaddingHeight="cardPaddingHeight"
          :cardPaddingWidth="cardPaddingWidth"
          :isShowCard="isShowCard"
          :backgroundDisable="item.disable"
          :backgroundColorWrong="backgroundColorWrong"
          :backgroundColorCard="backgroundColorCard"
          :backgroundColorDisable="backgroundColorDisable"
          :borderColor="borderColor"
          :cardBorderSize="cardBorderSize"
          :item="item.element"
          :cardContentPosition="cardContentPosition"
          :displaySource="displaySource"
          :displayType="displayType"
          :assetMediaImageUrl="assetMediaImageUrl"
          :isCorrect="leftSetIsCorrect && rightSetIsCorrect ? true : false"
        >
        </CardD>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Velocity from 'velocity-animate'
import SetLayout from '../components/SetLayout'
import CardD from '../components/CardD'
import Util from '../helpers/Util'

export default {
  name: 'ClassLayoutA',
  components: {
    'SetLayout': SetLayout,
    "CardD": CardD,
  },
  props: [
    "items",
    "leftSetItems",
    "rightSetItems",
    "assetMediaImageUrl",
    "themeLocation",
    "themeName",
    "cardFontColor",
    "cardFontSize",
    "cardPaddingHeight",
    "cardPaddingWidth",
    "displaySource",
    "displayType",
    "setFontColor",
    "setFontSize",
    "cardBorderSize",
    "backgroundColorWrong",
    "backgroundColorCard",
    "backgroundColorDisable",
    "borderColor",
    "rightSetIsCorrect",
    "leftSetIsCorrect",
    "reinitialize",
    "callHideAnimation",
    "cardContentPosition",
    "titleSetOne",
    "titleSetTwo",
    "isFinalFadein",
  ],
  data () {
    return {
      myComponent: this.$parent.myComponent,
      myHome: this.$parent.myHome,
      setReducePercentage: '0.5985',
      cardSinkWidth: 0,
      cardSinkHeight: 0,
      pos1: false,
      pos2: false,
      pos3: false,
      pos4: false,
      isMouseMove: false,
      isMouseMoved: false,
      oldMouseX: false,
      oldMouseY: false,
      cardMargin: 23,
      contentOffsetDown: 0,
      contentOffsetTop: 0,
      leftContentOffsetLeft: 0,
      leftContentOffsetRight: 0,
      rightContentOffsetLeft: 0,
      rightContentOffsetRight: 0,
      dropLeftContent: false,
      dropRightContent: false,
      counterShowGuideCardLeft: true,
      counterShowGuideCardRight: true,
      guideCardIndexLeft: null,
      guideCardIndexRight: null,
      cardHeight: false,
      isShowCard: false,
      isAddTransCard: false,
      fadeOutContent: false,
      isDisable: false,
      currentIndex: null,
      isTouch: false,
      onResizing: null,
      removeTrans: false,
    }
  },
  mounted() {
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    initialize: async function() {
      var _this = this;
      this.isShowCard = false;
      this.enableShowAnimate = false;
      this.enableSwitchAnimate = false;
      this.itemsSizeContainer = [];
      this.onResizing = null;

      if (this.myHome.isIpadOrIphone) 
        this.setReducePercentage = 0.7;
      
      await Util.setWait(function () {
        _this.initializeCard();
      }, 600);
    },

    onResize: async function() {
      var _this = this,
          items = document.getElementsByClassName("card");
          this.itemsSizeContainer = [];
          this.removeTrans = true;
          this.myComponent.onResizeScreen = true;

      await Util.setWait(function () {
        if (_this.myHome.isIpadOrIphone) {
          _this.onResizing = true;
          _this.setReducePercentage = 0.7;
          _this.initializeCard()
          _this.initializeCalendarContent();
        }
        else {
          _this.onResizing = false;
          _this.setReducePercentage = 0.5985;
          _this.initializeCard()
          _this.initializeCalendarContent();
        }
          
        for (let index = 0; index < _this.items.length; index++) {
          var guideIndex = _this.items[index].guideIndex;
          if (_this.items[index].guideType == 'one') {
            _this.items[index].x = _this.leftSetItems[guideIndex].x;
            _this.items[index].y = _this.leftSetItems[guideIndex].y;
            _this.items[index].dx = _this.leftSetItems[guideIndex].x;
            _this.items[index].dy = _this.leftSetItems[guideIndex].y;
          }
          else if (_this.items[index].guideType == 'two') {
            _this.items[index].x = _this.rightSetItems[guideIndex].x;
            _this.items[index].y = _this.rightSetItems[guideIndex].y;
            _this.items[index].dx = _this.rightSetItems[guideIndex].x;
            _this.items[index].dy = _this.rightSetItems[guideIndex].y;
          }
          
        }
        _this.myComponent.onResizeScreen = true;
      }, 1);
      
      await Util.setWait(function () {
        _this.myComponent.onResizeScreen = true;
        _this.removeTrans = false;
      }, 100);
      
    },

    initializeCard: async function() {
      
      if (this.myHome.isIpadOrIphone) 
        this.cardMargin = 19;
        
      var halfTotalSizeNegative = 0, 
          itemsLength = this.items.length,
          totalSize = this.calculateItemSize(),
          _this = this;
      halfTotalSizeNegative = -(totalSize / 2);

      for (let index = 0; index < itemsLength; index++) {
        var value = !index ? halfTotalSizeNegative : halfTotalSizeNegative + (this.itemsSizeContainer[index-1] + this.cardMargin);
        halfTotalSizeNegative = value;
        value = value + (this.itemsSizeContainer[index] / 2);
        
        this.items[index].x = value;
        this.items[index].y = 0;
        this.items[index].dy = 0;
        this.items[index].dx = value;
        this.items[index].defaultX = value;
        this.items[index].defaultY = 0;

        if (this.onResizing == null) {
          this.items[index].border = 0;
          this.items[index].guideType = null;
        }
        this.items[index].size = this.itemsSizeContainer[index];
        this.items.splice(index, 1, this.items[index]);
      }

      if (this.onResizing == null) {
        await Util.setWait(function () {
          _this.initializeCalendarContent();
        }, 50);
        this.showCardsAnimation();
      }
    },

    calculateItemSize: function() {
      var items = document.getElementsByClassName("card"),
          total = 0;

        if (!this.myHome.isIpadOrIphone) {
          this.cardWidth = 238;
          this.cardHeight = 113;
        }
        else {
          this.cardWidth = 204;
          this.cardHeight = 98;
        }

        this.cardSinkWidth = this.cardWidth * this.setReducePercentage;
        this.cardSinkHeight = this.cardHeight * this.setReducePercentage;

      for (let index = 0; index < items.length; index++) {
        var value = this.cardWidth,
            addedVal = this.cardMargin;
        if (index == (items.length -1)) 
          addedVal = 0;

        this.itemsSizeContainer.push(value);
        total += value + addedVal;
      }
      return total;
    },

    initializeCalendarContent: function() {
      var offsetHeightHalf = 0,
          offsetWidthHalf = 0,
          tempContent = document.getElementsByClassName('content'),
          leftContent = tempContent[0],
          rightContent = tempContent[1],
          leftCalendar = document.getElementsByClassName('left-calendar')[0],
          cardsOffset = document.getElementsByClassName('cards')[0],
          cardWidth = leftContent.offsetWidth,
          cardHalfWidth = cardWidth + 11,
          guideCardLeft = document.getElementsByClassName('guide-card-left'),
          guideCardRight = document.getElementsByClassName('guide-card-right'),
          guideContent = 0;
      
      this.contentOffsetDown = cardsOffset.offsetTop - ((leftCalendar.offsetTop + leftContent.offsetTop) + leftContent.offsetHeight);
      this.contentOffsetTop = this.contentOffsetDown + leftContent.offsetParent.offsetHeight;
      guideContent = -(this.contentOffsetDown + leftContent.offsetHeight);

      this.contentOffsetDown = -(this.contentOffsetDown);
      this.contentOffsetTop = -(this.contentOffsetTop);

      this.leftContentOffsetLeft = -(cardHalfWidth);
      this.leftContentOffsetRight = -(cardHalfWidth) + cardWidth;

      this.rightContentOffsetLeft = -(this.leftContentOffsetRight);
      this.rightContentOffsetRight = -(this.leftContentOffsetLeft);
      
      offsetHeightHalf = (this.cardHeight - this.cardSinkHeight) / 2;
      offsetWidthHalf = this.cardSinkWidth / 2;

      this.leftSetItems = this.setupSetItems(this.leftSetItems, guideContent, this.leftContentOffsetLeft, offsetHeightHalf, offsetWidthHalf, guideCardLeft);
      this.rightSetItems = this.setupSetItems(this.rightSetItems, guideContent, this.rightContentOffsetLeft, offsetHeightHalf, offsetWidthHalf, guideCardRight);
    },

    setupSetItems: function(setItems, contentOffsetTop, contentOffsetLeft, offsetHeightHalf, offsetWidthHalf, guideCard) {
      for (let index = 0; index < setItems.length; index++) {
        var y = (contentOffsetTop + guideCard[index].offsetTop) - offsetHeightHalf,
            x = ((contentOffsetLeft + guideCard[index].offsetLeft) + offsetWidthHalf);

        setItems[index].y = y;
        setItems[index].x = x;
        setItems[index].dx = x;
        if (this.onResizing == null) {
          setItems[index].show = 0;
          setItems[index].hideBorder = 0;
        }
        setItems.splice(index, 1, setItems[index]);
      }

      return setItems;
    },

    backDefaultPos: async function(event) {
      if (this.isDisable || !this.isMouseMoved) 
        return false;

      var currentIndex, 
          tempPrevGuideType = null,
          tempPrevGuideIndex = null,
          _this = this;
          
      this.isMouseMoved = false;
      this.elementItem = event.target.parentElement;
      this.isDisable = true;
      tempPrevGuideType = this.elementItem.getAttribute("prev-guide-type");
      tempPrevGuideIndex = this.elementItem.getAttribute("prev-guide-index");
      currentIndex = parseInt(this.elementItem.getAttribute("currentIndex"));


      if (this.dropLeftContent || this.dropRightContent) {
        var tempGuideIndex = this.guideCardIndexLeft,
            tempGuideType = '';
        if (this.dropLeftContent && this.guideCardIndexLeft != null) {
          tempGuideIndex = this.guideCardIndexLeft;
          tempGuideType = 'one';
        }
        else if (this.guideCardIndexRight != null) {
          tempGuideIndex = this.guideCardIndexRight;
          tempGuideType = 'two';
        }
        for (let index = 0; index < this.items.length; index++) {
          if (this.items[index].guideType == tempGuideType && 
              this.items[index].prevGuideIndex != this.tempGuideIndex && 
              this.items[index].myIndex != currentIndex && 
              this.items[index].guideIndex == this.tempGuideIndex) 
            this.dropLeftContent = this.dropLeftContent = false; 
        }
      }

      if (this.dropLeftContent || this.dropRightContent) {
        if (this.dropLeftContent && this.guideCardIndexLeft != null) {
          var tempIndex = this.guideCardIndexLeft;
          this.counterShowGuideCardLeft = true;
          this.items[currentIndex].x = this.leftSetItems[this.guideCardIndexLeft].dx;
          this.items[currentIndex].dx = this.leftSetItems[this.guideCardIndexLeft].dx;
          this.items[currentIndex].y = this.leftSetItems[this.guideCardIndexLeft].y;
          this.items[currentIndex].dy = this.leftSetItems[this.guideCardIndexLeft].y;
          this.items[currentIndex].sink = true;
          this.items[currentIndex].guideIndex = this.guideCardIndexLeft;
          this.items[currentIndex].prevGuideIndex = this.guideCardIndexLeft;
          this.items[currentIndex].guideType = 'one';
          this.items[currentIndex].prevGuideType = 0;

          setTimeout(function() {
            _this.leftSetItems[tempIndex].hideBorder = 1;
            _this.leftSetItems.splice(tempIndex, 1, _this.leftSetItems[tempIndex]);
          }, 600);
        }
        else if (this.dropRightContent && this.guideCardIndexRight != null) {
          var tempIndex = this.guideCardIndexRight;
          this.counterShowGuideCardRight = true;
          this.items[currentIndex].x = this.rightSetItems[this.guideCardIndexRight].dx;
          this.items[currentIndex].dx = this.rightSetItems[this.guideCardIndexRight].dx;
          this.items[currentIndex].y = this.rightSetItems[this.guideCardIndexRight].y;
          this.items[currentIndex].dy = this.rightSetItems[this.guideCardIndexRight].y;
          this.items[currentIndex].sink = true;
          this.items[currentIndex].guideIndex = this.guideCardIndexRight;
          this.items[currentIndex].prevGuideIndex = this.guideCardIndexRight;
          this.items[currentIndex].guideType = 'two';
          this.items[currentIndex].prevGuideType = 1;

          setTimeout(function() {
            _this.rightSetItems[tempIndex].hideBorder = 1;
            _this.rightSetItems.splice(tempIndex, 1, _this.rightSetItems[tempIndex]);
          }, 600);
        }
        
        this.guideCardIndexLeft = null;
        this.guideCardIndexRight = null;
      }
      else {
        this.items[currentIndex].guideIndex = null;
        this.items[currentIndex].guideType = null;
        this.items[currentIndex].prevGuideIndex = null;
        this.items[currentIndex].prevGuideType = null;
        this.items[currentIndex].x = this.items[currentIndex].defaultX;
        this.items[currentIndex].y = this.items[currentIndex].defaultY;

        this.guideCardIndexLeft = null;
        this.guideCardIndexRight = null;
      }
      
      this.items[currentIndex].myIndex = currentIndex;
      this.items.splice(currentIndex, 1, this.items[currentIndex]);

      this.isMouseMove = false;

      event.target.parentElement.classList.add('switch-animation');

      setTimeout(function(){
        event.target.parentElement.classList.remove('dragging');
      }, 450);

      setTimeout(function() {
        event.target.parentElement.classList.remove('switch-animation');
        _this.dropLeftContent = false;
        _this.dropRightContent = false;
        _this.isDisable = false;
      }, 650);

      return true;
    },

    mouseOver: function(e) {
      if (e.target.classList.contains('card')) {
        e.target.classList.add('back');
        e.target.classList.remove('front');
      }
    },

    mouseOut: function(e) {
      if (e.target.classList.contains('card')) 
        e.target.classList.remove('back');
    },

    touchStart: function(e) {
      this.isTouch = true;
      this.dragStart(e);
    },

    touchEnd: function(e) {
      this.isTouch = false;
      this.backDefaultPos(e);
    },

    touchMove: function(e) {
      this.dragMove(e);
    },
    
    mouseDown: function(e) {
      if (!e.target.classList.contains('card')) 
        this.dragStart(e);
      else
        return false
    },

    mouseUp: function(e) {
      this.backDefaultPos(e);
    },

    onMouseUp: function(e) {
      this.isMouseMove = false;
    },

    onMouseMove: function(e) {
      this.dragMove(e);
    },

    dragStart: function(e) {
      if (this.isDisable)
        return false;

      var tempE = e;
      if (this.isTouch) 
        tempE = e.changedTouches[0];
      this.elementItem = e;
      this.pos3 = tempE.clientX;
      this.pos4 = tempE.clientY;
      e.target.parentElement.classList.remove('switch-animation');
      this.isMouseMove = true;
      
    },

    dragMove: function(e) {
      if (this.isMouseMove) {
        var tempE = e,
            transform = "",
            comparedItemIndex = 0,
            xCounter = 0,
            tempArray = {}, 
            Xpos = parseInt(this.elementItem.target.parentElement.getAttribute("postion-x")), 
            Ypos = parseInt(this.elementItem.target.parentElement.getAttribute("postion-y")), 
            Dpos = parseInt(this.elementItem.target.parentElement.getAttribute("default-pos")),
            currentIndex = parseInt(this.elementItem.target.parentElement.getAttribute("currentIndex")),
            thisIndex = parseInt(this.elementItem.target.parentElement.getAttribute("itemIndex")),
            tempPrevGuideType = this.elementItem.target.parentElement.getAttribute("prev-guide-type"),
            tempPrevGuideIndex = this.elementItem.target.parentElement.getAttribute("prev-guide-index"),
            screenLimitData = {};
          
      if (this.isTouch) 
        tempE = e.changedTouches[0];

        this.isMouseMoved = true;
        this.elementItem.target.parentElement.classList.add('dragging');
        this.items[currentIndex].sink = 0;
        this.dropLeftContent = false;
        this.dropRightContent = false;
        // calculate the new cursor position:
        this.calculateCursorPos(e);
        // set the element's new position:
        tempArray = this.calculateElementPos(Xpos, Ypos);

        Xpos = tempArray.Xpos;
        Ypos = tempArray.Ypos;

        this.resetGuideItem(currentIndex);
          
        this.checkMouseScreenPos(Ypos, Xpos, tempPrevGuideIndex, tempPrevGuideType);

        screenLimitData = this.setScreenLimit(e, Xpos, Ypos, currentIndex);

        if (!screenLimitData) 
          return;

        Xpos = screenLimitData.Xpos;
        Ypos = screenLimitData.Ypos;

        this.items[currentIndex].x = Xpos;
        this.items[currentIndex].y = Ypos;
        this.items.splice(currentIndex, 1, this.items[currentIndex]);
        this.items.splice(comparedItemIndex, 1, this.items[comparedItemIndex]);

        this.oldMouseX = tempE.pageX;
        this.oldMouseY = tempE.pageY;
      }
      e.preventDefault();
    },

    resetGuideItem: function(currentIndex ) {
      var guideIndex = this.items[currentIndex].guideIndex;

      if (this.items[currentIndex].guideType != null) {
        if (this.items[currentIndex].guideType === 'one') {
          this.leftSetItems[guideIndex].show = 0;
          this.leftSetItems[guideIndex].hideBorder = 0;
          this.leftSetItems.splice(guideIndex, 1, this.leftSetItems[guideIndex]);
        }
        else {
          this.rightSetItems[guideIndex].show = 0;
          this.rightSetItems[guideIndex].hideBorder = 0;
          this.rightSetItems.splice(guideIndex, 1, this.rightSetItems[guideIndex]);
        }
        this.items[currentIndex].guideType = null;
      }
      else 
        this.resetGuideCards();
    },

    checkMouseScreenPos: function(Ypos, Xpos, tempPrevGuideIndex, tempPrevGuideType) {
      var halfHeightCard = this.elementItem.target.parentElement.clientHeight / 2;

      if (Ypos < this.contentOffsetDown - halfHeightCard && Ypos > this.contentOffsetTop - halfHeightCard) {
        this.showGuideItem(Xpos, tempPrevGuideType, tempPrevGuideIndex);
        this.prevGuideItem(tempPrevGuideIndex, tempPrevGuideType);
      } 
      else {
        this.dropRightContent = false;
        this.dropLeftContent = false;
      }
    },

    showGuideItem: function(Xpos, tempPrevGuideType, tempPrevGuideIndex) {
      var data = {};
      data = this.contentPosCheck(
        Xpos, this.leftContentOffsetLeft, 
        this.leftContentOffsetRight, 
        this.dropLeftContent, 
        this.counterShowGuideCardLeft, 
        this.leftSetItems, 
        this.guideCardIndexLeft,
        tempPrevGuideIndex,
        tempPrevGuideType,
        '0'
      );

      if (data.setItem) {
        this.dropLeftContent = data.dropContent;
        this.counterShowGuideCardLeft = data.counterShowGuideCard;
        this.guideCardIndexLeft = data.guideCardIndex;
        this.leftSetItems.splice(this.guideCardIndexLeft, 1, data.setItem);
      }
      
      data = this.contentPosCheck(
        Xpos, this.rightContentOffsetLeft, 
        this.rightContentOffsetRight, 
        this.dropRightContent, 
        this.counterShowGuideCardRight, 
        this.rightSetItems, 
        this.guideCardIndexRight,
        tempPrevGuideIndex,
        tempPrevGuideType,
        '1'
      );
      
      if (data.setItem) {
        this.dropRightContent = data.dropContent;
        this.counterShowGuideCardRight = data.counterShowGuideCard;
        this.guideCardIndexRight = data.guideCardIndex;
        this.rightSetItems.splice(this.guideCardIndexRight, 1, data.setItem);
      }
    },

    contentPosCheck: function(pos, contentOffsetLeft, contentOffsetRight, dropContent, counterShowGuideCard, setItems, guideCardIndex, tempPrevGuideIndex, tempPrevGuideType, calendarType) {
      var tempIndex = null,
          setItem = false;
          
      if (pos > contentOffsetLeft && pos < contentOffsetRight) {
        dropContent = true;
        if (counterShowGuideCard) {
          for (let index = 0; index < setItems.length; index++) {
            if (tempPrevGuideType == calendarType) {
              if (tempPrevGuideIndex == index) {
                setItems[index].show = 1;
                setItems.splice(index, 1, setItems[index]);
                setItem = setItems[index];
                guideCardIndex = index;
                break;
              }
            }
            else if (!setItems[index].show) {
              setItems[index].show = 1;
              setItems.splice(index, 1, setItems[index]);
              setItem = setItems[index];
              guideCardIndex = index;
              break;
            }
          }
          counterShowGuideCard = false;
        }
      }

      return {
        'dropContent': dropContent, 
        'counterShowGuideCard': counterShowGuideCard, 
        'guideCardIndex': guideCardIndex, 
        'setItem': setItem
      };
    },

    prevGuideItem: function(tempPrevGuideIndex, tempPrevGuideType) {
      if (!this.dropLeftContent && !this.dropRightContent) {
        this.resetGuideCards();
        
        if (tempPrevGuideIndex != null) {
          this.dropLeftContent = null;
          this.dropRightContent = null;
        }
      }
    },

    setScreenLimit: function(e, Xpos, Ypos, currentIndex) {
      var tempE = e,
          halftSizeCardWidth = 0,
          fullSizeCardHeight = 60,
          screenWidthLimit1 = document.getElementsByClassName("right-container")[0].clientWidth / 2,
          screenWidthLimit2 = -(document.getElementsByClassName("right-container")[0].clientWidth / 2),
          screenHeightLimit1 = document.getElementsByClassName("right-container")[0].clientHeight / 2,
          screenHeightLimit2 = -(document.getElementsByClassName("right-container")[0].clientHeight / 2),
          cards = document.getElementsByClassName("cards"),
          cardsNewYpos = 0,
          xCounter = 0,
          yCounter = 0,
          isLimit = false;

      if (this.isTouch) 
        tempE = e.changedTouches[0];

      fullSizeCardHeight = this.cardHeight;

      cardsNewYpos = document.getElementsByClassName("cards")[0].offsetParent.clientHeight / 2;
      cardsNewYpos = document.getElementsByClassName("cards")[0].offsetTop - cardsNewYpos;
      cardsNewYpos = cardsNewYpos + (fullSizeCardHeight / 2);

      
      halftSizeCardWidth = (this.cardWidth / 2);
      fullSizeCardHeight = fullSizeCardHeight / 2;

      if (tempE.pageX > this.oldMouseX) {
        xCounter = 1;
        halftSizeCardWidth = -(halftSizeCardWidth);
      }
      else if (tempE.pageX < this.oldMouseX) {
        xCounter = 0;
      }
      
      if (tempE.pageY > this.oldMouseY) {
        yCounter = 1;
      }
      else if (tempE.pageY < this.oldMouseY) {
        yCounter = 0;
      }

      screenWidthLimit1 += halftSizeCardWidth;
      screenWidthLimit2 += halftSizeCardWidth;
      screenHeightLimit1 -= cardsNewYpos + (fullSizeCardHeight + 30);
      screenHeightLimit2 -= cardsNewYpos - (fullSizeCardHeight - 20);
      
      if (Xpos > screenWidthLimit1 && xCounter) {
        Xpos = screenWidthLimit1;
        isLimit = true;
      }
      else if (Xpos < screenWidthLimit2 && !xCounter) {
        Xpos = screenWidthLimit2;
        isLimit = true;
      }

      if (Ypos > screenHeightLimit1 && yCounter){
        Ypos = screenHeightLimit1;
        isLimit = true;
      }
      else if (Ypos < screenHeightLimit2 && !yCounter){
        Ypos = screenHeightLimit2;
        isLimit = true;
      }

      if (isLimit) 
        this.backDefaultPos(this.elementItem);
      else
        return {"Xpos": Xpos, "Ypos": Ypos};

      return false;
    },

    resetGuideCards: function () {
      this.dropLeftContent = false;
      this.dropRightContent = false;
      this.counterShowGuideCardLeft = true;
      this.counterShowGuideCardRight = true;

      if (this.guideCardIndexLeft != null) {
        this.leftSetItems[this.guideCardIndexLeft].show = 0;
        this.leftSetItems.splice(this.guideCardIndexLeft, 1, this.leftSetItems[this.guideCardIndexLeft]);
        this.guideCardIndexLeft = null;
      }
      if (this.guideCardIndexRight != null) {
        this.rightSetItems[this.guideCardIndexRight].show = 0;
        this.rightSetItems.splice(this.guideCardIndexRight, 1, this.rightSetItems[this.guideCardIndexRight]);
        this.guideCardIndexRight = null;
      }
    },
    
    calculateCursorPos: function(e) {
      var tempE = e;
      if (this.isTouch) 
        tempE = e.changedTouches[0];

      this.pos1 = this.pos3 - tempE.clientX;
      this.pos2 = this.pos4 - tempE.clientY;
      this.pos3 = tempE.clientX;
      this.pos4 = tempE.clientY;
    },
    
    calculateElementPos: function(Xpos, Ypos) {
      Xpos = Xpos - this.pos1;
      Ypos = Ypos - this.pos2;
      return {"Xpos": Xpos, "Ypos": Ypos};
    },

    showCardsAnimation: function() {
      this.isShowCard = true;
    },

    fadeOutCardsAnimation: function() {
      var _this = this;
      this.fadeOutContent = true;
      setTimeout(function() {
        _this.fadeOutContent = false;
      }, 1000);
    },
  },

  created: async function () {
    this.initialize();
  },

  watch: {
    reinitialize: function(data) {
      if (data) 
        this.initialize();
    },
    callHideAnimation: function(data) {
      if (data) 
        this.fadeOutCardsAnimation();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/animation";

.class-layout {
  padding-top: 2px;

  .cards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 113px;
    
    .card {
      position: absolute;
      width: 238px;
      height: 113px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: none;
      z-index: 10;

      &.switch-animation, &.show-animation {
        transition: all 600ms cubic-bezier(0.69, 0.43, 0.48, 1.18);
        z-index: 11;
      }

      &.back {
        z-index: 9;
      }

      &.front {
        z-index: 12;
      }

      &.disable {
        pointer-events: none;
      }

      &.dragging {
        z-index: 111;
      }
    }
  }
}

.ipad-version {
  .class-layout {
    .cards {
      height: 98px;
      .card {
        width: 204px;
        height: 98px;
      }
    }
  }
}
</style>
<style>
.card.dragging .card-content .border {
  box-shadow: 0px 10px 0px 2px rgba(0, 0, 0, 0.2);
}
.card.disable .card-content .border {
  box-shadow: none;
}
</style>
